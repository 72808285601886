const baseDomain = 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com'
import mayoga from '../images/mayoga.jpg'
import fullmoonCard from '../images/fullmoon.webp'

export const extraImage = {
  mayogaImg: `${mayoga}`,
}

export const background = {
  donation: '/backgrounds/donation_bg.jpg',
  facilities: '/backgrounds/facilities_bg.jpg',
  gifting: '/backgrounds/gifting_bg_1.jpg',
  museum: '/backgrounds/museum_bg.jpg',
  volunteer: '/backgrounds/volunteer_bg.jpg',
  faqs: '/backgrounds/faqs.jpg',
  alma: '/backgrounds/support-alma-mater.jpg',
  benefits: '/backgrounds/benifits.png',
  wellness: '/wellness/wellness.jpg',
  affiliations: '/backgrounds/affiliations.jpeg',
  contact: '/backgrounds/contact_us_bg.png',
}

export const certificates = {
  IAYT: '/cerificates/IAYT-logo.JPG',
  IYA: '/cerificates/IYA-logo.JPG',
  YCB: '/cerificates/YCB-logo.PNG',
  YAL: '/cerificates/Yoga-Alliance-logo.PNG',
}

export const socialInitiativeNew = {
  socialNew1: '/home_page/ShriYogendraj.webp',
  socialNew2: '/Home/Social%20Initiatives/new11/SocialPolice.jpg',
  socialNew3: '/SocialResponsibility/cover_image.jpg',
}

export const homeAssets = {
  homeAsset1: '/SEO-New-Images/Others/Madhavdasji.webp',
  homeAsset2: '/SEO-New-Images/Others/shriyogendraji-the-yoga-institute.webp',
  homeAsset3: '/SEO-New-Images/Others/Sitadevi.webp',
  homeAsset4: '/SEO-New-Images/Others/Jayadeva.webp',
  homeAsset5: '/SEO-New-Images/Others/Hansaji.webp',
  homeAsset6: '/home_page/Hrishi.webp',
  homeAsset7: '/home_page/7dayscamp_copy.webp',
  homeAsset8: '/Home/Offerings/21days.jpg',
  homeAsset9: '/Home/Offerings/200hrsTTC.jpg',
  homeAsset10: '/home_page/Childrencam_%20copy.webp',
  homeAsset11: '/Home/Offerings/900hrs.jpg',
  homeAsset12: '/SEO-New-Images/course/certificate-program-on-yoga-for-cancer-online.webp',
  homeAsset13: '/home_page/couple_copy.webp',
  homeAsset14: '/home_page/fullmoon.webp',
  fullmoonCard: fullmoonCard,
  homeAsset15: '/Home/Highlights/homeSession.jpg',
  homeAsset16: '/home_page/satvam.webp',
  homeAsset17: '/home_page/corporate_copy.webp',
  homeAsset18: '/home_page/cooking_copy.webp',
  homeAsset099: '/home_page/saatvik.png',
  homeAsset109: '../../StaticSite/assets/images/mayoga.jpg',
  homeAsset19: '/Home/Social Initiatives/SocialAnnamBhrama.jpg',
  homeAsset20: '/Home/Social Initiatives/SocialPolice.jpg',
  homeAsset21: '/Home/Social Initiatives/SocialBMCSchools.jpg',
  homeAsset22: '/Home/Nispand/google-play-logo-10628.png',
  homeAsset23: '/Home/Nispand/AppStore.png',
  homeAsset24: '/home_page/nispandApp.webp',
  homeAsset25: '/Home/Nispand/nispand-icon.png',
  homeAsset26: '/home_page/Web_site_banner.webp',
  homeAsset27: '/',
  homeAsset28: '/',
  homeAsset29: '/',
  homeAsset31: '/Home/Facts/1990-2000/1.jpg',
  homeAsset32: '/Home/Facts/1990-2000/2.jpg',
  homeAsset33: '/Home/Facts/1990-2000/3.jpg',
  homeAsset34: '/Home/Facts/1990-2000/4.jpg',
  homeAsset35: '/Home/Facts/1990-2000/5.jpg',
  homeAsset36: '/Home/Facts/1990-2000/6.jpg',

  homeAsset37: '/Home/Facts/2000-2010/1.jpg',
  homeAsset38: '/Home/Facts/2000-2010/2.jpg',
  homeAsset39: '/Home/Facts/2000-2010/3.jpg',
  homeAsset40: '/Home/Facts/2000-2010/4.jpg',
  homeAsset41: '/Home/Facts/2000-2010/5.jpg',
  homeAsset42: '/Home/Facts/2000-2010/6.jpg',

  homeAsset43: '/Home/Facts/2010-2020/1.jpg',
  homeAsset44: '/Home/Facts/2010-2020/2.jpg',
  homeAsset45: '/Home/Facts/2010-2020/3.jpg',
  homeAsset46: '/Home/Facts/2010-2020/4.jpg',
  homeAsset47: '/Home/Facts/2010-2020/5.jpg',
  homeAsset48: '/Home/Facts/2010-2020/6.jpg',

  homeAsset49: '/Home/Facts/2020-onwards/1.jpg',
  homeAsset50: '/Home/Facts/2020-onwards/2.jpg',
  homeAsset51: '/Home/Facts/2020-onwards/3.jpg',
  homeAsset52: '/Home/Facts/2020-onwards/4.jpg',
  homeAsset53: '/Home/Facts/2020-onwards/5.jpg',
  homeAsset54: '/Home/Facts/2020-onwards/6.jpg',

  // experience

  homeAsset55: '/home_page/shriramnathkovid.webp',
  homeAsset56: '/home_page/Modiji.webp',
  homeAsset57: '/Home/Experience/Naiduji.jpg',
  homeAsset58: '/home_page/AmitabhBachchan_copy.webp',

  // blogs

  homeAsset59: '/Home/Blogs/blog1.jpg',
  homeAsset60: '/Home/Blogs/Blog2.jpg',
  homeAsset61: '/Home/Blogs/Blog3.jpg',
  homeAsset62: '/Home/Blogs/Blog4.jpg',
  homeAsset63: '/Home/Blogs/Blog5.jpg',
  homeAsset71: '/Home/Blogs/new11/best-healty-fat-food.jpg',
  homeAsset72: '/Home/Blogs/new11/best-natural-anti-aging-techniques.jpg',
  homeAsset73: '/Home/Blogs/new11/best-yoga-mudras-to-get-rid-of-pimples-naturally-at-home.jpeg',
  homeAsset74: '/Home/Blogs/new11/blog-images-anaemia02.jpg',
  homeAsset75: '/Home/Blogs/new11/blog-images-backpain05.jpg',
  homeAsset76: '/Home/Blogs/new11/blog-images-immunity03.jpg',
  homeAsset77: '/Home/Blogs/new11/blog-images-liver01.jpg',
  homeAsset78: '/Home/Blogs/new11/blog-images-lung04.jpg',
  homeAsset79: '/Home/Blogs/new11/how-much-distance-we-should-walk-daily.jpeg',
  homeAsset81:
    '/Home/Blogs/new11/how-to-reduce-back-fat-naturally-by-yoga-home-remedies.jpeg',
  homeAsset82: '/Home/Blogs/new11/top-5-non-dairy-calcium-rich-foods.jpeg',
  homeAsset83: '/Home/Blogs/new11/womens-days.jpg',
  homeAsset84: '/Home/Blogs/new11/world-health-day.jpg',
  homeAsset85: '/Home/Blogs/new11/yoga-for-ear-balance-disorder-1-1.jpg',
  homeAsset86: '/Home/Blogs/new11/yoga-poses-to-do-befor-you-go-to-sleep.jpg',

  // videos

  homeAsset64: '/home_page/v1.webp',
  homeAsset65: '/home_page/v2.webp',
  homeAsset66: '/home_page/v3.webp',

  // newsletter
  homeAsset67: '/Home/NewsLetter/Yogasattva-jan.jpg',
  homeAsset68: '/Home/NewsLetter/Yogasattva-Feb.jpg',
  homeAsset69: '/Home/NewsLetter/Yogasattva-March.jpg',

  // footer

  homeAsset70: '/Home/Footer/tyi.png',


}

export const courseAssets = {
  courseAsset1: '/Courses/course (2).png',
  courseAsset2: '/Courses/courses-hero (1).jpg',
  courseAsset3: '/Courses/course (1).png',
  courseAsset4: '/Courses/detailImg.png',
  courseAsset5: '/Courses/courses_More_than_just_learning.jpg',
  // camps
  courseAsset6: '/SEO-New-Images/Courses/cardiac-_-hypertension-yoga-camp-the-yoga-institute.webp',
  courseAsset7: '/SEO-New-Images/Courses/diabetes-yoga-camp.webp',
  courseAsset8: '/SEO-New-Images/Courses/life-management-yoga-camp.webp',
  courseAsset9: '/Courses/Camps/Meditation_Camp.jpg',
  courseAsset10:
    '/SEO-New-Images/Courses/orthopaedic-yoga-camp-for-back-and-joint-disorders.webp',
  courseAsset11: '/SEO-New-Images/course/pregnancy-yoga-camp-for-ante-post.webp',
  courseAsset12: '/SEO-New-Images/Courses/senior-citizens-yoga-camp-the-yoga-institute.webp',
  courseAsset13: '/SEO-New-Images/Courses/stress-management-yoga-camp.webp',
  courseAsset14: '/SEO-New-Images/Courses/weight-management-the-yoga-institute.webp',
  courseAsset15: '/SEO-New-Images/Courses/weight-management-the-yoga-institute.webp',
  courseAsset16: '/Courses/Camps/Weight_management.jpg',
  courseAsset83: '/SEO-New-Images/Courses/respiratory-yoga-workshop.webp',
  courseAsset84: '/SEO-New-Images/Courses/women-yoga-camp-the-yoga-institute.webp',
  courseAsset85: '/Courses/diet_workshp.jpg',
  courseAsset111: '/SEO-New-Images/Courses/cardiac-_-hypertension-yoga-camp-the-yoga-institute.webp',
  courseAsset112: '/SEO-New-Images/Courses/life-management-yoga-camp.webp',
  courseAssets113: '/Courses/Camps/pregnacy.jpg',
  courseAssets114: '/Courses/Camps/Kid.jpg',
  courseAssets115: '/SEO-New-Images/Courses/pregnancy-yoga-camp.webp',
  courseAssets116: '/SEO-New-Images/Courses/kids-yoga-camp.webp',
  courseAssets122: '/SEO-New-Images/Courses/newCorpWellness.jpeg',

  // certificate

  courseAsset17: '/Courses/Certificate/Department of Ayush.png',
  courseAsset18: '/Courses/Certificate/Yoga Alliance.png',
  courseAsset19: '/Courses/Certificate/Yoga Certification Board.png',

  // class
  courseAsset20: '/Courses/Class/Advanced_regular_asana_class_tyi_teachers.jpg',
  courseAsset21: '/Courses/Class/Samattvam.jpg',
  courseAsset22: '/Courses/Class/Weekend_regular_class.jpg',
  courseAsset23: '/Courses/Class/Yog prayas(1).jpg',
  courseAsset24:
    '/Courses/Class/certificate%20course_on_advance_pranayama_techniques.jpg',
  courseAsset25: '/Courses/Class/couples_class.jpg',
  courseAsset26: '/Courses/Class/couples_class.jpg',
  courseAsset27: '/Courses/Class/mens_class.jpg',
  courseAsset28: '/SEO-New-Images/course/online-meditation-course.webp',
  courseAsset29: '/Courses/Class/womens_class.jpg',
  courseAsset30: '/Courses/Class/yog_prayas.jpg',
  courseAsset31: '/Courses/Class/yoga_therapy.jpg',
  courseAsset32: '/Courses/Class/yogasutra_class.jpg',
  courseAsset67: '/SEO-New-Images/course/advance-yoga-asana-regular-class-online-only-for-tYI-teachers.webp',
  courseAsset68: '/SEO-New-Images/Courses/healing-yoga-movement-&-rythm-classes.webp',
  //courseAsset69: '/Courses/Class/Anand_Sadhna_online%20_%20On_Campus.jpeg',
  courseAsset70: '/SEO-New-Images/Courses/yoga-asana-regular-classes-for-men.webp',
  courseAsset71: '/SEO-New-Images/course/asana-regular-classes_for-women.webp',
  courseAsset72:
    '/SEO-New-Images/course/yoga-certificate-course-advanced-pranayama-techniques-online.webp',
  courseAsset73:
    '/Courses/Class/Certificate_Course_on_Yoga_Sutra%2BOnline.jpeg',
  courseAsset74:
    '/SEO-New-Images/course/certificate-program-on-yoga-for-cancer-online.webp',
  courseAsset75: '/SEO-New-Images/course/certificate-yoga-therapy_course-online.webp',
  courseAsset76:
    '/SEO-New-Images/Courses/children-camp-the-yoga-institute.webp',
  courseAsset77: '/SEO-New-Images/Courses/weekend-yoga-Asana-Classes_-Men-Women.webp',
  courseAsset78: '/Courses/Class/Regular_Meditation_Classes_Online.jpeg',
  courseAsset79: '/Courses/Class/Samattvam_%28Health_Checkup%29.jpeg',
  courseAsset80: '/SEO-New-Images/Courses/Satsang.webp',
  courseAsset81:
    '/SEO-New-Images/course/weekend-yoga-Asana-Classes_-Men-Women.webp',
  courseAsset82: '/SEO-New-Images/Courses/yog-prayas-online -yogasana.webp',
  courseAsset113: '/SEO-New-Images/Courses/yoga-certificate-program-for-lung-Cancer-Online.webp',
  courseAsset114: '/Courses/Camps/new11/Certificate%20Program%20on%20Yoga%20for%20Lung%20Cancer%20-%20Online_1x1.jpg',
  courseAsset116: '/Courses/Class/new11/couples-classes-online.jpg',

  // most popular

  courseAsset33: '/SEO-New-Images/Courses/200-hour-yoga-teacher-training-course.webp',
  courseAsset34: '/SEO-New-Images/course/21-days+-better-living-course.webp',
  courseAsset35: '/SEO-New-Images/course/7-days+health-camp-yoga+camp.webp',
  courseAsset36: '/SEO-New-Images/Courses/900-hour-advanced-yoga teacher-training-course.webp',
  courseAsset37: '/SEO-New-Images/Courses/pranayama-workshop-the-yoga-institute.webp',

  // ttc
  courseAsset38: '/SEO-New-Images/course/200-hour-yoga-teacher-training-course.webp',
  courseAsset39: '/SEO-New-Images/course/500-hour-+online-yoga-teacher-training-course.webp',
  courseAsset40: '/SEO-New-Images/course/900-hour-advanced-yoga+teacher-training-course.webp',

  // ttc ->   alumni

  courseAsset41:
    '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/Ajinkya.jpg',
  courseAsset42:
    '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/Disha_Vakhani.jpg',
  courseAsset43:
    '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/Disha_Vakhani.jpg',
  courseAsset44:
    '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/Nirmal-Gangwal.png',
  courseAsset45:
    '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/Premchand_Godha.jpg',
  courseAsset46:
    '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/RUJUTA_DIWEKAR.jpg',
  courseAsset47:
    '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/Shilpa_Shirodkar.jpg',
  courseAsset48:
    '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/Shivkumar_Sharma.jpg',
  courseAsset49:
    '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/Shweta_Tiwari.jpg',
  courseAsset50:
    '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/Sukhwinder_Singh.jpg',
  courseAsset51:
    '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/ankita sood.jpg',
  courseAsset52: '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/carlo.jpeg',
  courseAsset53: '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/kavita.jpg',
  courseAsset54:
    '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/natalia.JPG',
  courseAsset55:
    '/Courses/TTC/Alumni/Almuni_pictures_and_word_file/veronica.jpg',

  // workShops

  courseAsset56: '/Courses/Workshops/Food_and_nutrition .jpg',
  courseAsset57: '/Courses/Workshops/digestion_camp.jpg',
  courseAsset58: '/Courses/Workshops/pranayama_workshop.jpg',

  // tyi activities

  courseAsset59: '/Courses/tyi_activities/2_year_ttc.jpg',
  courseAsset60: '/Courses/tyi_activities/Copy_of_900_hrs_TTC.jpg',
  courseAsset61: '/Courses/tyi_activities/corporate_slider.jpg',
  courseAsset62: '/Courses/tyi_activities/corporate_workshop.jpg',
  courseAsset63: '/Courses/tyi_activities/couple_class.jpg',
  courseAsset64: '/Courses/tyi_activities/home_tuitions.jpg',
  courseAsset65: '/Courses/tyi_activities/ortho_camp.jpg',
  courseAsset66: '/Courses/tyi_activities/womens_camp.jpg',

  //ttc-card images

  courseAsset101: '/SEO-New-Images/Courses/200-hour-yoga teacher-training-course (2).webp',
  courseAsset102: '/Courses/TTC/new11/500_hrs_TTC.jpg',
  courseAsset103: '/Courses/TTC/new11/900_hrs_TTC.jpg',

  //camps&worshop-card images

  courseAsset104: '/Courses/Camps/new11/cardiac-hypertension-workshop.jpg',
  courseAsset105: '/SEO-New-Images/Courses/diabetes-yoga-camp.webp',
  courseAsset106: '/Courses/Camps/new11/digestion-camp.jpg',
  courseAsset107: '/Courses/Camps/new11/life-management-camp.jpg',

  //class-card images

  courseAsset108:
    '/Courses/Class/new11/advance-asana-regular-class-online%28only-for-tyi-teachers%29%20.jpg',
  courseAsset109: '/Courses/Class/new11/anand-sadhna-online-on-campus.JPG',
  courseAsset110:
    '/Courses/Class/new11/weekend-asana-classes-%28men-women%29%20-%20common.jpg',
  courseAsset115: '/home_page/Regular_asana_class.webp',
  courseAsset117: '/SEO-New-Images/Courses/Restorative_Yoga_for_Cancer.webp',
  courseAsset118: '/home_page/corporate_copy.webp'

}

export const facilitiyAssets = {
  facilitiyAssets1: '/SEO-New-Images/Facilities/hostel-the-yoga-institute.webp',
  facilitiyAssets2: '/SEO-New-Images/Facilities/conference-room-the-yoga-institute.webp',
  facilitiyAssets3: '/SEO-New-Images/Facilities/library-the-yoga-institute.webp',
  facilitiyAssets4: '/SEO-New-Images/Facilities/the-yoga-institute-bookstore.webp',
  facilitiyAssets5: '/SEO-New-Images/Facilities/yogahalls-the-yoga-institute.webp',
  facilitiyAssets6: '/SEO-New-Images/Facilities/rooftop-the-yoga-institute.webp',
  facilitiyAssets7: '/SEO-New-Images/Facilities/dininghall-the-yoga-institute.webp',
  facilitiyAssets8: '/SEO-New-Images/Facilities/naturetrail-the-yoga-institute.webp',
  facilitiyAssets9: '/SEO-New-Images/Facilities/Gazebo-the-yoga-institute.webp',
  facilitiyAssets10: '/SEO-New-Images/Facilities/kelashpati-the-yoga-institute.webp',
  homeAsset70: '/Home/Footer/tyi.png',
  facilitiyAssets11: '/SEO-New-Images/Facilities/kelashpati-the-yoga-institute.webp',
  facilitiyAssets12: '/SEO-New-Images/Facilities/dininghall-the-yoga-institute.webp',
}

export const careerAssets = {
  careerAsset1: '/Home/Footer/careers/content%20writer.png',
  careerAsset2: '/Home/Footer/careers/video%20editor.png',
}

export const aboutAssets = {
  aboutAsset1: '/About/Overview/about-overview.jpg',
  aboutAsset50: '/About/Overview/bhopal.webp',

  // shree Yogendra jI

  aboutAsset2: '/About/Our%20Legacy/Shree%20Yogendra%20ji/r.jpg',
  aboutAsset3: '/SEO-New-Images/Others/shriyogendraji-the-yoga-institute.webp',
  aboutAsset4: '/About/Our%20Legacy/Shree%20Yogendra%20ji/1.jpg',
  aboutAsset5: '/About/Our%20Legacy/Shree%20Yogendra%20ji/2.jpg',
  aboutAsset6: '/About/Our%20Legacy/Shree%20Yogendra%20ji/3.jpg',
  aboutAsset7: '/About/Our%20Legacy/Shree%20Yogendra%20ji/4.jpg',
  aboutAsset8: '/About/Our%20Legacy/Shree%20Yogendra%20ji/5.jpg',
  aboutAsset9: '/About/Our%20Legacy/Shree%20Yogendra%20ji/6.jpg',
  aboutAsset70: '/About/Our%20Legacy/new11/founder-1.jpg',
  aboutAsset71: '/About/Our%20Legacy/new11/founder-2.jpg',

  // Madhavdas Ji

  aboutAsset10:
    '/About/Our%20Legacy/Paramhamsa%20Madhavadas%20ji/Madhavdasji.jpg',
  aboutAsset82: '/About/Our%20Legacy/new11/Madhavdas_ji/Madhavdasji_4_3.jpg',
  aboutAsset83: '/About/Our%20Legacy/new11/Madhavdas_ji/madhavdasji1_1.jpg',
  aboutAsset84: '/About/Our%20Legacy/new11/Madhavdas_ji/madhavdasji_16-9.jpg',
  aboutAsset85: '/About/Our%20Legacy/new11/Madhavdas_ji/madhavdasji_16_9.jpg',
  aboutAsset86: '/About/Our%20Legacy/new11/Madhavdas_ji/madhavdasji_1_1.jpg',
  aboutAsset87: '/About/Our%20Legacy/new11/Madhavdas_ji/madhavdasji_3.jpg',

  // sitaDevi Ji

  aboutAsset11: '/About/Our%20Legacy/Smt.%20Sitadevi%20Ji/l.jpg',
  aboutAsset12: '/About/Our%20Legacy/Smt.%20Sitadevi%20Ji/r.jpg',
  aboutAsset13: '/About/Our%20Legacy/Smt.%20Sitadevi%20Ji/1.jpg',
  aboutAsset14: '/About/Our%20Legacy/Smt.%20Sitadevi%20Ji/2.jpg',
  aboutAsset15: '/About/Our%20Legacy/Smt.%20Sitadevi%20Ji/3.jpg',
  aboutAsset16: '/About/Our%20Legacy/Smt.%20Sitadevi%20Ji/4.jpg',
  aboutAsset17: '/About/Our%20Legacy/Smt.%20Sitadevi%20Ji/5.jpg',
  aboutAsset18: '/About/Our%20Legacy/Smt.%20Sitadevi%20Ji/6.jpg',

  // jayadeva Yogendra

  aboutAsset19: '/About/Our%20Legacy/Dr.%20Jayadeva%20Yogendra/l.jpg',
  aboutAsset20: '/About/Our%20Legacy/Dr.%20Jayadeva%20Yogendra/r.jpg',
  aboutAsset21: '/About/Our%20Legacy/Dr.%20Jayadeva%20Yogendra/1.jpg',
  aboutAsset22: '/About/Our%20Legacy/Dr.%20Jayadeva%20Yogendra/2.jpg',
  aboutAsset23: '/About/Our%20Legacy/Dr.%20Jayadeva%20Yogendra/3.jpg',
  aboutAsset24: '/About/Our%20Legacy/Dr.%20Jayadeva%20Yogendra/4.jpg',
  aboutAsset25: '/About/Our%20Legacy/Dr.%20Jayadeva%20Yogendra/5.jpg',
  aboutAsset26: '/About/Our%20Legacy/Dr.%20Jayadeva%20Yogendra/6.jpg',
  aboutAsset73: '/About/Our%20Legacy/new11/doctor-1.jpg',
  aboutAsset74: '/About/Our%20Legacy/new11/doctor-2.jpg',

  // hansaji Yogengra

  aboutAsset27: '/About/Our%20Legacy/Dr.%20Hansaji%20Yogendra/1 left side .jpg',
  aboutAsset28:
    '/About/Our%20Legacy/Dr.%20Hansaji%20Yogendra/2nd right side.jpg',
  aboutAsset29: '/About/Our%20Legacy/Dr.%20Hansaji%20Yogendra/1.jpg',
  aboutAsset30: '/About/Our%20Legacy/Dr.%20Hansaji%20Yogendra/2.jpg',
  aboutAsset31: '/About/Our%20Legacy/Dr.%20Hansaji%20Yogendra/3.jpg',
  aboutAsset32: '/About/Our%20Legacy/Dr.%20Hansaji%20Yogendra/4.jpg',
  aboutAsset33: '/About/Our%20Legacy/Dr.%20Hansaji%20Yogendra/5.jpg',
  aboutAsset34: '/About/Our%20Legacy/Dr.%20Hansaji%20Yogendra/6.jpg',
  aboutAsset75: '/About/Our%20Legacy/new11/hansaji-1.jpg',
  aboutAsset76: '/About/Our%20Legacy/new11/hansaji-2.jpg',

  // hrishiji

  aboutAsset35: '/About/Our%20Legacy/Harish%20Ji/l.jpg',
  aboutAsset36: '/About/Our%20Legacy/Harish%20Ji/r.jpg',
  aboutAsseharisht37: '/About/Our%20Legacy/Harish%20Ji/1.jpg',
  aboutAsset38: '/About/Our%20Legacy/Harish%20Ji/2.jpg',
  aboutAsset39: '/About/Our%20Legacy/Harish%20Ji/3.jpg',
  aboutAsset40: '/About/Our%20Legacy/Harish%20Ji/4.jpg',
  aboutAsset41: '/About/Our%20Legacy/Harish%20Ji/5.jpg',
  aboutAsset42: '/About/Our%20Legacy/Harish%20Ji/6.jpg',
  aboutAsset77: '/About/Our%20Legacy/Harish%20Ji/new11/hrishi-1-1-1.JPG',
  aboutAsset78: '/About/Our%20Legacy/Harish%20Ji/new11/hrishi-16-9.jpg',
  aboutAsset79: '/About/Our%20Legacy/Harish%20Ji/new11/hrishi-4-1-1-min.JPG',
  aboutAsset80: '/About/Our%20Legacy/Harish%20Ji/new11/hrishi-4-3-1.jpg',
  aboutAsset81: '/About/Our%20Legacy/Harish%20Ji/new11/hrishi-4-3-2.jpg',

  // blessings
  aboutAsset43: '/About/Pujya%20Maa%20Dr%20Hansaji’s%20blessings/why_tyi.png',

  // legacy images

  aboutAsset44: '/About/Our%20Legacy/l-0.jpg',
  aboutAsset45: '/About/Our%20Legacy/l-1.jpg',
  aboutAsset46: '/About/Our%20Legacy/l-2.jpg',
  aboutAsset47: '/About/Our%20Legacy/l-3.jpg',
  aboutAsset48: '/About/Our%20Legacy/l-4.jpg',
  aboutAsset49: '/About/Our%20Legacy/l-5.jpg',
}

export const mediaAssets = {
  // gallery
  seminarImg: '/Media/gallery/seminar.png',
  igCeremony: '/SEO-New-Images/News_Event/igceremony-yoga-institute.webp',
  mediaAssets198: '/Media/gallery/igceremony.jpg',
  mediaAssets199: '/Media/gallery/media_1.jpg',
  mediaAssets200: '/Media/gallery/media_2.jpg',
  mediaAssets201: '/Media/gallery/media_3.jpg',
  mediaAssets202: '/Media/gallery/media_4.jpg',
  mediaAssets203: '/Media/gallery/media_5.jpg',
  mediaAssets204: '/Media/gallery/media_6.jpg',
  mediaAssets205: '/Media/gallery/media_7.jpg',
  mediaAssets206: '/Media/gallery/media_8.jpg',
  mediaAssets207: '/Media/gallery/seminar.png',

  // harmony-sec
  mediaAssetsHero: 'Media/gallery/99FoundationDay/Hero image .jpg',
  mediaAsset1: '/Media/gallery/harmony/har_1.jpg',
  mediaAsset2: '/Media/gallery/harmony/har_2.jpg',
  mediaAsset3: '/Media/gallery/harmony/har_3.jpg',
  mediaAsset4: '/Media/gallery/harmony/har_4.jpg',
  mediaAsset5: '/Media/gallery/harmony/har_5.jpg',
  mediaAsset6: '/Media/gallery/harmony/har_6.jpg',
  mediaAsset7: '/Media/gallery/harmony/har_7.jpg',
  mediaAsset8: '/Media/gallery/harmony/har_8.jpg',
  mediaAsset9: '/Media/gallery/harmony/har_9.jpg',
  mediaAsset208: '/Media/gallery/harmony/har_10.jpg',
  mediaAsset209: '/Media/gallery/harmony/har_11.jpg',
  mediaAsset210: '/Media/gallery/harmony/har_12.jpg',
  mediaAsset211: '/Media/gallery/harmony/har_13.JPG',
  mediaAsset212: '/Media/gallery/harmony/har_14.JPG',
  mediaAsset213: '/Media/gallery/harmony/har_15.JPG',
  mediaAsset214: '/Media/gallery/harmony/har_16.JPG',
  mediaAsset215: '/Media/gallery/harmony/har_17.JPG',
  mediaAsset216: '/Media/gallery/harmony/har_18.JPG',
  mediaAsset217: '/Media/gallery/harmony/har_19.JPG',
  mediaAsset218: '/Media/gallery/harmony/har_20.jpg',
  mediaAsset219: '/Media/gallery/harmony/har_21.JPG',
  mediaAsset220: '/Media/gallery/harmony/har_22.JPG',
  mediaAsset221: '/Media/gallery/harmony/har_23.JPG',
  mediaAsset222: '/Media/gallery/harmony/har_24.JPG',
  mediaAsset223: '/Media/gallery/harmony/har_25.JPG',

  // 99foundation sec
  mediaAsset10: '/Media/gallery/99FoundationDay/img1.jpg',
  mediaAsset11: '/Media/gallery/99FoundationDay/img2.jpg',
  mediaAsset12: '/Media/gallery/99FoundationDay/img3.jpg',
  mediaAsset13: '/Media/gallery/99FoundationDay/img4.jpg',
  mediaAsset14: '/Media/gallery/99FoundationDay/img5.jpg',

  //101foundation day
  mediaAssets15: '/Media/gallery/foundationday-101/f101_1.JPG',
  mediaAssets16: '/Media/gallery/foundationday-101/f101_2.JPG',
  mediaAssets17: '/Media/gallery/foundationday-101/f101_3.JPG',
  mediaAssets18: '/Media/gallery/foundationday-101/f101_4.JPG',
  mediaAssets19: '/Media/gallery/foundationday-101/f101_5.JPG',
  mediaAssets20: '/Media/gallery/foundationday-101/f101_6.JPG',
  mediaAssets21: '/Media/gallery/foundationday-101/f101_7.JPG',
  mediaAssets22: '/Media/gallery/foundationday-101/f101_8.JPG',
  mediaAssets23: '/Media/gallery/foundationday-101/f101_9.JPG',
  mediaAssets24: '/Media/gallery/foundationday-101/f101_10.JPG',

  //gff-2018
  mediaAsset25: '/Media/gallery/gff-2018/gff_1.jpeg',
  mediaAsset26: '/Media/gallery/gff-2018/gff_2.jpeg',
  mediaAsset27: '/Media/gallery/gff-2018/gff_3.JPG',
  mediaAsset28: '/Media/gallery/gff-2018/gff_4.jpeg',
  mediaAsset29: '/Media/gallery/gff-2018/gff_5.jpg',

  //98th foundation day
  mediaAsset30: '/Media/gallery/foundation-98/f98_1.jpg',
  mediaAsset31: '/Media/gallery/foundation-98/f98_2.jpg',
  mediaAsset32: '/Media/gallery/foundation-98/f98_3.jpg',
  mediaAsset33: '/Media/gallery/foundation-98/f98_4.jpg',
  mediaAsset34: '/Media/gallery/foundation-98/f98_5.jpg',
  mediaAsset35: '/Media/gallery/foundation-98/f98_6.jpg',
  mediaAsset36: '/Media/gallery/foundation-98/f98_7.jpg',
  mediaAsset37: '/Media/gallery/foundation-98/f98_8.jpg',

  //120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017

  mediaAssets38:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/Hero%20image.jpeg',
  mediaAssets39:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_8890.jpeg',
  mediaAssets41:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_8920.jpeg',
  mediaAssets42:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_8950.jpeg',
  mediaAssets40:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_8913.jpeg',
  mediaAssets43:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_8966.jpeg',
  mediaAssets44:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_8979.jpeg',
  mediaAssets45:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_8983.jpeg',
  mediaAssets46:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_9006.jpeg',
  mediaAssets47:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_9007.jpeg',
  mediaAssets48:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_9020.jpeg',
  mediaAssets49:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_9022.jpeg',
  mediaAssets50:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_9025.jpeg',
  mediaAssets51:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_9026.jpeg',
  mediaAssets52:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_9060.jpeg',
  mediaAssets53:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_9061.jpeg',
  mediaAssets54:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_9129.jpeg',
  mediaAssets55:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_9146.jpeg',
  mediaAssets56:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_9148.jpeg',
  mediaAssets57:
    '/Media/gallery/%20120th_birth_Anniversary_of_Shri_Yogendraji_Founder_2017/IMG_9178.jpeg',

  //05052018

  mediaAssets58: '/Media/gallery/05052018/IMG-20180507-WA0013.jpg',
  mediaAssets59: '/Media/gallery/05052018/IMG-20180507-WA0014.jpg',
  mediaAssets60: '/Media/gallery/05052018/IMG_6125.jpeg',
  mediaAssets61: '/Media/gallery/05052018/IMG_6137.jpeg',
  mediaAssets62: '/Media/gallery/05052018/IMG_6427.jpeg',
  mediaAssets63: '/Media/gallery/05052018/IMG_6428.jpeg',

  //2009 Mayor_of_mumbai_shraddha_jadhav

  mediaAssets64:
    '/Media/gallery/2009/Mayor_of_mumbai_shraddha_jadhav/mayor_1.jpeg',
  mediaAssets65:
    '/Media/gallery/2009/Mayor_of_mumbai_shraddha_jadhav/mayor_2.jpeg',
  mediaAssets66:
    '/Media/gallery/2009/Mayor_of_mumbai_shraddha_jadhav/mayor_3.jpeg',
  mediaAssets67:
    '/Media/gallery/2009/Mayor_of_mumbai_shraddha_jadhav/mayor_4.jpeg',
  mediaAssets68:
    '/Media/gallery/2009/Mayor_of_mumbai_shraddha_jadhav/mayor_5.jpeg',
  mediaAssets69:
    '/Media/gallery/2009/Mayor_of_mumbai_shraddha_jadhav/mayor_6.jpeg',
  mediaAssets70:
    '/Media/gallery/2009/Mayor_of_mumbai_shraddha_jadhav/mayor_7.jpeg',
  mediaAssets71:
    '/Media/gallery/2009/Mayor_of_mumbai_shraddha_jadhav/mayor_8.jpeg',

  //Mumbai_Police_Commissioner_D_Sivanandan_2009

  mediaAssets72:
    '/Media/gallery/2009/Mumbai_Police_Commissioner_D_Sivanandan_2009/Com_1.jpeg',
  mediaAssets73:
    '/Media/gallery/2009/Mumbai_Police_Commissioner_D_Sivanandan_2009/Com_10.JPG',
  mediaAssets74:
    '/Media/gallery/2009/Mumbai_Police_Commissioner_D_Sivanandan_2009/Com_2.JPG',
  mediaAssets75:
    '/Media/gallery/2009/Mumbai_Police_Commissioner_D_Sivanandan_2009/Com_3.JPG',
  mediaAssets76:
    '/Media/gallery/2009/Mumbai_Police_Commissioner_D_Sivanandan_2009/Com_4.JPG',
  mediaAssets77:
    '/Media/gallery/2009/Mumbai_Police_Commissioner_D_Sivanandan_2009/Com_5.JPG',
  mediaAssets78:
    '/Media/gallery/2009/Mumbai_Police_Commissioner_D_Sivanandan_2009/Com_6.JPG',
  mediaAssets79:
    '/Media/gallery/2009/Mumbai_Police_Commissioner_D_Sivanandan_2009/Com_9.JPG',
  mediaAssets80:
    '/Media/gallery/2009/Mumbai_Police_Commissioner_D_Sivanandan_2009/com_11.jpeg',
  mediaAssets81:
    '/Media/gallery/2009/Mumbai_Police_Commissioner_D_Sivanandan_2009/com_12.jpeg',
  //mediaAssets81: 'Media/gallery/2009/Mumbai_Police_Commissioner_D_Sivanandan_2009/com_13.jpeg',

  //Annam_brahma_anuradha_ji

  mediaAssets82: '/Media/gallery/Annam_brahma_anuradha_ji/Hero image.jpeg',
  mediaAssets83: '/Media/gallery/Annam_brahma_anuradha_ji/DSC02916.jpeg',
  mediaAssets84: '/Media/gallery/Annam_brahma_anuradha_ji/DSC02952.jpeg',
  mediaAssets85: '/Media/gallery/Annam_brahma_anuradha_ji/DSC02995.jpeg',
  mediaAssets86: '/Media/gallery/Annam_brahma_anuradha_ji/DSC03009.jpeg',
  mediaAssets87: '/Media/gallery/Annam_brahma_anuradha_ji/DSC03051.jpeg',
  mediaAssets88: '/Media/gallery/Annam_brahma_anuradha_ji/DSC03165.jpeg',
  mediaAssets89: '/Media/gallery/Annam_brahma_anuradha_ji/DSC03199.jpeg',
  mediaAssets90: '/Media/gallery/Annam_brahma_anuradha_ji/DSC03223.jpeg',
  mediaAssets91: '/Media/gallery/Annam_brahma_anuradha_ji/DSC03240.jpeg',

  //Ayuryog_Expo_7th_&_10th_Nov_2019

  mediaAssets92: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/021A4050.jpg',
  mediaAssets93: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/021A4108.jpg',
  mediaAssets94: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/021A4125.jpg',
  mediaAssets95: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/021A4213.jpg',
  mediaAssets96: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/021A4217.jpg',
  mediaAssets97: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/1.jpeg',
  mediaAssets98: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/2.jpeg',
  mediaAssets99: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/3.jpeg',
  mediaAssets100: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/4.jpeg',
  mediaAssets101: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/5.jpeg',
  mediaAssets102: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/6.jpeg',
  mediaAssets103: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/7.jpeg',
  mediaAssets104: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/8.jpeg',
  mediaAssets105: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/9.jpeg',
  mediaAssets106: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/10.jpeg',
  mediaAssets107: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/11.jpeg',
  mediaAssets108: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/12.jpeg',
  mediaAssets109: '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/13.jpeg',
  mediaAssets110:
    '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/A15I1216.jpg',
  mediaAssets111:
    '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/A15I1221.jpg',
  mediaAssets112:
    '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/A15I1265.jpg',
  mediaAssets113:
    '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/A15I1267.jpg',
  mediaAssets114:
    '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/A15I1286.jpg',
  mediaAssets115:
    '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/A15I1380.jpg',
  mediaAssets116:
    '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/A15I1385.jpg',
  mediaAssets117:
    '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/A15I1511.jpg',
  mediaAssets118:
    '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/A15I2074.jpg',
  mediaAssets119:
    '/Media/gallery/Ayuryog_Expo_7th_&_10th_Nov_2019/Hero image.jpg',

  //Bharat_Gaurav_Award

  mediaAssets120: '/Media/gallery/Bharat_Gaurav_Award_2019/Hero_image.JPG',
  mediaAssets121: '/Media/gallery/Bharat_Gaurav_Award_2019/DSC_6672.JPG',
  mediaAssets122: '/Media/gallery/Bharat_Gaurav_Award_2019/DSC_6894.JPG',

  //Decade

  mediaAssets123: '/Media/gallery/Decade/decade_1.jpg',
  mediaAssets124: '/Media/gallery/Decade/decade_2.JPG',
  mediaAssets125: '/Media/gallery/Decade/decade_3.jpg',
  mediaAssets126: '/Media/gallery/Decade/decade_4.JPG',
  mediaAssets127: '/Media/gallery/Decade/decade_5.jpg',
  mediaAssets128: '/Media/gallery/Decade/decade_6.jpg',
  mediaAssets129: '/Media/gallery/Decade/decade_7.jpg',

  //Goa_PES_college_Pics_26th_Nov_2019

  mediaAssets130: '/Media/gallery/Goa_PES_college_Pics_26th_Nov_2019/goa_1.jpg',
  mediaAssets131: '/Media/gallery/Goa_PES_college_Pics_26th_Nov_2019/goa_2.jpg',
  mediaAssets132: '/Media/gallery/Goa_PES_college_Pics_26th_Nov_2019/goa_3.jpg',
  mediaAssets133: '/Media/gallery/Goa_PES_college_Pics_26th_Nov_2019/goa_4.jpg',
  mediaAssets134: '/Media/gallery/Goa_PES_college_Pics_26th_Nov_2019/goa_5.jpg',

  //Golden_Book_of_World_Record_Award Meeting

  mediaAssets135:
    '/Media/gallery/Golden_Book_of_World_Record_Award%20Meeting_18022016/IMG_0681.jpg',
  mediaAssets136:
    '/Media/gallery/Golden_Book_of_World_Record_Award%20Meeting_18022016/IMG_0682.jpg',

  //Hansaji_birthday_2021

  mediaAssets137: '/Media/gallery/Hansaji_birthday_2021/Hero image.jpeg',
  mediaAssets138: '/Media/gallery/Hansaji_birthday_2021/DSC07776.jpeg',
  mediaAssets139: '/Media/gallery/Hansaji_birthday_2021/DSC07776.jpeg',
  mediaAssets140: '/Media/gallery/Hansaji_birthday_2021/DSC07728.jpeg',

  //Hansajis_70th_Birthday

  mediaAssets141: '/Media/gallery/Hansajis_70th_Birthday/Hero image.jpg',
  mediaAssets142: '/Media/gallery/Hansajis_70th_Birthday/hansaji_70_1.jpg',
  mediaAssets143: '/Media/gallery/Hansajis_70th_Birthday/hansaji_70_2.jpg',
  mediaAssets144: '/Media/gallery/Hansajis_70th_Birthday/hansaji_70_3.jpg',
  mediaAssets145: '/Media/gallery/Hansajis_70th_Birthday/hansaji_70_4.jpg',
  mediaAssets146: '/Media/gallery/Hansajis_70th_Birthday/hansaji_70_5.jpg',

  //IIT_29th_Feb_2020_Hansaji

  mediaAssets147: '/Media/gallery/IIT_29th_Feb_2020_Hansaji/868A0965.jpeg',
  mediaAssets148: '/Media/gallery/IIT_29th_Feb_2020_Hansaji/868A0969.jpeg',
  mediaAssets149: '/Media/gallery/IIT_29th_Feb_2020_Hansaji/868A0970.jpeg',
  mediaAssets150: '/Media/gallery/IIT_29th_Feb_2020_Hansaji/868A0972.jpeg',
  mediaAssets151: '/Media/gallery/IIT_29th_Feb_2020_Hansaji/868A0974.jpeg',
  mediaAssets152: '/Media/gallery/IIT_29th_Feb_2020_Hansaji/868A0976.jpeg',
  mediaAssets153: '/Media/gallery/IIT_29th_Feb_2020_Hansaji/868A0980.jpeg',

  //Mass_Yoga_Bhopal

  mediaAssets154: '/Media/gallery/Mass_Yoga_Bhopal/bhopal_1.jpeg',
  mediaAssets155: '/Media/gallery/Mass_Yoga_Bhopal/bhopal_2.jpeg',
  mediaAssets156: '/Media/gallery/Mass_Yoga_Bhopal/bhopal_3.jpeg',
  mediaAssets157: '/Media/gallery/Mass_Yoga_Bhopal/bhopal_4.jpeg',
  mediaAssets158: '/Media/gallery/Mass_Yoga_Bhopal/bhopal_5.jpeg',
  mediaAssets159: '/Media/gallery/Mass_Yoga_Bhopal/bhopal_6.jpg',

  //Pranab_Mukherjee

  mediaAssets160: '/Media/gallery/Pranab_Mukherjee_Aug_2019/Hero_image.jpg',
  mediaAssets161: '/Media/gallery/Pranab_Mukherjee_Aug_2019/IMG_4248.JPG',
  mediaAssets162: '/Media/gallery/Pranab_Mukherjee_Aug_2019/IMG_4253.JPG',
  mediaAssets163: '/Media/gallery/Pranab_Mukherjee_Aug_2019/IMG_4261.JPG',

  //Shri_Amitabh_Gupta_Add_CP

  mediaAssets164: '/Media/gallery/Shri_Amitabh_Gupta_Add_CP_2010/amit_g_1.JPG',
  mediaAssets165: '/Media/gallery/Shri_Amitabh_Gupta_Add_CP_2010/amit_g_2.JPG',
  mediaAssets166: '/Media/gallery/Shri_Amitabh_Gupta_Add_CP_2010/amit_g_3.JPG',
  mediaAssets167: '/Media/gallery/Shri_Amitabh_Gupta_Add_CP_2010/amit_g_4.JPG',
  mediaAssets168: '/Media/gallery/Shri_Amitabh_Gupta_Add_CP_2010/amit_g_5.JPG',
  mediaAssets169: '/Media/gallery/Shri_Amitabh_Gupta_Add_CP_2010/amit_g_6.JPG',
  mediaAssets170: '/Media/gallery/Shri_Amitabh_Gupta_Add_CP_2010/amit_g_7.JPG',
  mediaAssets171: '/Media/gallery/Shri_Amitabh_Gupta_Add_CP_2010/amit_g_8.JPG',

  //Shri_Anupam_Kher_&_Pandit_Shiv_Kumar_Sharma_&_Shri_Sukhwinder_Singh

  mediaAssets172:
    '/Media/gallery/Shri_Anupam_Kher_&_Pandit_Shiv_Kumar_Sharma_&_Shri_Sukhwinder_Singh_2012/Hero_image.jpeg',
  mediaAssets173:
    '/Media/gallery/Shri_Anupam_Kher_&_Pandit_Shiv_Kumar_Sharma_&_Shri_Sukhwinder_Singh_2012/DSCN0212.jpeg',
  mediaAssets174:
    '/Media/gallery/Shri_Anupam_Kher_&_Pandit_Shiv_Kumar_Sharma_&_Shri_Sukhwinder_Singh_2012/DSCN0214.jpeg',
  mediaAssets175:
    '/Media/gallery/Shri_Anupam_Kher_&_Pandit_Shiv_Kumar_Sharma_&_Shri_Sukhwinder_Singh_2012/DSCN0221.jpeg',
  mediaAssets176:
    '/Media/gallery/Shri_Anupam_Kher_&_Pandit_Shiv_Kumar_Sharma_&_Shri_Sukhwinder_Singh_2012/DSCN0412.jpeg',
  mediaAssets177:
    '/Media/gallery/Shri_Anupam_Kher_&_Pandit_Shiv_Kumar_Sharma_&_Shri_Sukhwinder_Singh_2012/DSCN0413.jpeg',
  mediaAssets178:
    '/Media/gallery/Shri_Anupam_Kher_&_Pandit_Shiv_Kumar_Sharma_&_Shri_Sukhwinder_Singh_2012/DSCN0514.jpeg',
  mediaAssets179:
    '/Media/gallery/Shri_Anupam_Kher_&_Pandit_Shiv_Kumar_Sharma_&_Shri_Sukhwinder_Singh_2012/DSCN0663.jpeg',

  //Shri_Pradeep_Sonawane_&_Shri_Suresh_Bharwani

  mediaAssets180:
    '/Media/gallery/Shri_Pradeep_Sonawane_&_Shri_Suresh_Bharwani_2014/IMG_6622.jpeg',
  mediaAssets181:
    '/Media/gallery/Shri_Pradeep_Sonawane_&_Shri_Suresh_Bharwani_2014/IMG_6625.jpeg',
  mediaAssets182:
    '/Media/gallery/Shri_Pradeep_Sonawane_&_Shri_Suresh_Bharwani_2014/IMG_6626.jpeg',
  mediaAssets183:
    '/Media/gallery/Shri_Pradeep_Sonawane_&_Shri_Suresh_Bharwani_2014/IMG_6627.jpeg',
  mediaAssets184:
    '/Media/gallery/Shri_Pradeep_Sonawane_&_Shri_Suresh_Bharwani_2014/IMG_6631.jpeg',

  //Shri_Sukhwinder_Singh_&_Shri_Rajan_Saxena

  mediaAssets185:
    '/Media/gallery/Shri_Sukhwinder_Singh_&_Shri_Rajan_Saxena_2015/Shuk_1.jpeg',
  mediaAssets186:
    '/Media/gallery/Shri_Sukhwinder_Singh_&_Shri_Rajan_Saxena_2015/Shuk_2.jpeg',
  mediaAssets187:
    '/Media/gallery/Shri_Sukhwinder_Singh_&_Shri_Rajan_Saxena_2015/Shuk_3.jpeg',
  mediaAssets188:
    '/Media/gallery/Shri_Sukhwinder_Singh_&_Shri_Rajan_Saxena_2015/Shuk_4.jpeg',
  mediaAssets189:
    '/Media/gallery/Shri_Sukhwinder_Singh_&_Shri_Rajan_Saxena_2015/Shuk_5.jpeg',
  mediaAssets190:
    '/Media/gallery/Shri_Sukhwinder_Singh_&_Shri_Rajan_Saxena_2015/Shuk_6.jpeg',
  mediaAssets191:
    '/Media/gallery/Shri_Sukhwinder_Singh_&_Shri_Rajan_Saxena_2015/Shuk_7.jpeg',
  mediaAssets192:
    '/Media/gallery/Shri_Sukhwinder_Singh_&_Shri_Rajan_Saxena_2015/Shuk_8.jpeg',
  mediaAssets193:
    '/Media/gallery/Shri_Sukhwinder_Singh_&_Shri_Rajan_Saxena_2015/Shuk_9.jpeg',
  mediaAssets194:
    '/Media/gallery/Shri_Sukhwinder_Singh_&_Shri_Rajan_Saxena_2015/Shuk_10.jpeg',
  mediaAssets195:
    '/Media/gallery/Shri_Sukhwinder_Singh_&_Shri_Rajan_Saxena_2015/Shuk_11.jpeg',

  //Suresh_Prabhu-Dr_Saabs_birthday

  mediaAssets196:
    '/Media/gallery/Suresh_Prabhu-Dr_Saabs_birthday/Hero_image.jpg',
  mediaAssets197: '/Media/gallery/Suresh_Prabhu-Dr_Saabs_birthday/dr_1.jpg',

  //

  // news

  // events
}

// Publication

export const publicationAssests = {
  // library

  publicationAssests1: '/SEO-New-Images/Library/library-hero-the-yoga-institute.webp',
  publicationAssests2: '/SEO-New-Images/Library/library-hero-the_yoga_institute.webp',
  publicationAssests3: '/SEO-New-Images/Library/library_hero-the-yoga-institute.webp',
  publicationAssests4: '/SEO-New-Images/Library/library__hero-the-yoga-institute.webp',
  publicationAssests5: '/SEO-New-Images/Library/library--hero-the-yoga-institute.webp',
  publicationAssests6: '/SEO-New-Images/Library/libraryhero-the-yoga-institute.webp',
  publicationAssests7: '/SEO-New-Images/Library/library_hero_the_yoga_institute.webp',


  //emag
  publicationAssests85: '/Publications/Yoga%20%26%20Total%20Health/YTH-Aug-22-Emag-1.jpg',
  publicationAssests86: '/Publications/Yoga & Total Health/Yoga&Total-Health-November-2022.JPG',
  publicationAssests87: '/Publications/Yoga & Total Health/Yoga-and-Total-Health-December-2022.JPG',
  publicationAssests88: '/Publications/Yoga & Total Health/Yoga&Total-Health-January-2023.JPG',
  publicationAssests89: '/Publications/Yoga & Total Health/Yoga-and-Total-Health-February-2023.JPG',
  publicationAssests90: '/SEO-New-Images/Others/yoga-and-total-health-march-2023.webp',
  publicationAssests91: '/Publications/Yoga & Total Health/Yoga -and -Total-Health-April-2023.JPG',

  // Yogasattva-Newsletter
  // 2017
  newsletterAssests1:
    '/Publications/Yogsattava/Newsletter/2017/yogasattava2017-1.jpg',
  newsletterAssests2:
    '/Publications/Yogsattava/Newsletter/2017/yogasattava2017-2.jpg',
  newsletterAssests3:
    '/Publications/Yogsattava/Newsletter/2017/yogasattava2017-3.jpg',
  newsletterAssests4:
    '/Publications/Yogsattava/Newsletter/2017/yogasattava2017-4.jpg',

  // 2018
  newsletterAssests5:
    '/Publications/Yogsattava/Newsletter/2018/yogasattava2018-1.jpg',
  newsletterAssests6:
    '/Publications/Yogsattava/Newsletter/2018/yogasattava2018-2.jpg',
  newsletterAssests7:
    '/Publications/Yogsattava/Newsletter/2018/yogasattava2018-3.jpg',
  newsletterAssests8:
    '/Publications/Yogsattava/Newsletter/2018/yogasattava2018-4.jpg',
  // 2019
  newsletterAssests9:
    '/Publications/Yogsattava/Newsletter/2019/yogasattava2019-1.jpg',
  newsletterAssests10:
    '/Publications/Yogsattava/Newsletter/2019/yogasattava2019-2.jpg',
  newsletterAssests11:
    '/Publications/Yogsattava/Newsletter/2019/yogasattava2019-3.jpg',
  newsletterAssests12:
    '/Publications/Yogsattava/Newsletter/2019/yogasattava2019-4.jpg',

  // 2020

  newsletterAssests13:
    '/Publications/Yogsattava/Newsletter/2020/yogasattava2020-1.jpg',
  newsletterAssests14:
    '/Publications/Yogsattava/Newsletter/2020/yogasattava2020-2.jpg',
  newsletterAssests15:
    '/Publications/Yogsattava/Newsletter/2020/yogasattava2020-3.jpg',
  newsletterAssests16:
    '/Publications/Yogsattava/Newsletter/2020/yogasattava2020-4.jpg',

  // 2021

  newsletterAssests17:
    '/Publications/Yogsattava/Newsletter/2021/yogasattava2021-1.jpg',
  newsletterAssests18:
    '/Publications/Yogsattava/Newsletter/2021/yogasattava2021-2.jpg',
  newsletterAssests19:
    '/Publications/Yogsattava/Newsletter/2021/yogasattava2021-3.jpg',
  newsletterAssests20:
    '/Publications/Yogsattava/Newsletter/2021/yogasattava2021-4.jpg',

  // YTH

  //2017
  ythAssets25: '/Publications/Yogsattava/PreviousIssues/2017/YogaSattva.jpg',
  ythAssets26: '/Publications/Yogsattava/PreviousIssues/2017/YogaSattva1.jpg',
  ythAssets27: '/Publications/Yogsattava/PreviousIssues/2017/YogaSattva2.jpg',
  ythAssets28: '/SEO-New-Images/Yogsattva/yogasattava2017-4-the-yoga-institute.webp',
  ythAssets29: '/SEO-New-Images/Yogsattva/yogasattava2017-3-the-yoga-institute.webp',
  ythAssets30: '/Publications/Yogsattava/PreviousIssues/2017/yogasattva5.jpg',
  ythAssets31: '/SEO-New-Images/Yogsattva/yogasattava2017-1-the-yoga-institute.webp',

  // 2018
  ythAssets1: '/Publications/Yogsattava/PreviousIssues/2018/yth2018-1.jpg',
  ythAssets2: '/Publications/Yogsattava/PreviousIssues/2018/yth2018-2.jpg',
  ythAssets3: '/Publications/Yogsattava/PreviousIssues/2018/yth2018-3.jpg',
  ythAssets4: '/Publications/Yogsattava/PreviousIssues/2018/yth2018-4.jpg',
  ythAssets5: '/Publications/Yogsattava/PreviousIssues/2018/yth2018-5.jpg',
  ythAssets6: '/Publications/Yogsattava/PreviousIssues/2018/yth2018-6.jpg',

  // 2019

  ythAssets7: '/Publications/Yogsattava/PreviousIssues/2019/yth2019-1.jpg',
  ythAssets8: '/Publications/Yogsattava/PreviousIssues/2019/yth2019-2.jpg',
  ythAssets9: '/Publications/Yogsattava/PreviousIssues/2019/yth2019-3.jpg',
  ythAssets10: '/Publications/Yogsattava/PreviousIssues/2019/yth2019-4.jpg',
  ythAssets11: '/Publications/Yogsattava/PreviousIssues/2019/yth2019-5.jpg',
  ythAssets12: '/Publications/Yogsattava/PreviousIssues/2019/yth2019-6.jpg',

  // 2020
  ythAssets13: '/Publications/Yogsattava/PreviousIssues/2020/yth2020-1.jpg',
  ythAssets14: '/Publications/Yogsattava/PreviousIssues/2020/yth2020-2.jpg',
  ythAssets15: '/Publications/Yogsattava/PreviousIssues/2020/yth2020-3.jpg',
  ythAssets16: '/Publications/Yogsattava/PreviousIssues/2020/yth2020-4.jpg',
  ythAssets17: '/Publications/Yogsattava/PreviousIssues/2020/yth2020-5.jpg',
  ythAssets18: '/Publications/Yogsattava/PreviousIssues/2020/yth2020-6.jpg',

  // 2021
  ythAssets19: '/Publications/Yogsattava/PreviousIssues/2021/yth2021-1.jpg',
  ythAssets20: '/Publications/Yogsattava/PreviousIssues/2021/yth2021-2.jpg',
  ythAssets21: '/Publications/Yogsattava/PreviousIssues/2021/yth2021-3.jpg',
  ythAssets22: '/Publications/Yogsattava/PreviousIssues/2021/yth2021-4.jpg',
  ythAssets23: '/Publications/Yogsattava/PreviousIssues/2021/yth2021-5.jpg',
  ythAssets24: '/Publications/Yogsattava/PreviousIssues/2021/yth2021-6.jpg',

  //2022
  ythAssets95: '/Home/NewsLetter/Yogasattva-April 2022.jpg',
  ythAssets96: '/Home/NewsLetter/Yogasattva-May2022.jpg',
  ythAssets85: '/Home/NewsLetter/Yogasattva-June2022--v3-1.jpg',
  ythAssets86: '/Home/NewsLetter/Yogasattva-July2022-Revised-1.jpg',
  ythAssets87: '/Home/NewsLetter/Yogasattva-August2022-1.jpg',
  ythAssets88: '/Home/NewsLetter/Yogasattva-Sept-2022.jpg',
  ythAssets89: '/Home/NewsLetter/Yogasattva-Oct-2022-21024_1.jpg',
  ythAssets90: '/Home/NewsLetter/Yogasattva-Nov-2022-cover-page.jpg',
  ythAssets91: '/Home/NewsLetter/Yogasattva-Dec-2022-Cover.jpg',


  //2023 

  ythAssets92: '/Publications/Yogsattava/PreviousIssues/2023/Yogasattva-Jan-2023.jpg',
  ythAssets93: '/home_page/Yogasattva-Feb-2023.webp',
  ythAssets94: '/home_page/Yogasattva-Mar-2023.webp',
  ythAssets97: '/SEO-New-Images/Yogsattva/Yogasattva-April2023-the-yoga-institute.webp',
  ythAssets98: '/Publications/Yogsattava/PreviousIssues/2023/Yogasattva May 2023 first page.jpg',

  //2017

  ythAssets32: '/Publications/Yogsattava/PreviousIssues/2017/YogaSattva.jpg',
  ythAssets33: '/Publications/Yogsattava/PreviousIssues/2017/YogaSattva1.jpg',
  ythAssets34: '/Publications/Yogsattava/PreviousIssues/2017/YogaSattva2.jpg',
  ythAssets35: '/Publications/Yogsattava/PreviousIssues/2017/yogasattva3.jpg',
  ythAssets36: '/Publications/Yogsattava/PreviousIssues/2017/yogasattva4.jpg',
  ythAssets37: '/Publications/Yogsattava/PreviousIssues/2017/yogasattva5.jpg',
  ythAssets38: '/Publications/Yogsattava/PreviousIssues/2017/yogasattva6.jpg',

  //2018

  ythAssets39:
    '/SEO-New-Images/Yogsattva/yogasattava2018-1-the-yoga-institute.webp',
  ythAssets40:
    '/Publications/Yogsattava/PreviousIssues/2018/YogaSattva-Aug-2019-Cover.jpg',
  ythAssets41:
    '/Publications/Yogsattava/PreviousIssues/2018/YogaSattva-January2018-PDF-1.jpg',
  ythAssets42:
    '/Publications/Yogsattava/PreviousIssues/2018/YogaSattva-July2018-1.jpeg',
  ythAssets43:
    '/Publications/Yogsattava/PreviousIssues/2018/YogaSattva-May2018-PDF-1.jpeg',
  ythAssets44:
    '/Publications/Yogsattava/PreviousIssues/2018/YogaSattva-October-2018-PDF-1.jpeg',
  ythAssets45:
    '/Publications/Yogsattava/PreviousIssues/2018/YogaSattva-TheYogaInstitute-September2018-1.jpeg',
  ythAssets46:
    '/SEO-New-Images/Yogsattva/yogasattava2018-3-the-yoga-institute.webp',
  ythAssets47:
    '/Publications/Yogsattava/PreviousIssues/2018/yogasattva-march2018-2-1.jpeg',
  ythAssets48:
    '/SEO-New-Images/Yogsattva/yogasattava2018-2-the-yoga-institute.webp',
  ythAssets49:
    '/Publications/Yogsattava/PreviousIssues/2018/yogasattva_nov_cover.jpg',

  //2019

  ythAssets50:
    '/Publications/Yogsattava/PreviousIssues/2019/new/YogaSattva-Apr-2019-TheYogaInstitute-1.jpeg',
  ythAssets51:
    '/SEO-New-Images/Yogsattva/yogasattava2019-2-the-yoga-institute.webp',
  ythAssets52:
    '/SEO-New-Images/Yogsattva/yogasattava2019-4-the-yoga-institute.webp',
  ythAssets53:
    '/Publications/Yogsattava/PreviousIssues/2019/new/YogaSattva-January19-1.jpeg',
  ythAssets54:
    '/Publications/Yogsattava/PreviousIssues/2019/new/YogaSattva-July-2019-1.jpeg',
  ythAssets55:
    '/Publications/Yogsattava/PreviousIssues/2019/new/YogaSattva-June-2019-1.jpeg',
  ythAssets56:
    '/Publications/Yogsattava/PreviousIssues/2019/new/YogaSattva-Mar-2019 _cover.png',
  ythAssets57:
    '/Publications/Yogsattava/PreviousIssues/2019/new/YogaSattva-May-2019--TheYogaInstitute-1.jpg',
  ythAssets58:
    '/Publications/Yogsattava/PreviousIssues/2019/new/Yogasattva-Dec-2019-TheYogaInstitute-1.jpeg',
  ythAssets59:
    '/Publications/Yogsattava/PreviousIssues/2019/new/Yogasattva-Nov-2019-TheYogaInstitute-1.jpeg',
  ythAssets60:
    '/Publications/Yogsattava/PreviousIssues/2019/new/Yogasattva-Oct-19-Cover.jpeg',
  ythAssets61:
    '/Publications/Yogsattava/PreviousIssues/2019/new/Yogasattva-Sept2019-1.jpeg',

  //2021

  ythAssets62:
    '/SEO-New-Images/Yogsattva/yogasattava2021-4-the-yoga-institute.webp',
  ythAssets63:
    '/SEO-New-Images/Yogsattva/yogasattava2021-1-the-yoga-institute.webp',
  ythAssets64:
    '/SEO-New-Images/Yogsattva/yogasattava2021-2-the-yoga-institute.webp',
  ythAssets65:
    '/SEO-New-Images/Yogsattva/yogasattava2021-3-the-yoga-institute.webp',
  ythAssets66:
    '/Publications/Yogsattava/PreviousIssues/2021/2021/Yogasattva-FEB-2021-1.jpeg',
  ythAssets67:
    '/Publications/Yogsattava/PreviousIssues/2021/2021/Yogasattva-JAN-2020.jpeg',
  ythAssets68:
    '/Publications/Yogsattava/PreviousIssues/2021/2021/Yogasattva-July-2021-TheYogaInstitute.jpg',
  ythAssets69:
    '/Publications/Yogsattava/PreviousIssues/2021/2021/Yogasattva-June-2021-TheYogaInstitute-1.jpeg',
  ythAssets70:
    '/Publications/Yogsattava/PreviousIssues/2021/2021/Yogasattva-May-2021-The-Yoga-Institute-1.jpeg',
  ythAssets71:
    '/Publications/Yogsattava/PreviousIssues/2021/2021/Yogasattva-November-2021-Final-1.jpeg',
  ythAssets72:
    '/Publications/Yogsattava/PreviousIssues/2021/2021/Yogasattva-September-2021-1.jpeg',

  //2020

  ythAssets73:
    '/SEO-New-Images/Yogsattva/yogasattava2020-1-the-yoga-institute.webp',
  ythAssets74:
    '/SEO-New-Images/Yogsattva/yogasattava2020-2-the-yoga-institute.webp',
  ythAssets75:
    '/SEO-New-Images/Yogsattva/yogasattava2020-3-the-yoga-institute.webp',
  ythAssets76:
    '/SEO-New-Images/Yogsattva/yogasattava2020-4-the-yoga-institute.webp',
  ythAssets77:
    '/Publications/Yogsattava/PreviousIssues/2020/new/Yogasattva-DEC-2020_F-1.jpeg',
  ythAssets78:
    '/Publications/Yogsattava/PreviousIssues/2020/new/Yogasattva-JULY-2020-1.jpeg',
  ythAssets79:
    '/Publications/Yogsattava/PreviousIssues/2020/new/Yogasattva-Jan-2020_Final-1.jpeg',
  ythAssets80:
    '/Publications/Yogsattava/PreviousIssues/2020/new/Yogasattva-MAY-2020-1.jpeg',
  ythAssets81:
    '/Publications/Yogsattava/PreviousIssues/2020/new/Yogasattva-Mar-2020-1.jpeg',
  ythAssets82:
    '/Publications/Yogsattava/PreviousIssues/2020/new/Yogasattva-NOV-2020-Cover 1.jpg',
  ythAssets83:
    '/Publications/Yogsattava/PreviousIssues/2020/new/Yogasattva-OCT-2020-TheYogaInstitute-1.jpeg',
  ythAssets84:
    '/Publications/Yogsattava/PreviousIssues/2020/new/Yogasattva-SEP-2020-1.jpeg',
}

export const alumniAssets = {
  // Alumni-Gallery
  // 2017
  galleryAssets1: '/Alumni/Alumni%20Gallery/2017/2017-1.jpg',
  galleryAssets2: '/Alumni/Alumni%20Gallery/2017/2017-2.jpg',
  galleryAssets3: '/Alumni/Alumni%20Gallery/2017/2017-3.jpg',
  galleryAssets4: '/Alumni/Alumni%20Gallery/2017/2017-4.jpg',
  galleryAssets5: '/Alumni/Alumni%20Gallery/2017/2017-5.jpg',
  galleryAssets6: '/Alumni/Alumni%20Gallery/2017/2017-6.jpg',
  galleryAssets7: '/Alumni/Alumni%20Gallery/2017/2017-7.jpg',
  galleryAssets8: '/Alumni/Alumni%20Gallery/2017/2017-8.jpg',
  galleryAssets9: '/Alumni/Alumni%20Gallery/2017/2017-9.jpg',
  galleryAssets10: '/Alumni/Alumni%20Gallery/2017/2017-10.jpg',
  galleryAssets11: '/Alumni/Alumni%20Gallery/2017/2017-11.jpg',
  galleryAssets12: '/Alumni/Alumni%20Gallery/2017/2017-12.jpg',

  // 2018
  galleryAssets13: '/Alumni/Alumni%20Gallery/2018/2018-1.jpg',
  galleryAssets14: '/Alumni/Alumni%20Gallery/2018/2018-2.jpg',
  galleryAssets15: '/Alumni/Alumni%20Gallery/2018/2018-3.jpg',
  galleryAssets16: '/Alumni/Alumni%20Gallery/2018/2018-4.jpg',
  galleryAssets17: '/Alumni/Alumni%20Gallery/2018/2018-5.jpg',
  galleryAssets18: '/Alumni/Alumni%20Gallery/2018/2018-6.jpg',
  galleryAssets19: '/Alumni/Alumni%20Gallery/2018/2018-7.jpg',
  galleryAssets20: '/Alumni/Alumni%20Gallery/2018/2018-8.jpg',
  galleryAssets21: '/Alumni/Alumni%20Gallery/2018/2018-9.jpg',
  galleryAssets22: '/Alumni/Alumni%20Gallery/2018/2018-10.jpg',
  galleryAssets23: '/Alumni/Alumni%20Gallery/2018/2018-11.jpg',
  galleryAssets24: '/Alumni/Alumni%20Gallery/2018/2018-12.jpg',

  // 2019
  galleryAssets25: '/Alumni/Alumni%20Gallery/2019/2019-1.jpg',
  galleryAssets26: '/Alumni/Alumni%20Gallery/2019/2019-2.jpg',
  galleryAssets27: '/Alumni/Alumni%20Gallery/2019/2019-3.jpg',
  galleryAssets28: '/Alumni/Alumni%20Gallery/2019/2019-4.jpg',
  galleryAssets29: '/Alumni/Alumni%20Gallery/2019/2019-5.jpg',
  galleryAssets30: '/Alumni/Alumni%20Gallery/2019/2019-6.jpg',
  galleryAssets31: '/Alumni/Alumni%20Gallery/2019/2019-7.jpg',
  galleryAssets32: '/Alumni/Alumni%20Gallery/2019/2019-8.jpg',
  galleryAssets33: '/Alumni/Alumni%20Gallery/2019/2019-9.jpg',
  galleryAssets34: '/Alumni/Alumni%20Gallery/2019/2019-10.jpg',
  galleryAssets35: '/Alumni/Alumni%20Gallery/2019/2019-11.jpg',
  galleryAssets36: '/Alumni/Alumni%20Gallery/2019/2019-12.jpg',

  // 2020
  galleryAssets37: '/Alumni/Alumni%20Gallery/2020/2020-1.jpg',
  galleryAssets38: '/Alumni/Alumni%20Gallery/2020/2020-2.jpg',
  galleryAssets39: '/Alumni/Alumni%20Gallery/2020/2020-3.jpg',
  galleryAssets40: '/Alumni/Alumni%20Gallery/2020/2020-4.jpg',
  galleryAssets41: '/Alumni/Alumni%20Gallery/2020/2020-5.jpg',
  galleryAssets42: '/Alumni/Alumni%20Gallery/2020/2020-6.jpg',
  galleryAssets43: '/Alumni/Alumni%20Gallery/2020/2020-7.jpg',
  galleryAssets44: '/Alumni/Alumni%20Gallery/2020/2020-8.jpg',
  galleryAssets45: '/Alumni/Alumni%20Gallery/2020/2020-9.jpg',
  galleryAssets46: '/Alumni/Alumni%20Gallery/2020/2020-10.jpg',
  galleryAssets47: '/Alumni/Alumni%20Gallery/2020/2020-11.jpg',
  galleryAssets48: '/Alumni/Alumni%20Gallery/2020/2020-12.jpg',

  // 2021
  galleryAssets49: '/Alumni/Alumni%20Gallery/2021/2021-1.jpg',
  galleryAssets50: '/Alumni/Alumni%20Gallery/2021/2021-2.jpg',
  galleryAssets51: '/Alumni/Alumni%20Gallery/2021/2021-3.jpg',
  galleryAssets52: '/Alumni/Alumni%20Gallery/2021/2021-4.jpg',
  galleryAssets53: '/Alumni/Alumni%20Gallery/2021/2021-5.jpg',
  galleryAssets54: '/Alumni/Alumni%20Gallery/2021/2021-6.jpg',
  galleryAssets55: '/Alumni/Alumni%20Gallery/2021/2021-7.jpg',
  galleryAssets56: '/Alumni/Alumni%20Gallery/2021/2021-8.jpg',
  galleryAssets57: '/Alumni/Alumni%20Gallery/2021/2021-9.jpg',
  galleryAssets58: '/Alumni/Alumni%20Gallery/2021/2021-10.jpg',
  galleryAssets59: '/Alumni/Alumni%20Gallery/2021/2021-11.jpg',
  galleryAssets60: '/Alumni/Alumni%20Gallery/2021/2021-12.jpg',

  // 2022
  galleryAssets61: '/Alumni/Alumni%20Gallery/2022/2022-1.jpg',
  galleryAssets62: '/Alumni/Alumni%20Gallery/2022/2022-2.jpg',
  galleryAssets63: '/Alumni/Alumni%20Gallery/2022/2022-3.jpg',
  galleryAssets64: '/Alumni/Alumni%20Gallery/2022/2022-4.jpg',
  galleryAssets65: '/Alumni/Alumni%20Gallery/2022/2022-5.jpg',
  galleryAssets66: '/Alumni/Alumni%20Gallery/2022/2022-6.jpg',
  galleryAssets67: '/Alumni/Alumni%20Gallery/2022/2022-7.jpg',
  galleryAssets68: '/Alumni/Alumni%20Gallery/2022/2022-8.jpg',
  galleryAssets69: '/Alumni/Alumni%20Gallery/2022/2022-9.jpg',
  galleryAssets70: '/Alumni/Alumni%20Gallery/2022/2022-10.jpg',
  galleryAssets71: '/Alumni/Alumni%20Gallery/2022/2022-11.jpg',
  galleryAssets72: '/Alumni/Alumni%20Gallery/2022/2022-12.jpg',

  // Support-AnnamBrahma

  supportAssets1: '/Alumni/Support/Annam Brahma/img-1.jpg',
  supportAssets2: '/Alumni/Support/Annam Brahma/img-2.jpg',
  supportAssets3: '/Alumni/Support/Annam Brahma/img-3.jpg',
  supportAssets4: '/Alumni/Support/Annam Brahma/img-4.jpg',
  supportAssets5: '/Alumni/Support/Annam Brahma/img-5.jpg',
  supportAssets6: '/Alumni/Support/Annam Brahma/img-6.jpg',
  // Upcoming Events

  eventsAssetsTitle: '/Alumni/Upcoming Events/upcoming-events.jpg',
  eventsAssets1: '/Alumni/Upcoming Events/upcoming-event-1.jpg',
  eventsAssets2: '/Alumni/Upcoming Events/upcoming-event-2.jpg',
  eventsAssets3: '/Alumni/Upcoming Events/upcoming-event-3.jpg',

  // AlumniDay
  alumniDayAssets1: '/Alumni/Alumni Day/alumniGallery0.jpg',
  alumniDayAssets2: '/Alumni/Alumni Day/alumniGallery1.jpg',
  alumniDayAssets3: '/Alumni/Alumni Day/alumniGallery2.jpg',
  alumniDayAssets4: '/Alumni/Alumni Day/alumniGallery3.jpg',
  alumniDayAssets5: '/Alumni/Alumni Day/alumniGallery3.jpg',
}

//  Museum
export const museumAssets = {
  // Virual Tour
  virualAssets: '/SEO-New-Images/Museum/Virtual-tour-the-yoga-institute-museum.webp',

  // gallery

  meseumAssets1: '/Museum/Gallery/m_1.jpg',
  meseumAssets2: '/SEO-New-Images/Museum/the-yoga-institute-museum.webp',
  meseumAssets3: '/SEO-New-Images/Museum/the-yoga-institute-museum-1.webp',
  meseumAssets4: '/SEO-New-Images/Museum/the-yoga-institute-museum-2.webp',
  meseumAssets5: '/SEO-New-Images/Museum/the-yoga-institute-museum-3.webp',
  meseumAssets6: '/SEO-New-Images/Museum/the-yoga-institute-museum-4.webp',
  meseumAssets7: '/SEO-New-Images/Museum/the-yoga-institute-museum-5.webp',
  meseumAssets8: '/SEO-New-Images/Museum/the-yoga-institute-museum-6.webp',
  meseumAssets9: '/SEO-New-Images/Museum/the-yoga-institute-museum-7.webp',
  meseumAssets10: '/SEO-New-Images/Museum/the-yoga-institute-museum-8.webp',
  meseumAssets11: '/SEO-New-Images/Museum/the-yoga-institute-museum-9.webp',
  meseumAssets12: '/SEO-New-Images/Museum/the-yoga-institute-museum-10.webp',
  meseumAssets13: '/SEO-New-Images/Museum/the-yoga-institute-museum-11.webp',
  meseumAssets14: '/SEO-New-Images/Museum/the-yoga-institute-museum-12.webp',
  meseumAssets15: '/SEO-New-Images/Museum/the-yoga-institute-museum-13.webp',
  meseumAssets16: '/SEO-New-Images/Museum/the-yoga-institute-museum-14.webp',
  meseumAssets17: '/SEO-New-Images/Museum/the-yoga-institute-museum-15.webp',
  meseumAssets18: '/SEO-New-Images/Museum/the-yoga-institute-museum-16.webp',
}
//  Donation
export const donationAssets = {
  // background
  donationAsset1: '/Donation/background/background-donation.jpg',
  // annam-brahma
  annamAssets: '/Donation/Annam-brahma/Annam-Brahma-1.jpg',

  // bamc-school
  bmcAssets: '/Donation/BMC-school/bmc-7.jpg',

  // children
  childrenAssets: '/Donation/special-children/cover-special.jpg',

  // community
  communityAssets: '/Donation/Annam-brahma/Annam-Brahma-1.jpg',

  // police
  policeAssets: '/Donation/mumbai-police/police-4.jpg',

  // trucksana
  truckasanaAssets: '/Donation/trucksana/truckasana-1.jpg',

  // underprivileged
  underprivilegedAssets: '/Donation/BMC-school/bmc-7.jpg',

  //transgender
  transgender: '/Donation/transgender/transgender-cover.jpg',
}
//  gifting

export const giftingAssets = {
  widsomassets: '/Gifting/gift-wisdom.jpg',
  careerAssets: '/Gifting/gift-acareer.jpg',
  nispandAssets: '/Gifting/gift nispand.jpg',
  healthAssets: '/Gifting/Gift health & wellness.jpg',
}

export const volunteerAssets = {
  volunteerAssets1: '/Volunteer/Annam-Brahma/annam-1.jpg',
  volunteerAssets2: '/Volunteer/Bmc-Schools/volunteer-bmc.jpg',
  volunteerAssets3: '/Volunteer/Traffic-police/volunteer-3.jpg',
  volunteerAssets4: '/Volunteer/Trees-plantation/planting-modified.jpg',
  volunteerAssets5: '/Volunteer/new11/course-coordinator-cover-image.jpg',
  volunteerAssets6: '/SEO-New-Images/Day_Activities/the-yoga-institute--activities.webp',
  volunteerAssets7: '/SEO-New-Images/Kitchen_Works/kitchen-help-cover-the-yoga-institute.webp',
  volunteerAssets8: '/Volunteer/new11/teachers-cover-image.jpg',
  volunteerAssets9:
    '/Volunteer/new11/volunteer-programs-expertise-cover-image.jpg',
  //values
  valuesAssets: '/Volunteer/our-values.jpg',

  //anna brahma
  volunteerAssets10: '/SEO-New-Images/Annan-Brahma/annam-brahma - The Yoga Institute.webp',
  volunteerAssets11: '/Volunteer/latest/AnnamBramha/1st-image-min.JPG',
  volunteerAssets12: '/SEO-New-Images/Annan-Brahma/annam-brahma -food-donation.webp',
  volunteerAssets13: '/Volunteer/latest/AnnamBramha/cover-image-min.JPG',
  volunteerAssets14: '/SEO-New-Images/Annan-Brahma/annam-brahma-food--donation.webp',
  volunteerAssets15: '/Volunteer/latest/AnnamBramha/img_0971-1-1-min.JPG',
  volunteerAssets16: '/SEO-New-Images/Annan-Brahma/annam-brahma-food-donation.webp',
  volunteerAssets17: '/SEO-New-Images/Annan-Brahma/annam-brahma -food-donation-tyi.webp',
  //course coordinator

  volunteerAssets18: '/SEO-New-Images/Coordinator/the-yoga-institute-class-coordinator.webp',
  volunteerAssets19: '/Volunteer/latest/Course-Coordinator/29-16x9.jpg',
  volunteerAssets20: '/SEO-New-Images/Coordinator/the-yoga-institute-class_coordinator.webp',
  volunteerAssets21: '/SEO-New-Images/Coordinator/the-yoga-institute--class-coordinator.webp',
  volunteerAssets22: '/SEO-New-Images/Coordinator/the-yoga-institute-class--coordinator.webp',
  volunteerAssets23: '/SEO-New-Images/Coordinator/the-yoga-institute_class-coordinator.webp',
  //day to day avtivities

  volunteerAssets45: '/Volunteer/latest/day-to-day-activities/16x9-01.jpg',
  volunteerAssets46: '/SEO-New-Images/Day_Activities/the-yoga-institute_activities.webp',
  volunteerAssets47: '/SEO-New-Images/Day_Activities/the-yoga-institute.activities.webp',
  volunteerAssets48: '/SEO-New-Images/Day_Activities/the-yoga-institute-activities.webp',
  volunteerAssets49: '/Volunteer/latest/day-to-day-activities/4X3-01.jpg',
  volunteerAssets50: '/SEO-New-Images/Day_Activities/the_yoga_institute_activities.webp',

  //expertise

  volunteerAssets24: '/Volunteer/latest/Expertise/16x9-01.jpg',
  volunteerAssets25: '/SEO-New-Images/Expetitse/the-yoga-institute-volunteer-programs-expertise.webp',
  volunteerAssets26: '/Volunteer/latest/Expertise/1x1-01.jpg',
  volunteerAssets28: '/SEO-New-Images/Expetitse/the-yoga-institute-volunteer--programs-expertise.webp',
  volunteerAssets29: '/Volunteer/latest/Expertise/4X3-01.jpg',
  volunteerAssets30: '/Volunteer/latest/Expertise/4X3.jpg',

  //kitchen help

  volunteerAssets31: '/Volunteer/latest/Kitchen-Help/16x9-01.jpg',
  volunteerAssets32: '/SEO-New-Images/Kitchen_Works/kitchen-the-yoga-institute.webp',
  volunteerAssets33: '/SEO-New-Images/Kitchen_Works/kitchen-the-yoga-institute-1x1-01-min.webp',
  volunteerAssets34: '/SEO-New-Images/Kitchen_Works/kitchen-the-yoga-institute-1x1.webp',
  volunteerAssets35: '/SEO-New-Images/Kitchen_Works/kitchen-the-yoga-institute-4X3-01.webp',
  volunteerAssets37: '/SEO-New-Images/Kitchen_Works/kitchen-the-yoga-institute-4X3.webp',
  volunteerAssets51: '/',

  //teacher

  volunteerAssets38: '/SEO-New-Images/Day_Activities/the-yoga-institute__activities.webp',
  volunteerAssets39: '/Volunteer/latest/Teacher/16x9.jpg',
  volunteerAssets40: '/SEO-New-Images/Teacher/teachers-cover-the-yoga-institute.webp',
  volunteerAssets41: '/Volunteer/latest/Teacher/1x1.jpg',
  volunteerAssets42: '/Volunteer/latest/Teacher/4X3-01.jpg',
  volunteerAssets43: '/Volunteer/latest/Teacher/4X3.jpg',
  volunteerAssets44: '/Volunteer/latest/Teacher/cover-image -min.jpg',
}

// home => facts Assets

export const factAssets = {
  // 1916-1936
  factAsset1: '/home_page/1916_copy.webp',
  factAsset2: '/Home/Facts/1916-1936/1918.jpg',
  factAsset3: '/Home/Facts/1916-1936/1920.jpg',
  factAsset4: '/Home/Facts/1916-1936/1921.jpg',
  factAsset5: '/Home/Facts/1916-1936/1924.jpg',
  factAsset6: '/Home/Facts/1916-1936/1928.jpg',
  factAsset7: '/Home/Facts/1916-1936/1933.jpg',
  factAsset8: '/Home/Facts/1916-1936/1933_1.jpg',
  factAsset9: '/Home/Facts/1916-1936/1934.jpg',

  // 1936-56
  factAsset10: '/Home/Facts/1936-1956/1940.jpg',
  factAsset11: '/Home/Facts/1936-1956/1941.jpg',
  factAsset12: '/Home/Facts/1936-1956/1948.jpg',
  factAsset13: '/Home/Facts/1936-1956/1951.jpg',
  factAsset98:
    '/Home/Facts/new11/1941-the-yoga-manual-written-by-shri-yogendra-and-shri-jayadeva-yogendra-translated-in-italian.jpg',

  // 1956-76

  factAsset14: '/Home/Facts/1956-1976/1958.jpg',
  factAsset15: '/Home/Facts/1956-1976/1961.jpg',
  factAsset16: '/Home/Facts/1956-1976/1963.jpg',
  factAsset17: '/Home/Facts/1956-1976/1963_1.jpg',
  factAsset18: '/Home/Facts/1956-1976/1970.jpg',
  factAsset19: '/Home/Facts/1956-1976/1972.jpg',

  // 1990-2000

  factAsset20: '/Home/Facts/1990-2000/1986.jpg',
  factAsset21: '/Home/Facts/1990-2000/1986_1.jpg',
  factAsset22: '/Home/Facts/1990-2000/1987.jpg',
  factAsset23: '/Home/Facts/1990-2000/1987_1.jpg',
  factAsset24: '/Home/Facts/1990-2000/1987_2.jpg',
  factAsset25: '/Home/Facts/1990-2000/1990.jpg',
  factAsset26: '/Home/Facts/1990-2000/1997.jpg',
  factAsset27: '/Home/Facts/1990-2000/8.jpg',
  factAsset28: '/Home/Facts/1990-2000/9.jpg',
  factAsset29: '/Home/Facts/1990-2000/10.jpg',
  factAsset30: '/Home/Facts/1990-2000/11.jpg',
  factAsset31: '/Home/Facts/1990-2000/12.jpg',
  factAsset32: '/Home/Facts/1990-2000/13.jpg',
  factAsset33: '/Home/Facts/1990-2000/14.jpg',
  factAsset34: '/Home/Facts/1990-2000/15.jpg',
  factAsset35: '/Home/Facts/1990-2000/16.jpg',
  factAsset36: '/Home/Facts/1990-2000/17.jpg',
  factAsset38: '/Home/Facts/1990-2000/18.jpg',
  factAsset39: '/Home/Facts/1990-2000/19.jpg',
  factAsset40: '/Home/Facts/1990-2000/20.jpg',
  factAsset41: '/Home/Facts/1990-2000/21.jpg',
  factAsset42: '/Home/Facts/1990-2000/22.jpg',
  factAsset43: '/Home/Facts/1990-2000/23.jpg',
  factAsset44: '/Home/Facts/1990-2000/24.jpg',
  factAsset45: '/Home/Facts/1990-2000/25.jpg',
  factAsset46: '/Home/Facts/1990-2000/26.jpg',
  factAsset99: '/Home/Facts/new11/1987-first-volume-of-yoga-cyclopaedia..jpg',

  // 2000-2010

  factAsset47: '/Home/Facts/2000-2010/1.jpg',
  factAsset48: '/Home/Facts/2000-2010/2.jpg',
  factAsset49: '/Home/Facts/2000-2010/3.jpg',
  factAsset50: '/Home/Facts/2000-2010/4.jpg',
  factAsset51: '/Home/Facts/2000-2010/5.jpg',
  factAsset52: '/Home/Facts/2000-2010/6.jpg',
  factAsset53: '/Home/Facts/2000-2010/7.jpg',
  factAsset54: '/Home/Facts/2000-2010/8.jpg',

  // 2010-2020
  factAsset55: '/Home/Facts/2010-2020/1.jpg',
  factAsset56: '/Home/Facts/2010-2020/2.jpg',
  factAsset57: '/Home/Facts/2010-2020/3.jpg',
  factAsset58: '/Home/Facts/2010-2020/4.jpg',
  factAsset59: '/Home/Facts/2010-2020/5.jpg',
  factAsset60: '/Home/Facts/2010-2020/6.jpg',
  factAsset61: '/Home/Facts/2010-2020/7.jpg',
  factAsset62: '/Home/Facts/2010-2020/8.jpg',
  factAsset63: '/Home/Facts/2010-2020/9.jpg',
  factAsset64: '/Home/Facts/2010-2020/10.jpg',
  factAsset65: '/Home/Facts/2010-2020/11.jpg',
  factAsset66: '/Home/Facts/2010-2020/12.jpg',
  factAsset67: '/Home/Facts/2010-2020/13.jpg',
  factAsset68: '/Home/Facts/2010-2020/14.jpg',
  factAsset69: '/Home/Facts/2010-2020/15.jpg',
  factAsset70: '/Home/Facts/2010-2020/16.jpg',
  factAsset71: '/Home/Facts/2010-2020/17.jpg',
  factAsset72: '/Home/Facts/2010-2020/18.jpg',
  factAsset73: '/Home/Facts/2010-2020/19.jpg',
  factAsset74: '/Home/Facts/2010-2020/20.jpg',

  // 2020-onwards
  factAsset75: '/Home/Facts/2020-onwards/1.jpg',
  factAsset76: '/Home/Facts/2020-onwards/2.jpg',
  factAsset77: '/Home/Facts/2020-onwards/3.jpg',
  factAsset78: '/Home/Facts/2020-onwards/4.jpg',
  factAsset79: '/Home/Facts/2020-onwards/5.jpg',
  factAsset80: '/Home/Facts/2020-onwards/6.jpg',
  factAsset81: '/Home/Facts/2020-onwards/7.jpg',
  factAsset82: '/Home/Facts/2020-onwards/8.jpg',
  factAsset83: '/Home/Facts/2020-onwards/9.jpg',
  factAsset84: '/Home/Facts/2020-onwards/10.jpg',
  factAsset85: '/Home/Facts/2020-onwards/11.jpg',
  factAsset86: '/Home/Facts/2020-onwards/12.jpg',
  factAsset87: '/Home/Facts/2020-onwards/13.jpg',
  factAsset88: '/Home/Facts/2020-onwards/14.jpg',
  factAsset89: '/Home/Facts/2020-onwards/15.jpg',
  factAsset90: '/Home/Facts/2020-onwards/16.jpg',
  factAsset91: '/Home/Facts/2020-onwards/17.jpg',
  factAsset92: '/Home/Facts/2020-onwards/18.jpg',
  factAsset93: '/Home/Facts/2020-onwards/19.jpg',
  factAsset94: '/Home/Facts/2020-onwards/20.jpg',
  factAsset95: '/Home/Facts/2020-onwards/21.jpg',
  factAsset96: '/Home/Facts/2020-onwards/22.jpg',
  factAsset97: '/Home/Facts/2020-onwards/23.jpg',

  //updated facts image

  //1916-1936
  factAsset100: '/home_page/(3)-1x1copy.webp',
  factAsset101: '/home_page/1918_versova-4x3copy.webp',
  factAsset102:
    '/Home/Facts/new/1916-1936/(4 )1916_shri-madhavdasji_founder-4x3.jpg',
  factAsset103: '/home_page/1920_harriman-usa-16x9_copy.webp',
  factAsset104:
    '/home_page/1924_surendranath-das-gupta-16x9_copy.webp',
  factAsset105: '/home_page/1928_yoga-hygiene-1x1_copy.webp',

  //1936-1956

  factAsset106: '/Home/Facts/new/1936-1956/1940_crypt-of-civilization-1X1.jpg',
  factAsset107: '/Home/Facts/new/1936-1956/1940_crypt-of-civilization-4x3.jpg',
  factAsset108: '/Home/Facts/new/1936-1956/1941_the-yoga-manual(copy).jpg',
  factAsset109: '/Home/Facts/new/1936-1956/1941_the-yoga-manual.jpg',
  factAsset110: '/Home/Facts/new/1936-1956/1948_the-yoga-institute-4X3.jpg',
  factAsset111: '/Home/Facts/new/1936-1956/1951_unesco-1X1.jpg',
  factAsset146: '/Home/Facts/1936-1956/Bldg.jpg',

  //1956-1976

  factAsset112:
    '/Home/Facts/new/1956-1976/1958_the-central-government-16x9.jpg',
  factAsset113: '/Home/Facts/new/1956-1976/1961_yoga-in-modern-1x1-min.jpg',
  factAsset114: '/Home/Facts/new/1956-1976/1963_dr- zakir-hussain-16x9.jpg',
  factAsset115: '/Home/Facts/new/1956-1976/1963_x-ray-research-4x3.jpg',
  factAsset116: '/Home/Facts/new/1956-1976/1970-medical-research-1x1.jpg',
  factAsset117:
    '/Home/Facts/new/1956-1976/1972_the-yoga -institute-organized-4x3.jpg',

  //1976-2000

  factAsset118: '/Home/Facts/new/1976-2000/1986_ research-project-1x1.jpg',
  factAsset119: '/Home/Facts/new/1976-2000/1986_dr-shankar-dayal-4x3.jpg',
  factAsset120: '/Home/Facts/new/1976-2000/1987_first-volume-of-yoga-1x1.jpg',
  factAsset121: '/Home/Facts/new/1976-2000/1987_giani-4x3.jpg',
  factAsset122: '/Home/Facts/new/1976-2000/1990_unique-experiment-19x6.jpg',
  factAsset123: '/Home/Facts/new/1976-2000/1997-hansaji-16x9.jpg',

  //2000-2010

  factAsset124: '/Home/Facts/new/2000-2010/2000_woman-of-the-year-1x1.jpg',
  factAsset125: '/Home/Facts/new/2000-2010/2010_rashtriya-ayurveda-19x9.jpg',
  factAsset126: '/Home/Facts/new/2000-2010/2010_the-central-council-1x1.jpg',
  factAsset127: '/Home/Facts/new/2000-2010/dsc_4649-4x3.jpg',
  factAsset128: '/Home/Facts/new/2000-2010/dsc_4737-16x9.jpg',
  factAsset129: '/Home/Facts/new/2000-2010/dsc_4740-1x1.jpg',

  //2010-2020

  factAsset130: '/Home/Facts/new/2010-2020/2012_94th-foundation--4x3.jpg',
  factAsset131: '/Home/Facts/new/2010-2020/cover-image-1x1.jpg',
  factAsset132: '/Home/Facts/new/2010-2020/dalai-lama-4x.jpg',
  factAsset133: '/Home/Facts/new/2010-2020/indian-yoga-association-16x9.jpg',
  factAsset134: '/Home/Facts/new/2010-2020/loksabha-1x1.jpg',
  factAsset135: '/Home/Facts/new/2010-2020/shri-vishwanath-16x9.jpg',

  //2020 onwards

  factAsset136: '/Home/Facts/new/2020onwards/1x1.jpg',
  factAsset137: '/Home/Facts/new/2020onwards/dsc-116x9.jpg',
  factAsset138: '/Home/Facts/new/2020onwards/dsc-16x9.jpg',
  factAsset139: '/Home/Facts/new/2020onwards/indu-1x1.jpg',
  factAsset140: '/Home/Facts/new/2020onwards/whatsapp-image-2021-14x3.jpg',
  factAsset141: '/Home/Facts/new/2020onwards/whatsapp-image2020-4x3.jpg',

  //extra

  factAsset142: '/Home/Facts/new/governor-maharashtra-1986..jpg',
  factAsset143: '/Home/Facts/new/hansaji-jayadeva.png',
  factAsset144: '/Home/Facts/new/shankar.jpg',
  factAsset145: '/Home/Facts/new/vinshnu-anna.jpg',
}

export const socialResponsibilityAssets = {
  annamBrahma: '/SocialResponsibility/Annam-brahma-cover.jpg',
  bmcSchool: '/SocialResponsibility/BMC-school-cover.jpg',
  police: '/SocialResponsibility/Copy _of_IMG_0166.jpg',
  schoolChildren: '/SocialResponsibility/School_children_cover.jpg',
  specialChildren: '/SocialResponsibility/Special_children_cover.jpg',
  trafficPolice: '/SocialResponsibility/Traffic_police_rename.jpg',
  truckAsana: '/SocialResponsibility/Truckasana_cover (1).jpg',
  palnting: '/SocialResponsibility/planting_cover.jpg',
  transgender: '/SocialResponsibility/transgender_cover.jpg',
}

export const experienceAssets = {
  ajankiya: '/Home/Experience/Ajinkya.jpg',
  amitabhBacchan: '/Home/Experience/AmitabhBachchan.jpg',
  dishaVakhani: '/Home/Experience/Disha%20Vakhani.jpg',
  madanBahal: '/Home/Experience/Madan Bahal.jpg',
  modiji: '/Home/Experience/Modiji.jpg',
  naiduji: '/Home/Experience/Naiduji.jpg',
  nirmalGangwal: '/Home/Experience/Nirmal-Gangwal.png',
  rujutaDiwekar: '/Home/Experience/RUJUTA%20DIWEKAR.jpg',
  shilpaShirodkar: '/Home/Experience/Shilpa%20Shirodkar.jpg',
  shivKumarSharma: '/Home/Experience/Shivkumar%20Sharma.jpg',
  shwetaTiwari: '/Home/Experience/Shweta%20Tiwari.jpg',
  sukhwinderSingh: '/Home/Experience/Sukhwinder%20Singh.jpg',
  ankitaSood: '/Home/Experience/ankita sood.jpg',
  carlo: '/Home/Experience/carlo.jpeg',
  kavita: '/Home/Experience/kavita.jpg',
  natalia: '/Home/Experience/natalia.JPG',
  kovindji: '/Home/Experience/shriramnathkovid.png',
  veronica: '/Home/Experience/veronica.JPG',
  premchandgodha: '/Home/Experience/Premchand%20Godha.jpg',
  ravishankar: '/Home/Experience/shri-shri-ravi-shankar.jpeg',
  sadhguru: '/Home/Experience/sadhguru-jaggi-vasudev.jpg',
  mahadeshwar:
    '/Home/Experience/shri-c-vidyasagar-rao-governor-of-maharashtra.jpeg',
  nagendra: '/Home/Experience/padmashri-dr-h-r-nagendra-guruji.jpg',
  bharatBhushan: '/Home/Experience/swami-bharat-bhushan-ji.jpg',
  chidanand: '/Home/Experience/chidanand-saraswati.jpg',
  gopalDas: '/Home/Experience/gaur-gopal-das.jpg',
  vidyasagar:
    '/Home/Experience/shri-c-vidyasagar-rao-governor-of-maharashtra.jpeg',
  isha: '/Home/Experience/isha-koppikar.jpg',
  yesso: '/Home/Experience/shripad yesso naik.jpg',
  shankarM: '/Home/Experience/shankar-mahadevan.jpg',
  nitinG: '/Home/Experience/nitin-gadkari.png',
  ranvirBrar: '/Home/Experience/chef-ranveer-brar.jpg',
  kabirB: '/Home/Experience/kabir-bedi.jpg',
  billBennet: '/Home/Experience/bill-bennett.jpeg',
  jennifer: '/Home/Experience/jennifer-cluff.jpg',
  shivaniBajaj: '/Home/Experience/shivani.jpeg',
  jinal: '/Home/Experience/zinel.jpg',
  nidhiSharma: '/Home/Experience/nidhi.JPG',
  mercyD: '/Home/Experience/mercy-min.jpg',
  nisarg: '/Home/Experience/nisarg.jpg',
  zinal: '',
}

export const studentExperienceAssests = {
  Rohan: '/Home/Student-Experience/Rohan-Singh.png',
  Jacqueline: '/Home/Student-Experience/Jacqueline-Mulliert.png',
  Kiran: '/Home/Student-Experience/Kiran-Kapoor.png',
  Monika: '/Home/Student-Experience/Monika-Mehta.png',
  Sankshita: '/Home/Student-Experience/Sankshita-Zutshi.png',
  Tamara: '/Home/Student-Experience/Tamara-Bućma-Kecman.png',
  Tanvi: '/Home/Student-Experience/Tanvi-Jain-200-hrs-TTC-student.png',
  Vaidehi: '/Home/Student-Experience/Vaidehi-Ajit-Kale.png',
  Virginie: '/Home/Student-Experience/Virginie-Albar-Mounier.png',
  Angela: '/Alumni/new_alumni/Angela.png',
  Deepti: '/Alumni/new_alumni/Deepti.png',
  Hirak: '/Alumni/new_alumni/Hirak.png',
  Jose: '/Alumni/new_alumni/Jose.png',
  Kelly: '/Alumni/new_alumni/Kelly.png',
  Marwa: '/Alumni/new_alumni/Marwa.png',
  Neeran: '/Alumni/new_alumni/Neeran.png',
  Niki: '/Alumni/new_alumni/Niki.png',
  Pascal: '/Alumni/new_alumni/Pascal.png',
  Tonatiuh: '/Alumni/new_alumni/Tonatiuh.png',
  Tran: '/Alumni/new_alumni/tran.png',
}
export const CSR = {
  peace: '/CSR/peace-justice.png',
  hunger: '/CSR/zero-hunger.png',
  responsible: '/CSR/responsible1.png',
  health: '/CSR/good-health.png',
}

export const wellnessAssets = {
  wellnessAssets1: '/wellness/corporate_wellness_background%20(1).jpg',
  wellnessAssets2: '/wellness/our_offerings_left .jpeg',
  wellnessAssets3: '/wellness/Our_offerings_right.png',
  wellnessAssets4: '/wellness/wellness_program_at_work (1).jpg',
  wellnessAssets5: '/wellness/wellness_programs_at_work1.jpg',
  wellnessAssets6: '/wellness/wellness_programs_at_work_2.jpg',
  wellnessAssets7: '/wellness/wellness_programs_at_work_3.jpg',
}

export const newsAssets = {
  printMedia: '/Media/print-media.jpg',
  digitalMedia: '/Media/digital-media.jpg'
}


export const printMediaAssest = {
  //archives

  printMediaAssest1: '/print_media/newspaper_archives/image789.jpeg',
  printMediaAssest2: '/print_media/newspaper_archives/image790.jpeg',
  printMediaAssest3: '/print_media/newspaper_archives/image791.jpeg',
  printMediaAssest4: '/print_media/newspaper_archives/image792.jpeg',
  printMediaAssest5: '/print_media/newspaper_archives/image793.jpeg',
  printMediaAssest6: '/print_media/newspaper_archives/image794.jpeg',
  printMediaAssest7: '/print_media/newspaper_archives/image795.jpeg',
  printMediaAssest8: '/print_media/newspaper_archives/image796.jpeg',
  printMediaAssest9: '/print_media/newspaper_archives/image797.jpeg',
  printMediaAssest10: '/print_media/newspaper_archives/image798.jpeg',
  printMediaAssest11: '/print_media/newspaper_archives/image799.jpeg',
  printMediaAssest12: '/print_media/newspaper_archives/image800.jpeg',
  printMediaAssest13: '/print_media/newspaper_archives/image801.jpeg',
  printMediaAssest14: '/print_media/newspaper_archives/image802.jpeg',
  printMediaAssest15: '/print_media/newspaper_archives/image803.jpeg',
  printMediaAssest16: '/print_media/newspaper_archives/image804.jpeg',
  printMediaAssest17: '/print_media/newspaper_archives/image805.jpeg',
  printMediaAssest18: '/print_media/newspaper_archives/image806.jpeg',
  printMediaAssest19: '/print_media/newspaper_archives/image807.jpeg',
  printMediaAssest20: '/print_media/newspaper_archives/image808.jpeg',
  printMediaAssest21: '/print_media/newspaper_archives/image809.jpeg',
  printMediaAssest22: '/print_media/newspaper_archives/image810.jpeg',
  printMediaAssest23: '/print_media/newspaper_archives/image811.jpeg',
  printMediaAssest24: '/print_media/newspaper_archives/image812.jpeg',
  printMediaAssest25: '/print_media/newspaper_archives/image813.jpeg',
  printMediaAssest26: '/print_media/newspaper_archives/image814.jpeg',
  printMediaAssest27: '/print_media/newspaper_archives/image815.jpeg',
  printMediaAssest28: '/print_media/newspaper_archives/image816.jpeg',
  printMediaAssest29: '/print_media/newspaper_archives/image817.jpeg',
  printMediaAssest30: '/print_media/newspaper_archives/image818.jpeg',
  printMediaAssest31: '/print_media/newspaper_archives/image819.jpeg',
  printMediaAssest32: '/print_media/newspaper_archives/image820.jpeg',
  printMediaAssest33: '/print_media/newspaper_archives/image821.jpeg',
  printMediaAssest34: '/print_media/newspaper_archives/image822.jpeg',
  printMediaAssest35: '/print_media/newspaper_archives/image823.jpeg',
  printMediaAssest36: '/print_media/newspaper_archives/image824.jpeg',
  printMediaAssest37: '/print_media/newspaper_archives/image825.jpeg',
  printMediaAssest38: '/print_media/newspaper_archives/image826.jpeg',
  printMediaAssest39: '/print_media/newspaper_archives/image827.jpeg',
  printMediaAssest40: '/print_media/newspaper_archives/image828.jpeg',
  printMediaAssest41: '/print_media/newspaper_archives/image829.jpeg',
  printMediaAssest42: '/print_media/newspaper_archives/image830.jpeg',
  printMediaAssest43: '/print_media/newspaper_archives/image831.jpeg',
  printMediaAssest44: '/print_media/newspaper_archives/image832.jpeg',
  printMediaAssest45: '/print_media/newspaper_archives/image833.jpeg',
  printMediaAssest46: '/print_media/newspaper_archives/image834.jpeg',
  printMediaAssest47: '/print_media/newspaper_archives/image835.jpeg',
  printMediaAssest48: '/print_media/newspaper_archives/image836.jpeg',
  printMediaAssest49: '/print_media/newspaper_archives/image837.jpeg',
  printMediaAssest50: '/print_media/newspaper_archives/image838.jpeg',
  printMediaAssest51: '/print_media/newspaper_archives/image839.jpeg',
  printMediaAssest52: '/print_media/newspaper_archives/image840.jpeg',
  printMediaAssest53: '/print_media/newspaper_archives/image841.jpeg',
  printMediaAssest54: '/print_media/newspaper_archives/image842.jpeg',
  printMediaAssest55: '/print_media/newspaper_archives/image843.jpeg',
  printMediaAssest56: '/print_media/newspaper_archives/image844.jpeg',
  printMediaAssest57: '/print_media/newspaper_archives/image845.jpeg',
  printMediaAssest58: '/print_media/newspaper_archives/image846.jpeg',
  printMediaAssest59: '/print_media/newspaper_archives/image847.jpeg',
  printMediaAssest60: '/print_media/newspaper_archives/image848.jpeg',
  printMediaAssest61: '/print_media/newspaper_archives/image849.jpeg',
  printMediaAssest62: '/print_media/newspaper_archives/image850.jpeg',
  printMediaAssest63: '/print_media/newspaper_archives/image851.jpeg',
  printMediaAssest64: '/print_media/newspaper_archives/image852.jpeg',
  printMediaAssest65: '/print_media/newspaper_archives/image853.jpeg',
  printMediaAssest66: '/print_media/newspaper_archives/image854.jpeg',
  printMediaAssest67: '/print_media/newspaper_archives/image855.jpeg',
  printMediaAssest68: '/print_media/newspaper_archives/image856.jpeg',
  printMediaAssest69: '/print_media/newspaper_archives/image857.jpeg',
  printMediaAssest70: '/print_media/newspaper_archives/image858.jpeg',
  printMediaAssest71: '/print_media/newspaper_archives/image859.jpeg',
  printMediaAssest72: '/print_media/newspaper_archives/image860.jpeg',
  printMediaAssest73: '/print_media/newspaper_archives/image861.jpeg',
  printMediaAssest74: '/print_media/newspaper_archives/image862.jpeg',
  printMediaAssest75: '/print_media/newspaper_archives/image863.jpeg',
  printMediaAssest76: '/print_media/newspaper_archives/image864.jpeg',
  printMediaAssest77: '/print_media/newspaper_archives/image865.jpeg',
  printMediaAssest78: '/print_media/newspaper_archives/image866.jpeg',
  printMediaAssest79: '/print_media/newspaper_archives/image867.jpeg',
  printMediaAssest80: '/print_media/newspaper_archives/image868.jpeg',
  printMediaAssest81: '/print_media/newspaper_archives/image869.jpeg',
  printMediaAssest82: '/print_media/newspaper_archives/image870.jpeg',
  printMediaAssest83: '/print_media/newspaper_archives/image871.jpeg',
  printMediaAssest84: '/print_media/newspaper_archives/image872.jpeg',
  printMediaAssest85: '/print_media/newspaper_archives/image873.jpeg',
  printMediaAssest86: '/print_media/newspaper_archives/image874.jpeg',
  printMediaAssest87: '/print_media/newspaper_archives/image875.jpeg',
  printMediaAssest88: '/print_media/newspaper_archives/image876.jpeg',
  printMediaAssest89: '/print_media/newspaper_archives/image877.jpeg',
  printMediaAssest90: '/print_media/newspaper_archives/image878.jpeg',
  printMediaAssest91: '/print_media/newspaper_archives/image879.jpeg',
  printMediaAssest92: '/print_media/newspaper_archives/image880.jpeg',
  printMediaAssest93: '/print_media/newspaper_archives/image881.jpeg',
  printMediaAssest94: '/print_media/newspaper_archives/image882.jpeg',
  printMediaAssest95: '/print_media/newspaper_archives/image883.jpeg',
  printMediaAssest96: '/print_media/newspaper_archives/image884.jpeg',
  printMediaAssest97: '/print_media/newspaper_archives/image885.jpeg',
  printMediaAssest98: '/print_media/newspaper_archives/image886.jpeg',
  printMediaAssest99: '/print_media/newspaper_archives/image887.jpeg',
  printMediaAssest100: '/print_media/newspaper_archives/image888.jpeg',
  printMediaAssest101: '/print_media/newspaper_archives/image889.jpeg',
  printMediaAssest102: '/print_media/newspaper_archives/image890.jpeg',
  printMediaAssest103: '/print_media/newspaper_archives/image891.jpeg',
  printMediaAssest104: '/print_media/newspaper_archives/image892.jpeg',
  printMediaAssest105: '/print_media/newspaper_archives/image893.jpeg',
  printMediaAssest106: '/print_media/newspaper_archives/image894.jpeg',
  printMediaAssest107: '/print_media/newspaper_archives/image895.jpeg',
  printMediaAssest108: '/print_media/newspaper_archives/image896.jpeg',
  printMediaAssest109: '/print_media/newspaper_archives/image897.jpeg',
  printMediaAssest110: '/print_media/newspaper_archives/image898.jpeg',
  printMediaAssest111: '/print_media/newspaper_archives/image899.jpeg',
  printMediaAssest112: '/print_media/newspaper_archives/image900.jpeg',
  printMediaAssest113: '/print_media/newspaper_archives/image901.jpeg',
  printMediaAssest114: '/print_media/newspaper_archives/image902.jpeg',
  printMediaAssest115: '/print_media/newspaper_archives/image903.jpeg',
  printMediaAssest116: '/print_media/newspaper_archives/image904.jpeg',
  printMediaAssest117: '/print_media/newspaper_archives/image905.jpeg',
  printMediaAssest118: '/print_media/newspaper_archives/image906.jpeg',
  printMediaAssest119: '/print_media/newspaper_archives/image907.jpeg',
  printMediaAssest120: '/print_media/newspaper_archives/image908.jpeg',
  printMediaAssest121: '/print_media/newspaper_archives/image909.jpeg',
  printMediaAssest122: '/print_media/newspaper_archives/image910.jpeg',
  printMediaAssest123: '/print_media/newspaper_archives/image911.jpeg',
  printMediaAssest124: '/print_media/newspaper_archives/image912.jpeg',
  printMediaAssest125: '/print_media/newspaper_archives/image913.jpeg',
  printMediaAssest126: '/print_media/newspaper_archives/image914.jpeg',
  printMediaAssest127: '/print_media/newspaper_archives/image915.jpeg',
  printMediaAssest128: '/print_media/newspaper_archives/image916.jpeg',
  printMediaAssest129: '/print_media/newspaper_archives/image917.jpeg',
  printMediaAssest130: '/print_media/newspaper_archives/image918.jpeg',
  printMediaAssest131: '/print_media/newspaper_archives/image919.jpeg',
  printMediaAssest132: '/print_media/newspaper_archives/image920.jpeg',
  printMediaAssest133: '/print_media/newspaper_archives/image921.jpeg',
  printMediaAssest134: '/print_media/newspaper_archives/image922.jpeg',
  printMediaAssest135: '/print_media/newspaper_archives/image923.jpeg',
  printMediaAssest136: '/print_media/newspaper_archives/image924.jpeg',
  printMediaAssest137: '/print_media/newspaper_archives/image925.jpeg',
  printMediaAssest138: '/print_media/newspaper_archives/image926.jpeg',
  printMediaAssest139: '/print_media/newspaper_archives/image927.jpeg',
  printMediaAssest140: '/print_media/newspaper_archives/image928.jpeg',
  printMediaAssest141: '/print_media/newspaper_archives/image929.jpeg',
  printMediaAssest142: '/print_media/newspaper_archives/image930.jpeg',

  //2014

  printMediaAssest143: '/print_media/2014/image760.jpeg',
  printMediaAssest144: '/print_media/2014/image761.jpeg',
  printMediaAssest145: '/print_media/2014/image762.jpeg',
  printMediaAssest146: '/print_media/2014/image763.jpeg',
  printMediaAssest147: '/print_media/2014/image764.jpeg',
  printMediaAssest148: '/print_media/2014/image765.jpeg',
  printMediaAssest149: '/print_media/2014/image766.jpeg',
  printMediaAssest150: '/print_media/2014/image767.jpeg',
  printMediaAssest151: '/print_media/2014/image768.jpeg',
  printMediaAssest152: '/print_media/2014/image769.jpeg',
  printMediaAssest153: '/print_media/2014/image770.jpeg',
  printMediaAssest154: '/print_media/2014/image771.jpeg',
  printMediaAssest155: '/print_media/2014/image772.jpeg',
  printMediaAssest156: '/print_media/2014/image773.jpeg',
  printMediaAssest157: '/print_media/2014/image774.jpeg',
  printMediaAssest158: '/print_media/2014/image775.jpeg',
  printMediaAssest159: '/print_media/2014/image776.jpeg',
  printMediaAssest160: '/print_media/2014/image777.jpeg',
  printMediaAssest161: '/print_media/2014/image778.jpeg',
  printMediaAssest162: '/print_media/2014/image779.jpeg',
  printMediaAssest163: '/print_media/2014/image780.jpeg',
  printMediaAssest164: '/print_media/2014/image781.jpeg',
  printMediaAssest165: '/print_media/2014/image782.jpeg',
  printMediaAssest166: '/print_media/2014/image783.jpeg',
  printMediaAssest167: '/print_media/2014/image784.jpeg',
  printMediaAssest168: '/print_media/2014/image785.jpeg',
  printMediaAssest169: '/print_media/2014/image786.jpeg',
  printMediaAssest170: '/print_media/2014/image787.jpeg',
  printMediaAssest171: '/print_media/2014/image788.jpeg',

  //2015
  printMediaAssest172: '/print_media/2015/image743.jpeg',
  printMediaAssest173: '/print_media/2015/image744.jpeg',
  printMediaAssest174: '/print_media/2015/image745.jpeg',
  printMediaAssest175: '/print_media/2015/image746.jpeg',
  printMediaAssest176: '/print_media/2015/image747.jpeg',
  printMediaAssest177: '/print_media/2015/image748.jpeg',
  printMediaAssest178: '/print_media/2015/image749.jpeg',
  printMediaAssest179: '/print_media/2015/image750.jpeg',
  printMediaAssest180: '/print_media/2015/image751.jpeg',
  printMediaAssest181: '/print_media/2015/image752.jpeg',
  printMediaAssest182: '/print_media/2015/image753.jpeg',
  printMediaAssest183: '/print_media/2015/image754.jpeg',
  printMediaAssest184: '/print_media/2015/image755.jpeg',
  printMediaAssest185: '/print_media/2015/image756.jpeg',
  printMediaAssest186: '/print_media/2015/image757.jpeg',
  printMediaAssest187: '/print_media/2015/image758.jpeg',
  printMediaAssest188: '/print_media/2015/image759.jpeg',

  //2016

  printMediaAssest189: '/print_media/2016/image694.jpeg',
  printMediaAssest190: '/print_media/2016/image695.jpeg',
  printMediaAssest191: '/print_media/2016/image696.jpeg',
  printMediaAssest192: '/print_media/2016/image697.jpeg',
  printMediaAssest193: '/print_media/2016/image698.jpeg',
  printMediaAssest194: '/print_media/2016/image699.jpeg',
  printMediaAssest195: '/print_media/2016/image700.jpeg',
  printMediaAssest196: '/print_media/2016/image701.jpeg',
  printMediaAssest197: '/print_media/2016/image702.jpeg',
  printMediaAssest198: '/print_media/2016/image703.jpeg',
  printMediaAssest199: '/print_media/2016/image704.jpeg',
  printMediaAssest200: '/print_media/2016/image705.jpeg',
  printMediaAssest201: '/print_media/2016/image706.jpeg',
  printMediaAssest202: '/print_media/2016/image707.jpeg',
  printMediaAssest203: '/print_media/2016/image708.jpeg',
  printMediaAssest204: '/print_media/2016/image709.jpeg',
  printMediaAssest205: '/print_media/2016/image710.jpeg',
  printMediaAssest206: '/print_media/2016/image711.jpeg',
  printMediaAssest207: '/print_media/2016/image712.jpeg',
  printMediaAssest208: '/print_media/2016/image713.jpeg',
  printMediaAssest209: '/print_media/2016/image714.jpeg',
  printMediaAssest210: '/print_media/2016/image715.jpeg',
  printMediaAssest211: '/print_media/2016/image716.jpeg',
  printMediaAssest212: '/print_media/2016/image717.jpeg',
  printMediaAssest213: '/print_media/2016/image718.jpeg',
  printMediaAssest214: '/print_media/2016/image719.jpeg',
  printMediaAssest215: '/print_media/2016/image720.jpeg',
  printMediaAssest216: '/print_media/2016/image721.jpeg',
  printMediaAssest217: '/print_media/2016/image722.jpeg',
  printMediaAssest218: '/print_media/2016/image723.jpeg',
  printMediaAssest219: '/print_media/2016/image724.jpeg',
  printMediaAssest220: '/print_media/2016/image725.jpeg',
  printMediaAssest221: '/print_media/2016/image726.jpeg',
  printMediaAssest222: '/print_media/2016/image727.jpeg',
  printMediaAssest223: '/print_media/2016/image728.jpeg',
  printMediaAssest224: '/print_media/2016/image729.jpeg',
  printMediaAssest225: '/print_media/2016/image730.jpeg',
  printMediaAssest226: '/print_media/2016/image731.jpeg',
  printMediaAssest227: '/print_media/2016/image732.jpeg',
  printMediaAssest228: '/print_media/2016/image733.jpeg',
  printMediaAssest229: '/print_media/2016/image734.jpeg',
  printMediaAssest230: '/print_media/2016/image735.jpeg',
  printMediaAssest231: '/print_media/2016/image736.jpeg',
  printMediaAssest232: '/print_media/2016/image737.jpeg',
  printMediaAssest233: '/print_media/2016/image738.jpeg',
  printMediaAssest234: '/print_media/2016/image739.jpeg',
  printMediaAssest235: '/print_media/2016/image740.jpeg',
  printMediaAssest236: '/print_media/2016/image741.jpeg',
  printMediaAssest237: '/print_media/2016/image742.jpeg',

  //2017

  printMediaAssest238: '/print_media/2017/image600.jpeg',
  printMediaAssest239: '/print_media/2017/image601.jpeg',
  printMediaAssest240: '/print_media/2017/image602.jpeg',
  printMediaAssest241: '/print_media/2017/image604.jpeg',
  printMediaAssest242: '/print_media/2017/image605.jpeg',
  printMediaAssest243: '/print_media/2017/image606.jpeg',
  printMediaAssest244: '/print_media/2017/image607.jpeg',
  printMediaAssest245: '/print_media/2017/image608.jpeg',
  printMediaAssest246: '/print_media/2017/image609.jpeg',
  printMediaAssest247: '/print_media/2017/image610.jpeg',
  printMediaAssest248: '/print_media/2017/image611.jpeg',
  printMediaAssest249: '/print_media/2017/image612.jpeg',
  printMediaAssest250: '/print_media/2017/image613.jpeg',
  printMediaAssest251: '/print_media/2017/image614.jpeg',
  printMediaAssest252: '/print_media/2017/image615.jpeg',
  printMediaAssest253: '/print_media/2017/image616.jpeg',
  printMediaAssest254: '/print_media/2017/image617.jpeg',
  printMediaAssest255: '/print_media/2017/image618.jpeg',
  printMediaAssest256: '/print_media/2017/image619.jpeg',
  printMediaAssest257: '/print_media/2017/image620.jpeg',
  printMediaAssest258: '/print_media/2017/image621.jpeg',
  printMediaAssest259: '/print_media/2017/image622.jpeg',
  printMediaAssest260: '/print_media/2017/image623.jpeg',
  printMediaAssest261: '/print_media/2017/image624.jpeg',
  printMediaAssest262: '/print_media/2017/image625.jpeg',
  printMediaAssest263: '/print_media/2017/image626.jpeg',
  printMediaAssest264: '/print_media/2017/image627.jpeg',
  printMediaAssest265: '/print_media/2017/image628.jpeg',
  printMediaAssest266: '/print_media/2017/image629.jpeg',
  printMediaAssest267: '/print_media/2017/image630.jpeg',
  printMediaAssest268: '/print_media/2017/image631.jpeg',
  printMediaAssest269: '/print_media/2017/image632.jpeg',
  printMediaAssest270: '/print_media/2017/image633.jpeg',
  printMediaAssest271: '/print_media/2017/image634.jpeg',
  printMediaAssest272: '/print_media/2017/image635.jpeg',
  printMediaAssest273: '/print_media/2017/image636.jpeg',
  printMediaAssest274: '/print_media/2017/image637.jpeg',
  printMediaAssest275: '/print_media/2017/image639.jpeg',
  printMediaAssest276: '/print_media/2017/image640.jpeg',
  printMediaAssest277: '/print_media/2017/image641.jpeg',
  printMediaAssest278: '/print_media/2017/image642.jpeg',
  printMediaAssest279: '/print_media/2017/image643.jpeg',
  printMediaAssest280: '/print_media/2017/image644.jpeg',
  printMediaAssest281: '/print_media/2017/image645.jpeg',
  printMediaAssest282: '/print_media/2017/image646.jpeg',
  printMediaAssest283: '/print_media/2017/image647.jpeg',
  printMediaAssest284: '/print_media/2017/image648.jpeg',
  printMediaAssest285: '/print_media/2017/image649.jpeg',
  printMediaAssest286: '/print_media/2017/image650.jpeg',
  printMediaAssest287: '/print_media/2017/image651.jpeg',
  printMediaAssest288: '/print_media/2017/image652.jpeg',
  printMediaAssest289: '/print_media/2017/image653.jpeg',
  printMediaAssest290: '/print_media/2017/image654.jpeg',
  printMediaAssest291: '/print_media/2017/image655.jpeg',
  printMediaAssest292: '/print_media/2017/image656.jpeg',
  printMediaAssest293: '/print_media/2017/image657.jpeg',
  printMediaAssest294: '/print_media/2017/image658.jpeg',
  printMediaAssest295: '/print_media/2017/image659.jpeg',
  printMediaAssest296: '/print_media/2017/image660.jpeg',
  printMediaAssest297: '/print_media/2017/image661.jpeg',
  printMediaAssest298: '/print_media/2017/image662.jpeg',
  printMediaAssest299: '/print_media/2017/image663.jpeg',
  printMediaAssest300: '/print_media/2017/image664.jpeg',
  printMediaAssest301: '/print_media/2017/image665.jpeg',
  printMediaAssest302: '/print_media/2017/image666.jpeg',
  printMediaAssest303: '/print_media/2017/image667.jpeg',
  printMediaAssest304: '/print_media/2017/image668.jpeg',
  printMediaAssest305: '/print_media/2017/image669.jpeg',
  printMediaAssest306: '/print_media/2017/image670.jpeg',
  printMediaAssest307: '/print_media/2017/image671.jpeg',
  printMediaAssest308: '/print_media/2017/image672.jpeg',
  printMediaAssest309: '/print_media/2017/image673.jpeg',
  printMediaAssest310: '/print_media/2017/image674.jpeg',
  printMediaAssest311: '/print_media/2017/image675.jpeg',
  printMediaAssest312: '/print_media/2017/image676.jpeg',
  printMediaAssest313: '/print_media/2017/image677.jpeg',
  printMediaAssest314: '/print_media/2017/image678.jpeg',
  printMediaAssest315: '/print_media/2017/image679.jpeg',
  printMediaAssest316: '/print_media/2017/image680.jpeg',
  printMediaAssest317: '/print_media/2017/image681.jpeg',
  printMediaAssest318: '/print_media/2017/image682.jpeg',
  printMediaAssest319: '/print_media/2017/image683.jpeg',
  printMediaAssest320: '/print_media/2017/image684.jpeg',
  printMediaAssest321: '/print_media/2017/image685.jpeg',
  printMediaAssest322: '/print_media/2017/image686.jpeg',
  printMediaAssest323: '/print_media/2017/image687.jpeg',
  printMediaAssest324: '/print_media/2017/image688.jpeg',
  printMediaAssest325: '/print_media/2017/image689.jpeg',
  printMediaAssest326: '/print_media/2017/image689.jpeg',
  printMediaAssest327: '/print_media/2017/image690.jpeg',
  printMediaAssest328: '/print_media/2017/image691.jpeg',
  printMediaAssest329: '/print_media/2017/image692.jpeg',
  printMediaAssest330: '/print_media/2017/image693.jpeg',

  //2018

  printMediaAssest331: '/print_media/2018/image428.jpeg',
  printMediaAssest332: '/print_media/2018/image429.jpeg',
  printMediaAssest333: '/print_media/2018/image430.jpeg',
  printMediaAssest334: '/print_media/2018/image431.jpeg',
  printMediaAssest335: '/print_media/2018/image432.jpeg',
  printMediaAssest336: '/print_media/2018/image433.jpeg',
  printMediaAssest337: '/print_media/2018/image434.jpeg',
  printMediaAssest338: '/print_media/2018/image435.jpeg',
  printMediaAssest339: '/print_media/2018/image436.jpeg',
  printMediaAssest340: '/print_media/2018/image437.jpeg',
  printMediaAssest341: '/print_media/2018/image438.jpeg',
  printMediaAssest342: '/print_media/2018/image439.jpeg',
  printMediaAssest343: '/print_media/2018/image440.jpeg',
  printMediaAssest344: '/print_media/2018/image441.jpeg',
  printMediaAssest345: '/print_media/2018/image442.jpeg',
  printMediaAssest346: '/print_media/2018/image443.jpeg',
  printMediaAssest347: '/print_media/2018/image444.jpeg',
  printMediaAssest348: '/print_media/2018/image445.jpeg',
  printMediaAssest349: '/print_media/2018/image446.jpeg',
  printMediaAssest350: '/print_media/2018/image447.jpeg',
  printMediaAssest351: '/print_media/2018/image448.jpeg',
  printMediaAssest352: '/print_media/2018/image449.jpeg',
  printMediaAssest353: '/print_media/2018/image450.jpeg',
  printMediaAssest354: '/print_media/2018/image451.jpeg',
  printMediaAssest355: '/print_media/2018/image451.jpeg',
  printMediaAssest356: '/print_media/2018/image453.jpeg',
  printMediaAssest357: '/print_media/2018/image454.jpeg',
  printMediaAssest358: '/print_media/2018/image455.jpeg',
  printMediaAssest359: '/print_media/2018/image456.jpeg',
  printMediaAssest360: '/print_media/2018/image457.jpeg',
  printMediaAssest361: '/print_media/2018/image458.jpeg',
  printMediaAssest362: '/print_media/2018/image459.jpeg',
  printMediaAssest363: '/print_media/2018/image460.jpeg',
  printMediaAssest364: '/print_media/2018/image461.jpeg',
  printMediaAssest365: '/print_media/2018/image462.jpeg',
  printMediaAssest366: '/print_media/2018/image463.jpeg',
  printMediaAssest367: '/print_media/2018/image464.jpeg',
  printMediaAssest368: '/print_media/2018/image465.jpeg',
  printMediaAssest369: '/print_media/2018/image466.jpeg',
  printMediaAssest370: '/print_media/2018/image467.jpeg',
  printMediaAssest371: '/print_media/2018/image468.jpeg',
  printMediaAssest372: '/print_media/2018/image469.jpeg',
  printMediaAssest373: '/print_media/2018/image470.jpeg',
  printMediaAssest374: '/print_media/2018/image471.jpeg',
  printMediaAssest375: '/print_media/2018/image471.jpeg',
  printMediaAssest376: '/print_media/2018/image473.jpeg',
  printMediaAssest377: '/print_media/2018/image474.jpeg',
  printMediaAssest378: '/print_media/2018/image475.jpeg',
  printMediaAssest379: '/print_media/2018/image476.jpeg',
  printMediaAssest380: '/print_media/2018/image477.jpeg',
  printMediaAssest381: '/print_media/2018/image478.jpeg',
  printMediaAssest382: '/print_media/2018/image479.jpeg',
  printMediaAssest383: '/print_media/2018/image479.jpeg',
  printMediaAssest384: '/print_media/2018/image481.jpeg',
  printMediaAssest385: '/print_media/2018/image482.jpeg',
  printMediaAssest386: '/print_media/2018/image483.jpeg',
  printMediaAssest387: '/print_media/2018/image484.jpeg',
  printMediaAssest388: '/print_media/2018/image485.jpeg',
  printMediaAssest389: '/print_media/2018/image486.jpeg',
  printMediaAssest390: '/print_media/2018/image487.jpeg',
  printMediaAssest391: '/print_media/2018/image488.jpeg',
  printMediaAssest392: '/print_media/2018/image489.jpeg',
  printMediaAssest393: '/print_media/2018/image490.jpeg',
  printMediaAssest394: '/print_media/2018/image491.jpeg',
  printMediaAssest395: '/print_media/2018/image492.jpeg',
  printMediaAssest396: '/print_media/2018/image493.jpeg',
  printMediaAssest397: '/print_media/2018/image494.jpeg',
  printMediaAssest398: '/print_media/2018/image495.jpeg',
  printMediaAssest399: '/print_media/2018/image496.jpeg',
  printMediaAssest400: '/print_media/2018/image497.jpeg',
  printMediaAssest401: '/print_media/2018/image498.jpeg',
  printMediaAssest402: '/print_media/2018/image499.jpeg',
  printMediaAssest403: '/print_media/2018/image500.jpeg',
  printMediaAssest404: '/print_media/2018/image501.jpeg',
  printMediaAssest405: '/print_media/2018/image502.jpeg',
  printMediaAssest406: '/print_media/2018/image503.jpeg',
  printMediaAssest407: '/print_media/2018/image504.jpeg',
  printMediaAssest408: '/print_media/2018/image505.jpeg',
  printMediaAssest409: '/print_media/2018/image506.jpeg',
  printMediaAssest410: '/print_media/2018/image507.jpeg',
  printMediaAssest411: '/print_media/2018/image508.jpeg',
  printMediaAssest412: '/print_media/2018/image509.jpeg',
  printMediaAssest413: '/print_media/2018/image510.jpeg',
  printMediaAssest414: '/print_media/2018/image511.jpeg',
  printMediaAssest415: '/print_media/2018/image512.jpeg',
  printMediaAssest416: '/print_media/2018/image513.jpeg',
  printMediaAssest417: '/print_media/2018/image514.jpeg',
  printMediaAssest418: '/print_media/2018/image515.jpeg',
  printMediaAssest419: '/print_media/2018/image516.jpeg',
  printMediaAssest420: '/print_media/2018/image517.jpeg',
  printMediaAssest421: '/print_media/2018/image518.jpeg',
  printMediaAssest422: '/print_media/2018/image519.jpeg',
  printMediaAssest423: '/print_media/2018/image520.jpeg',
  printMediaAssest424: '/print_media/2018/image521.jpeg',
  printMediaAssest425: '/print_media/2018/image522.jpeg',
  printMediaAssest426: '/print_media/2018/image523.jpeg',
  printMediaAssest427: '/print_media/2018/image524.jpeg',
  printMediaAssest428: '/print_media/2018/image525.jpeg',
  printMediaAssest429: '/print_media/2018/image526.jpeg',
  printMediaAssest430: '/print_media/2018/image527.jpeg',
  printMediaAssest431: '/print_media/2018/image528.jpeg',
  printMediaAssest432: '/print_media/2018/image528.jpeg',
  printMediaAssest433: '/print_media/2018/image529.jpeg',
  printMediaAssest434: '/print_media/2018/image530.jpeg',
  printMediaAssest435: '/print_media/2018/image531.jpeg',
  printMediaAssest436: '/print_media/2018/image532.jpeg',
  printMediaAssest437: '/print_media/2018/image533.jpeg',
  printMediaAssest438: '/print_media/2018/image534.jpeg',
  printMediaAssest439: '/print_media/2018/image535.jpeg',
  printMediaAssest440: '/print_media/2018/image536.jpeg',
  printMediaAssest441: '/print_media/2018/image537.jpeg',
  printMediaAssest442: '/print_media/2018/image538.jpeg',
  printMediaAssest443: '/print_media/2018/image539.jpeg',
  printMediaAssest444: '/print_media/2018/image540.jpeg',
  printMediaAssest445: '/print_media/2018/image541.jpeg',
  printMediaAssest446: '/print_media/2018/image542.jpeg',
  printMediaAssest447: '/print_media/2018/image543.jpeg',
  printMediaAssest448: '/print_media/2018/image544.jpeg',
  printMediaAssest449: '/print_media/2018/image545.jpeg',
  printMediaAssest450: '/print_media/2018/image546.jpeg',
  printMediaAssest451: '/print_media/2018/image547.jpeg',
  printMediaAssest452: '/print_media/2018/image548.jpeg',
  printMediaAssest453: '/print_media/2018/image549.jpeg',
  printMediaAssest454: '/print_media/2018/image550.jpeg',
  printMediaAssest455: '/print_media/2018/image551.jpeg',
  printMediaAssest456: '/print_media/2018/image552.jpeg',
  printMediaAssest457: '/print_media/2018/image553.jpeg',
  printMediaAssest458: '/print_media/2018/image554.jpeg',
  printMediaAssest459: '/print_media/2018/image555.jpeg',
  printMediaAssest460: '/print_media/2018/image556.jpeg',
  printMediaAssest461: '/print_media/2018/image557.jpeg',
  printMediaAssest462: '/print_media/2018/image558.jpeg',
  printMediaAssest463: '/print_media/2018/image559.jpeg',
  printMediaAssest464: '/print_media/2018/image560.jpeg',
  printMediaAssest465: '/print_media/2018/image561.jpeg',
  printMediaAssest466: '/print_media/2018/image562.jpeg',
  printMediaAssest467: '/print_media/2018/image563.jpeg',
  printMediaAssest468: '/print_media/2018/image564.jpeg',
  printMediaAssest469: '/print_media/2018/image565.jpeg',
  printMediaAssest470: '/print_media/2018/image566.jpeg',
  printMediaAssest471: '/print_media/2018/image567.jpeg',
  printMediaAssest472: '/print_media/2018/image568.jpeg',
  printMediaAssest473: '/print_media/2018/image569.jpeg',
  printMediaAssest474: '/print_media/2018/image570.jpeg',
  printMediaAssest475: '/print_media/2018/image571.jpeg',
  printMediaAssest476: '/print_media/2018/image572.jpeg',
  printMediaAssest477: '/print_media/2018/image573.jpeg',
  printMediaAssest478: '/print_media/2018/image574.jpeg',
  printMediaAssest479: '/print_media/2018/image575.jpeg',
  printMediaAssest480: '/print_media/2018/image576.jpeg',
  printMediaAssest481: '/print_media/2018/image577.jpeg',
  printMediaAssest482: '/print_media/2018/image578.jpeg',
  printMediaAssest483: '/print_media/2018/image579.jpeg',
  printMediaAssest484: '/print_media/2018/image580.jpeg',
  printMediaAssest485: '/print_media/2018/image581.jpeg',
  printMediaAssest486: '/print_media/2018/image582.jpeg',
  printMediaAssest487: '/print_media/2018/image583.jpeg',
  printMediaAssest488: '/print_media/2018/image584.jpeg',
  printMediaAssest489: '/print_media/2018/image585.jpeg',
  printMediaAssest490: '/print_media/2018/image586.jpeg',
  printMediaAssest491: '/print_media/2018/image587.jpeg',
  printMediaAssest492: '/print_media/2018/image588.jpeg',
  printMediaAssest493: '/print_media/2018/image589.jpeg',
  printMediaAssest494: '/print_media/2018/image590.jpeg',
  printMediaAssest495: '/print_media/2018/image591.jpeg',
  printMediaAssest496: '/print_media/2018/image592.jpeg',
  printMediaAssest497: '/print_media/2018/image593.jpeg',
  printMediaAssest498: '/print_media/2018/image594.jpeg',
  printMediaAssest499: '/print_media/2018/image595.jpeg',
  printMediaAssest500: '/print_media/2018/image596.jpeg',
  printMediaAssest501: '/print_media/2018/image597.jpeg',
  printMediaAssest502: '/print_media/2018/image598.jpeg',
  printMediaAssest931: '/print_media/2018/image599.jpeg',

  //2019


  printMediaAssest503: '/print_media/2019/ABP-maza-live-marathi-News_10th-Jan-2019.jpg',
  printMediaAssest504: '/print_media/2019/Aacharan_5th-January-2019.jpg',
  printMediaAssest505: '/print_media/2019/Biospectrum_14th-June-2019.jpg',
  printMediaAssest506: '/print_media/2019/Business-remedies_2nd-January-2019.jpg',
  printMediaAssest507: '/print_media/2019/Bussiness-standard-28th-Dec-2019.jpeg',
  printMediaAssest508: '/print_media/2019/DABANG-DUNIA-22nd-June-2019.jpg',
  printMediaAssest509: '/print_media/2019/DNA_6th-January-2019.jpg',
  printMediaAssest510: '/print_media/2019/Dailyhunt_6th-Jan-2019.jpg',
  printMediaAssest511: '/print_media/2019/Dainik-Bhor_1st-January-2019.jpg',
  printMediaAssest512: '/print_media/2019/Et-Auto.com-from-The-Economic-Times_20th-June-2019.jpg',
  printMediaAssest513: '/print_media/2019/Express-Healthcare_21st-June-2019.jpg',
  printMediaAssest514: '/print_media/2019/Flipboard_6th-Jan-2019.jpg',
  printMediaAssest515: '/print_media/2019/Forever-News-21st-June-2019.jpg',
  printMediaAssest516: '/print_media/2019/Forever-News_25th-July-2019.png',
  printMediaAssest517: '/print_media/2019/Future-Kerala_1st-January-2019.jpg',
  printMediaAssest518: '/print_media/2019/GUJARAT-SAMACHAR-22nd-June-2019.jpg',
  printMediaAssest519: '/print_media/2019/Global-News-Service_24th-July-2019.jpg',
  printMediaAssest520: '/print_media/2019/Gorakhpur-Mail_1st-January-2019.jpg',
  printMediaAssest521: '/print_media/2019/Gujarat%20Samachar_18th-Sept-2019.jpg',
  printMediaAssest522: '/print_media/2019/Hello-Mumbai_12th-December-2018.jpg',
  printMediaAssest523: '/print_media/2019/Her--zindagi.jpeg',
  printMediaAssest524: '/print_media/2019/Her-Zindagi.jpeg',
  printMediaAssest525: '/print_media/2019/Her-zindagi.jpeg',
  printMediaAssest526: '/print_media/2019/Hindmata-Prime-News_24th-July-2019.jpg',
  printMediaAssest527: '/print_media/2019/Hindustan-Express_31st-January-2019.jpg',
  printMediaAssest528: '/print_media/2019/I-Next_2nd-January-2019.jpg',
  printMediaAssest529: '/print_media/2019/Indiantelevision_20th-June-2019.jpg',
  printMediaAssest530: '/print_media/2019/JANMABHOOMI-22nd-June-2019.jpg',
  printMediaAssest531: '/print_media/2019/Janmabhoomi_18th-December-2018.jpg',
  printMediaAssest532: '/print_media/2019/Jeevan-Express_2nd-January-2019.jpg',
  printMediaAssest533: '/print_media/2019/Kerala-Bhooshanam-Daily_3rd-January-2019.jpg',
  printMediaAssest534: '/print_media/2019/LOKMAT-22nd-June-2019.jpg',
  printMediaAssest535: '/print_media/2019/LOKMAT-HELLO-MUMBAI-22nd-June-2019.jpg',
  printMediaAssest536: '/print_media/2019/Lokmat-Hello-mumbai-paper_29th-December-2019.jpg',
  printMediaAssest537: '/print_media/2019/Lokmat_25th-July-2019.jpg',
  printMediaAssest538: '/print_media/2019/Lokmat_25th-July-2019.jpg',
  printMediaAssest539: '/print_media/2019/MAHARASHTRA-TIMES-22nd-June-2019.jpg',
  printMediaAssest540: '/print_media/2019/MUMBAI-TARUN-BHARAT-22-June-2019.jpg',
  printMediaAssest541: '/print_media/2019/MUMBAI-TARUN-BHARAT-22nd-June-2019.jpg',
  printMediaAssest542: '/print_media/2019/Maharashtra-Times_25th-July-2019.jpg',
  printMediaAssest543: '/print_media/2019/Medical-Dialogues.jpg',
  printMediaAssest544: '/print_media/2019/Mission-Journalism_24th-July-2019.jpg',
  printMediaAssest545: '/print_media/2019/NAVBHARAT-22nd-June-2019.jpg',
  printMediaAssest546: '/print_media/2019/NAVBHARAT-TIMES-22nd-June-2019.jpg',
  printMediaAssest547: '/print_media/2019/Navabharat-6th-Dec-2019.jpg',
  printMediaAssest548: '/print_media/2019/Navbharat-Times-15th-Dec-2019.jpg',
  printMediaAssest549: '/print_media/2019/Navbharat-Times-22nd-Dec-2019.jpg',
  printMediaAssest550: '/print_media/2019/Navbharat-Times-29th-Dec-2019.jpg',
  printMediaAssest551: '/print_media/2019/Navbharat-Times-8th-Dec-2019.jpg',
  printMediaAssest552: '/print_media/2019/Navbharat-Times.jpg',
  printMediaAssest553: '/print_media/2019/Navbharat-Times_13th-June-2019.jpg',
  printMediaAssest554: '/print_media/2019/Navbharat-Times_14th-June-2019.jpg',
  printMediaAssest555: '/print_media/2019/Navbharat-times_31st-March-2019.jpg',
  printMediaAssest556: '/print_media/2019/Navbharattimes_221st-July-2019.jpg',
  printMediaAssest557: '/print_media/2019/New-Age_1st-January-2019.jpg',
  printMediaAssest558: '/print_media/2019/News-Harpal.jpeg',
  printMediaAssest559: '/print_media/2019/News18.jpeg',
  printMediaAssest560: '/print_media/2019/NewsBytes_6th-Jan-2019.jpg',
  printMediaAssest561: '/print_media/2019/Nyoooz_6th-Jan-2019.jpg',
  printMediaAssest562: '/print_media/2019/Peoples-Samachar_6th-January-2019.jpg',
  printMediaAssest563: '/print_media/2019/SANDESH-22-June-2019.jpg',
  printMediaAssest564: '/print_media/2019/Samana-paper_28th-December-2019.jpg',
  printMediaAssest565: '/print_media/2019/Samana-paper_29th-December-2019.jpg',
  printMediaAssest566: '/print_media/2019/Sandesh-Gujarati_19th-December-2018.jpg',
  printMediaAssest567: '/print_media/2019/Sanjeevni-Today_25th-July-2019.jpg',
  printMediaAssest568: '/print_media/2019/Swadesh_31st-January-2019.jpg',
  printMediaAssest569: '/print_media/2019/Swatantra-Chetna_1st-January-2019.jpg',
  printMediaAssest570: '/print_media/2019/THE-HINDU.jpg',
  printMediaAssest571: '/print_media/2019/The-Indian-Express_2nd-January-2019.jpg',
  printMediaAssest572: '/print_media/2019/The-Indian-Express_5th-February-2019.jpg',
  printMediaAssest573: '/print_media/2019/The-New-Indian-Express_1st-Jan-2019.jpg',
  printMediaAssest574: '/print_media/2019/The-Times-of-India_19th-Feb-2019.png',
  printMediaAssest575: '/print_media/2019/The-Times-of-India_1st-April-2019.jpg',
  printMediaAssest576: '/print_media/2019/The-Times-of-India_1st-April-2019.jpg',
  printMediaAssest577: '/print_media/2019/The-Times-of-India_2nd-February-2019.jpg',
  printMediaAssest578: '/print_media/2019/The-Times-of-India_30th-January-2019.jpg',
  printMediaAssest579: '/print_media/2019/The-Times-of-India_31st-January-2019.jpg',
  printMediaAssest580: '/print_media/2019/The-Times-of-India_4th-February-2019.jpg',
  printMediaAssest581: '/print_media/2019/Times-of-India_16th-June-2019.jpg',
  printMediaAssest582: '/print_media/2019/Times-of-india-india-times.jpeg',
  printMediaAssest583: '/print_media/2019/UNN.jpg',
  printMediaAssest584: '/print_media/2019/VRUTTA-MANAS-22nd-June-2019.jpg',
  printMediaAssest585: '/print_media/2019/everything-experimental.jpeg',
  printMediaAssest586: '/print_media/2019/herzindagi.jpeg',
  printMediaAssest587: '/print_media/2019/hindmata-prime-news-tyi.png',
  printMediaAssest588: '/print_media/2019/mumbai-ws-express.jpeg',

  //2020
  printMediaAssest589: '/print_media/2020/AAJ-KANPUR-9-Yoga-26th-December-2020.jpg',
  printMediaAssest590: '/print_media/2020/AJ-DI-AWAAZ-11-Yoga-26th-December-2020.jpg',
  printMediaAssest591: '/print_media/2020/AKALI-PATRIKA-Page-No-6-26th-December-2020.jpg',
  printMediaAssest592: '/print_media/2020/AKALI-PATRIKA-%E2%80%93-Page-No.-6-26th-December-2020.jpg',
  printMediaAssest593: '/print_media/2020/AZAD-SOCH-Page-No.-3-26th-December-2020.jpg',
  printMediaAssest594: '/print_media/2020/Aapka-Faisla-7-Yoga-%E2%80%93-Ludhiana-26th-December-2020.jpg',
  printMediaAssest595: '/print_media/2020/Aapka-Faisla-Page-no.-5-26th-December-2020.jpg',
  printMediaAssest596: '/print_media/2020/Amoghavani%E2%80%93-Page-No.-3-26th-December-2020.jpg',
  printMediaAssest597: '/print_media/2020/Amrit-Sandesh-Raipur-%E2%80%93-Page-No.-12-26th-December-2020.jpg',
  printMediaAssest598: '/print_media/2020/Arthniti-tyi-article-june-2020.png',
  printMediaAssest599: '/print_media/2020/Buland-Bharat-Bareilly-Page-No.-4-26th-December-2020.jpg',
  printMediaAssest600: '/print_media/2020/Bussiness-insider-tyi-june-20.png',
  printMediaAssest601: '/print_media/2020/Central-Chronicle-Raipur-Page-No.-7-28th-December-2020.jpg',
  printMediaAssest602: '/print_media/2020/Chaitanyaa-Lok_Indore-Page-no.-5-26th-December-2020.jpg',
  printMediaAssest603: '/print_media/2020/Chandi-Bhumi-Page-No.-6-28th-December-2020.jpg',
  printMediaAssest604: '/print_media/2020/Chandrapur-Samachar-Nagpur-Page-No.-7-26th-December-2020.jpg',
  printMediaAssest605: '/print_media/2020/DAILY-MILAP-Page-No.-12-26th-December-2020.jpg',
  printMediaAssest606: '/print_media/2020/DAILY-SURAJ-Page-No.-2-26th-December-2020.jpg',
  printMediaAssest607: '/print_media/2020/DHABKAR-Page-No.-6-26th-December-2020.jpg',
  printMediaAssest608: '/print_media/2020/Dainik-Bhaskar-Bareilly-Page-No.-10-26th-December-2020.jpg',
  printMediaAssest609: '/print_media/2020/Dainik-Bhaskar-Meerut-Page-No.-10-28th-December-2020.jpg',
  printMediaAssest610: '/print_media/2020/Dainik-Jagran-Inext-Bareilly-Page-No.-9-27th-December-2020.jpg',
  printMediaAssest611: '/print_media/2020/Dainik-Jagran-Inext_Meerut-Page-No.-9-27th-December-2020.jpg',
  printMediaAssest612: '/print_media/2020/Dainik-Jagran-lifestyle-online-harzindagi-10th-feb-2020.jpeg',
  printMediaAssest613: '/print_media/2020/Dainik-Jagran-lifestyle-online-harzindagi-13th-feb-2020.jpeg',
  printMediaAssest614: '/print_media/2020/Dainik-Jagran-lifestyle-online-harzindagi-15th-feb-2020.jpeg',
  printMediaAssest615: '/print_media/2020/Dainik-Jagran-lifestyle-online-harzindagi-2nd-feb-2020.jpeg',
  printMediaAssest616: '/print_media/2020/Dainik-Jagran-lifestyle-online-harzindagi-6th-feb-2020.jpeg',
  printMediaAssest617: '/print_media/2020/Dainik-Jagran-lifestyle-online-harzindagi-7th-feb-2020.jpeg',
  printMediaAssest618: '/print_media/2020/Desh-bhandhu-Page-No.-11-26th-December-2020.jpg',
  printMediaAssest619: '/print_media/2020/Deshbandhu-Meerut-Page-No.-9-26th-December-2020.jpg',
  printMediaAssest620: '/print_media/2020/Devboomi-Yoga-%E2%80%93-Ludhiana-Page-no.-7-26th-December-2020.jpg',
  printMediaAssest621: '/print_media/2020/Devboomi-Yoga-%E2%80%93-Ludhiana-Page-no.-7-26th-December-2020.jpg',
  printMediaAssest622: '/print_media/2020/Dhara-News-Meerut-Page-No.-3-27th-December-2020.jpg',
  printMediaAssest623: '/print_media/2020/Dittadhwani-Page-no.-1-%E2%80%93-26th-December-2020.jpg',
  printMediaAssest624: '/print_media/2020/Divya-Vishvas-Meerut-Page-No.-4-28th-December-2020.jpg',
  printMediaAssest625: '/print_media/2020/Gujarat-Guardian-Page-No.-4-%E2%80%93-27th-December-2020.jpg',
  printMediaAssest626: '/print_media/2020/Hallie-Sandesh-Page-No.-5-%E2%80%93-27th-December-2020.jpg',
  printMediaAssest627: '/print_media/2020/Hari-Bhoomi-Page-No.-5-26th-December-2020.jpg',
  printMediaAssest628: '/print_media/2020/Hari-Bhoomi-page-Number-5-26th-december-2020.jpeg',
  printMediaAssest629: '/print_media/2020/Hasiru-Kranti-Page-No.-3-%E2%80%93-27th-December-2020.jpg',
  printMediaAssest630: '/print_media/2020/Hello-Madras-Page-no.-2-26th-December-2020.jpg',
  printMediaAssest631: '/print_media/2020/Hello-Mysore-Page-No.-5-%E2%80%93-27th-December-2020.jpg',
  printMediaAssest632: '/print_media/2020/Himprabha-Page-No.-2-%E2%80%93-28th-December-2020.jpg',
  printMediaAssest633: '/print_media/2020/Hindustan-times-health-shots-17th-feb-2020.jpeg',
  printMediaAssest634: '/print_media/2020/Hint-Meerut-Page-No.-6-28th-December-2020.jpg',
  printMediaAssest635: '/print_media/2020/Hosadigantha-Page-No.-8-27th-December-2020.jpg',
  printMediaAssest636: '/print_media/2020/Hubali-Sanje-Page-No.-2-26th-December-2020.jpg',
  printMediaAssest637: '/print_media/2020/Human-India-7-Yoga-%E2%80%93-Ludhiana-26th-December-2020.jpg',
  printMediaAssest638: '/print_media/2020/Ispat-Times-Raipur-Page-No.-4-27th-December-2020.jpg',
  printMediaAssest639: '/print_media/2020/JAGRITI-LEHAR-Page-No.-4-26th-December-2020.jpg',
  printMediaAssest640: '/print_media/2020/Jabalpur%20Express-%20Page%20No.%2011%20%E2%80%93%2026th-December-2020.jpg',
  printMediaAssest641: '/print_media/2020/Jansandesh-Times_Bareilly-Page-No.-10-%E2%80%93-27th-December-2020.jpg',
  printMediaAssest642: '/print_media/2020/Jigyasa-Kunj_Meerut-Page-No.-3-27th-December-2020.jpg',
  printMediaAssest643: '/print_media/2020/Kahi-Ankahi-Raipur-Page-No.-5-%E2%80%93-26th-December-2020.jpg',
  printMediaAssest644: '/print_media/2020/Kaifiyat-Page-No.-5-%E2%80%93-26th-December-2020.jpg',
  printMediaAssest645: '/print_media/2020/Karavali-Ale-Page-No.-3-%E2%80%93-27th-December-2020.jpg',
  printMediaAssest646: '/print_media/2020/Kolhapur-Mahasatta-Page-No.-8-27th-December-2020.jpg',
  printMediaAssest647: '/print_media/2020/Kolhapur-Samaj-Page-No.-2-26th-December-2020.jpg',
  printMediaAssest648: '/print_media/2020/Kolhapur-Swatantra-Pragati-Page-No.-2-27th-December-2020.jpg',
  printMediaAssest649: '/print_media/2020/Kolhapur-Varta-Shakti-Page-No.-2-26th-December-2020.jpg',
  printMediaAssest650: '/print_media/2020/Lokdarpan-Vadodara-Page-no.-104-28th-December-2020.jpg',
  printMediaAssest651: '/print_media/2020/Lokmat-28-feb-2020.jpeg',
  printMediaAssest652: '/print_media/2020/Loksatya-Meerut-Page-No.-3-28th-December-2020.jpg',
  printMediaAssest653: '/print_media/2020/Loktej-Page-No.-3-26th-December-2020.jpg',
  printMediaAssest654: '/print_media/2020/Makkal-Karuththu-Page-no.-4-28th-December-2020.jpg',
  printMediaAssest655: '/print_media/2020/Mayur-Samvad-Page-No.-6-26th-December-2020.jpg',
  printMediaAssest656: '/print_media/2020/Media-Darshan-Page-No.-5-26th-December-2020.jpg',
  printMediaAssest657: '/print_media/2020/Media-Seithi-Page-no.-3-%E2%80%93-27th-December-2020.jpg',
  printMediaAssest658: '/print_media/2020/Mid-day-19-feb-2020.jpeg',
  printMediaAssest659: '/print_media/2020/Motherland-voice-5-Yoga-%E2%80%93-Ludhiana-26th-December-2020.jpg',
  printMediaAssest660: '/print_media/2020/Mysore-Digantha-Page-No.-3-26th-December-2020.jpg',
  printMediaAssest661: '/print_media/2020/Navbharat%20times-11th-Oct-2020.jpg',
  printMediaAssest662: '/print_media/2020/Navbharat times-15th-Nov-2020.jpg',
  printMediaAssest663: '/print_media/2020/Navbharat%20times-16th-August-2020.jpg',
  printMediaAssest664: '/print_media/2020/Navbharat%20times-19th-July-2020.jpg',
  printMediaAssest665: '/print_media/2020/Navbharat%20times-1st-Nov-2020.jpg',
  printMediaAssest666: '/print_media/2020/Navbharat%20times-22nd-Nov-2020.jpg',
  printMediaAssest667: '/print_media/2020/Navbharat%20times-25th-Oct-2020.jpg',
  printMediaAssest668: '/print_media/2020/Navbharat%20times-26th%20-july-2020.jpg',
  printMediaAssest669: '/print_media/2020/Navbharat%20times-28th-June-2020.jpg',
  printMediaAssest670: '/print_media/2020/Navbharat%20times-29th-Nov-2020.jpg',
  printMediaAssest671: '/print_media/2020/Navbharat%20times-2nd-August-2020.jpg',
  printMediaAssest672: '/print_media/2020/Navbharat%20times-5th-July-2020.jpg',
  printMediaAssest673: '/print_media/2020/Navbharat%20times-8th%20-Nov-2020.jpg',
  printMediaAssest674: '/print_media/2020/Navbharat-times-13th-September-2020.jpg',
  printMediaAssest675: '/print_media/2020/Navbharat-times-20th-September-2020.jpg',
  printMediaAssest676: '/print_media/2020/Navbharat-times-27th-September-2020.jpg',
  printMediaAssest677: '/print_media/2020/Navbharat-times-30th-August-2020.jpg',
  printMediaAssest678: '/print_media/2020/Navbharat-times-4th-Oct-2020.jpg',
  printMediaAssest679: '/print_media/2020/Navbharat-times-6th-September-2020.jpg',
  printMediaAssest680: '/print_media/2020/Navbharattimes-10th-May-2020.jpg',
  printMediaAssest681: '/print_media/2020/Navbharattimes-12th-April-2020.jpg',
  printMediaAssest682: '/print_media/2020/Navbharattimes-13th-Dec-2020.jpg',
  printMediaAssest683: '/print_media/2020/Navbharattimes-14th-June-2020.jpg',
  printMediaAssest684: '/print_media/2020/Navbharattimes-15th-March-2020.jpg',
  printMediaAssest685: '/print_media/2020/Navbharattimes-16th-February-2020.jpg',
  printMediaAssest686: '/print_media/2020/Navbharattimes-17th-May-2020.jpg',
  printMediaAssest687: '/print_media/2020/Navbharattimes-19th-April-2020.jpg',
  printMediaAssest688: '/print_media/2020/Navbharattimes-20th-Dec-2020.jpg',
  printMediaAssest689: '/print_media/2020/Navbharattimes-22nd-March-2020.jpg',
  printMediaAssest690: '/print_media/2020/Navbharattimes-23rd-February-2020.jpg',
  printMediaAssest691: '/print_media/2020/Navbharattimes-24th-May-2020.jpg',
  printMediaAssest692: '/print_media/2020/Navbharattimes-26th-April-2020.jpg',
  printMediaAssest693: '/print_media/2020/Navbharattimes-26th-Dec-2020.jpg',
  printMediaAssest694: '/print_media/2020/Navbharattimes-26th-January-2020.jpg',
  printMediaAssest695: '/print_media/2020/Navbharattimes-27th-Dec-2020.jpg',
  printMediaAssest696: '/print_media/2020/Navbharattimes-29th-march-2020.jpg',
  printMediaAssest697: '/print_media/2020/Navbharattimes-31st-May-2020.jpg',
  printMediaAssest698: '/print_media/2020/Navbharattimes-3rd-May-2020.jpg',
  printMediaAssest699: '/print_media/2020/Navbharattimes-5th-April-2020.jpg',
  printMediaAssest700: '/print_media/2020/Navbharattimes-5th-January-2020.jpg',
  printMediaAssest701: '/print_media/2020/Navbharattimes-6th-Dec-2020.jpg',
  printMediaAssest702: '/print_media/2020/Navbharattimes-7th-June-2020.jpg',
  printMediaAssest703: '/print_media/2020/Navbharattimes-8th-March-2020.jpg',
  printMediaAssest704: '/print_media/2020/Navbharattimes-9th-February-2020.jpg',
  printMediaAssest705: '/print_media/2020/Navgujarat-Times-Page-No.-3-26th-December-2020.jpg',
  printMediaAssest706: '/print_media/2020/Nawan-Zamana-Page-No.-11-27th-December-2020.jpg',
  printMediaAssest707: '/print_media/2020/Odayara-Naadu-Page-No.-3-27th-December-2020.jpg',
  printMediaAssest708: '/print_media/2020/Prachand-Samay-Page-no.-4-28th-December-2020.jpg',
  printMediaAssest709: '/print_media/2020/Pratidin-Rajdhani-Raipur-Page-No.-8-26th-December-2020.jpg',
  printMediaAssest710: '/print_media/2020/Punjab-Times-Page-No.-5-26th-December-2020.jpg',
  printMediaAssest711: '/print_media/2020/Rahul-sharmaperforming-tyi-2020.png',
  printMediaAssest712: '/print_media/2020/Raj-Express-Page-No.-7-26th-December-2020.jpg',
  printMediaAssest713: '/print_media/2020/Rajya-Dharma-Page-No.-6-26th-December-2020.jpg',
  printMediaAssest714: '/print_media/2020/Rashtriya-Sakshi-Bareilly-Page-No.-3-26th-December-2020.jpg',
  printMediaAssest715: '/print_media/2020/Rashtriya-Waroop-Bareilly-Page-No.-7-28th-December-2020.jpg',
  printMediaAssest716: '/print_media/2020/Ratnagiri-Ranzunjar-Page-No.-3-28th-December-2020.jpg',
  printMediaAssest717: '/print_media/2020/Rozana-news-2-Yoga-%E2%80%93-Ludhiana-26th-December-2020.jpg',
  printMediaAssest718: '/print_media/2020/Samatola-Page-No.-6-27th-December-2020.jpg',
  printMediaAssest719: '/print_media/2020/Samay-Darshan-Raipur-Page-no.-03-26th-December-2020.jpg',
  printMediaAssest720: '/print_media/2020/Sanje-Drafan-Page-No.-2-26th-December-2020.jpg',
  printMediaAssest721: '/print_media/2020/Sanje-Mitra-Page-No.-3-26th-December-2020.jpg',
  printMediaAssest722: '/print_media/2020/Sanje-Mitra-Page-No.-5-26th-December-2020.jpg',
  printMediaAssest723: '/print_media/2020/Sanje-Mitra-Page-no.-5-%E2%80%93-26th-December-2020.jpg',
  printMediaAssest724: '/print_media/2020/Sanje-Vani-Page-No.-2-26th-December-2020.jpg',
  printMediaAssest725: '/print_media/2020/Sanjevani-Page-no.-04-26th-December-2020.jpg',
  printMediaAssest726: '/print_media/2020/Sanmarg-Page-No.-5-26th-December-2020.jpg',
  printMediaAssest727: '/print_media/2020/Satara-Mahasatta-Page-no.-8-%E2%80%93-27th-December-2020.jpg',
  printMediaAssest728: '/print_media/2020/Shah-Bulletin-Meeurt-Page-No.-1-26th-December-2020.jpg',
  printMediaAssest729: '/print_media/2020/Spasth-Awaz-Bareilly-Page-no.-10-27th-December-2020.jpg',
  printMediaAssest730: '/print_media/2020/Suvarna-Loka-Page-no.-8-%E2%80%93-27th-December-2020.jpg',
  printMediaAssest731: '/print_media/2020/Swatantra-Bharat-Bareilly-Page-no.-14-27th-December-2020.jpg',
  printMediaAssest732: '/print_media/2020/Tamil-Anjal-Page-no.-3-26th-December-2020.jpg',
  printMediaAssest733: '/print_media/2020/Tarun-Bharat-%E2%80%93-Nagpur-Page-no.-9-27th-December-2020.jpg',
  printMediaAssest734: '/print_media/2020/The-big-stretch-mid-day-june-2020.png',
  printMediaAssest735: '/print_media/2020/Times-of-india-entertainment-internation-yoga-day-june-18th.png',
  printMediaAssest736: '/print_media/2020/Veekshanam-Page-no.-05-26th-December-2020.jpg',
  printMediaAssest737: '/print_media/2020/Vidarbha-Ki-Baat-Nagpur-Page-no.-02-26th-December-2020.jpg',
  printMediaAssest738: '/print_media/2020/Vishwa-Pariwar-Raipur-Page-no.-7-26th-December-2020.jpg',
  printMediaAssest739: '/print_media/2020/Y-GGN-Action-India-Page-no.-09-26th-December-2020.jpg',
  printMediaAssest740: '/print_media/2020/Y-GGN-Hadoti-Adhikar-Page-no.-7-26th-December-2020.jpg',
  printMediaAssest741: '/print_media/2020/Y-GGN-Haryana-Jyoti-Page-no.-04-27th-December-2020.jpg',
  printMediaAssest742: '/print_media/2020/Y-GGN-Haryana-Ki-Awaz-Page-no.-05-26th-December-2020.jpg',
  printMediaAssest743: '/print_media/2020/Y-GGN-Haryana-Vatika-Page-no.-05-26th-December-2020.jpg',
  printMediaAssest744: '/print_media/2020/Y-GGN-Jyoti-Darpan-Page-no.-05-26th-December-2020.jpg',
  printMediaAssest745: '/print_media/2020/Y-GGN-Pioneer-Page-no.-05-28th-December-2020.jpg',
  printMediaAssest746: '/print_media/2020/Y-GGN-Satyajai-Times-Page-no.-05-26th-December-2020.jpg',
  printMediaAssest747: '/print_media/2020/Y-GGN-Satyajai-Times-Page-no.-105-26th-December-2020.jpg',
  printMediaAssest748: '/print_media/2020/Y-KNL-Aapka-Faisla-no.-2-%E2%80%93-26th-December-2020.jpg',
  printMediaAssest749: '/print_media/2020/Y-KNL-Action-India-Page-no.-09-26th-December-2020.jpg',
  printMediaAssest750: '/print_media/2020/Y-KNL-Rozana-Page-no.-2-28th-December-2020.jpg',
  printMediaAssest751: '/print_media/2020/Y-KNLGhoshna-Patra-Page-no.-104-27th-December-2020.jpg',
  printMediaAssest752: '/print_media/2020/Y-RTK-Amar-Bharti-Page-no.-6-26th-December-2020.jpg',
  printMediaAssest753: '/print_media/2020/YOGSADHNA-SANJSHAKTI-Page-no.-03-%E2%80%93-26th-December-2020.jpg',
  printMediaAssest754: '/print_media/2020/YOGSADHNA-SHASAN-SAMRAT-Page-no.-3-26th-December-2020.jpg',
  printMediaAssest755: '/print_media/2020/Yoga-Pradesh-Today-Bhopal-Page-no.-11-26th-December-2020.jpg',
  printMediaAssest756: '/print_media/2020/Yoga-Pradesh-Today-Bhopal-Page-no.-11-26th-December-2020.jpg',
  printMediaAssest757: '/print_media/2020/Yoga-Raj-Express-Indore-Page-no.-107-27th-December-2020.jpg',
  printMediaAssest758: '/print_media/2020/Yoga-Repoter-Page-no.-5-27th-December-2020.jpg',
  printMediaAssest759: '/print_media/2020/Yoga-Sach-Express-Bhopal-Page-no.-11-26th-December-2020.jpg',
  printMediaAssest760: '/print_media/2020/Yogsadhna-Aurangabad-Samachar-Page-no.-3-27th-December-2020.jpg',
  printMediaAssest761: '/print_media/2020/Yogsadhna-Dainik-Bhaskar-Page-no.-5-27th-December-2020.jpg',
  printMediaAssest762: '/print_media/2020/Yogsadhna-Global-Bharat-Page-no.-3-27th-December-2020%20%281%29.jpg',
  printMediaAssest763: '/print_media/2020/Yogsadhna-Global-Bharat-Page-no.-3-27th-December-2020.jpg',
  printMediaAssest764: '/print_media/2020/castrol-india-take-initiative-june-19th-tyi.png',
  printMediaAssest765: '/print_media/2020/india.com-25-feb-2020.jpeg',
  printMediaAssest766: '/print_media/2020/international-day-tyi-luxebook-18th-june.png',

  //2021
  printMediaAssest767: '/print_media/2021/Aadarsh Maharashta-The-Yoga-Institute-17th-May-2021.jpeg',
  printMediaAssest768: '/print_media/2021/Dabang-Duniya_PG-03_22nd-June-2021%20.jpg',
  printMediaAssest769: '/print_media/2021/Dabang-Duniya_PG-03_22nd-June-2021.jpg',
  printMediaAssest770: '/print_media/2021/Dainik-Bhaskar-16th-May-2021.jpg',
  printMediaAssest771: '/print_media/2021/Dainik-Bhaskar-7th-April-2021.jpg',
  printMediaAssest772: '/print_media/2021/Free-Press-Journal-28th-May-2021.jpeg',
  printMediaAssest773: '/print_media/2021/Hamara-Mahanagar-22nd-June-2021.jpg',
  printMediaAssest774: '/print_media/2021/Hindustan-samachaar-28th-May-2021.jpeg',
  printMediaAssest775: '/print_media/2021/Hindusthan-Samachar-15th-May-2021.jpg',
  printMediaAssest776: '/print_media/2021/Janmabhoomi-22nd-June-2021.jpg',
  printMediaAssest777: '/print_media/2021/JanmbhoomiMumbai16th-May-2021.jpg',
  printMediaAssest778: '/print_media/2021/Nav-Bharat-Times-17th-May-2021.jpeg',
  printMediaAssest779: '/print_media/2021/Navbaharat-22nd-June-2021.jpg',
  printMediaAssest780: '/print_media/2021/Navbharattime-7th-Feb-2021.jpg',
  printMediaAssest781: '/print_media/2021/Navbharattimes-10th-October%20-021.jpg',
  printMediaAssest782: '/print_media/2021/Navbharattimes-11th-April-2021.jpg',
  printMediaAssest783: '/print_media/2021/Navbharattimes-11th-July-2021.jpg',
  printMediaAssest784: '/print_media/2021/Navbharattimes-12th-September-2021.jpg',
  printMediaAssest785: '/print_media/2021/Navbharattimes-13th Dec-2021.jpg',
  printMediaAssest786: '/print_media/2021/Navbharattimes-13th-june-2021.jpg',
  printMediaAssest787: '/print_media/2021/Navbharattimes-14th-Feb-2021.jpg',
  printMediaAssest788: '/print_media/2021/Navbharattimes-14th-March-2021.jpg',
  printMediaAssest789: '/print_media/2021/Navbharattimes-14th-Nov-2021.jpg',
  printMediaAssest790: '/print_media/2021/Navbharattimes-15th-August-2021.jpg',
  printMediaAssest791: '/print_media/2021/Navbharattimes-16th-May-2021.jpg',
  printMediaAssest792: '/print_media/2021/Navbharattimes-17th-Jan-2021.jpg',
  printMediaAssest793: '/print_media/2021/Navbharattimes-17th-October-2021.jpg',
  printMediaAssest794: '/print_media/2021/Navbharattimes-18th-April-2021.jpg',
  printMediaAssest795: '/print_media/2021/Navbharattimes-18th-July-2021.jpg',
  printMediaAssest796: '/print_media/2021/Navbharattimes-18th-June-2021.jpg',
  printMediaAssest797: '/print_media/2021/Navbharattimes-18th-October-2021.jpg',
  printMediaAssest798: '/print_media/2021/Navbharattimes-19th -September-2021.jpg',
  printMediaAssest799: '/print_media/2021/Navbharattimes-19th-Dec-2021.jpg',
  printMediaAssest800: '/print_media/2021/Navbharattimes-1st-August-2021.jpg',
  printMediaAssest801: '/print_media/2021/Navbharattimes-20th-Dec-2021.jpg',
  printMediaAssest802: '/print_media/2021/Navbharattimes-20th-June-2021.jpg',
  printMediaAssest803: '/print_media/2021/Navbharattimes-21st-Feb-2021.jpg',
  printMediaAssest804: '/print_media/2021/Navbharattimes-21st-March-2021.jpg',
  printMediaAssest805: '/print_media/2021/Navbharattimes-21st-Nov-2021.jpg',
  printMediaAssest806: '/print_media/2021/Navbharattimes-22nd-August-2021.jpg',
  printMediaAssest807: '/print_media/2021/Navbharattimes-22nd-June-2021.jpg',
  printMediaAssest808: '/print_media/2021/Navbharattimes-23rd-May-2021.jpg',
  printMediaAssest809: '/print_media/2021/Navbharattimes-24th-Jan-2021.jpg',
  printMediaAssest810: '/print_media/2021/Navbharattimes-24th-October-2021.jpg',
  printMediaAssest811: '/print_media/2021/Navbharattimes-25th%20-july-2021.jpg',
  printMediaAssest812: '/print_media/2021/Navbharattimes-25th-April-2021.jpg',
  printMediaAssest813: '/print_media/2021/Navbharattimes-26th-Dec-2021.jpg',
  printMediaAssest814: '/print_media/2021/Navbharattimes-27th-Dec-2021.jpg',
  printMediaAssest815: '/print_media/2021/Navbharattimes-27th-June-2021.jpg',
  printMediaAssest816: '/print_media/2021/Navbharattimes-28th-Feb-2021.jpg',
  printMediaAssest817: '/print_media/2021/Navbharattimes-28th-Feb-2021.jpg',
  printMediaAssest818: '/print_media/2021/Navbharattimes-28th-Nov-2021.jpg',
  printMediaAssest819: '/print_media/2021/Navbharattimes-29th-August-2021%20.jpg',
  printMediaAssest820: '/print_media/2021/Navbharattimes-29th-August-2021.jpg',
  printMediaAssest821: '/print_media/2021/Navbharattimes-29th-August-2021.jpg',
  printMediaAssest822: '/print_media/2021/Navbharattimes-2nd-May-2021.jpg',
  printMediaAssest823: '/print_media/2021/Navbharattimes-30th-May-2021.jpg',
  printMediaAssest824: '/print_media/2021/Navbharattimes-31st-Jan-2021.jpg',
  printMediaAssest825: '/print_media/2021/Navbharattimes-31st-October-2021.jpg',
  printMediaAssest826: '/print_media/2021/Navbharattimes-3rd-April-2022.jpg',
  printMediaAssest827: '/print_media/2021/Navbharattimes-3rd-October-2021.jpg',
  printMediaAssest828: '/print_media/2021/Navbharattimes-4th-April-2021.jpg',
  printMediaAssest829: '/print_media/2021/Navbharattimes-4th-April-2022.jpg',
  printMediaAssest830: '/print_media/2021/Navbharattimes-4th-July-2021.jpg',
  printMediaAssest831: '/print_media/2021/Navbharattimes-5th%20-September-2021.jpg',
  printMediaAssest832: '/print_media/2021/Navbharattimes-5th-Dec-2021.jpg',
  printMediaAssest833: '/print_media/2021/Navbharattimes-6th June-2021.jpg',
  printMediaAssest834: '/print_media/2021/Navbharattimes-7th-March-2021.jpg',
  printMediaAssest835: '/print_media/2021/Navbharattimes-7th-Nov-2021.jpg',
  printMediaAssest836: '/print_media/2021/Navbharattimes-8th-August-2021.jpg',
  printMediaAssest837: '/print_media/2021/Navbharattimes-9th%20May-2021.jpg',
  printMediaAssest838: '/print_media/2021/Navshakti%2022nd-June-2021.jpg',
  printMediaAssest839: '/print_media/2021/Prahaar%2022nd-June-2021.jpg',
  printMediaAssest840: '/print_media/2021/Pravasi-Edition-16th-May-2021.jpg',
  printMediaAssest841: '/print_media/2021/Saamna-16th-May-2021.jpeg',
  printMediaAssest842: '/print_media/2021/The-Times-of-India-26th-April-2021.jpg',
  printMediaAssest843: '/print_media/2021/Twistarticle-28th-May-2021.jpeg',
  printMediaAssest844: '/print_media/2021/educrateweb-21st-june-021.jpeg',
  printMediaAssest845: '/print_media/2021/globalpremennews%3D28th-May-2021.jpeg',
  printMediaAssest846: '/print_media/2021/india-education-diary-28th-May-2021.jpeg',
  printMediaAssest847: '/print_media/2021/onlineandyou-28th-May-2021.jpeg',

  //2022
  printMediaAssest848: '/print_media/2022/Bussiness-News-Week-20th-June-2022.jpeg',
  printMediaAssest849: '/print_media/2022/Bussiness-News-this-Week-20th-June-2022.jpeg',
  printMediaAssest850: '/print_media/2022/Content-Media-Solution-20th-June-2022.jpeg',
  printMediaAssest851: '/print_media/2022/Dainik%20Bhaskar-New%20Delhi--21st-June-2022.jpg',
  printMediaAssest852: '/print_media/2022/Digital-First-Magazine-20th-June-2022.jpeg',
  printMediaAssest853: '/print_media/2022/Divya-Bhaskar-Bhuj-21st-June-2022.jpg',
  printMediaAssest854: '/print_media/2022/Divya-Marathi-21st-June-2022.jpg',
  printMediaAssest855: '/print_media/2022/Free-Press-Journal-PG04-22.06.22.jpg',
  printMediaAssest856: '/print_media/2022/Free-Press-Journal_PG08_22.06.22.jpg',
  printMediaAssest857: '/print_media/2022/Gujarat-Samachar-PG03-22.06.22.jpg',
  printMediaAssest858: '/print_media/2022/Hamara-Mahanagar-PG03-21.06.22.jpg',
  printMediaAssest859: '/print_media/2022/Janmabhoomi-news-papers-22nd-June-2022.jpg',
  printMediaAssest860: '/print_media/2022/Lokmat-Helo-Mumbai-22nd-June.jpg',
  printMediaAssest861: '/print_media/2022/Media-Bullitens-20th-June-2022.jpeg',
  printMediaAssest862: '/print_media/2022/Mid-Day-Gujarati-_pg15.21.06.22.jpg',
  printMediaAssest863: '/print_media/2022/NBT-%2023rd-Jan-2022.jpg',
  printMediaAssest864: '/print_media/2022/NBT-10th-April-2022.jpg',
  printMediaAssest865: '/print_media/2022/NBT-10th-Jan-2022.jpg',
  printMediaAssest866: '/print_media/2022/NBT-11th-April-2022.jpg',
  printMediaAssest867: '/print_media/2022/NBT-12th-June-2022.jpg',
  printMediaAssest868: '/print_media/2022/NBT-13th-Feb-2022.jpg',
  printMediaAssest869: '/print_media/2022/NBT-13th-June-2022.jpg',
  printMediaAssest870: '/print_media/2022/NBT-13th-March-2022.jpg',
  printMediaAssest871: '/print_media/2022/NBT-14th-Feb-2022.jpg',
  printMediaAssest872: '/print_media/2022/NBT-14th-March-2022.jpg',
  printMediaAssest873: '/print_media/2022/NBT-15th-May-2022.jpg',
  printMediaAssest874: '/print_media/2022/NBT-16th-Jan-2022.jpg',
  printMediaAssest875: '/print_media/2022/NBT-16th-May-2022.jpg',
  printMediaAssest876: '/print_media/2022/NBT-17th-April-2022.jpg',
  printMediaAssest877: '/print_media/2022/NBT-17th-Jan-2022.jpg',
  printMediaAssest878: '/print_media/2022/NBT-18th-April-2022.jpg',
  printMediaAssest879: '/print_media/2022/NBT-19th-June-2022.jpg',
  printMediaAssest880: '/print_media/2022/NBT-1st-May-2022.jpg',
  printMediaAssest881: '/print_media/2022/NBT-20th-Feb-2022.jpg',
  printMediaAssest882: '/print_media/2022/NBT-20th-June-2022.jpg',
  printMediaAssest883: '/print_media/2022/NBT-20th-March-2022.jpg',
  printMediaAssest884: '/print_media/2022/NBT-21st-Feb-2022.jpg',
  printMediaAssest885: '/print_media/2022/NBT-21st-June-2022.jpg',
  printMediaAssest886: '/print_media/2022/NBT-21st-June-2022.jpg',
  printMediaAssest887: '/print_media/2022/NBT-21st-March-2022.jpg',
  printMediaAssest888: '/print_media/2022/NBT-22nd-May-2022.jpg',
  printMediaAssest889: '/print_media/2022/NBT-23rd-May-2022.jpg',
  printMediaAssest890: '/print_media/2022/NBT-24th-April-2022.jpg',
  printMediaAssest891: '/print_media/2022/NBT-24th-Jan-2022.jpg',
  printMediaAssest892: '/print_media/2022/NBT-25th-April-2022.jpg',
  printMediaAssest893: '/print_media/2022/NBT-26th-June-2022.jpg',
  printMediaAssest894: '/print_media/2022/NBT-27th-Feb-2022.jpg',
  printMediaAssest895: '/print_media/2022/NBT-27th-June-2022.jpg',
  printMediaAssest896: '/print_media/2022/NBT-27th-March-2022.jpg',
  printMediaAssest897: '/print_media/2022/NBT-28th-Feb-2022.jpg',
  printMediaAssest898: '/print_media/2022/NBT-28th-Feb-2022.jpg',
  printMediaAssest899: '/print_media/2022/NBT-29th-May-2022.jpg',
  printMediaAssest900: '/print_media/2022/NBT-2nd-May-2022.jpg',
  printMediaAssest901: '/print_media/2022/NBT-30th-Jan-2022.jpg',
  printMediaAssest902: '/print_media/2022/NBT-30th-May-2022.jpg',
  printMediaAssest903: '/print_media/2022/NBT-31st-Jan-2022%20%281%29.jpg',
  printMediaAssest904: '/print_media/2022/NBT-31st-Jan-2022.jpg',
  printMediaAssest905: '/print_media/2022/Online-media-cafe-20th-june-2022.jpeg',
  printMediaAssest906: '/print_media/2022/Speaking-Tree.jpeg',
  printMediaAssest907: '/print_media/2022/The-Cambay-Post-21st-june-2022.jpeg',
  printMediaAssest908: '/print_media/2022/Twistarticle-20th-june-2022.jpeg',
  printMediaAssest909: '/print_media/new2022/aug1.jpg',
  printMediaAssest910: '/print_media/new2022/aug14.jpg',
  printMediaAssest911: '/print_media/new2022/aug22.jpg',
  printMediaAssest912: '/print_media/new2022/aug28.jpg',
  printMediaAssest913: '/print_media/new2022/aug31.jpg',
  printMediaAssest914: '/print_media/new2022/aug7.jpg',
  printMediaAssest915: '/print_media/new2022/aug8.jpg',
  printMediaAssest916: '/print_media/new2022/july10.jpg',
  printMediaAssest917: '/print_media/new2022/july17.jpg',
  printMediaAssest918: '/print_media/new2022/july18.jpg',
  printMediaAssest919: '/print_media/new2022/july24.jpg',
  printMediaAssest920: '/print_media/new2022/july25.jpg',
  printMediaAssest921: '/print_media/new2022/july3.jpg',
  printMediaAssest922: '/print_media/new2022/july31.jpg',
  printMediaAssest923: '/print_media/new2022/july4.jpg',
  printMediaAssest924: '/print_media/2022/Navbharat-Times-Epaper-23rd-October-2022.jpg',
  printMediaAssest925: '/print_media/2022/Navbharat-Times-Epaper-30th-October-2022.jpg',
  printMediaAssest926: '/print_media/2022/Navbharat-Times-Epaper-31st-October-2022.jpg',
  printMediaAssest927: '/print_media/new2022/Sakal-Paper-3rd-November -2022-Page-10.jpg',
  printMediaAssest928: '/print_media/2022/Navbharat%20Times%20Epaper%20-%206th%20November%202022.jpg',
  printMediaAssest929: '/print_media/new2022/Divya Bhaskar 16th November 2022.jfif',
  printMediaAssest930: '/print_media/new2022/FreePressJournal-13th November 2022.jpg',
  printMediaAssest932: '/print_media/new2022/Mid-Day-22nd-Nov-2022.jpg',
  printMediaAssest933: '/print_media/new2022/Mid-day-19-nov-2022 .jpg',
  printMediaAssest934: '/print_media/new2022/Navbharat-Times-Epaper-21st-November-2022 .jpg',
  printMediaAssest935: '/print_media/new2022/Navbharat Times Epaper - 20th November 2022.jpg',
  printMediaAssest936: '/print_media/new2022/Navbharat-Times-Epaper-27th-November-2022.jpg',
  printMediaAssest937: '/print_media/new2022/Navbharat-Times-Epaper-28th-November-2022.jpg',
  printMediaAssest938: '/print_media/new2022/Navbharat Times Epaper - 4th December 2022.jpg',
  printMediaAssest939: '/print_media/new2022/Navbharat-Times-Epaper-5th-December-2022.jpg',
  printMediaAssest940: '/print_media/new2022/sakal- 4-dec-2022.jpg',
  printMediaAssest941: '/print_media/new2022/sakal-2-4-dec.jpg',
  printMediaAssest942: '/print_media/new2022/Navbharat Times Epaper - 11th December 2022.jpg',
  printMediaAssest943: '/print_media/new2022/Navbharat-Times-Epaper-12th-December-2022.jpg',
  printMediaAssest944: '/print_media/new2022/esakal-12th-December-2022.jpg',
  printMediaAssest945: '/print_media/new2022/Navbharat-Times-Epaper-18th-December-2022.jpg',
  printMediaAssest946: '/print_media/new2022/TOI_Mumbai_18-12-2022-1.jpg',
  printMediaAssest947: '/print_media/new2022/Navbharat-Times-Epaper-20th-December-2022.jpg',
  printMediaAssest948: '/print_media/new2022/Navbharat-Times-Epaper-25th-December-2022.jpg',
  printMediaAssest949: '/print_media/new2022/Navbharat-Times-Epaper-27th-December-2022.jpg',

  //2023
  printMediaAssest950: '/print_media/2023/Navbharat-Times-Epaper-1st-January-2023.jpg',
  printMediaAssest951: '/print_media/2023/Sakal-Epaper-3rd-January-2023.jpg',
  printMediaAssest952: '/print_media/2023/Navbharat-Times-Epaper-4th-January-2023.jpg',
  printMediaAssest953: '/print_media/2023/Sakad-news-paper.jpg',
  printMediaAssest954: '/print_media/2023/Navbharat_Times_Epaper-8th_January_2023.jpg',
  printMediaAssest955: '/print_media/2023/Navbharat_Times_Epaper-9th_January_2023.jpg',
  printMediaAssest956: '/print_media/2023/Sakad_news_paper_3rd_Jan_2023.jpg',
  printMediaAssest957: '/print_media/2023/hamaramahanagar_10th_Jan_2023.jpg',
  printMediaAssest958: '/print_media/2023/Esaka_Epaper-10th_January_2023_Pg.8.jpg',
  printMediaAssest959: '/print_media/2023/Esaka_Epaper-17th_January_2023_Pg.8.jpg',
  printMediaAssest960: '/print_media/2023/Navbharat_Times_Epaper-22nd_January_2023.jpg',
  printMediaAssest961: '/print_media/2023/Navbharat_Times_Epaper-29th_January 2023.jpg',
  printMediaAssest962: '/print_media/2023/Navbharat_Times_Epaper-30th_January_2023.jpg',
  printMediaAssest963: '/print_media/2023/Navbharat-Times-Epaper-5th-February-2023.png',
  printMediaAssest964: '/print_media/2023/Navbharat-Times-Epaper-6th-February-2023.jpg',
  printMediaAssest965: '/print_media/2023/Times-Economics-Times-11th-Feb-2023.jpg',
  printMediaAssest966: '/print_media/2023/Navbharat-Times-Epaper-12th-February-2023.PNG',
  printMediaAssest967: '/print_media/2023/Navbharat-Times-Epaper-13th-February-2023.jpg',
  printMediaAssest968: '/print_media/2023/Esaka_Epaper-14th_February_2023.jpg',
  printMediaAssest969: '/print_media/2023/Navbharat_Times_Epaper-19th_February_2023.jpg',
  printMediaAssest970: '/print_media/2023/Navbharat_Times_Epaper-20th_February_2023.jpg',
  printMediaAssest971: '/print_media/2023/Navbharat_Times_Epaper-27th_February2023.jpg',
  printMediaAssest972: '/print_media/2023/Navbharat_Time_Epaper-26th_February_2023.jpg',
  printMediaAssest973: '/print_media/2023/Esaka_Epaper-21st_February_2023.jpg',
  printMediaAssest974: '/print_media/2023/Esaka_Epaper-28th_February_2023.jpg',
  printMediaAssest975: '/print_media/2023/Freepressjournal.in-23rd_February_2023.jpg',
  printMediaAssest976: '/print_media/2023/Navbharat_Times_Epaper-12th_March_2023.jpg',
  printMediaAssest977: '/print_media/2023/Navbharat_Times_Epaper-13th_March_2023.jpg',
  printMediaAssest978: '/print_media/2023/Navbharat_Times_Epaper-5th_March_2023.jpg',
  printMediaAssest979: '/print_media/2023/Navbharat_Times_Epaper-6th_March_2023.jpg',
  printMediaAssest980: '/print_media/2023/Navarashtra - 30th April 2023 (1).jpg',
  printMediaAssest981: '/print_media/2023/Toi.jpg',
  printMediaAssest982: '/Print%20Media/2023/PressRelease%20-%2022-05-2023%20%281%29.jpg',
  printMediaAssest983: '/Print%20Media/2023/Hindusthan-Samachar-23rdMay2023.jpg',
  printMediaAssest984: '/Print%20Media/2023/Shiksha-23rdMay2023.jpg',
  printMediaAssest985: '/Print%20Media/2023/Ani-News-23rdMay2023.jpg',
  printMediaAssest986: '/Print%20Media/2023/Insamachar-23rdMay2023.jpg',
  printMediaAssest987: '/Print%20Media/2023/Navbharat-Times-21stJune2023.jpg',
  printMediaAssest988: '/Print%20Media/2023/Dainik-Jagran-21stJune2023.png'
}




export const locateAsset = {
  India: '/Locate_us/Flag-India.jpg',
  italy: '/Locate_us/italy.jpg',
  thailand: '/Locate_us/thailand.jpg',
  costaRica: '/Locate_us/costa_rica.jpg',
  france: '/Locate_us/france.jpg',
  urugauy: '/Locate_us/uruguay.png',
  hongKong: '/Locate_us/hong_kong.jpg',
  uae: '/Locate_us/UAE.png',
  locate: '/Locate_us/locate.jpg'
}

export const juhuVileAsset = {
  carosoul1: '/JuhuVile-images/carosoul1.jpg',
  carosoul2: '/JuhuVile-images/carosoul2.jpg',
  carosoul3: '/JuhuVile-images/carosoul3.jpg',
  left: '/JuhuVile-images/Left.jpg',
  right: '/JuhuVile-images/right.jpg',
}

export const santaCruz = {
  slide1: '/Santa-cruz-images/Slide-1.jpg',
  slide2: '/Santa-cruz-images/Slide-2.jpg',
  slide3: '/Santa-cruz-images/Slide-3.jpg',
  apply: '/Santa-cruz-images/Pranayama_workshop.jpg',
  details: '/Santa-cruz-images/Details.jpg',
  malad1: '/Santa-cruz-images/malad1.jpg',
  malad2: '/Santa-cruz-images/malad2.jpg',
  malad3: '/Santa-cruz-images/malad3.jpg',
}

export const nutriDiet = {
  nutriDietMain: '/SEO-New-Images/Others/nutri-diet-get-custom-diet-plan.webp',
  nutriDietCard: '/SEO-New-Images/Courses/Nutri-Diet-Clinic-Post.webp'
}


export const banner = {
  storeMats: '/shop-banner/TYI-Store-Banners-Mats.png',
  storeTshirts: '/shop-banner/TYI-Store-Banners-TShirts.png',
  storeBooks: '/shop-banner/TYI-Store-Banners-Books.png',
  newBook: '/shop-banner/Web_site_banner_new_book.jpg'
}

export const landingPage = {
  mataji: '/Landing_page/mataji.png',
  curve1: '/Landing_page/curve1.png',
  curve2: '/Landing_page/curve2.jpeg',
  text: '/Landing_page/Text.png'
}
export const CorporateWellnessLogos = {
  BankOfBarodaNew: '/Corporate-wellness/BANKBARODA.png',
  bankOfBaroda: '/Corporate-wellness/Bank_of_Baroda_Logo.png',
  indianOil: '/Corporate-wellness/Indian_Oil_Corporation.png',
  lnt: '/Corporate-wellness/L&T.png',
  pFizer: '/Corporate-wellness/Pfizer_(2021).svg.png',
  unionBank: '/Corporate-wellness/Union-Bank-of-India.png',
  deutscheBank: '/Corporate-wellness/deutsche-bank-logo.png',
  siemens: '/Corporate-wellness/siemens-logo.png',
  lifeStyle: '/Corporate-wellness/Lifestyle & Disease Management.jpg',
  WellnessProgram: '/Corporate-wellness/What Makes Our Corporate Wellness Programs Unique.JPG',
  ManageStress: '/Corporate-wellness/How to Manage Stress at Work.jpg',
  WorkpaceProgram: '/Corporate-wellness/Holistic Workplace Wellness Program.jpg',
  corporateWheel: '/Corporate-wellness/corporate-wheel.png',
  corporate: '/Corporate-wellness/corporate.png',
  workspace: '/Corporate-wellness/WorkspaceNew.png',
  holisticFirst: '/Corporate-wellness/1v.jpg',
  holisticSecond: '/Corporate-wellness/2v.jpg',
  holisticThird: '/Corporate-wellness/3v.jpg',
  holisticFourth: '/Corporate-wellness/4v.jpg',
  corpWellness: '/Corporate-wellness/newCorpWellness.jpeg'

}

export const nutriClinic = {
  main: '/Nutri-Clinic/Salad.png'
}

export const iybCourse = {
  main: '/Courses/IBY_Course_ad_for_website.jpg',
  mainImage: '/SEO-New-Images/Courses/iby-classes-for-tyi-yoga-ttc-teacher.webp'
}

// Csr (bmc images)

export const csrBmc = {
  bmcCover: '/Social_Res/bmc-cover.webp',
  // bmc1 : '/Social_Res/bmc-1.webp',
  // bmc2 : '/Social_Res/bmc-2.webp',
  // bmc3 : '/Social_Res/bmc-3.webp',
  // bmc4 : '/Social_Res/bmc-4.webp',
  // bmc5 : '/Social_Res/bmc-5.webp',
  // bmc6 : '/Social_Res/bmc-6.webp',
  // bmc7 : '/Social_Res/bmc-7.webp',
  // bmc8 : '/Social_Res/bmc-8.webp',
  // bmc9 : '/Social_Res/bmc-9.webp',
  // bmc10 : '/Social_Res/bmc-10.webp',
  // bmc11 : '/Social_Res/bmc-11.webp',
  // bmc12 : '/Social_Res/bmc-12.webp',
  // bmc13 : '/Social_Res/bmc-13.webp',
  // bmc14 : '/Social_Res/bmc-14.webp',
  // bmc15 : '/Social_Res/bmc-15.webp',
  //BmcNew images
  bmc16: '/Social_Res/BmcNew1.webp',
  bmc17: '/Social_Res/BmcNew2.webp',
  bmc18: '/Social_Res/BmcNew3.webp',
  bmc19: '/Social_Res/BmcNew4.webp',
  bmc20: '/Social_Res/BmcNew5.webp',
  bmc21: '/Social_Res/BmcNew6.webp',
  bmc22: '/Social_Res/BmcNew7.webp',
  bmc23: '/Social_Res/BmcNew8.webp',
  bmc24: '/Social_Res/BmcNew9.webp',
  bmc25: '/Social_Res/BmcNew10.webp',
  bmc26: '/Social_Res/BmcNew11.webp',
  bmc27: '/Social_Res/BmcNew12.webp',
  bmc28: '/Social_Res/BmcNew13.webp',
  bmc29: '/Social_Res/BmcNew14.webp',
  bmc30: '/Social_Res/BmcNew15.webp',
  bmc31: '/Social_Res/BmcNew16.webp',
  bmc32: '/Social_Res/BmcNew17.webp',
  bmc33: '/Social_Res/BmcNew18.webp',
  bmc34: '/Social_Res/BmcNew19.webp',
  bmc35: '/Social_Res/BmcNew20.webp',


  //AnnamBrahma images
  abCover: '/Csr-annam-brahma/annam-brahma-cover.webp',
  ab1: '/Csr-annam-brahma/annam-brahma1.webp',
  ab2: '/Csr-annam-brahma/annam-brahma2.webp',
  ab3: '/Csr-annam-brahma/annam-brahma3.webp',
  ab4: '/Csr-annam-brahma/annam-brahma4.webp',
  ab5: '/Csr-annam-brahma/annam-brahma5.webp',
  ab6: '/Csr-annam-brahma/annam-brahma6.webp',
  ab7: '/Csr-annam-brahma/annam-brahma7.webp',
  ab8: '/Csr-annam-brahma/annam-brahma8.webp',
  ab9: '/Csr-annam-brahma/annam-brahma9.webp',
  ab10: '/Csr-annam-brahma/annam-brahma10.webp',
  ab11: '/Csr-annam-brahma/annam-brahma11.webp',
  ab12: '/Csr-annam-brahma/annam-brahma12.webp',
  ab13: '/Csr-annam-brahma/annam-brahma13.webp',
  ab14: '/Csr-annam-brahma/annam-brahma14.jpeg',
  ab15: '/Csr-annam-brahma/annam-brahma15.webp',
  ab16: '/Csr-annam-brahma/annam-brahma16.webp',
  ab17: '/Csr-annam-brahma/annam-brahma17.webp',
  ab18: '/Csr-annam-brahma/annam-brahma18.webp',
  ab19: '/Csr-annam-brahma/annam-brahma19.webp',
  ab20: '/Csr-annam-brahma/annam-brahma20.webp',


  //Yoga for special children
  yfskCover: '/Csr-yfsk/yfskCover.webp',
  yfsk1: '/Csr-yfsk/yfsk1.webp',
  yfsk2: '/Csr-yfsk/yfsk2.webp',
  yfsk3: '/Csr-yfsk/yfsk3.webp',
  yfsk4: '/Csr-yfsk/yfsk4.webp',
  yfsk5: '/Csr-yfsk/yfsk5.webp',
  yfsk6: '/Csr-yfsk/yfsk6.webp',
  yfsk7: '/Csr-yfsk/yfsk7.webp',
  yfsk8: '/Csr-yfsk/yfsk8.webp',
  yfsk9: '/Csr-yfsk/yfsk9.webp',
  yfsk10: '/Csr-yfsk/yfsk10.webp',
  yfsk11: '/Csr-yfsk/yfsk11.webp',
  yfsk12: '/Csr-yfsk/yfsk12.webp',
  yfsk13: '/Csr-yfsk/yfsk13.webp',
  yfsk14: '/Csr-yfsk/yfsk14.webp',
  yfsk15: '/Csr-yfsk/yfsk15.webp',
  yfsk16: '/Csr-yfsk/yfsk16.webp',
  yfsk17: '/Csr-yfsk/yfsk17.webp',
  yfsk18: '/Csr-yfsk/yfsk18.webp',
  yfsk19: '/Csr-yfsk/yfsk19.webp',
  yfsk20: '/Csr-yfsk/yfsk20.webp',
  yfsk21: '/Csr-yfsk/yfsk21.webp',
  yfsk22: '/Csr-yfsk/yfsk22.webp',


  //Truckasana
  taCover: '/Csr-Truckasana/taCover.webp',
  ta1: '/Csr-Truckasana/ta1.webp',
  ta2: '/Csr-Truckasana/ta2.webp',
  ta3: '/Csr-Truckasana/ta3.webp',
  ta4: '/Csr-Truckasana/ta4.webp',
  ta5: '/Csr-Truckasana/ta5.webp',
  ta6: '/Csr-Truckasana/ta6.webp',
  ta7: '/Csr-Truckasana/ta7.webp',
  ta8: '/Csr-Truckasana/ta8.webp',
  ta9: '/Csr-Truckasana/ta9.webp',
  ta10: '/Csr-Truckasana/ta10.webp',
  ta11: '/Csr-Truckasana/ta11.webp',

  //Yoga for transgender
  transCover: '/Csr-trans/transCover.webp',
  trans1: '/Csr-trans/trans1.webp',
  trans2: '/Csr-trans/trans2.webp',
  trans3: '/Csr-trans/trans3.webp',
  trans4: '/Csr-trans/trans4.webp',
  trans5: '/Csr-trans/trans5.webp',
  trans6: '/Csr-trans/trans6.webp',
  trans7: '/Csr-trans/trans7.webp',
  trans8: '/Csr-trans/trans8.webp',
  trans9: '/Csr-trans/trans9.webp',
  trans10: '/Csr-trans/trans10.webp',
  trans11: '/Csr-trans/trans11.webp',

  //Tree plantation
  tpCover: '/Csr-treePlantation/tpCover.webp',
  tp1: '/Csr-treePlantation/tp1.webp',
  tp2: '/Csr-treePlantation/tp2.webp',
  tp3: '/Csr-treePlantation/tp3.webp',
  tp4: '/Csr-treePlantation/tp4.webp',
  tp5: '/Csr-treePlantation/tp5.webp',
  tp6: '/Csr-treePlantation/tp6.webp',
  tp7: '/Csr-treePlantation/tp7.webp',
  tp8: '/Csr-treePlantation/tp8.webp',
  tp9: '/Csr-treePlantation/tp9.webp',
  tp10: '/Csr-treePlantation/tp10.webp',
  tp11: '/Csr-treePlantation/tp11.webp',

  //Yoga for kids
  kycCover: '/Csr-kyc/kycCover.webp',
  kyc1: '/Csr-kyc/kyc1.webp',
  kyc2: '/Csr-kyc/kyc2.webp',
  kyc3: '/Csr-kyc/kyc3.webp',
  kyc4: '/Csr-kyc/kyc4.webp',
  kyc5: '/Csr-kyc/kyc5.webp',
  kyc6: '/Csr-kyc/kyc6.webp',
  kyc7: '/Csr-kyc/kyc7.webp',

  //Yoga for police
  policeCover: '/Csr-police/policeCover.webp',
  police1: '/Csr-police/police1.webp',
  police2: '/Csr-police/police2.webp',
  police3: '/Csr-police/police3.webp',
  police4: '/Csr-police/police4.webp',
  police5: '/Csr-police/police5.webp',
  police6: '/Csr-police/police6.webp',
  police7: '/Csr-police/police7.webp',
  police8: '/Csr-police/police8.webp',
  police9: '/Csr-police/police9.webp',
  police10: '/Csr-police/police10.webp',
  police11: '/Csr-police/police11.webp',
  police12: '/Csr-police/police12.webp',
  police13: '/Csr-police/police13.webp',
  police14: '/Csr-police/police14.webp',
  police15: '/Csr-police/police15.webp',
  police16: '/Csr-police/police16.webp',
  police17: '/Csr-police/police17.webp',
  police18: '/Csr-police/police18.webp',



}
export default baseDomain
