import baseDomain, {
  courseAssets,
  extraImage,
  homeAssets,
  nutriDiet,
} from '../../../assets/images/imageAsset'

const ttc200hr = {
  1: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    key: 'one-month-ttc',
    // templateId: 'COURSE200_1M_TTC',
    templateId: {
      templateOnline: 'COURSE200_1M_ONLINE_TTC',
      templateOffline: {
        templateResidential: 'COURSE200_RES_TTC',
        templateNonResidential: 'COURSE200_NONRES_TTC',
      },
    },
    weekDays: true,
    batch: 1,
    metaDescription:
      'Master your physical, mental, and spiritual well-being by learning the skills',
    image: `${baseDomain}${courseAssets.courseAsset38}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset101}`,
    mostPopular: true,
    rating: 5,
    colorCode: '#E38F73',

    timing: 'Monday to Saturday: 10:00 am - 6:00 pm (IST)',
    fees: {
      onlineFee: 27500,
      offlineFee: { residentialFee: 60000, nonResidentialFee: 33000 },
    },
    category: 'ttc',
    title: '200-Hour Yoga Teacher Training Course - 1 Month Yoga TTC Online & On Campus - English - Batch 1',
    dates: [

      // '1st Jan to 31st Jan 2024',
      // '1st Feb to 29th Feb 2024',
      // '1st Mar to 31st Mar 2024',
      // '1st Apr to 30th Apr 2024',
      // '1st May to 31st May 2024',
      // '1st Jun to 30th Jun 2024 ',
      // '1st Jul to 31st Jul 2024 ',
      // '1st Aug to 31st Aug 2024',
      // '1st Sep to 30th Sep 2024 ',
      // '1st Oct to 31st Oct 2024',
      // '2nd Nov to 30th Nov 2024',
      '2nd Dec to 31st Dec 2024',
      '1st Jan to 31st Jan 2025',
      '1st Feb to 28th Feb 2025',
      '1st Mar to 31st Mar 2025',
      '1st Apr to 30th Apr 2025',
      '1st May to 31st May 2025',
      '2nd Jun to 30th Jun 2025',
      '1st Jul to 31st Jul 2025',
      '1st Aug to 30th Aug 2025',
      '1st Sep to 30th Sep 2025',
      '1st Oct to 31st Oct 2025',
      '1st Nov to 29th Nov 2025',
      '1st Dec to 31st Dec 2025'

    ],
    online: true,
    onCampus: true,
    nonResidential: true,
    residential: true,

    tenure: '1 month',
    courseType: '(RYT 200- Basic)',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Educational',
    courseSubType: '200 Hour Yoga Teacher Training',
    newName: '200 Hours - Basic Yoga Teacher Training Course (YTTC)',
    days: [
      'Monday to Saturday'
    ],
    time: [
      '10:00 am - 6:00 pm (IST)'
    ],

    enrollInfo: {
      duration: "1 month",
      timings: [
        {
          day: "Monday to Saturday",
          time: "10:00 am - 6:00 pm (IST)"
        }
      ]
    },

    courseCertificate: 'TTC/Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: true,
      residentialMode: true
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    certficate: false,
    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'With over 1,00,000 successfully certified students, the 200 Hour Yoga Teacher Training Course at The Yoga Institute covers the nuances of Traditional Yoga. This includes an introduction to the study of principal Yoga Asanas, Pranayamas, Meditation, Kriyas, Patanjali’s Yoga Sutra, and Hatha Yoga Pradipika. Further, complete immersion into practical experiences of the concept studies, public speaking, the methodology of teaching, etc. are covered. Strategically designed by the founder Shri Yogendraji, Smt. Sita Devi Yogendra, Dr. Jayadeva Yogendra, and Dr. Hansaji J Yogendra, the one month yoga course serves as a valuable‘ life school’ to those seeking balance and fulfilment in life.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Benefits of 200 Hour Yoga Teacher Training Course',
          points: [
            {
              listItem:
                'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your life, relationships and work.',
              subItems: [],
            },
            {
              listItem:
                'A new career avenue opens up for you where you can pass on the knowledge to others and facilitate their wellness journeys. The art of Yoga is something you can teach anywhere, no matter where you are.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Course',
          text: [
            '200 hrs Yoga TTC  Batch 1 - 1 Month TTC Online & On Campus - English',
          ],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Duration',
      //     text: [o
      //       '1 Month | Course commences on Commences on the 1st day of each month',
      //     ],
      //   },
      // },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Date',
      //     text: ['1st Aug 2022 to 31st Aug 2022'],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Timings',
          text: ['Monday to Saturday: 10:00 am - 6:00 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [

            '2024 : Online:Rs. 27,500 /-', 'Non-Residential: Rs. 33,000/- (Inclusive of course fee, study material, lunch, and snacks)', ' Residential: Rs. 60,000/- (Inclusive of course fee, study material, accommodation on triple occupancy basis and 4 meals a day)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Prerequisites',
          text: ['None'],
        },
      },
    ],
    curriculum: [
      {
        type: 'paragraph',
        content: {
          title: '200 Hour Yoga Teacher Training Curriculum',
          text: [],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'A – KNOWLEDGE',
          points: [
            {
              listItem: 'Introduction to Yoga and Yogic Practices.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Hatha Yoga.',
              subItems: [
                'Introduction to important Hatha Yoga Texts with special reference to Hatha Yoga Pradipika.',
              ],
            },
            {
              listItem: 'Introduction to Patanjali Yoga Sutras.',
              subItems: [],
            },
            {
              listItem: 'Bhavas of the Buddhi – Basis of Yoga Education.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Anatomy and Physiology.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Human Systems, Yoga and Health',
              subItems: [],
            },
            {
              listItem:
                'Yoga for wellness – prevention and promotion of positive health',
              subItems: [],
            },
            {
              listItem: 'Yoga and stress management.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'B-SKILL',
          points: [
            {
              listItem: 'Sahajbhava Asanas',
              subItems: [],
            },
            {
              listItem: 'Shat Karmas',
              subItems: [],
            },
            {
              listItem: 'Surya Namaskar (Sun Salutation)',
              subItems: [],
            },
            {
              listItem: 'Yoga Asanas',
              subItems: [
                'Knowledge of selected postures and demonstrated ability to perform these postures – meditative and cultural (dynamic and static versions).',
                'Selected psychophysical and conceptual Yoga techniques pioneered by The Yoga Institute.',
              ],
            },
            {
              listItem: 'Pranayamas',
              subItems: [
                'Knowledge, teaching, and demonstrated ability to select Pranayamas.',
              ],
            },
            {
              listItem: 'Practices leading to Yoga Meditation',
              subItems: [],
            },
            {
              listItem: 'Communication and Teaching Practice',
              subItems: [
                'Understanding and practice of basic skills of public speaking.',
                'Familiarity and demonstration ability to apply teaching methods.',
                'Adapt the above to unique styles of learning.',
                'Provide supportive and effective feedback.',
                'Acknowledge the aspirant’s progress and ability to cope with difficulties.',
              ],
            },
            {
              listItem: 'Principles and skills for working with groups.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'url',
        content: {
          text: 'Access the detailed One Month Yoga Teacher Training Course curriculum here:',
          url: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/courses_pdf/200hr.pdf',
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Course Attendance Criteria',
          points: [
            {
              listItem:
                'A student is required to have a minimum 80% attendance',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },
      {
        type: 'table',
        content: {
          title: '',
          tableHeading: ['Details', 'Marks', 'Minimum Score Req.'],
          tableData: [['Theory Component', '50', '50%']],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'On successfully passing the exam, students will be .',
          points: [
            {
              listItem: 'Awarded a certificate – Basic Yoga Teacher Training Certificate (200 hours) by The Yoga Institute',
              subItems: []
            },
            {
              listItem: 'Qualified by Yoga Certification Board (AYUSH Level-1)',
              subItems: []
            },
            {
              listItem: 'Eligible to apply with Yoga Alliance USA (RYT 200)',
              subItems: []
            }
          ]
        },
      },

      {
        type: 'u-list',
        content: {
          title:
            'Post qualification, a number of opportunities are open to you, like:',
          points: [
            {
              listItem: 'Working with schools and teaching children',
              subItems: [],
            },
            {
              listItem:
                'Opening your own yoga center for healthy adults and children',
              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',
              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',
              subItems: [],
            },
          ],
        },
      },
    ],
    teaching: [
      {
        type: 'u-list',
        content: {
          title: 'Course Attendance Criteria',
          points: [
            {
              listItem:
                'A student is required to have a minimum 80% attendance',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },
      {
        type: 'table',
        content: {
          title: '',
          tableHeading: ['Details', 'Marks', 'Minimum Score Req.'],
          tableData: [['Theory Component', '50', '50%']],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'On successfully passing the exam, students will be awarded a certificate – Basic Teacher Training Certificate (200 hours) by The Yoga Institute, which is recognized by Yoga Certification Board (AYUSH Level-1) and Yoga Alliance USA (RYT 200).',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title:
            'Post qualification, a number of opportunities are open to you, like:',
          points: [
            {
              listItem: 'Working with schools and teaching children',
              subItems: [],
            },
            {
              listItem:
                'Opening your own yoga center for healthy adults and children',
              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',
              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Exam Details',
          text: [],
        }
      },
      {
        type: 'u-list',
        content: {
          title:
            ' ',
          points: [
            {
              listItem: 'Yoga Students will be eligible to appear for the Final certification exams only if they have completed all the required course submissions, class work and the required attendance',
              subItems: [],
            },
            {
              listItem:
                'Students will have to appear for the final exams within 60 days from the course end date.',
              subItems: [],
            },
            {
              listItem: 'Students will be permitted a maximum of 3 attempts, to clear the final exams, failing which they will have to repeat the course.',
              subItems: ['In the 2nd attempt, they will be exempted from either theory or practical’s, if they have cleared it in their 1st attempt.',
                'They will have to appear for both in case of a 3rd attempt.'
              ],
            },
            {
              listItem: 'Students can register for either online or on-campus exams.',
              subItems: ['Once finalized, students will not be permitted to switch from online to on-campus and vice versa.'],
            },
            {
              listItem: 'Examiners will evaluate students only as per the final approved list.',
              subItems: [],
            },


          ],

        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Exam Schedule ',
          points: [
            {
              listItem: 'On-campus Final exams (both theory and practical’s) will be conducted on the last working day of each month, or as announced (in case of exigencies), between 10 am (IST) and 2 pm IST.',
              subItems: [

              ],
            },
            {
              listItem: 'Online Final exams will be conducted on the 1st Sat and the 3rd Sat of each month or as announced (in case of exigencies), subject to a minimum quorum of 8 students.',
              subItems: [
                'Theory exams will be conducted online at 5pm (IST)',
                'Practical exams will be conducted online at 3.30 pm (IST) / 6.30 pm (IST), subject to quorum.'
              ],
            },
            {
              listItem: 'Students, after submission, can log out /leave only with the explicit permission of the examiner.',
              subItems: [
                'For practical’s - students will have to wait till the exams of all students are completed.',
                'The examiner may call out students at random and not necessarily in alphabetic order'
              ],
            },
            {
              listItem: 'A Mock test will be held 7 -14 days prior to the course end date, to familiarise students with the exam process.',
              subItems: [],
            },
          ],
        }
      }

    ],
    offerings: [
      {
        type: 'u-list',
        content: {
          title: '',
          points: [
            {
              listItem:
                'You will have an opportunity to directly interact with spiritual Guru Dr. Hansaji Yogendra. She is available to attend to your queries and give counsel.',
              subItems: [],
            },
            {
              listItem:
                ' Our Yoga expert trainers for the 200 Hour Yoga Teacher Training course, over 40 in number, have mastered the yogic way of life and are proficient in passing on their learnings in a simple and practical way. Armed with decades of experience, they offer tremendous value and insight to new learners.',
              subItems: [],
            },
            {
              listItem:
                ' Apart from the main yoga curriculum, you will learn immensely from the class experience itself. You will meet people with diverse backgrounds and experiences that you can gain insights from. There will be sadhakas of all age groups – right from teenagers to senior citizens. Every person has had a unique experience in life and it will be enriching to know all these stories.',
              subItems: [],
            },
            {
              listItem:
                'This one-of-a-kind experience will groom you to calmly and mindfully handle people and situations',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'quote',
        content: {
          title: '',
          text: [
            'This is an exclusive experience, and the wisdom you gain here will guide you all your life!',
          ],
        },
      },
    ],
    regsitraion: [],
    faq: [],
    moreInfo: {
      duration: "01 Month",
      mode: "Online & On Campus",
      language: "English",
      timing: "10:00 am - 6:00 pm (IST)",
      days: "Monday - Saturday",
      navigate: "/one-month-ttc",
    },
  },
  2: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    key: '200-hrs-part-time-ttc-on-campus-english',
    join: true,
    // templateId: 'COURSE200_1M_TTC2',
    templateId: {
      templateOnline: 'COURSE_200_HRS_ONLINE_CONFIRMATION_MAIL',
      templateOffline: {
        templateResidential: 'COURSE_200_HRS_RESIDENTIAL_CONFIRMATION_MAIL',
        templateNonResidential: 'COURSE_200_HRS_NONRESIDENTIAL_CONFIRMATION_MAIL',
      },
    },
    batch: 2,
    metaDescription: 'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your life, relationships and work.',
    image: `${baseDomain}${courseAssets.courseAsset38}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset101}`,
    mostPopular: false,
    rating: 5,
    colorCode: '#E38F73',
    timing: 'Monday to Friday : 12:00 noon - 4:00 pm (IST)',
    fees: {
      onlineFee: 27500,
      offlineFee: { residentialFee: 90000, nonResidentialFee: 33000 },
    },
    category: 'ttc',
    title: '200 Hours Yoga Teacher Training Course - 2 Months Yoga TTC Online and On Campus - English - Batch 2',
    dates: [
      // '12th Feb – 6th Apr 2024',
      // '15th Apr – 8th Jun 2024',
      // '10th Jun – 3rd Aug 2024',
      // '5th Aug – 28th Sep 2024',
      // '7th Oct – 30th Nov 2024',
      '9th Dec – 1st Feb 2025',
      '10th Feb - 5th Apr 2025',
      '14th Apr - 7th Jun 2025',
      '16th Jun - 9th Aug 2025',
      '18th Aug - 11th Oct 2025',
      '21st Oct - 13th Dec 2025',
      '22nd Dec - 14th Feb 2026',
    ],
    online: true,
    onCampus: true,
    nonResidential: true,
    residential: true,
    certficate: false,

    tenure: '2 month',
    courseType: '(RYT 200- Basic)',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Educational',
    courseSubType: '200 Hour Yoga Teacher Training',
    newName: '200 Hours - Basic Yoga Teacher Training Course (YTTC)',
    days: [
      'Monday to Friday'
    ],
    time: [
      '12:00 noon - 4:00 pm (IST)'
    ],

    enrollInfo: {
      duration: "2 months",
      timings: [
        {
          day: "Monday to Friday",
          time: "12:00 noon - 4:00 pm (IST)"
        }
      ]
    },

    courseCertificate: 'TTC/Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: true,
      residentialMode: true
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'With over 1,00,000 successfully certified students, the 200 Hours Yoga Teacher Training Course at The Yoga Institute covers the nuances of Traditional Yoga. This includes an introduction to the study of principal Yoga Asanas, Pranayamas, Meditation, Kriyas, Patanjali’s Yoga Sutra, and Hatha Yoga Pradipika. Further, complete immersion into practical experiences of the concept studies, public speaking, the methodology of teaching, etc. are covered. Strategically designed by the founder Shri Yogendraji, Smt. Sita Devi Yogendra, Dr. Jayadeva Yogendra, and Dr. Hansaji J Yogendra, ttc course serves as a valuable life school’ to those seeking balance and fulfillment in life.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Benefits of 200 Hours Yoga Teacher Training Course',
          points: [
            {
              listItem:
                'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your life, relationships and work.',
              subItems: [],
            },
            {
              listItem:
                'A new career avenue opens up for you where you can pass on the knowledge to others and facilitate their wellness journeys. The art of Yoga is something you can teach anywhere, no matter where you are.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Course',
          text: ['200 hrs Yoga TTC Batch 2 - 2 Months TTC Online & On Campus - English'],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Duration',
      //     text: ['11th Jul 2022to 2nd Sep 2022'],
      //   },
      // },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Date',
      //     text: [],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Timings',
          text: ['Monday to Friday : 12:00 noon - 4:00 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [
            '2024 : Online:Rs. 27,500 /-', 'Non-Residential: Rs. 33,000/- (Inclusive of course fee, study material.)', ' Residential: Rs. 90,000/- (Inclusive of course fee, study material, accommodation on triple occupancy basis and 4 meals a day)',
          ],

        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Prerequisites',
          text: ['None'],
        },
      },
    ],
    curriculum: [
      {
        type: 'paragraph',
        content: {
          title: '200 Hours Yoga Teacher Training Curriculum',
          text: [],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'A – KNOWLEDGE',
          points: [
            {
              listItem: 'Introduction to Yoga and Yogic Practices.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Hatha Yoga.',
              subItems: [
                'Introduction to important Hatha Yoga Texts with special reference to Hatha Yoga Pradipika.',
              ],
            },
            {
              listItem: 'Introduction to Patanjali Yoga Sutras.',
              subItems: [],
            },
            {
              listItem: 'Bhavas of the Buddhi – Basis of Yoga Education.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Anatomy and Physiology.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Human Systems, Yoga and Health',
              subItems: [],
            },
            {
              listItem:
                'Yoga for wellness – prevention and promotion of positive health',
              subItems: [],
            },
            {
              listItem: 'Yoga and stress management.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'B-SKILL',
          points: [
            {
              listItem: 'Sahajbhava Asanas',
              subItems: [],
            },
            {
              listItem: 'Shat Karmas',
              subItems: [],
            },
            {
              listItem: 'Surya Namaskar (Sun Salutation)',
              subItems: [],
            },
            {
              listItem: 'Yoga Asanas',
              subItems: [
                'Knowledge of selected postures and demonstrated ability to perform these postures – meditative and cultural (dynamic and static versions).',
                'Selected psychophysical and conceptual yoga techniques pioneered by The Yoga Institute.',
              ],
            },
            {
              listItem: 'Pranayamas',
              subItems: [
                'Knowledge, teaching, and demonstrated ability to select Pranayamas.',
              ],
            },
            {
              listItem: 'Practices leading to Meditation',
              subItems: [],
            },
            {
              listItem: 'Communication and Teaching Practice',
              subItems: [
                'Understanding and practice of basic skills of public speaking.',
                'Familiarity and demonstration ability to apply teaching methods.',
                'Adapt the above to unique styles of learning.',
                'Provide supportive and effective feedback.',
                'Acknowledge the aspirant’s progress and ability to cope with difficulties.',
              ],
            },
            {
              listItem: 'Principles and skills for working with groups.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'url',
        content: {
          text: 'Access the detailed 200 hour yttc curriculum:',
          url: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/courses_pdf/200hr.pdf',
        },
      },
    ],
    teaching: [
      {
        type: 'u-list',
        content: {
          title: 'Course Attendance Criteria',
          points: [
            {
              listItem:
                'A student is required to have a minimum 80% attendance',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },
      {
        type: 'table',
        content: {
          title: '',
          tableHeading: ['Details', 'Marks', 'Minimum Score Req.'],
          tableData: [['Theory Component', '50', '50%']],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'On successfully passing the exam, students will be .',
          points: [
            {
              listItem: 'Awarded a certificate – Basic Yoga Teacher Training Certificate (200 hours) by The Yoga Institute',
              subItems: []
            },
            {
              listItem: 'Qualified by Yoga Certification Board (AYUSH Level-1)',
              subItems: []
            },
            {
              listItem: 'Eligible to apply with Yoga Alliance USA (RYT 200)',
              subItems: []
            }
          ]
        },
      },
      {
        type: 'u-list',
        content: {
          title:
            'Post qualification, a number of opportunities are open to you, like:',
          points: [
            {
              listItem: 'Working with schools and teaching children',
              subItems: [],
            },
            {
              listItem:
                'Opening your own yoga center for healthy adults and children',
              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',
              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Exam Details',
          text: [],
        }
      },
      {
        type: 'u-list',
        content: {
          title:
            ' ',
          points: [
            {
              listItem: 'Yoga Students will be eligible to appear for the Final certification exams only if they have completed all the required course submissions, class work and the required attendance',
              subItems: [],
            },
            {
              listItem:
                'Students will have to appear for the final exams within 60 days from the course end date.',
              subItems: [],
            },
            {
              listItem: 'Students will be permitted a maximum of 3 attempts, to clear the final exams, failing which they will have to repeat the course.',
              subItems: ['In the 2nd attempt, they will be exempted from either theory or practical’s, if they have cleared it in their 1st attempt.',
                'They will have to appear for both in case of a 3rd attempt.'
              ],
            },
            {
              listItem: 'Students can register for either online or on-campus exams.',
              subItems: ['Once finalized, students will not be permitted to switch from online to on-campus and vice versa.'],
            },
            {
              listItem: 'Examiners will evaluate students only as per the final approved list.',
              subItems: [],
            },


          ],

        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Exam Schedule ',
          points: [
            {
              listItem: 'On-campus Final exams (both theory and practical’s) will be conducted on the last working day of each month, or as announced (in case of exigencies), between 10 am (IST) and 2 pm IST.',
              subItems: [

              ],
            },
            {
              listItem: 'Online Final exams will be conducted on the 1st Sat and the 3rd Sat of each month or as announced (in case of exigencies), subject to a minimum quorum of 8 students.',
              subItems: [
                'Theory exams will be conducted online at 5pm (IST)',
                'Practical exams will be conducted online at 3.30 pm (IST) / 6.30 pm (IST), subject to quorum.'
              ],
            },
            {
              listItem: 'Students, after submission, can log out /leave only with the explicit permission of the examiner.',
              subItems: [
                'For practical’s - students will have to wait till the exams of all students are completed.',
                'The examiner may call out students at random and not necessarily in alphabetic order'
              ],
            },
            {
              listItem: 'A Mock test will be held 7 -14 days prior to the course end date, to familiarise students with the exam process.',
              subItems: [],
            },
          ],
        }
      }
    ],
    offerings: [
      {
        type: 'u-list',
        content: {
          title: '',
          points: [
            {
              listItem:
                'You will have an opportunity to directly interact with spiritual Guru Dr. Hansaji Yogendra. She is available to attend to your queries and give counsel.',
              subItems: [],
            },
            {
              listItem:
                'Our Yoga expert trainers for the 200 hrs yoga teacher training course, over 40 in number, have mastered the yogic way of life and are proficient in passing on their learnings in a simple and practical way. Armed with decades of experience, they offer tremendous value and insight to new learners.',
              subItems: [],
            },
            {
              listItem:
                'Apart from the main yoga curriculum, you will learn immensely from the class experience itself. You will meet people with diverse backgrounds and experiences that you can gain insights from. There will be sadhakas of all age groups – right from teenagers to senior citizens. Every person has had a unique experience in life and it will be enriching to know all these stories.',
              subItems: [],
            },
            {
              listItem:
                'This one-of-a-kind experience will groom you to calmly and mindfully handle people and situations',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'quote',
        content: {
          title: '',
          text: [
            'This is an exclusive experience, and the wisdom you gain here will guide you all your life!',
          ],
        },
      },
    ],
    regsitraion: [],
    faq: [],
    moreInfo: {
      duration: "02 Months",
      mode: "Online & On Campus",
      language: "English",
      timing: "12:00 noon - 4:00 pm (IST)",
      days: "Monday - Friday",
      navigate: "/200-hrs-part-time-ttc-on-campus-english",
    },
  },
  3: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    key: '200-hrs-part-time-ttc-online-english',
    // templateId: 'COURSE200_2M_TTC2',
    templateId: {
      templateOnline: 'COURSE200_2M_ONLINE_TTC',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    batch: 3,
    metaDescription:
      'You will learn the tools to master your physical, mental and spiritual well-being.',
    textDescription: 'You will see a marked improvement in your life, relationships and work.',
    image: `${baseDomain}${courseAssets.courseAsset38}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset101}`,
    mostPopular: false,
    rating: 5,
    colorCode: '#E38F73',
    timing:
      'Monday to Saturday: Morning: 7:00 am - 8:30 am (IST) and Evening : 6:30 pm - 8:30 pm (IST) (Both the time slots are mandatory)',
    fees: {
      onlineFee: 27500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'ttc',
    title: '200-Hour Yoga Teacher Training Online Course - 2 Months TTC Online - English - Batch 3',
    dates: [
      // '8th Jan – 2nd Mar 2024',
      // '11th Mar – 4th May 2024',
      // '13th May – 6th Jul 2024',
      // '15th Jul – 7th Sep 2024',
      // '16th Sep – 9th Nov 2024',
      // '18th Nov – 11th Jan 2025',
      // '19th Feb - 8th Jun 2024',
      // '17th Jun - 5th Oct 2024',
      // '14th Oct - 1st Feb 2025',
      '20th Jan - 15th Mar 2025',
      '24th Mar - 17th May 2025',
      '26th May - 19th Jul 2025',
      '28th Jul - 20th Sep 2025',
      '29th Sep - 22nd Nov 2025',
      '25th Nov - 18th Jan 2026',
    ],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '2 month',
    courseType: '(RYT 200- Basic)',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Educational',
    courseSubType: '200 Hour Yoga Teacher Training',
    newName: '200 Hours - Basic Yoga Teacher Training Course (YTTC)',
    days: [
      'Monday to Saturday'
    ],
    time:
      [
        'Morning: 7:00 am - 8:30 am (IST)',
        'Evening : 6:30 pm - 8:30 pm (IST)'
      ],

    enrollInfo: {
      duration: "2 months",
      timings: [
        {
          day: "Monday to Saturday",
          time: "Morning: 7:00 am - 8:30 am (IST)"
        },
        {
          day: "Monday to Saturday",
          time: "Evening : 6:30 pm - 8:30 pm (IST) "
        }
      ],
      message: "(Both the time slots are mandatory)",
    },

    courseCertificate: 'TTC/Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'With over 1,00,000 successfully certified students, the 200-Hour Yoga Teacher Training Online Course at The Yoga Institute covers the nuances of Traditional Yoga. This includes an introduction to the study of principal Yoga Asanas, Pranayamas, Meditation, Kriyas, Patanjali’s Yoga Sutra, and Hatha Yoga Pradipika. Further, complete immersion into practical experiences of the concept studies, public speaking, the methodology of teaching, etc. are covered. Strategically designed by the founder Shri Yogendraji, Smt. Sita Devi Yogendra, Dr. Jayadeva Yogendra, and Dr. Hansaji J Yogendra, the online yoga course serve as a valuable life school’ to those seeking balance and fulfillment in life.'
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Benefits of 200-Hour Yoga Teacher Training Online Course',
          points: [
            {
              listItem:
                'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your life, relationships and work.',
              subItems: [],
            },
            {
              listItem:
                'A new career avenue opens up for you where you can pass on the knowledge to others and facilitate their wellness journeys. The art of Yoga is something you can teach anywhere, no matter where you are.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Course',
          text: ['200 hrs Yoga TTC Batch 3 - 2 Months TTC Online - English'],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Duration',
      //     text: ['8th Aug 2022to 1st Oct 2022'],
      //   },
      // },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Date',
      //     text: [],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Timings',
          text: [
            'Monday to Saturday: Morning: 7:00 am - 8:30 am (IST) and                                Evening : 6:30 pm - 8:30 pm (IST) (Both the time slots are mandatory)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['Rs. 27,500 /-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Prerequisites',
          text: ['None'],
        },
      },
    ],
    curriculum: [
      {
        type: 'paragraph',
        content: {
          title: '200-Hour Teacher Training Yoga Online Course Curriculum',
          text: [],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'A – KNOWLEDGE',
          points: [
            {
              listItem: 'Introduction to Yoga and Yogic Practices.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Hatha Yoga.',
              subItems: [
                'Introduction to important Hatha Yoga Texts with special reference to Hatha Yoga Pradipika.',
              ],
            },
            {
              listItem: 'Introduction to Patanjali Yoga Sutras.',
              subItems: [],
            },
            {
              listItem: 'Bhavas of the Buddhi – Basis of Yoga Education.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Anatomy and Physiology.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Human Systems, Yoga and Health',
              subItems: [],
            },
            {
              listItem:
                'Yoga for wellness – prevention and promotion of positive health',
              subItems: [],
            },
            {
              listItem: 'Yoga and stress management.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'B-SKILL',
          points: [
            {
              listItem: 'Sahajbhava Asanas',
              subItems: [],
            },
            {
              listItem: 'Shat Karmas',
              subItems: [],
            },
            {
              listItem: 'Surya Namaskar (Sun Salutation)',
              subItems: [],
            },
            {
              listItem: 'Yoga Asanas',
              subItems: [
                'Knowledge of selected postures and demonstrated ability to perform these postures – meditative and cultural (dynamic and static versions).',
                'Selected psychophysical and conceptual techniques pioneered by The Yoga Institute.',
              ],
            },
            {
              listItem: 'Pranayamas',
              subItems: [
                'Knowledge, teaching, and demonstrated ability to select Pranayamas.',
              ],
            },
            {
              listItem: 'Practices leading to Meditation',
              subItems: [],
            },
            {
              listItem: 'Communication and Teaching Practice',
              subItems: [
                'Understanding and practice of basic skills of public speaking.',
                'Familiarity and demonstration ability to apply teaching methods.',
                'Adapt the above to unique styles of learning.',
                'Provide supportive and effective feedback.',
                'Acknowledge the aspirant’s progress and ability to cope with difficulties.',
              ],
            },
            {
              listItem: 'Principles and skills for working with groups.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'url',
        content: {
          text: 'Access the online yoga certification course detailed curriculum here:',
          url: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/courses_pdf/200hr.pdf',
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Course Attendance Criteria',
          points: [
            {
              listItem:
                'A student is required to have a minimum 80% attendance',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },
      {
        type: 'table',
        content: {
          title: '',
          tableHeading: ['Details', 'Marks', 'Minimum Score Req.'],
          tableData: [['Theory Component', '50', '50%']],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'On successfully passing the exam, students will be .',
          points: [
            {
              listItem: 'Awarded a certificate – Basic Yoga Teacher Training Certificate (200 hours) by The Yoga Institute',
              subItems: []
            },
            {
              listItem: 'Qualified by Yoga Certification Board (AYUSH Level-1)',
              subItems: []
            },
            {
              listItem: 'Eligible to apply with Yoga Alliance USA (RYT 200)',
              subItems: []
            }
          ]
        },
      },
      {
        type: 'u-list',
        content: {
          title:
            'Post qualification, a number of opportunities are open to you, like:',
          points: [
            {
              listItem: 'Working with schools and teaching children',
              subItems: [],
            },
            {
              listItem:
                'Opening your own yoga center for healthy adults and children',
              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',
              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Exam Details',
          text: [],
        }
      },
      {
        type: 'u-list',
        content: {
          title:
            '',
          points: [
            {
              listItem: 'Students will be eligible to appear for the Final certification exams only if they have completed all the required course submissions, class work and the required attendance',
              subItems: [],
            },
            {
              listItem:
                'Students will have to appear for the final exams within 60 days from the course end date.',
              subItems: [],
            },
            {
              listItem: 'Students will be permitted a maximum of 3 attempts, to clear the final exams, failing which they will have to repeat the course.',
              subItems: ['In the 2nd attempt, they will be exempted from either theory or practical’s, if they have cleared it in their 1st attempt.',
                'They will have to appear for both in case of a 3rd attempt.'
              ],
            },
            {
              listItem: 'Examiners will evaluate students only as per the final approved list.',
              subItems: [],
            },


          ],

        },
      },
      {
        type: 'u-list',
        content: {
          title: 'All Exams / tests will be conducted online.',
          points: [
            {
              listItem: 'Students, after submission, can log out only with the explicit permission of the examiner.',
              subItems: [
                'For practical’s - students will have to wait till the exams of all students are completed.',
                'The examiner may call out students at random and not necessarily in alphabetic order'
              ],
            },

          ],
        }
      },
      {
        type: 'u-list',
        content: {
          title: 'Exam Schedule ',
          points: [
            {
              listItem: 'Final exams will be conducted online on the 1st Sat and the 3rd Sat of each month or as announced (in case of exigencies), subject to a quorum of 8 students.',
              subItems: [
                'Theory exams will be conducted online at 5pm (IST)',
                'Practical exams will be conducted at 3.30 pm (IST) / 6.30 pm (IST), subject to quorum.'
              ],
            },
            {
              listItem: 'A Mock test will be held 7 -14 days prior to the course end date, to familiarise students with the exam process.',
              subItems: [

              ],
            },

          ],
        }
      }
    ],
    teaching: [
      {
        type: 'u-list',
        content: {
          title: 'Course Attendance Criteria',
          points: [
            {
              listItem:
                'A student is required to have a minimum 80% attendance',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },
      {
        type: 'table',
        content: {
          title: '',
          tableHeading: ['Details', 'Marks', 'Minimum Score Req.'],
          tableData: [['Theory Component', '50', '50%']],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'On successfully passing the exam, students will be awarded a certificate – Basic Teacher Training Certificate (200 hours) by The Yoga Institute, which is recognized by Yoga Certification Board (AYUSH Level-1) and Yoga Alliance USA (RYT 200).',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title:
            'Post qualification, a number of opportunities are open to you, like:',
          points: [
            {
              listItem: 'Working with schools and teaching children',
              subItems: [],
            },
            {
              listItem:
                'Opening your own yoga center for healthy adults and children',
              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',
              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',
              subItems: [],
            },
          ],
        },
      },
    ],
    offerings: [
      {
        type: 'u-list',
        content: {
          title: '',
          points: [
            {
              listItem:
                'You will have an opportunity to directly interact with spiritual Guru Dr. Hansaji Yogendra. She is available to attend to your queries and give counsel.',
              subItems: [],
            },
            {
              listItem:
                'Our Yoga expert trainers for the 200-Hour Yoga Teacher Training Online course, over 40 in number, have mastered the yogic way of life and are proficient in passing on their learnings in a simple and practical way. Armed with decades of experience, they offer tremendous value and insight to new learners.',
              subItems: [],
            },
            {
              listItem:
                'Apart from the main yoga curriculum, you will learn immensely from the class experience itself. You will meet people with diverse backgrounds and experiences that you can gain insights from. There will be sadhakas of all age groups – right from teenagers to senior citizens. Every person has had a unique experience in life and it will be enriching to know all these stories.',
              subItems: [],
            },
            {
              listItem:
                'This one-of-a-kind experience will groom you to calmly and mindfully handle people and situations',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'quote',
        content: {
          title: '',
          text: [
            'This is an exclusive experience, and the wisdom you gain here will guide you all your life!',
          ],
        },
      },
    ],
    regsitraion: [],
    faq: [],
    moreInfo: {
      duration: "02 Months",
      mode: "Online",
      language: "English",
      timing: "Morning: 7:00am - 8:30am and Evening : 6:30pm - 8:30pm (IST)",
      days: "Monday - Saturday",
      navigate: "/200-hrs-part-time-ttc-online-english",
    },
  },
  4: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    key: '200-hrs-part-time-ttc-online',
    join: true,
    // templateId: 'COURSE200_2M_TTC_HINDI',
    templateId: {
      templateOnline: 'COURSE200_2M_ONLINE_HINDI_TTC',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    batch: 4,
    metaDescription: 'You will learn the tools to master your physical, mental, and spiritual well-being. You will see a marked improvement in your life, relationships, and work.',
    image: `${baseDomain}${courseAssets.courseAsset38}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset101}`,
    mostPopular: false,
    rating: 5,
    colorCode: '#E38F73',
    timing: 'Monday to Saturday: 1:00 pm - 4:30 pm (IST)',
    fees: {
      onlineFee: 27500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'ttc',
    title: '200 Hours Hindi Teacher Training Course - 2 Months TTC Online - Hindi - Batch 4',
    dates: [
      // '15th Jan to 9th Mar 2024',
      // '18th Mar to 11th May 2024',
      // '20th May to 13th Jul 2024',
      // '22nd Jul to 14th Sep 2024',
      // '23rd Sep to 16th Nov 2024',
      // '14th Oct to 7th Dec 2024',
      // '25th Nov to 18th Jan 2025'
      '17th Feb to 12th Apr 2025',
      '5th May to 28th Jun 2025',
      '7th Jul to 30th Aug 2025',
      '6th Oct to 29th Nov 2025',

    ],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '2 month',
    courseType: '(RYT 200- Basic)',
    language: 'Hindi',
    preRequisite: 'None',
    courseCategory: 'Educational',
    courseSubType: '200 Hour Yoga Teacher Training',
    newName: '200 Hours - Basic Yoga Teacher Training Course (YTTC)',
    days: [
      'Monday to Saturday'
    ],
    time: [
      '1:00 pm - 4:30 pm (IST)'
    ],

    enrollInfo: {
      duration: "2 months",
      timings: [
        {
          day: "Monday to Saturday",
          time: "1:00 pm - 4:30 pm (IST)"
        },
      ],
    },

    courseCertificate: 'TTC/Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'With over 1,00,000 successfully certified students, the 200 Hours Hindi Teacher Training Course at The Yoga Institute covers the nuances of Traditional Yoga. This includes an introduction to the study of principal Yoga Asanas, Pranayamas, Meditation, Kriyas, Patanjali’s Yoga Sutra, and Hatha Yoga Pradipika. Further, complete immersion into practical experiences of the concept studies, public speaking, the methodology of teaching, etc. are covered. Strategically designed by the founder Shri Yogendraji, Smt. Sita Devi Yogendra, Dr. Jayadeva Yogendra, and Dr. Hansaji J Yogendra, the TTC course serves as a valuable life school’ to those seeking balance and fulfillment in life.'
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Benefits of 200 Hours Hindi Teacher Training Course',
          points: [
            {
              listItem:
                'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your life, relationships and work.',
              subItems: [],
            },
            {
              listItem:
                'A new career avenue opens up for you where you can pass on the knowledge to others and facilitate their wellness journeys. The art of Yoga is something you can teach anywhere, no matter where you are.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Course',
          text: ['200 hrs Yoga TTC Batch 4 - 2 Months TTC Online  - Hindi'],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: 'Timings',
          text: ['Monday to Saturday: 1:00 pm - 4:30 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['Rs. 27,500/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Prerequisites',
          text: ['None'],
        },
      },
    ],
    curriculum: [
      {
        type: 'u-list',
        content: {
          title: 'A – KNOWLEDGE',
          points: [
            {
              listItem: 'Introduction to Yoga and Yogic Practices.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Hatha Yoga.',
              subItems: [
                'Introduction to important Hatha Yoga Texts with special reference to Hatha Yoga Pradipika.',
              ],
            },
            {
              listItem: 'Introduction to Patanjali Yoga Sutras.',
              subItems: [],
            },
            {
              listItem: 'Bhavas of the Buddhi – Basis of Yoga Education.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Anatomy and Physiology.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Human Systems, Yoga and Health',
              subItems: [],
            },
            {
              listItem:
                'Yoga for wellness – prevention and promotion of positive health',
              subItems: [],
            },
            {
              listItem: 'Yoga and stress management.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'B-SKILL',
          points: [
            {
              listItem: 'Sahajbhava Asanas',
              subItems: [],
            },
            {
              listItem: 'Shat Karmas',
              subItems: [],
            },
            {
              listItem: 'Surya Namaskar (Sun Salutation)',
              subItems: [],
            },
            {
              listItem: 'Yoga Asanas',
              subItems: [
                'Knowledge of selected postures and demonstrated ability to perform these postures – meditative and cultural (dynamic and static versions).',
                'Selected psychophysical and conceptual techniques pioneered by The Yoga Institute.',
              ],
            },
            {
              listItem: 'Pranayamas',
              subItems: [
                'Knowledge, teaching, and demonstrated ability to select Pranayamas.',
              ],
            },
            {
              listItem: 'Practices leading to Meditation',
              subItems: [],
            },
            {
              listItem: 'Communication and Teaching Practice',
              subItems: [
                'Understanding and practice of basic skills of public speaking.',
                'Familiarity and demonstration ability to apply teaching methods.',
                'Adapt the above to unique styles of learning.',
                'Provide supportive and effective feedback.',
                'Acknowledge the aspirant’s progress and ability to cope with difficulties.',
              ],
            },
            {
              listItem: 'Principles and skills for working with groups.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'url',
        content: {
          text: 'Access the detailed Online Hindi Teacher Training Course curriculum here:',
          url: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/courses_pdf/200hr.pdf',
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Course Attendance Criteria',
          points: [
            {
              listItem:
                'A student is required to have a minimum 80% attendance',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },
      {
        type: 'table',
        content: {
          title: '',
          tableHeading: ['Details', 'Marks', 'Minimum Score Req.'],
          tableData: [['Theory Component', '50', '50%']],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'On successfully passing the exam, students will be awarded a certificate – Basic Teacher Training Certificate (200 hours) by The Yoga Institute, which is recognized by Yoga Certification Board (AYUSH Level-1) and Yoga Alliance USA (RYT 200).',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title:
            'Post qualification, a number of opportunities are open to you, like:',
          points: [
            {
              listItem: 'Working with schools and teaching children',
              subItems: [],
            },
            {
              listItem:
                'Opening your own yoga center for healthy adults and children',
              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',
              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',
              subItems: [],
            },
          ],
        },
      },
    ],
    teaching: [
      {
        type: 'u-list',
        content: {
          title: 'Course Attendance Criteria',
          points: [
            {
              listItem:
                'A student is required to have a minimum 80% attendance',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },
      {
        type: 'table',
        content: {
          title: '',
          tableHeading: ['Details', 'Marks', 'Minimum Score Req.'],
          tableData: [['Theory Component', '50', '50%']],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'On successfully passing the exam, students will be .',
          points: [
            {
              listItem: 'Awarded a certificate – Basic Yoga Teacher Training Certificate (200 hours) by The Yoga Institute',
              subItems: []
            },
            {
              listItem: 'Qualified by Yoga Certification Board (AYUSH Level-1)',
              subItems: []
            },
            {
              listItem: 'Eligible to apply with Yoga Alliance USA (RYT 200)',
              subItems: []
            }
          ]
        },
      },
      {
        type: 'u-list',
        content: {
          title:
            'Post qualification, a number of opportunities are open to you, like:',
          points: [
            {
              listItem: 'Working with schools and teaching children',
              subItems: [],
            },
            {
              listItem:
                'Opening your own yoga center for healthy adults and children',
              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',
              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Exam Details',
          text: [],
        }
      },
      {
        type: 'u-list',
        content: {
          title:
            '',
          points: [
            {
              listItem: 'Students will be eligible to appear for the Final certification exams only if they have completed all the required course submissions, class work and the required attendance',
              subItems: [],
            },
            {
              listItem:
                'Students will have to appear for the final exams within 60 days from the course end date.',
              subItems: [],
            },
            {
              listItem: 'Students will be permitted a maximum of 3 attempts, to clear the final exams, failing which they will have to repeat the course.',
              subItems: ['In the 2nd attempt, they will be exempted from either theory or practical’s, if they have cleared it in their 1st attempt.',
                'They will have to appear for both in case of a 3rd attempt.'
              ],
            },
            {
              listItem: 'Examiners will evaluate students only as per the final approved list.',
              subItems: [],
            },


          ],

        },
      },
      {
        type: 'u-list',
        content: {
          title: 'All Exams / tests will be conducted online.',
          points: [
            {
              listItem: 'Students, after submission, can log out only with the explicit permission of the examiner.',
              subItems: [
                'For practical’s - students will have to wait till the exams of all students are completed.',
                'The examiner may call out students at random and not necessarily in alphabetic order'
              ],
            },

          ],
        }
      },
      {
        type: 'u-list',
        content: {
          title: 'Exam Schedule ',
          points: [
            {
              listItem: 'Final exams will be conducted online on the 1st Sat and the 3rd Sat of each month or as announced (in case of exigencies), subject to a quorum of 8 students.',
              subItems: [
                'Theory exams will be conducted online at 5pm (IST)',
                'Practical exams will be conducted at 3.30 pm (IST) / 6.30 pm (IST), subject to quorum.'
              ],
            },
            {
              listItem: 'A Mock test will be held 7 -14 days prior to the course end date, to familiarise students with the exam process.',
              subItems: [

              ],
            },

          ],
        }
      }
    ],
    offerings: [
      {
        type: 'u-list',
        content: {
          title: '',
          points: [
            {
              listItem:
                'You will have an opportunity to directly interact with spiritual Guru Dr. Hansaji Yogendra. She is available to attend to your queries and give counsel.',
              subItems: [],
            },
            {
              listItem:
                'Our expert trainers for the TTC Course, over 40 in number, have mastered the yogic way of life and are proficient in passing on their learnings in a simple and practical way. Armed with decades of experience, they offer tremendous value and insight to new learners.',
              subItems: [],
            },
            {
              listItem:
                'Apart from the main yoga curriculum, you will learn immensely from the class experience itself. You will meet people with diverse backgrounds and experiences that you can gain insights from. There will be sadhakas of all age groups – right from teenagers to senior citizens. Every person has had a unique experience in life and it will be enriching to know all these stories.',
              subItems: [],
            },
            {
              listItem:
                'This one-of-a-kind experience will groom you to calmly and mindfully handle people and situations',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'quote',
        content: {
          title: '',
          text: [
            'This is an exclusive experience, and the wisdom you gain here will guide you all your life!',
          ],
        },
      },
    ],
    regsitraion: [],
    faq: [],
    moreInfo: {
      duration: "02 Month",
      mode: "Online ",
      language: "Hindi",
      timing: "1:00 pm - 4:30 pm (IST)",
      days: "Monday - Saturday",
      navigate: "/200-hrs-part-time-ttc-online",
    },
  },
  5: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    weekends: true,
    key: 'weekend-teacher-training-course',
    weekDays: true,
    // templateId: 'COURSE200_3M_TTC',
    templateId: {
      templateOnline: 'COURSE200_WEEKEND_TTC',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    batch: 5,
    metaDescription:
      ' Learn the yogic principles and practices for a balanced life',
    image: `${baseDomain}${courseAssets.courseAsset38}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset101}`,
    mostPopular: true,
    rating: 5,
    colorCode: '#E38F73',
    timing:
      'Saturday & Sunday: Saturday Sessions: 10:00 am – 8:30 pm (IST) and Sunday Sessions 10:00 am – 1:30 pm (IST)',
    fees: {
      onlineFee: 27500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'ttc',
    title: '200 Hours Yoga TTC  - 3 Months Weekend Yoga Teacher Training Course Online - English - Batch 5',
    dates: [
      // '20th Jan to 7th Apr 2024',
      // '13th Apr to 30th Jun 2024',
      // '6th Jul to 22nd Sep 2024',
      // '28th Sep to 15th Dec 2024 ',
      // '5th Oct to 22nd Dec 2024 ',
      '28th Dec to 16th Mar 2025',
      "22nd Mar to 8th Jun 2025",
      "14th Jun to 31st Aug 2025",
      "6th Sep to 23rd Nov 2025",
      "29th Nov to 15th Feb 2026",
      // '15th Mar to 1st Jun 2025',
      // '7th Jun to 24th Aug 2025',
      // '30th Aug to 16th Nov 2025',
      // '22nd Nov to 8th Feb 2026'
    ],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '3 month',
    courseType: '(RYT 200- Basic)',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Educational',
    courseSubType: '200 Hour Yoga Teacher Training',
    newName: '200 Hours - Basic Yoga Teacher Training Course (YTTC)',
    days: [
      'Saturday & Sunday'
    ],
    time: [
      'Saturday Sessions: 10:00 am – 8:30 pm (IST)',
      'Sunday Sessions 10:00 am – 1:30 pm (IST)'
    ],

    enrollInfo: {
      duration: "3 months",
      timings: [
        {
          day: "Saturday & Sunday",
          time: "Saturday Sessions: 10:00 am – 8:30 pm (IST)"
        },
        {
          day: "Saturday & Sunday",
          time: "Sunday Sessions 10:00 am – 1:30 pm (IST)"
        }
      ]
    },

    courseCertificate: 'TTC/Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'With over 1,00,000 successfully certified students, the 200 Hours Weekend Yoga Teacher Training Course at The Yoga Institute covers the nuances of Traditional Yoga. This includes an introduction to the study of principal Yoga Asanas, Pranayamas, Meditation, Kriyas, Patanjali’s Yoga Sutra, and Hatha Yoga Pradipika. Further, complete immersion into practical experiences of the concept studies, public speaking, the methodology of teaching, etc. are covered. Strategically designed by the founder Shri Yogendraji, Smt. Sita Devi Yogendra, Dr. Jayadeva Yogendra, and Dr. Hansaji J Yogendra, the yoga on weekends course serves as a valuable life school’ to those seeking balance and fulfillment in life.'
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Benefits of 200 Hours Weekend Yoga Teacher Training Course',
          points: [
            {
              listItem:
                'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your life, relationships and work.',
              subItems: [],
            },
            {
              listItem:
                'A new career avenue opens up for you where you can pass on the knowledge to others and facilitate their wellness journeys. The art of Yoga is something you can teach anywhere, no matter where you are.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Course',
          text: ['Batch 5 - 3 Months Weekend Yoga TTC Online - English'],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Duration',
      //     text: ['27th Aug 2022to 13th Nov 2022'],
      //   },
      // },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Date',
      //     text: [''],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Timings',
          text: [
            'Saturday & Sunday: Saturday Sessions: 10:00 am – 8:30 pm (IST) and Sunday Sessions 10:00 am – 1:30 pm (IST)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['Rs. 27,500/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Prerequisites',
          text: ['None'],
        },
      },
    ],
    curriculum: [
      {
        type: 'u-list',
        content: {
          title: 'A – KNOWLEDGE',
          points: [
            {
              listItem: 'Introduction to Yoga and Yogic Practices.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Hatha Yoga.',
              subItems: [
                'Introduction to important Hatha Yoga Texts with special reference to Hatha Yoga Pradipika.',
              ],
            },
            {
              listItem: 'Introduction to Patanjali Yoga Sutras.',
              subItems: [],
            },
            {
              listItem: 'Bhavas of the Buddhi – Basis of Yoga Education.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Anatomy and Physiology.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Human Systems, Yoga and Health',
              subItems: [],
            },
            {
              listItem:
                'Yoga for wellness – prevention and promotion of positive health',
              subItems: [],
            },
            {
              listItem: 'Yoga and stress management.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'B-SKILL',
          points: [
            {
              listItem: 'Sahajbhava Asanas',
              subItems: [],
            },
            {
              listItem: 'Shat Karmas',
              subItems: [],
            },
            {
              listItem: 'Surya Namaskar (Sun Salutation)',
              subItems: [],
            },
            {
              listItem: 'Asanas',
              subItems: [
                'Knowledge of selected postures and demonstrated ability to perform these postures – meditative and cultural (dynamic and static versions).',
                'Selected psychophysical and conceptual techniques pioneered by The Yoga Institute.',
              ],
            },
            {
              listItem: 'Pranayamas',
              subItems: [
                'Knowledge, teaching, and demonstrated ability to select Pranayamas.',
              ],
            },
            {
              listItem: 'Practices leading to Meditation',
              subItems: [],
            },
            {
              listItem: 'Communication and Teaching Practice',
              subItems: [
                'Understanding and practice of basic skills of public speaking.',
                'Familiarity and demonstration ability to apply teaching methods.',
                'Adapt the above to unique styles of learning.',
                'Provide supportive and effective feedback.',
                'Acknowledge the aspirant’s progress and ability to cope with difficulties.',
              ],
            },
            {
              listItem: 'Principles and skills for working with groups.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'url',
        content: {
          text: 'Access the yoga classes weekend detailed curriculum here:',
          url: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/courses_pdf/200hr.pdf',
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Course Attendance Criteria',
          points: [
            {
              listItem:
                'A student is required to have a minimum 80% attendance',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },
      {
        type: 'table',
        content: {
          title: '',
          tableHeading: ['Details', 'Marks', 'Minimum Score Req.'],
          tableData: [['Theory Component', '50', '50%']],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'On successfully passing the exam, students will be .',
          points: [
            {
              listItem: 'Awarded a certificate – Basic Yoga Teacher Training Certificate (200 hours) by The Yoga Institute',
              subItems: []
            },
            {
              listItem: 'Qualified by Yoga Certification Board (AYUSH Level-1)',
              subItems: []
            },
            {
              listItem: 'Eligible to apply with Yoga Alliance USA (RYT 200)',
              subItems: []
            }
          ]
        },
      },
      {
        type: 'u-list',
        content: {
          title:
            'Post qualification, a number of opportunities are open to you, like:',
          points: [
            {
              listItem: 'Working with schools and teaching children',
              subItems: [],
            },
            {
              listItem:
                'Opening your own yoga center for healthy adults and children',
              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',
              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',
              subItems: [],
            },
          ],
        },
      },
    ],
    teaching: [
      {
        type: 'u-list',
        content: {
          title: '3-Month Weekend Yoga TTC Teaching & Certification',
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Course Attendance Criteria',
          points: [
            {
              listItem:
                'A student is required to have a minimum 80% attendance',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },
      {
        type: 'table',
        content: {
          title: '',
          tableHeading: ['Details', 'Marks', 'Minimum Score Req.'],
          tableData: [['Theory Component', '50', '50%']],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'On successfully passing the exam, students will be awarded a certificate – Basic Teacher Training Certificate (200 hours) by The Yoga Institute, which is recognized by Yoga Certification Board (AYUSH Level-1) and Yoga Alliance USA (RYT 200).',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title:
            'Post qualification, a number of opportunities are open to you, like:',
          points: [
            {
              listItem: 'Working with schools and teaching children',
              subItems: [],
            },
            {
              listItem:
                'Opening your own yoga center for healthy adults and children',
              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',
              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Exam Details',
          text: [],
        }
      },
      {
        type: 'u-list',
        content: {
          title:
            '',
          points: [
            {
              listItem: 'Students will be eligible to appear for the Final certification exams only if they have completed all the required course submissions, class work and the required attendance',
              subItems: [],
            },
            {
              listItem:
                'Students will have to appear for the final exams within 60 days from the course end date.',
              subItems: [],
            },
            {
              listItem: 'Students will be permitted a maximum of 3 attempts, to clear the final exams, failing which they will have to repeat the course.',
              subItems: ['In the 2nd attempt, they will be exempted from either theory or practical’s, if they have cleared it in their 1st attempt.',
                'They will have to appear for both in case of a 3rd attempt.'
              ],
            },
            {
              listItem: 'Examiners will evaluate students only as per the final approved list.',
              subItems: [],
            },


          ],

        },
      },
      {
        type: 'u-list',
        content: {
          title: 'All Exams / tests will be conducted online.',
          points: [
            {
              listItem: 'Students, after submission, can log out only with the explicit permission of the examiner.',
              subItems: [
                'For practical’s - students will have to wait till the exams of all students are completed.',
                'The examiner may call out students at random and not necessarily in alphabetic order'
              ],
            },

          ],
        }
      },
      {
        type: 'u-list',
        content: {
          title: 'Exam Schedule ',
          points: [
            {
              listItem: 'Final exams will be conducted online on the 1st Sat and the 3rd Sat of each month or as announced (in case of exigencies), subject to a quorum of 8 students.',
              subItems: [
                'Theory exams will be conducted online at 5pm (IST)',
                'Practical exams will be conducted at 3.30 pm (IST) / 6.30 pm (IST), subject to quorum.'
              ],
            },
            {
              listItem: 'A Mock test will be held 7 -14 days prior to the course end date, to familiarise students with the exam process.',
              subItems: [

              ],
            },

          ],
        }
      }
    ],
    offerings: [
      {
        type: 'u-list',
        content: {
          title: '',
          points: [
            {
              listItem:
                'You will have an opportunity to directly interact with spiritual Guru Dr. Hansaji Yogendra. She is available to attend to your queries and give counsel.',
              subItems: [],
            },
            {
              listItem:
                'Our expert trainers for the ttc course, over 40 in number, have mastered the yogic way of life and are proficient in passing on their learnings in a simple and practical way. Armed with decades of experience, they offer tremendous value and insight to new learners.',
              subItems: [],
            },
            {
              listItem:
                'Apart from the main yoga curriculum, you will learn immensely from the class experience itself. You will meet people with diverse backgrounds and experiences that you can gain insights from. There will be sadhakas of all age groups – right from teenagers to senior citizens. Every person has had a unique experience in life and it will be enriching to know all these stories.',
              subItems: [],
            },
            {
              listItem:
                'This one-of-a-kind experience will groom you to calmly and mindfully handle people and situations',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'quote',
        content: {
          title: '',
          text: [
            'This is an exclusive experience, and the wisdom you gain here will guide you all your life!',
          ],
        },
      },
    ],
    regsitraion: [],
    faq: [],
    moreInfo: {
      duration: "03 Months",
      mode: "Online",
      language: "English",
      timing: "Saturday: 10:00 am – 8:30 pm (IST) and Sunday: 10:00 am – 1:30 pm (IST)",
      days: "Saturday & Sunday",
      navigate: "/weekend-teacher-training-course",
    },
  },
  6: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    key: '200-hrs-part-time-ttc-online-english-batch-6',
    // templateId: 'COURSE200_2M_TTC',
    templateId: {
      templateOnline: 'COURSE200_2M_ONLINE_TTC_BATCH5',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    batch: 6,
    metaDescription:
      'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your life, relationships and work.',
    image: `${baseDomain}${courseAssets.courseAsset38}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset101}`,
    mostPopular: false,
    rating: 5,
    colorCode: '#E38F73',
    timing: 'Monday to Saturday: 4:30 pm – 8:00 pm (IST)',
    fees: {
      onlineFee: 27500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'ttc',
    title: '200 Hours Yoga TTC - 2 Months Online Yoga Teacher Training Course - English - Batch 6 ',
    dates: [
      // '29th July to 21st September 2024',
      '2nd Dec to 25th Jan 2025',
      '3rd Feb to 29th Mar 2025',
      '7th Apr to 31st May 2025',
      '9th Jun to 2nd Aug 2025',
      '11th Aug to 4th Oct 2025',
      '13th Oct to 6th Dec 2025',
      '15th Dec to 7th Feb 2026',
    ],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '2 month',
    courseType: '(RYT 200- Basic)',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Educational',
    courseSubType: '200 Hour Yoga Teacher Training',
    newName: '200 Hours - Basic Yoga Teacher Training Course (YTTC)',
    days: [
      'Monday to Saturday'
    ],
    time: [
      '4:30 pm – 8:00 pm (IST)'
    ],

    enrollInfo: {
      duration: "2 months",
      timings: [
        {
          day: "Monday to Saturday",
          time: "4:30 pm – 8:00 pm (IST)"
        }
      ]
    },

    courseCertificate: 'TTC/Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },


    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'With over 1,00,000 successfully certified students, the 200 Hours Online Yoga Teacher Training Course at The Yoga Institute covers the nuances of Traditional Yoga. This includes an introduction to the study of principal Yoga Asanas, Pranayamas, Meditation, Kriyas, Patanjali’s Yoga Sutra, and Hatha Yoga Pradipika. Further, complete immersion into practical experiences of the concept studies, public speaking, the methodology of teaching, etc. are covered. Strategically designed by the founder Shri Yogendraji, Smt. Sita Devi Yogendra, Dr. Jayadeva Yogendra, and Dr. Hansaji J Yogendra, the Online Teacher Training Course  serves as a valuable‘ life school’ to those seeking balance and fulfilment in life.'
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Benefits of 200 Hours Online Yoga Teacher Training Course',
          points: [
            {
              listItem:
                'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your life, relationships and work.',
              subItems: [],
            },
            {
              listItem:
                'A new career avenue opens up for you where you can pass on the knowledge to others and facilitate their wellness journeys. The art of Yoga is something you can teach anywhere, no matter where you are.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Course',
          text: ['200 hrs Yoga TTC Batch 6 - 2 Months TTC Online - English'],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Duration',
      //     text: [],
      //   },
      // },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Date',
      //     text: [''],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Timings',
          text: ['Monday to Saturday: 4:30 pm – 8:00 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['Rs. 27,500/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Prerequisites',
          text: ['None'],
        },
      },
    ],
    curriculum: [
      {
        type: 'paragraph',
        content: {
          title: 'Online Yoga Teacher Training Course Curriculum',
          text: [],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'A – KNOWLEDGE',
          points: [
            {
              listItem: 'Introduction to Yoga and Yogic Practices.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Hatha Yoga.',
              subItems: [
                'Introduction to important Hatha Yoga Texts with special reference to Hatha Yoga Pradipika.',
              ],
            },
            {
              listItem: 'Introduction to Patanjali Yoga Sutras.',
              subItems: [],
            },
            {
              listItem: 'Bhavas of the Buddhi – Basis of Yoga Education.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Anatomy and Physiology.',
              subItems: [],
            },
            {
              listItem: 'Introduction to Human Systems, Yoga and Health',
              subItems: [],
            },
            {
              listItem:
                'Yoga for wellness – prevention and promotion of positive health',
              subItems: [],
            },
            {
              listItem: 'Yoga and stress management.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'B-SKILL',
          points: [
            {
              listItem: 'Sahajbhava Asanas',
              subItems: [],
            },
            {
              listItem: 'Shat Karmas',
              subItems: [],
            },
            {
              listItem: 'Surya Namaskar (Sun Salutation)',
              subItems: [],
            },
            {
              listItem: 'Yoga Asanas',
              subItems: [
                'Knowledge of selected postures and demonstrated ability to perform these postures – meditative and cultural (dynamic and static versions).',
                'Selected psychophysical and conceptual techniques pioneered by The Yoga Institute.',
              ],
            },
            {
              listItem: 'Pranayamas',
              subItems: [
                'Knowledge, teaching, and demonstrated ability to select Pranayamas.',
              ],
            },
            {
              listItem: 'Practices leading to Yoga Meditation',
              subItems: [],
            },
            {
              listItem: 'Communication and Teaching Practice',
              subItems: [
                'Understanding and practice of basic skills of public speaking.',
                'Familiarity and demonstration ability to apply teaching methods.',
                'Adapt the above to unique styles of learning.',
                'Provide supportive and effective feedback.',
                'Acknowledge the aspirant’s progress and ability to cope with difficulties.',
              ],
            },
            {
              listItem: 'Principles and skills for working with groups.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'url',
        content: {
          text: 'Access the detailed curriculum here:',
          url: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/courses_pdf/200hr.pdf',
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Course Attendance Criteria',
          points: [
            {
              listItem:
                'A yoga student is required to have a minimum 80% attendance',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },
      {
        type: 'table',
        content: {
          title: '',
          tableHeading: ['Details', 'Marks', 'Minimum Score Req.'],
          tableData: [['Theory Component', '50', '50%']],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'On successfully passing the exam, students will be .',
          points: [
            {
              listItem: 'Awarded a certificate – Basic Yoga Teacher Training Certificate (200 hours) by The Yoga Institute',
              subItems: []
            },
            {
              listItem: 'Qualified by Yoga Certification Board (AYUSH Level-1)',
              subItems: []
            },
            {
              listItem: 'Eligible to apply with Yoga Alliance USA (RYT 200)',
              subItems: []
            }
          ]
        },
      },
      {
        type: 'u-list',
        content: {
          title:
            'Post qualification, a number of opportunities are open to you, like:',
          points: [
            {
              listItem: 'Working with schools and teaching children',
              subItems: [],
            },
            {
              listItem:
                'Opening your own yoga center for healthy adults and children',
              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',
              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Exam Details',
          text: [],
        }
      },
      {
        type: 'u-list',
        content: {
          title:
            '',
          points: [
            {
              listItem: 'Students will be eligible to appear for the Final certification exams only if they have completed all the required course submissions, class work and the required attendance',
              subItems: [],
            },
            {
              listItem:
                'Students will have to appear for the final exams within 60 days from the course end date.',
              subItems: [],
            },
            {
              listItem: 'Students will be permitted a maximum of 3 attempts, to clear the final exams, failing which they will have to repeat the course.',
              subItems: ['In the 2nd attempt, they will be exempted from either theory or practical’s, if they have cleared it in their 1st attempt.',
                'They will have to appear for both in case of a 3rd attempt.'
              ],
            },
            {
              listItem: 'Examiners will evaluate students only as per the final approved list.',
              subItems: [],
            },


          ],

        },
      },
      {
        type: 'u-list',
        content: {
          title: 'All Exams / tests will be conducted online.',
          points: [
            {
              listItem: 'Students, after submission, can log out only with the explicit permission of the examiner.',
              subItems: [
                'For practical’s - students will have to wait till the exams of all students are completed.',
                'The examiner may call out students at random and not necessarily in alphabetic order'
              ],
            },

          ],
        }
      },
      {
        type: 'u-list',
        content: {
          title: 'Exam Schedule ',
          points: [
            {
              listItem: 'Final exams will be conducted online on the 1st Sat and the 3rd Sat of each month or as announced (in case of exigencies), subject to a quorum of 8 students.',
              subItems: [
                'Theory exams will be conducted online at 5pm (IST)',
                'Practical exams will be conducted at 3.30 pm (IST) / 6.30 pm (IST), subject to quorum.'
              ],
            },
            {
              listItem: 'A Mock test will be held 7 -14 days prior to the course end date, to familiarise students with the exam process.',
              subItems: [

              ],
            },

          ],
        }
      }
    ],
    teaching: [
      {
        type: 'u-list',
        content: {
          title: 'Course Attendance Criteria',
          points: [
            {
              listItem:
                'A student is required to have a minimum 80% attendance',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },
      {
        type: 'table',
        content: {
          title: '',
          tableHeading: ['Details', 'Marks', 'Minimum Score Req.'],
          tableData: [['Theory Component', '50', '50%']],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'On successfully passing the exam, students will be awarded a certificate – Basic Teacher Training Certificate (200 hours) by The Yoga Institute, which is recognized by Yoga Certification Board (AYUSH Level-1) and Yoga Alliance USA (RYT 200).',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title:
            'Post qualification, a number of opportunities are open to you, like:',
          points: [
            {
              listItem: 'Working with schools and teaching children',
              subItems: [],
            },
            {
              listItem:
                'Opening your own yoga center for healthy adults and children',
              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',
              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',
              subItems: [],
            },
          ],
        },
      },
    ],
    offerings: [
      {
        type: 'u-list',
        content: {
          title: '',
          points: [
            {
              listItem:
                'You will have an opportunity to directly interact with spiritual Guru Dr. Hansaji Yogendra. She is available to attend to your queries and give counsel.',
              subItems: [],
            },
            {
              listItem:
                'Our expert trainers for the TTC Course, over 40 in number, have mastered the yogic way of life and are proficient in passing on their learnings in a simple and practical way. Armed with decades of experience, they offer tremendous value and insight to new learners.',
              subItems: [],
            },
            {
              listItem:
                'Apart from the main yoga curriculum, you will learn immensely from the class experience itself. You will meet people with diverse backgrounds and experiences that you can gain insights from. There will be sadhakas of all age groups – right from teenagers to senior citizens. Every person has had a unique experience in life and it will be enriching to know all these stories.',
              subItems: [],
            },
            {
              listItem:
                'This one-of-a-kind experience will groom you to calmly and mindfully handle people and situations',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'quote',
        content: {
          title: '',
          text: [
            'This is an exclusive experience, and the wisdom you gain here will guide you all your life!',
          ],
        },
      },
    ],
    regsitraion: [],
    faq: [],
    moreInfo: {
      duration: "02 Months",
      mode: "Online",
      language: "English",
      timing: "4:30pm – 8:00pm (IST)",
      days: "Monday - Saturday",
      navigate: "/200-hrs-part-time-ttc-online-english-batch-6",
    },
  },
}

const ttc500hr = {
  1: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    weekDays: true,
    key: '500-hrs-online-yoga-teacher-training-course-intermediate-level',
    // templateId: 'COURSE500_3M_TTC',
    templateId: {
      templateOnline: 'COURSE500_3M_ON_TTC',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    batch: 1,
    metaDescription:
      'Learn and witness marked improvement in your life, relationships, and work',
    mostPopular: true,
    rating: 5,
    colorCode: '#D88068',
    timing:
      'Monday to Saturday: Morning: 7:00 am - 8:30 am (IST) and Evening: 4:30 pm - 8:30 pm (IST) (Both time slots are mandatory)',
    fees: {
      onlineFee: 40000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'ttc',
    title: '500-Hour Yoga Teacher Training Online Course - 3 Months Yoga TTC - English',
    image: `${baseDomain}${courseAssets.courseAsset39}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset102}`,
    dates: [
      // '12th Feb  - 4th May 2024',
      // '13th May - 3rd Aug 2024',
      // '12th Aug - 2nd Nov 2024',
      // '11th Nov - 1st Feb 2025',
      '17th Feb - 10th May 2025',
      '19th May - 9th Aug 2025',
      '18th Aug - 8th Nov 2025',
      '17th Nov - 7th  Feb 2026',

    ],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '3 month',
    courseType: 'Intermediate TTC (500 Hour Courses)',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Educational',
    courseSubType: '500 Hour Yoga Teacher Training',
    newName: '500 Hours - Intemediate Yoga Teacher Training Course (YTTC)',
    days: [
      'Monday to Saturday'
    ],
    time: [
      'Morning: 7:00 am - 8:30 am (IST)',
      'Evening: 4:30 pm - 8:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "3 months",
      timings: [
        {
          day: "Monday to Saturday",
          time: "Morning: 7:00 am - 8:30 am (IST)"
        },
        {
          day: "Monday to Saturday",
          time: "Evening: 4:30 pm - 8:30 pm (IST)"
        },
      ],
      message: "(Both time slots are mandatory)"
    },
    courseCertificate: 'TTC/Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: 'PROGRAM DETAILS',
          text: [
            'With over 1,00,000 successfully certified students, the 500-Hour Yoga Teacher Training Course(Intermediate) at The Yoga Institute covers the nuances of Traditional Yoga. This includes an introduction to the study of principal Yoga Asanas, Pranayamas, Meditation, Kriyas, Patanjali’s Yoga Sutra, and Hatha Yoga Pradipika. Further, complete immersion into practical experiences of the concept studies, public speaking, the methodology of teaching, etc. are covered. Strategically designed by the founder Shri Yogendraji, Smt. Sita Devi Yogendra, Dr. Jayadeva Yogendra, and Dr. Hansaji J Yogendra, the online yoga course serve as a valuable ‘life school’ to those seeking balance and fulfillment in life.'
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Benefits of 500-Hour Yoga Teacher Training Online Course',
          points: [
            {
              listItem:
                'You will learn the tools to master your physical, mental, and spiritual well-being. You will see a marked improvement in your life, relationships, and work.',
              subItems: [],
            },
            {
              listItem:
                'A new career avenue opens up for you where you can pass on the knowledge to others and facilitate their wellness journeys. The art of Yoga is something you can teach anywhere, no matter where you are.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Course',
          text: ['3 Months Yoga Teacher Training Course - Online English'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Duration',
          text: ['3 months'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Timings',
          text: [
            'Monday to Saturday: Morning: 7:00 am - 8:30 am (IST) and Evening: 4:30 pm - 8:30 pm (IST) (Both time slots are mandatory)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [' Rs. 40,000/- '],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Prerequisites',
          text: ['None'],
        },
      },
    ],
    curriculum: [
      {
        type: 'table',
        content: {
          title: '500-Hour Yoga Teacher Training Online Course Curriculum at a Glance',
          tableHeading: [],
          tableData: [
            [
              'Three Themes – The Topic of the Month (TOM)',
              'a. History and Background of Yoga,b. Modern Physical Approach and the Impact of Materialism.,c. Technology of Yoga ',
            ],
            [
              'Patanjali’s Yoga Sutras',
              'Selected sutras with in-depth meaning are covered.',
            ],
            [
              'Bhagwada Gita ',
              'Selected shlokas of the Bhagwada Gita – their understanding, interpretation, and application. ',
            ],
            [
              'Bhavas of Buddhi – Basics of yoga education',
              'A detailed study of eight traits (Bhavas) of Buddhi acquired due to the influence of Trigunas on Buddhi.',
            ],
            [
              'Public speaking / Methodology of Teaching / Practice Teaching ',
              'Understanding the basic skills of public speaking. Familiarity and demonstration of ability to apply teaching methods. Adapting the above to unique styles of learning. Providing supportive and effective feedback. Acknowledging the aspirant’s progress and ability to cope with difficulties. Principles and skills for working with groups.',
            ],
            [
              'Hath Yoga Pradipika & Gheranda Samhita ',
              'Introduction to the Hatha Yoga texts, traditions, their principles, their techniques, aims, and objectives; with some insights to their propounders namely Matsyendranath and Gorakshanath.',
            ],
            [
              'Anatomy & Physiology ',
              'A general understanding and knowledge of the various body systems in understanding the breathing rhythm and complementary / counterposes.',
            ],
            [
              'Yoga and Stress Management',
              'Application of yogic techniques to promote stress-free lifestyles.',
            ],
            [
              'Yogic Diet',
              'Knowledge about different type of foods and their effects on health',
            ],
            [
              'Yoga Techniques ',
              'Study of the following techniques and demonstrated ability to perform: Meditative Asanas; Cultural Asanas: for the spine: Upward stretch, Forward bending, Backward bending, Sideward bending, Torsion, Inversion, For extremities: For balancing: For abdominal compression: etc. Pranayamas and Kriyas',
            ],
            [
              'Meditation Techniques ITTC',
              'Intermediary Level meditation practices',
            ],
          ],
        },
      },
      {
        type: 'url',
        content: {
          text: 'Access the 3 months teacher training course detailed syllabus here:',
          url: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/courses_pdf/500hr.pdf',
        },
      },
    ],
    teaching: [
      {
        type: 'u-list',
        content: {
          title: 'Course Attendance Criteria',
          point: {
            listItem: 'A student is required to have a minimum 80% attendance',
            subItems: [],
          },
        },
      },
      //   {
      //     type: "paragraph",
      //     content: {
      //       title: "",
      //       text: [
      //         "After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:",
      //       ],
      //     },
      //   },
      {
        type: 'table',
        content: {
          title:
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          tableHeading: ['Details', 'Marks', 'Minimum Score Reqd.'],
          tableData: [
            ['Theory Component', '50', '50%'],
            ['Practical Component', '50', '50%'],
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'On successfully passing the exam, students may avail of the triple certification benefit. The Yoga Institute is recognised by the Government of India (Ministry of AYUSH – QCI) and is the first Yoga school to be accredited after the AYUSH -QCI scheme was launched. The course conforms to the syllabus of Level 2 – Yoga Teacher YCB scheme– mentored by AYUSH (Government of India). Once The Yoga Institute’s exams are successfully cleared by the yoga students/participants they are eligible to appear for AYUSH Level 2 exams. This is an RYT 500-hour yoga teacher training course, fully accredited with the Yoga Alliance USA and the participant is eligible to apply on the Yoga Alliance site as Register Yoga Teacher. After successfully completing the online yoga certification course, ITTC certificate holders will be qualified to conduct Yoga sessions for:'
          ],
        },
      },
      // {
      //   type: 'u-list',
      //   content: {
      //     title: '',
      //     point: [
      //       {
      //         listItem: 'School children',
      //         subItems: [],
      //       },
      //       {
      //         listItem:
      //           'Healthy adults incorporate (individual and groups) institutions and social groups',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'People with minor ailments and disorders.',
      //         subItems: [],
      //       },
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Exam Details',
          text: [],
        }
      },
      {
        type: 'u-list',
        content: {
          title:
            '',
          points: [
            {
              listItem: 'Students will be eligible to appear for the Final certification exams only if they have completed all the required course submissions, class work and the required attendance',
              subItems: [],
            },
            {
              listItem:
                'Students will have to appear for the final exams within 60 days from the course end date.',
              subItems: [],
            },
            {
              listItem: 'Students will be permitted a maximum of 3 attempts, to clear the final exams, failing which they will have to repeat the course.',
              subItems: ['In the 2nd attempt, they will be exempted from either theory or practical’s, if they have cleared it in their 1st attempt.',
                'They will have to appear for both in case of a 3rd attempt.'
              ],
            },
            {
              listItem: 'Examiners will evaluate students only as per the final approved list.',
              subItems: [],
            },


          ],

        },
      },
      {
        type: 'u-list',
        content: {
          title: 'All Exams / tests will be conducted online.',
          points: [
            {
              listItem: 'Students, after submission, can log out only with the explicit permission of the examiner.',
              subItems: [
                'For practical’s - students will have to wait till the exams of all students are completed.',
                'The examiner may call out students at random and not necessarily in alphabetic order'
              ],
            },

          ],
        }
      },
      {
        type: 'u-list',
        content: {
          title: 'Exam Schedule ',
          points: [
            {
              listItem: 'Final exams will be conducted online on the 1st Sat and the 3rd Sat of each month or as announced (in case of exigencies), subject to a quorum of 8 students.',
              subItems: [
                'Theory exams will be conducted online at 5pm (IST)',
                'Practical exams will be conducted at 3.30 pm (IST) / 6.30 pm (IST), subject to quorum.'
              ],
            },
            {
              listItem: 'A Mock test will be held 7 -14 days prior to the course end date, to familiarise students with the exam process.',
              subItems: [

              ],
            },

          ],
        }
      }
    ],
    offerings: [
      {
        type: 'u-list',
        content: {
          title: '',
          points: [
            {
              listItem:
                'You will have an opportunity to directly interact with spiritual Guru Dr. Hansaji Yogendra. She is available to attend to your queries and give counsel.',
              subItems: [],
            },
            {
              listItem:
                'Our expert trainers for the online yoga course, over 40 in number, have mastered the yogic way of life and are proficient in passing on their learnings in a simple and practical way. Armed with decades of experience, they offer tremendous value and insight to new learners.',
              subItems: [],
            },
            {
              listItem:
                'Apart from the main yoga curriculum, you will learn immensely from the class experience itself. You will meet people with diverse backgrounds and experiences that you can gain insights from. There will be sadhakas of all age groups – right from teenagers to senior citizens. Every person has had a unique experience in life and it will be enriching to know all these stories.',
              subItems: [],
            },
            {
              listItem:
                'This one-of-a-kind experience will groom you to calmly and mindfully handle people and situations.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'quote',
        content: {
          title: '',
          text: [
            'This is an exclusive experience, and the wisdom you gain here will guide you all your life!',
          ],
        },
      },
    ],
  },
}

const ttc900hr = {
  1: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    weekDays: true,
    key: '3-months-advanced-teacher-training-course',
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: 'COURSE900_3M_ONLINE_RES_TTC',
        templateNonResidential: 'COURSE900_3M_ONLINE_NONRES_TTC',
      },
    },
    batch: 1,

    metaDescription:
      'Achieve balance and fulfillment in life through yogic tools and techniques.',
    title: '900 Hours Yoga TTC - 3 Months Teacher Training Course On Campus - English',

    image: `${baseDomain}${courseAssets.courseAsset40}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset103}`,

    mostPopular: false,
    rating: 5,

    colorCode: '#C9705F',
    timing: 'Monday to Saturday:6:30 am to 8:00 pm Holiday on all Sundays',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 190000, nonResidentialFee: 110000 },
    },
    category: 'ttc',
    dates: [
      // '27th Jan to 21st Apr 2024',
      // '27th Apr to 21st Jul 2024',
      // '27th Jul to 20th Oct 2024',
      // '26th Oct to 20th Jan 2025',
      '25th Jan to 20th Apr 2025',
      '26th Apr to 20th Jul 2025',
      '26th Jul to 19th Oct 2025',
      '25th Oct to 18th Jan 2026',

    ],
    online: false,
    onCampus: true,
    nonResidential: true,
    residential: true,
    certficate: false,

    tenure: '3 month',
    courseType: 'Advance TTC (900 Hour Courses)',
    language: 'English',
    preRequisite: '7 Days Health Camp, 21 Days Better Living Course, 200 Hours Teacher Training Course',
    courseCategory: 'Educational',
    courseSubType: '900 Hour Yoga Teacher Training',
    newName: '900 Hours - Advanced Yoga Teacher Training Course (YTTC)',
    days: [
      'Monday to Saturday'
    ],
    time: [
      '6:30 am to 8:00 pm Holiday on all Sundays'
    ],
    enrollInfo: {
      duration: "3 months",
      timings: [
        {
          day: "Monday to Saturday",
          time: "6:30 am to 8:00 pm"
        }
      ],
      message: "Holiday on all Sundays"
    },

    courseCertificate: 'TTC/Certificate',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: true,
      residentialMode: true
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',

        content: {
          title: '',
          text: [
            'With over 1,00,000 successfully certified students since 1918, the 900 Hours Yoga Teacher Training Course at The Yoga Institute is an in-depth study of classical Ashtanga yoga (Eightfold Path Yoga). It teaches core yoga philosophy with a comprehensive approach to the science and technology of yoga. Strategically designed by the founder Shri Yogendraji, Smt. Sita Devi Yogendra, Dr. Jayadeva Yogendra, and Dr. Hansaji J Yogendra, the yoga teacher course serve as a valuable ‘life school’ to those seeking balance and fulfilment in life.'
          ],
        },
      },

      {
        type: 'u-list',

        content: {
          title: 'Benefits of 900 Hours/3 Months’ Teacher Training Course',

          points: [
            {
              listItem:
                'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your work, your relationships and life.',

              subItems: [],
            },

            {
              listItem:
                'A new career avenue opens up for you where you can pass on the knowledge to others and facilitate their wellness journeys. The art of Yoga is something you can teach anywhere, no matter where you are. ',

              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Course:',

          text: ['900 hrs Advanced Yoga Teacher Training Course 3 Months YTTC On Campus - English'],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Duration',

          text: ['Duration of the course is three months oncampus'],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Timings',

          text: [
            'Monday to Saturday:6:30 am to 8:00 pm Holiday on all Sundays',
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Fees:',

          text: [
            'Non-Residential: INR 1,10,000/- (Inclusive of course fee, study material, 4 meals a day and 7 Days Camp)',
            'Residential: INR 1,90,000/- (Inclusive of course fee, study material, accommodation on triple occupancy basis and 4 meals a day and 7 Days Camp)',
          ],
        },
      },

      // {
      //   type: 'paragraph',

      //   content: {
      //     title: 'Prerequisites:',

      //     text: [
      //       '200 HRS TTC / 7 Day Health Camp / 21 Days Better Living Course',
      //     ],
      //   },
      // },
    ],

    curriculum: [
      {
        type: 'table',

        content: {
          title: '3 Months Teacher Training Course Curriculum at a Glance',

          tableHeading: [],

          tableData: [
            [
              'In-depth practice of asana, pranayama and kriya',
              'Mastery over asana, pranayama and kriyas transforms and resets your body to its highest efficiency, making it strong and flexible. Your blocked energies will begin to flow again, giving you vitality. Kriyas help release that which harms our prana.',
            ],
            [
              'Anatomy and Physiology',
              'Gaining in-depth knowledge of your body systems – muscular, endocrine, respiratory etc. will empower you to manage modern-day diseases with yoga.Ullamco aute reprehenderit laboris dolore labore.',
            ],
            [
              'Samkhya Philosophy',
              'Learn about the tattvas that make you and the cosmos. Understand the elements and mechanisms that constitute your chitta, the mind.',
            ],
            [
              'Stress Management',
              'Learn the techniques to channel your mind and emotions so you are always determining your own destiny. This module will help you to manage into your relationship stresses and work pressures.            ',
            ],
            [
              'Patanjali’s Yoga Sutras',
              'Insight into the all-encompassing yoga sutra written by Patanjali 2500 years ago will give you the key to life itself. It is a text that will give you insight into your own subconscious and help you deal with your samskaras and vaasanas.            ',
            ],
            [
              'Quintessence of Bhagvada Gita',
              'Access the knowledge of karma yoga, gyaan yoga, bhakti yoga and utilize it to traverse through your present-day challenges situations.',
            ],
            [
              'Public speaking / Practice Teaching',
              'Learn how to transfer your knowledge and expertise to your students. Become a master of addressing a crowd with finesse and ease.',
            ],
            [
              'Experiential Learning',
              'Learn hands-on skills at the health camps and special activities.',
            ],
            [
              'Attitude Training',
              'Develop your personality through the right yogic attitude.            ',
            ],
            [
              'Yogic Diet',
              'Learn all about the nuances of yogi ahar, and understand the food categories – Sattvik, Rajasik, and Tamasik. With this module, you will learn to manage your diet habits for your overall well-being and weight management.             ',
            ],
            [
              'Meditation',
              'Learn to center yourself through sessions that will take you deep within yourself. Master the techniques to quite your mind and balance your energies. This acquired calmness and peace will stay with you throughout the day.            ',
            ],
          ],
        },
      },

      {
        type: 'url',

        content: {
          text: 'Access the advanced yoga teacher training course detailed syllabus here:',

          url: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/courses_pdf/900hr.pdf',
        },
      },
    ],

    teaching: [
      {
        type: 'paragraph',

        content: {
          title: 'Course Attendance Criteria',

          text: [
            'A student is required to have a minimum 80% attendance, failing which he or she cannot appear for the Institute exam. In such cases, students would be denied certification. All classes need to be compulsorily attended.',
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },

      {
        type: 'table',

        content: {
          title: '',

          tableHeading: ['Details', 'Marks', 'Minimum Score Req.', ''],

          tableData: [
            [
              'Theory Component',

              '60',

              'Objective section-40 Marks,Subjective section-20 Marks',

              '50%',
            ],

            [
              'Practical Component',

              '40',

              'Technique Demonstration-10 Marks,Teaching Yogic Practice(viva included)-30 Marks',

              '50%',
            ],
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: ['On successfully passing the exam, students will be:'],
        },
      },

      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem:
                'Awarded a certificate – Advanced Yoga Teacher Training Certificate (900 hours) by The Yoga Institute',
              subItems: [],
            },
            {
              listItem: 'Qualified by Yoga Certification Board (AYUSH Level-3)',
              subItems: [],
            },
            {
              listItem: 'Eligible to apply with Yoga Alliance USA (RYT 500)',
              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            'Post qualification, a number of opportunities are open to you, like:',
          ],
        },
      },
      {
        type: 'u-list',

        content: {
          title: '',
          points: [
            {
              listItem: 'Opening your own yoga training center ',
              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',
              subItems: [],
            },
            {
              listItem: 'Working with schools and teaching children',

              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',
              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: ['This 3 months teacher training course makes you eligible to teach:'],
        },
      },

      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem: 'Normal healthy individuals and groups,',
              subItems: [],
            },
            {
              listItem: 'For people with minor ailments or disorders and',
              subItems: [],
            },
            {
              listItem:
                'For people, under the guidance of qualified medical practitioners, undergoing treatment for any physiological, psychological and psychosomatic diseases/conditions.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Exam Details',
          text: [],
        }
      },
      {
        type: 'u-list',
        content: {
          title: ' ',
          points: [
            {
              listItem: 'Students will be eligible to appear for the Final certification exams only if they have completed all the required course submissions, class work and the required attendance',
              subItems: [],
            },
            {
              listItem:
                'Students will have to appear for the final exams within 60 days from the course end date.',
              subItems: [],
            },
            {
              listItem: 'Students will be permitted a maximum of 3 attempts, to clear the final exams, failing which they will have to repeat the course.',
              subItems: ['In the 2nd attempt, they will be exempted from either theory or practical’s, if they have cleared it in their 1st attempt.',
                'They will have to appear for both in case of a 3rd attempt.'
              ],
            },
            {
              listItem: 'Students can register for either online or on-campus exams.',
              subItems: ['Once finalized, students will not be permitted to switch from online to on-campus and vice versa.'],
            },
            {
              listItem: 'Examiners will evaluate students only as per the final approved list.',
              subItems: [],
            },


          ],

        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Exam Schedule ',
          points: [
            {
              listItem: 'On-campus Final exams (both theory and practical’s) will be conducted on the last working day of each month, or as announced (in case of exigencies), between 10 am (IST) and 2 pm IST.',
              subItems: [],
            },
            {
              listItem: 'Online Final exams will be conducted on the 1st Sat and the 3rd Sat of each month or as announced (in case of exigencies), subject to a minimum quorum of 8 students.',
              subItems: [
                'Theory exams will be conducted online at 5pm (IST)',
                'Practical exams will be conducted online at 3.30 pm (IST) / 6.30 pm (IST), subject to quorum.'
              ],
            },
            {
              listItem: 'Students, after submission, can log out /leave only with the explicit permission of the examiner.',
              subItems: [
                'For practical’s - students will have to wait till the exams of all students are completed.',
                'The examiner may call out students at random and not necessarily in alphabetic order'
              ],
            },
            {
              listItem: 'A Mock test will be held 7 -14 days prior to the course end date, to familiarise students with the exam process.',
              subItems: [],
            },
          ],
        }
      }
    ],

    offerings: [
      {
        type: 'u-list',
        content: {
          title: '',

          points: [
            {
              listItem:
                'You will have an opportunity to directly interact with our Guru Dr. Hansaji Yogendra. She is always available to attend to your queries and give counsel.',
              subItems: [],
            },
            {
              listItem:
                'Our expert trainers, over 40 in number, have mastered the yogic way of life and are proficient is passing on their learnings in a simple and practical way. Armed with decades of experience, they offer tremendous value and insight to new learners.',
              subItems: [],
            },
            {
              listItem:
                'Apart from the main yoga curriculum, you will learn immensely from the class experience itself. You will meet people with diverse backgrounds and experiences that you can gain insights from. There will be sadhakas of all age groups – right from teenagers to senior citizens. Every person has had a unique experience in life and it will be enriching to know all these stories.',
              subItems: [],
            },
            {
              listItem:
                'This one-of-a-kind experience will groom you to calmly and mindfully handle people and situations',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'quote',

        content: {
          title: '',

          text: [
            'This is an exclusive experience, the memories of which will stay with you all your life!',
          ],
        },
      },
      {
        type: 'u-list',

        content: {
          title: 'Our Amenities / Our Facilities',

          points: [
            {
              listItem:
                'Yoga Library: Explore one of largest collections of spiritual and philosophical books to expand your knowledge',
              subItems: [],
            },
            {
              listItem:
                'Internet Room: Access the internet in a comfortable setting when you need',
              subItems: [],
            },
            {
              listItem:
                'Meditation Pods: Meditate on the five fundamental natural elements, the Panchamahabhutas,',
              subItems: [],
            },
            {
              listItem:
                'Acupressure Path: Give your feet a natural massage and activate your vital energies',
              subItems: [],
            },
            {
              listItem:
                'Gazebo: Enjoy the soothing sounds of flowing water and take in the peace and at this relaxation area',
              subItems: [],
            },
            {
              listItem:
                'Dining Area: Enjoy wholesome Sattvik meals, specially prepared for your nourishment',
              subItems: [],
            },
            {
              listItem:
                'Yoga Book Store: Shop in a wide selection of TYI publications. Find yourself the best yogic attire and mats.',
              subItems: [],
            },
          ],
        },
      },
    ],
  },
  2: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    key: 'one-year-ttc',

    // templateId: 'COURSE900_1Y_TTC',
    templateId: {
      templateOnline: 'COURSE900_1Y_ON_HINDI_TTC',
      templateOffline: {
        templateResidential: 'COURSE900_1Y_RES_TTC',
        templateNonResidential: 'COURSE900_1Y_NONRES_HINDI_TTC',
      },
    },
    batch: 1,
    metaDescription:
      'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your life, relationships and work.',
    title: '900 Hours Yoga TTC - 1 Year Advanced Yoga Teacher Training Course Online & On-Campus - Hindi',
    image: `${baseDomain}${courseAssets.courseAsset40}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset103}`,
    mostPopular: false,
    rating: 5,
    colorCode: '#C9705F',
    timing: 'Monday to Friday : 1:30 pm - 4:00 pm (IST)',
    fees: {
      onlineFee: 60000,
      offlineFee: { residentialFee: 60000, nonResidentialFee: 60000 },
    },
    category: 'ttc',
    dates: [

      // '1st Jan 2024',
      // '1st Feb 2024',
      // '1st Mar 2024',
      // '1st Apr 2024',
      // '1st May 2024',
      // '1st Jun 2024',
      // '1st Jul 2024',
      // '1st Aug 2024',
      // '1st Sep 2024',
      // '1st Oct 2024',
      // '1st Nov 2024',
      '1st Dec 2024',
      '1st Jan 2025',
      '3rd Feb 2025',
      '3rd Mar 2025',
      '1st Arp 2025',
      '1st May 2025',
      '2nd Jun 2025',
      '1st Jul 2025',
      '1st Aug 2025',
      '1st Sep 2025',
      '1st Oct 2025',
      '3rd Nov 2025',
      '1st Dec 2025'
    ],
    online: true,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,

    tenure: '1 year',
    courseType: 'Advance TTC (900 Hour Courses)',
    language: 'Hindi',
    preRequisite: '7 Days Health Camp, 21 Days Better Living Course, 200 Hours Teacher Training Course',
    courseCategory: 'Educational',
    courseSubType: '900 Hour Yoga Teacher Training',


    courseCertificate: 'TTC/Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },
    newName: '900 Hours - Advanced Yoga Teacher Training Course (YTTC)',
    days: [
      'Monday to Friday',
    ],
    time: [
      '1:30 pm - 4:00 pm (IST)'
    ],
    enrollInfo: {
      duration: "1 year",
      timings: [
        {
          day: "Monday to Friday",
          time: "1:30 pm - 4:00 pm (IST)"
        }
      ]
    },
    details: [
      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            'With over 1,00,000/ 50,000 successfully certified students since 1918, the 1 Year Advanced Yoga Teacher Training Course (Hindi) at The Yoga Institute is an in-depth study of classical Ashtanga yoga (Eightfold Path Yoga). It teaches core yoga philosophy with a comprehensive approach to the science and technology of yoga. Strategically designed by the founder Shri Yogendraji, Smt. Sita Devi Yogendra, Dr. Jayadeva Yogendra, and Dr. Hansaji J Yogendra, The Yoga Institute course serves as a valuable ‘life school’ to those seeking balance and fulfillment in life.',

            'This course is ideal for those who have daily commitments at home, like homemakers. With the suitable schedule of this course, they can have access to advanced yoga training at their convenience and can become certified yoga trainers within one year.',
          ],
        },
      },

      {
        type: 'u-list',

        content: {
          title: 'Benefits of 1 Year Advanced Yoga Teacher Training Course',

          points: [
            {
              listItem:
                'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your work, your relationships and life.',
              subItems: [],
            },
            {
              listItem:
                'A new career avenue opens up for you where you can pass on the knowledge to others and facilitate their wellness journeys. The art of Yoga is something you can teach anywhere, no matter where you are.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',

        content: {
          title: 'Course:',

          text: ['900 hrs YTTC 1 Year Advanced Yoga Teacher Training Course Online & On Campus - Hindi'],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Duration',

          text: ['1 Year | Course commences on the 1st of each month'],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Timings',

          text: ['Monday to Friday : 1:30 pm - 4:00 pm (IST)'],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Fees:',

          text: [
            ' 2024 : INR. 60,000/- (Inclusive of course fee and study material)'],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Prerequisites:',

          text: [
            // 'Health Camp / 21 Days Better Living Course from the Yoga Institute',
            'To enroll in the 1-year Teacher Training Course, completion of the 7-days Health Camp, 21-days Better Living Course, or 200-hour TTC is required.',
            'You can apply for the 1-year TTC within three months of finishing any of these prerequisite courses.'
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            // 'Students have a choice to register for an online or offline (on-campus) course. Admissions are open for both options.',
          ],
        },
      },

      {
        type: 'table',

        content: {
          title: '',

          tableHeading: ['Mode of Learning', 'Online', 'On Campus'],

          tableData: [
            [
              'Details',

              'All classes will be online except the last 15 days which are to be done mandatorily on-campus at the end of the course',

              'All classes to be attended on campus',
            ],
          ],
        },
      },

      // {
      //   type: 'paragraph',

      //   content: {
      //     title: '',

      //     text: [
      //       'Note: The course is currently being conducted online ONLY. Once the pandemic restrictions ease, we will open the course for on-campus attendance as well. Students can then choose to either continue their course online OR switch to on-campus mode.',
      //     ],
      //   },
      // },
    ],
    curriculum: [
      {
        type: 'table',

        content: {
          title: 'Curriculum at a Glance',

          tableHeading: [],

          tableData: [
            [
              'In-depth practice of asana, pranayama and kriya',

              'Mastery over asana, pranayama and kriyas transforms and resets your body to its highest efficiency, making it strong and flexible. Your blocked energies will begin to flow again, giving you vitality. Kriyas help release that which harms our prana.',
            ],

            [
              'Anatomy and Physiology',

              'Gaining in-depth knowledge of your body systems – muscular, endocrine, respiratory etc. will empower you to manage modern-day diseases with yoga.Ullamco aute reprehenderit laboris dolore labore.',
            ],

            [
              'Samkhya Philosophy',

              'Learn about the tattvas that make you and the cosmos. Understand the elements and mechanisms that constitute your chitta, the mind.',
            ],

            [
              'Stress Management',

              'Learn the techniques to channel your mind and emotions so you are always determining your own destiny. This module will help you to manage into your relationship stresses and work pressures.            ',
            ],

            [
              'Patanjali’s Yoga Sutras',

              'Insight into the all-encompassing yoga sutra written by Patanjali 2500 years ago will give you the key to life itself. It is a text that will give you insight into your own subconscious and help you deal with your samskaras and vaasanas.            ',
            ],

            [
              'Quintessence of Bhagvada Gita',

              'Access the knowledge of karma yoga, gyaan yoga, bhakti yoga and utilize it to traverse through your present-day challenges situations.',
            ],

            [
              'Public speaking / Practice Teaching',

              'Learn how to transfer your knowledge and expertise to your students. Become a master of addressing a crowd with finesse and ease.',
            ],

            [
              'Experiential Learning',

              'Learn hands-on skills at the health camps and special activities.',
            ],

            [
              'Attitude Training',

              'Develop your personality through the right yogic attitude.            ',
            ],

            [
              'Yogic Diet',

              'Learn all about the nuances of yogi ahar, and understand the food categories – Sattvik, Rajasik, and Tamasik. With this module, you will learn to manage your diet habits for your overall well-being and weight management.             ',
            ],

            [
              'Meditation',

              'Learn to center yourself through sessions that will take you deep within yourself. Master the techniques to quite your mind and balance your energies. This acquired calmness and peace will stay with you throughout the day.            ',
            ],
          ],
        },
      },

      {
        type: 'url',

        content: {
          text: 'Access the advanced yoga certificate course detailed syllabus here:',

          url: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/courses_pdf/900hr.pdf',
        },
      },

    ],
    teaching: [
      {
        type: 'paragraph',
        content: {
          title: 'Note: For the month of May, it will be a holiday and no classes will be held.',
          text: [],
        },
      },
      {
        type: 'u-list',

        content: {

          title: 'Course Attendance Criteria',

          points: [
            {
              listItem: 'Attending a minimum 210 days of classes',
              subItems: [],
            },

            {
              listItem:
                'Volunteering at Camps, Workshops, Satsangs, Reach-Out Initiatives',

              subItems: [],
            },

            {
              listItem:
                'Completing assignments as given (submissions & presentations)',

              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [['Breakdown of hours:']],
        },
      },

      {
        type: 'table',

        content: {
          title: '',

          tableHeading: [],

          tableData: [
            [
              'Instructional program, including orientation for various camps',

              '550 hours',
            ],

            [
              'Internship- health & wellness camps, 7 days camp & other initiatives',

              '135 hours',
            ],

            [
              'Self-study, assignments, submissions and examinations.',

              '225 hours',
            ],

            ['TOTAL', '910 hours'],
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },

      {
        type: 'table',

        content: {
          title: '',

          tableHeading: ['Details', 'Marks', 'Minimum Score Req.', ''],

          tableData: [
            [
              'Theory Component',

              '50',

              'Objective section-40 Marks,Subjective section-60 Marks',

              '50%',
            ],

            [
              'Practical Component',

              '50',

              'Technique Demonstration-10 Marks,Teaching Yogic Practice(viva included)-30 Marks',

              '50%',
            ],
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: ['On successfully passing the exam, students will be:'],
        },
      },

      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem:
                'Awarded a certificate – Advanced Teacher Training Certificate (900 hours) by The Yoga Institute',

              subItems: [],
            },

            {
              listItem: 'Qualified by Yoga Certification Board (AYUSH Level-3)',

              subItems: [],
            },

            {
              listItem: 'Eligible to apply with Yoga Alliance USA (RYT 500)',

              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            'Post qualification, a number of opportunities are open to you, like:',
          ],
        },
      },

      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem: 'Opening your own yoga training center ',

              subItems: [],
            },

            {
              listItem: 'Working with corporates for their wellness programs',

              subItems: [],
            },

            {
              listItem: 'Working with schools and teaching children',

              subItems: [],
            },

            {
              listItem: 'Personal yoga coaching',

              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: ['This advanced yoga certificate course makes you eligible to teach:'],
        },
      },

      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem: 'Normal healthy individuals and groups,',

              subItems: [],
            },

            {
              listItem: 'For people with minor ailments or disorders and',

              subItems: [],
            },

            {
              listItem:
                'For people, under the guidance of qualified medical practitioners, undergoing treatment for any physiological, psychological and psychosomatic diseases/conditions.',

              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Exam Details',
          text: [],
        }
      },
      {
        type: 'u-list',
        content: {
          title:
            ' ',
          points: [
            {
              listItem: 'Students will be eligible to appear for the Final certification exams only if they have completed all the required course submissions, class work and the required attendance',
              subItems: [],
            },
            {
              listItem:
                'Students will have to appear for the final exams within 60 days from the course end date.',
              subItems: [],
            },
            {
              listItem: 'Students will be permitted a maximum of 3 attempts, to clear the final exams, failing which they will have to repeat the course.',
              subItems: ['In the 2nd attempt, they will be exempted from either theory or practical’s, if they have cleared it in their 1st attempt.',
                'They will have to appear for both in case of a 3rd attempt.'
              ],
            },
            {
              listItem: 'Students can register for either online or on-campus exams.',
              subItems: ['Once finalized, students will not be permitted to switch from online to on-campus and vice versa.'],
            },
            {
              listItem: 'Examiners will evaluate students only as per the final approved list.',
              subItems: [],
            },


          ],

        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Exam Schedule ',
          points: [
            {
              listItem: 'On-campus Final exams (both theory and practical’s) will be conducted on the last working day of each month, or as announced (in case of exigencies), between 10 am (IST) and 2 pm IST.',
              subItems: [],
            },
            {
              listItem: 'Online Final exams will be conducted on the 1st Sat and the 3rd Sat of each month or as announced (in case of exigencies), subject to a minimum quorum of 8 students.',
              subItems: [
                'Theory exams will be conducted online at 5pm (IST)',
                'Practical exams will be conducted online at 3.30 pm (IST) / 6.30 pm (IST), subject to quorum.'
              ],
            },
            {
              listItem: 'Students, after submission, can log out /leave only with the explicit permission of the examiner.',
              subItems: [
                'For practical’s - students will have to wait till the exams of all students are completed.',
                'The examiner may call out students at random and not necessarily in alphabetic order'
              ],
            },
            {
              listItem: 'A Mock test will be held 7 -14 days prior to the course end date, to familiarise students with the exam process.',
              subItems: [],
            },
          ],
        }
      }
    ],
    offerings: [
      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem:
                'You will have an opportunity to directly interact with our Guru Dr. Hansaji Yogendra. She is always available to attend to your queries and give counsel.',

              subItems: [],
            },

            {
              listItem:
                'Our expert trainers, over 40 in number, have mastered the yogic way of life and are proficient is passing on their learnings in a simple and practical way. Armed with decades of experience, they offer tremendous value and insight to new learners.',

              subItems: [],
            },

            {
              listItem:
                'Apart from the main yoga curriculum, you will learn immensely from the class experience itself. You will meet people with diverse backgrounds and experiences that you can gain insights from. There will be sadhakas of all age groups – right from teenagers to senior citizens. Every person has had a unique experience in life and it will be enriching to know all these stories.',

              subItems: [],
            },

            {
              listItem:
                'This one-of-a-kind experience will groom you to calmly and mindfully handle people and situations',

              subItems: [],
            },
          ],
        },
      },

      {
        type: 'quote',

        content: {
          title: '',

          text: [
            'This is an exclusive experience, the memories of which will stay with you all your life!',
          ],
        },
      },

      {
        type: 'u-list',

        content: {
          title: 'Our Amenities / Our Facilities',

          points: [
            {
              listItem:
                'Yoga Library: Explore one of largest collections of spiritual and philosophical books to expand your knowledge',

              subItems: [],
            },

            {
              listItem:
                'Internet Room: Access the internet in a comfortable setting when you need',

              subItems: [],
            },

            {
              listItem:
                'Meditation Pods: Meditate on the five fundamental natural elements, the Panchamahabhutas,',
              subItems: [],
            },

            {
              listItem:
                'Acupressure Path: Give your feet a natural massage and activate your vital energies',

              subItems: [],
            },

            {
              listItem:
                'Gazebo: Enjoy the soothing sounds of flowing water and take in the peace and at this relaxation area',
              subItems: [],
            },

            {
              listItem:
                'Dining Area: Enjoy wholesome Sattvik meals, specially prepared for your nourishment',

              subItems: [],
            },

            {
              listItem:
                'Yoga Book Store: Shop in a wide selection of TYI publications. Find yourself the best yogic attire and mats.',
              subItems: [],
            },
          ],
        },
      },
    ],
  },
  3: {
    // templateId: 'COURSE900_1Y_TTC',
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    key: 'two-year-ttc',
    // templateId: 'COURSE900_2Y_TTC',
    templateId: {
      templateOnline: 'COURSE900_2Y_ON_TTC',
      templateOffline: {
        templateResidential: 'COURSE900_2Y_RES_TTC',
        templateNonResidential: 'COURSE900_2Y_NONRES_TTC',
      },
    },
    batch: 1,

    metaDescription:
      'You will learn the tools to master your physical, mental, and spiritual well-being. You will see a marked improvement in your life, relationships, and work.',
    title: '900 Hours YTTC - 2 Years Advanced Yoga Teacher Training Certificate Course Online & On Campus - English',

    image: `${baseDomain}${courseAssets.courseAsset40}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset103}`,

    mostPopular: false,
    rating: 5,
    colorCode: '#C9705F',
    timing:
      'Saturday & Sunday: Saturday: 4:30 pm – 7.30 pm (IST) and Sunday: 9:30 am – 1:30 pm (IST) (Both time slots are mandatory)',
    fees: {
      onlineFee: 60000,
      offlineFee: { residentialFee: 60000, nonResidentialFee: 60000 },
    },
    category: 'ttc',
    dates: [
      // '3rd Aug 2024',
      '7th Dec 2024',
      '5th Apr 2025',
      '2nd Aug 2025',
      '6th Dec 2025'
    ],
    online: true,
    onCampus: true,
    nonResidential: true,
    residential: true,
    certficate: false,

    tenure: '2 year',
    courseType: 'Advance TTC (900 Hour Courses)',
    language: 'English',
    preRequisite: '7 Days Health Camp, 21 Days Better Living Course, 200 Hours Teacher Training Course',
    courseCategory: 'Educational',
    courseSubType: '900 Hour Yoga Teacher Training',
    newName: '900 Hours - Advanced Yoga Teacher Training Course (YTTC)',
    days: [
      'Saturday & Sunday'
    ],
    time: [
      'Saturday: 4:30 pm – 7.30 pm (IST)',
      'Sunday: 9:30 am – 1:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "2 years",
      timings: [
        {
          day: "Saturday & Sunday",
          time: "Saturday: 4:30 pm – 7.30 pm (IST)"
        },
        {
          day: "Saturday & Sunday",
          time: "Sunday: 9:30 am – 1:30 pm (IST)"
        }
      ],
      message: "(Both time slots are mandatory)"
    },

    courseCertificate: 'TTC/Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            'With over 1,00,000/ 50,000 successfully certified students since 1918, the 2 Year Advanced Yoga Teacher Training Certificate Course (English) at The Yoga Institute is an in-depth study of classical Ashtanga yoga (Eightfold Path Yoga). It teaches core yoga philosophy with a comprehensive approach to the science and technology of yoga. Strategically designed by the founder Shri Yogendraji, Smt. Sita Devi Yogendra, Dr. Jayadeva Yogendra, and Dr. Hansaji J Yogendra, The Yoga Institute course serves as a valuable ‘life school’ to those seeking balance and fulfillment in life.',

            'This course is ideal for those who have daily commitments at home, like homemakers. With the suitable schedule of this course, they can have access to advanced yoga training at their convenience and can become certified yoga trainers within one year.',
          ],
        },
      },

      {
        type: 'u-list',

        content: {
          title: 'Benefits of 2-Year Yoga Teacher Training Certificate Course',

          points: [
            {
              listItem:
                'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your work, your relationships and life.',
              subItems: [],
            },
            {
              listItem:
                'A new career avenue opens up for you where you can pass on the knowledge to others and facilitate their wellness journeys. The art of Yoga is something you can teach anywhere, no matter where you are.',
              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Course:',

          text: ['900 hrs TTC 2 Years TTC Online & On Campus - English'],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Duration',

          text: [
            '2 Years | Course commences on 1st Apr, Aug, Dec each year',
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Timings',

          text: [
            'Saturday & Sunday: Saturday: 4:30 pm – 7.30 pm (IST) and Sunday: 9:30 am – 1:30 pm (IST) (Both the time slots are mandatory)',
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Fees:',

          text: [
            '2024: INR. 60,000/- (Inclusive of course fee and study material)'
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Prerequisites:',

          text: [
            // '200 HRS TTC / 7 Day Health Camp / 21 Days Better Living Course from the Yoga Institute',
            'To enroll in the 2-year Teacher Training Course, completion of the 7-days Health Camp, 21-days Better Living Course, or 200-hour TTC is required.',
            'You can apply for the 2-year TTC within three months of finishing any of these prerequisite courses'
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            // 'Students have a choice to register for an online or offline (on-campus) course. Admissions are open for both options.',
          ],
        },
      },

      {
        type: 'table',

        content: {
          title: '',

          tableHeading: ['Mode of Learning', 'Online', 'On Campus'],

          tableData: [
            [
              'Details',

              'All classes will be online except the last 15 days which are to be done mandatorily on-campus at the end of the course',

              'All classes to be attended on campus',
            ],
          ],
        },
      },

      // {
      //   type: 'paragraph',

      //   content: {
      //     title: '',

      //     text: [
      //       'Note: The course is currently being conducted online ONLY. Once the pandemic restrictions ease, we will open the course for on-campus attendance as well. Students can then choose to either continue their course online OR switch to on-campus mode.',
      //     ],
      //   },
      // },
    ],

    curriculum: [
      {
        type: 'table',

        content: {
          title: 'Curriculum at a Glance',

          tableHeading: [],

          tableData: [
            [
              'In-depth practice of asana, pranayama and kriya',

              'Mastery over Advanced Yoga Asana, pranayama and kriyas transforms and resets your body to its highest efficiency, making it strong and flexible. Your blocked energies will begin to flow again, giving you vitality. Kriyas help release that which harms our prana.',
            ],
            [
              'Anatomy and Physiology',

              'Gaining in-depth knowledge of your body systems – muscular, endocrine, respiratory etc. will empower you to manage modern-day diseases with yoga.Ullamco aute reprehenderit laboris dolore labore.',
            ],
            [
              'Samkhya Philosophy',

              'Learn about the tattvas that make you and the cosmos. Understand the elements and mechanisms that constitute your chitta, the mind.',
            ],
            [
              'Stress Management',

              'Learn the techniques to channel your mind and emotions so you are always determining your own destiny. This module will help you to manage into your relationship stresses and work pressures.            ',
            ],
            [
              'Patanjali’s Yoga Sutras',

              'Insight into the all-encompassing yoga sutra written by Patanjali 2500 years ago will give you the key to life itself. It is a text that will give you insight into your own subconscious and help you deal with your samskaras and vaasanas.            ',
            ],
            [
              'Quintessence of Bhagvada Gita',

              'Access the knowledge of karma yoga, gyaan yoga, bhakti yoga and utilize it to traverse through your present-day challenges situations.',
            ],
            [
              'Public speaking / Practice Teaching',

              'Learn how to transfer your knowledge and expertise to your students. Become a master of addressing a crowd with finesse and ease.',
            ],
            [
              'Experiential Learning',

              'Learn hands-on skills at the health camps and special activities.',
            ],
            [
              'Attitude Training',

              'Develop your personality through the right yogic attitude.            ',
            ],
            [
              'Yogic Diet',

              'Learn all about the nuances of yogi ahar, and understand the food categories – Sattvik, Rajasik, and Tamasik. With this module, you will learn to manage your diet habits for your overall well-being and weight management.             ',
            ],
            [
              'Meditation',

              'Learn to center yourself through sessions that will take you deep within yourself. Master the techniques to quite your mind and balance your energies. This acquired calmness and peace will stay with you throughout the day.            ',
            ],
          ],
        },
      },

      {
        type: 'url',

        content: {
          text: 'Access the Advanced Yoga Teacher Training Certificate Course detailed syllabus here:',

          url: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/courses_pdf/900hr.pdf',
        },
      },
    ],

    teaching: [
      {
        type: 'u-list',

        content: {
          title: 'Course Attendance Criteria',

          points: [
            {
              listItem: 'Attending a minimum 210 days of classes',
              subItems: [],
            },

            {
              listItem:
                'Volunteering at Camps, Workshops, Satsangs, Reach-Out Initiatives',

              subItems: [],
            },

            {
              listItem:
                'Completing assignments as given (submissions & presentations)',

              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [['Breakdown of hours:']],
        },
      },

      {
        type: 'table',

        content: {
          title: '',

          tableHeading: [],

          tableData: [
            [
              'Instructional program, including orientation for various camps',

              '550 hours',
            ],

            [
              'Internship- health & wellness camps, 7 days camp & other initiatives',

              '135 hours',
            ],

            [
              'Self-study, assignments, submissions and examinations.',

              '225 hours',
            ],

            ['TOTAL', '910 hours'],
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },

      {
        type: 'table',

        content: {
          title: '',

          tableHeading: ['Details', 'Marks', 'Minimum Score Req.', ''],

          tableData: [
            [
              'Theory Component',

              '60',

              'Objective section-40 Marks,Subjective section-20 Marks',

              '50%',
            ],

            [
              'Practical Component',

              '40',

              'Technique Demonstration-10 Marks,Teaching Yogic Practice(viva included)-30 Marks',

              '50%',
            ],
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: ['On successfully passing the exam, students will be:'],
        },
      },

      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem:
                'Awarded a certificate – Advanced Teacher Training Certificate (900 hours) by The Yoga Institute',

              subItems: [],
            },

            {
              listItem: 'Qualified by Yoga Certification Board (AYUSH Level-3)',

              subItems: [],
            },

            {
              listItem: 'Eligible to apply with Yoga Alliance USA (RYT 500)',

              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            'Post qualification, a number of opportunities are open to you, like:',
          ],
        },
      },

      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem: 'Opening your own yoga training center ',

              subItems: [],
            },

            {
              listItem: 'Working with corporates for their wellness programs',

              subItems: [],
            },

            {
              listItem: 'Working with schools and teaching children',

              subItems: [],
            },

            {
              listItem: 'Personal yoga coaching',

              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: ['This Yoga Teacher Training Certificate Course course makes you eligible to teach::'],
        },
      },

      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem: 'Normal healthy individuals and groups,',

              subItems: [],
            },

            {
              listItem: 'For people with minor ailments or disorders and',

              subItems: [],
            },

            {
              listItem:
                'For people, under the guidance of qualified medical practitioners, undergoing treatment for any physiological, psychological and psychosomatic diseases/conditions.',

              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Exam Details',
          text: [],
        }
      },
      {
        type: 'u-list',
        content: {
          title:
            ' ',
          points: [
            {
              listItem: 'Students will be eligible to appear for the Final certification exams only if they have completed all the required course submissions, class work and the required attendance',
              subItems: [],
            },
            {
              listItem:
                'Students will have to appear for the final exams within 60 days from the course end date.',
              subItems: [],
            },
            {
              listItem: 'Students will be permitted a maximum of 3 attempts, to clear the final exams, failing which they will have to repeat the course.',
              subItems: ['In the 2nd attempt, they will be exempted from either theory or practical’s, if they have cleared it in their 1st attempt.',
                'They will have to appear for both in case of a 3rd attempt.'
              ],
            },
            {
              listItem: 'Students can register for either online or on-campus exams.',
              subItems: ['Once finalized, students will not be permitted to switch from online to on-campus and vice versa.'],
            },
            {
              listItem: 'Examiners will evaluate students only as per the final approved list.',
              subItems: [],
            },


          ],

        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Exam Schedule ',
          points: [
            {
              listItem: 'On-campus Final exams (both theory and practical’s) will be conducted on the last working day of each month, or as announced (in case of exigencies), between 10 am (IST) and 2 pm IST.',
              subItems: [

              ],
            },
            {
              listItem: 'Online Final exams will be conducted on the 1st Sat and the 3rd Sat of each month or as announced (in case of exigencies), subject to a minimum quorum of 8 students.',
              subItems: [
                'Theory exams will be conducted online at 5pm (IST)',
                'Practical exams will be conducted online at 3.30 pm (IST) / 6.30 pm (IST), subject to quorum.'
              ],
            },
            {
              listItem: 'Students, after submission, can log out /leave only with the explicit permission of the examiner.',
              subItems: [
                'For practical’s - students will have to wait till the exams of all students are completed.',
                'The examiner may call out students at random and not necessarily in alphabetic order'
              ],
            },
            {
              listItem: 'A Mock test will be held 7 -14 days prior to the course end date, to familiarise students with the exam process.',
              subItems: [],
            },
          ],
        }
      }
    ],

    offerings: [
      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem:
                'You will have an opportunity to directly interact with our Guru Dr. Hansaji Yogendra. She is always available to attend to your queries and give counsel.',
              subItems: [],
            },
            {
              listItem:
                'Our expert trainers, over 40 in number, have mastered the yogic way of life and are proficient is passing on their learnings in a simple and practical way. Armed with decades of experience, they offer tremendous value and insight to new learners.',
              subItems: [],
            },
            {
              listItem:
                'Apart from the main yoga curriculum, you will learn immensely from the class experience itself. You will meet people with diverse backgrounds and experiences that you can gain insights from. There will be sadhakas of all age groups – right from teenagers to senior citizens. Every person has had a unique experience in life and it will be enriching to know all these stories.',
              subItems: [],
            },
            {
              listItem:
                'This one-of-a-kind experience will groom you to calmly and mindfully handle people and situations',
              subItems: [],
            },
          ],
        },
      },

      {
        type: 'quote',

        content: {
          title: '',

          text: [
            'This is an exclusive experience, the memories of which will stay with you all your life!',
          ],
        },
      },

      {
        type: 'u-list',

        content: {
          title: 'Our Amenities / Our Facilities',

          points: [
            {
              listItem:
                'Yoga Library: Explore one of largest collections of spiritual and philosophical books to expand your knowledge',

              subItems: [],
            },

            {
              listItem:
                'Internet Room: Access the internet in a comfortable setting when you need',

              subItems: [],
            },

            {
              listItem:
                'Meditation Pods: Meditate on the five fundamental natural elements, the Panchamahabhutas,',

              subItems: [],
            },

            {
              listItem:
                'Acupressure Path: Give your feet a natural massage and activate your vital energies',

              subItems: [],
            },

            {
              listItem:
                'Gazebo: Enjoy the soothing sounds of flowing water and take in the peace and at this relaxation area',

              subItems: [],
            },

            {
              listItem:
                'Dining Area: Enjoy wholesome Sattvik meals, specially prepared for your nourishment',

              subItems: [],
            },

            {
              listItem:
                'Yoga Book Store: Shop in a wide selection of TYI publications. Find yourself the best yogic attire and mats.',

              subItems: [],
            },
          ],
        },
      },
    ],
  },
  4: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    key: '900-hours-advanced-teacher-training-course',
    // templateId: 'COURSE900_4M_TTC',
    templateId: {
      templateOnline: 'COURSE900_4M_ON_TTC',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    batch: 1,

    metaDescription:
      'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your life, relationships and work.',
    title: '900 Hours Yoga TTC - 4 Months Online Yoga Teacher Training Course - English',
    image: `${baseDomain}${courseAssets.courseAsset40}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset103}`,
    mostPopular: false,
    rating: 5,
    colorCode: '#C9705F',
    timing:
      'Monday to Saturday: Morning: 7:00 am to 9:00 am (IST) and Evening: 4:30 pm to 8:00 pm (IST) (Both the time slots are mandatory)',
    fees: {
      onlineFee: 80000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'ttc',
    dates: [
      // '17th Jun - 5th Oct 2024', 
      // '14th Oct 2024 - 1st Feb 2025',
      '10th Feb - 31st May 2025',
      '9th Jun - 27th Sep 2025',
      '6th Oct - 24th Jan 2026'
    ]
    ,
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '4 month',
    courseType: 'Advance TTC (900 Hour Courses)',
    language: 'English',
    preRequisite: '7 Days Health Camp, 21 Days Better Living Course, 200 Hours Teacher Training Course',
    courseCategory: 'Educational',
    courseSubType: '900 Hour Yoga Teacher Training',
    newName: '900 Hours - Advanced Yoga Teacher Training Course (YTTC)',
    days: [
      'Monday to Saturday'
    ],
    time: [
      'Morning: 7:00 am to 9:00 am (IST)',
      'Evening: 4:30 pm to 8:00 pm (IST)'
    ],
    enrollInfo: {
      duration: "4 months",
      timings: [
        {
          day: "Monday to Saturday",
          time: "Morning: 7:00 am to 9:00 am (IST)"
        },
        {
          day: "Monday to Saturday",
          time: "Evening: 4:30 pm to 8:00 pm (IST)"
        }
      ],
      message: "(Both the time slots are mandatory)"
    },

    courseCertificate: 'TTC/Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'With over 1,00,000/ 50,000 successfully certified students since 1918, the 4 Months Online Yoga Teacher Training Course - English at The Yoga Institute is an in-depth study of classical Ashtanga yoga (Eightfold Path Yoga). It teaches core yoga philosophy with a comprehensive approach to the science and technology of yoga. Strategically designed by the founder Shri Yogendraji, Smt. Sita Devi Yogendra, Dr. Jayadeva Yogendra, and Dr. Hansaji J Yogendra, the teacher training programs serves as a valuable ‘life school’ to those seeking balance and fulfillment in life.',
            'This is an intensive advanced teacher training course, ideal for those who want an immersive yoga learning experience in a focused learning format. The curriculum is the same as the other ATTCs. Within 4 months, you can become an advanced yoga trainer. Kindly note that residential option is available for this course. Additional charges would be applicable for the same.',
            // 'Residential Course: this is a great option for those who want to be completely immersed in the yogi way of life. You would have access to a total yogic lifestyle at the Yoga Institute during the duration of your course. Wholesome Sattvik meals, a healthy daily routine in the peaceful environment of the Yoga Institute will reset your energies.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Benefits of 4 Months Online Yoga Teacher Training Course',
          points: [
            {
              listItem:
                'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your work, your relationships and life.',
              subItems: [],
            },
            {
              listItem:
                'A new career avenue opens up for you where you can pass on the knowledge to others and facilitate their wellness journeys. The art of Yoga is something you can teach anywhere, no matter where you are. ',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Course:',
          text: ['900 hrs Advanced Yoga Teacher Training Course - 4 Months YTTC Online - English'],
        },
      },

      // {
      //   type: 'paragraph',

      //   content: {
      //     title: 'Duration',

      //     text: ['20th Jun 2022to 8th Oct 2022'],
      //   },
      // },

      {
        type: 'paragraph',

        content: {
          title: 'Timings',
          text: [
            'Monday to Saturday:  Morning: 7:00 am to 9:00 am (IST) and Evening:  4:30 pm to 8:00 pm (IST) (Both the time slots are mandatory)',
          ],
        },
      },
      {
        type: 'paragraph',

        content: {
          title: 'Fees:',

          text: ['INR. 80,000/-(Inclusive of course fee and study material)'],
        },
      },

      // {
      //   type: 'paragraph',

      //   content: {
      //     title: 'Prerequisites:',

      //     text: [
      //       ' 200 HRS TTC / 7 Day Health Camp / 21 Days Better Living Course    ',
      //     ],
      //   },
      // },

      // {
      //   type: 'paragraph',

      //   content: {
      //     title: '',

      //     text: [
      //       'Students have a choice to register for an online or offline (on-campus) course. Admissions are open for both options.',
      //     ],
      //   },
      // },

      // {
      //   type: 'table',

      //   content: {
      //     title: '',

      //     tableHeading: ['Mode of Learning', 'Online', 'On Campus'],

      //     tableData: [
      //       [
      //         'Details',

      //         'All classes will be online except the last 15 days which are to be done mandatorily on-campus at the end of the course',

      //         'All classes to be attended on campus',
      //       ],
      //     ],
      //   },
      // },

      // {
      //   type: 'paragraph',

      //   content: {
      //     title: '',

      //     text: [
      //       'Note: The course is currently being conducted online ONLY. Once the pandemic restrictions ease, we will open the course for on-campus attendance as well. Students can then choose to either continue their course online OR switch to on-campus mode.',
      //     ],
      //   },
      // },
    ],

    curriculum: [
      {
        type: 'table',

        content: {
          title: '900 Hours Online Yoga Teacher Training Course Curriculum at a Glance',

          tableHeading: [],

          tableData: [
            [
              'In-depth practice of asana, pranayama and kriya',

              'Mastery over asana, pranayama and kriyas transforms and resets your body to its highest efficiency, making it strong and flexible. Your blocked energies will begin to flow again, giving you vitality. Kriyas help release that which harms our prana.',
            ],
            [
              'Anatomy and Physiology',

              'Gaining in-depth knowledge of your body systems – muscular, endocrine, respiratory etc. will empower you to manage modern-day diseases with yoga.Ullamco aute reprehenderit laboris dolore labore.',
            ],
            [
              'Samkhya Philosophy',

              'Learn about the tattvas that make you and the cosmos. Understand the elements and mechanisms that constitute your chitta, the mind.',
            ],
            [
              'Stress Management',

              'Learn the techniques to channel your mind and emotions so you are always determining your own destiny. This module will help you to manage into your relationship stresses and work pressures.            ',
            ],
            [
              'Patanjali’s Yoga Sutras',

              'Insight into the all-encompassing yoga sutra written by Patanjali 2500 years ago will give you the key to life itself. It is a text that will give you insight into your own subconscious and help you deal with your samskaras and vaasanas.            ',
            ],
            [
              'Quintessence of Bhagvada Gita',

              'Access the knowledge of karma yoga, gyaan yoga, bhakti yoga and utilize it to traverse through your present-day challenges situations.',
            ],
            [
              'Public speaking / Practice Teaching',

              'Learn how to transfer your knowledge and expertise to your students. Become a master of addressing a crowd with finesse and ease.',
            ],
            [
              'Experiential Learning',

              'Learn hands-on skills at the health camps and special activities.',
            ],
            [
              'Attitude Training',

              'Develop your personality through the right yogic attitude.            ',
            ],
            [
              'Yogic Diet',

              'Learn all about the nuances of yogi ahar, and understand the food categories – Sattvik, Rajasik, and Tamasik. With this module, you will learn to manage your diet habits for your overall well-being and weight management.             ',
            ],
            [
              'Meditation',

              'Learn to center yourself through sessions that will take you deep within yourself. Master the techniques to quite your mind and balance your energies. This acquired calmness and peace will stay with you throughout the day.            ',
            ],
          ],
        },
      },

      {
        type: 'url',
        content: {
          text: 'Access the 900 Hour Online Yoga Teacher Training Course detailed syllabus here:',
          url: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/courses_pdf/900hr.pdf',
        },
      },
    ],

    teaching: [
      {
        type: 'u-list',

        content: {
          title: 'Course Attendance Criteria',

          points: [
            {
              listItem:
                'Completing assignments as given (submissions & presentations)',

              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [['Breakdown of hours:']],
        },
      },

      {
        type: 'table',

        content: {
          title: '',

          tableHeading: [],

          tableData: [
            [
              'Instructional program, including orientation for various camps',

              '550 hours',
            ],
            [
              'Health & wellness camps, 7 days yoga camp & other initiatives',

              '135 hours',
            ],
            [
              'Self-study, assignments, submissions and examinations.',

              '225 hours',
            ],
            ['TOTAL', '910 hours'],
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },

      {
        type: 'table',

        content: {
          title: '',

          tableHeading: ['Details', 'Marks', 'Minimum Score Req.', ''],

          tableData: [
            [
              'Theory Component',

              '60',

              'Objective section-40 Marks,Subjective section-20 Marks',

              '50%',
            ],

            [
              'Practical Component',

              '40',

              'Technique Demonstration-10 Marks,Teaching Yogic Practice(viva included)-30 Marks',

              '50%',
            ],
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: ['On successfully passing the exam, students will be:'],
        },
      },

      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem:
                'Awarded a certificate – Advanced Yoga Teacher Training Certificate (900 hours) by The Yoga Institute',
              subItems: [],
            },
            {
              listItem: 'Qualified by Yoga Certification Board (AYUSH Level-3)',
              subItems: [],
            },
            {
              listItem: 'Eligible to apply with Yoga Alliance USA (RYT 500)',

              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            'Post qualification, a number of opportunities are open to you, like:',
          ],
        },
      },
      {
        type: 'u-list',

        content: {
          title: '',
          points: [
            {
              listItem: 'Opening your own yoga training center ',

              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',

              subItems: [],
            },
            {
              listItem: 'Working with schools and teaching children',

              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',

              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: ['This Online Yoga Certification course makes you eligible to teach:'],
        },
      },

      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem: 'Normal healthy individuals and groups,',

              subItems: [],
            },

            {
              listItem: 'For people with minor ailments or disorders and',

              subItems: [],
            },

            {
              listItem:
                'For people, under the guidance of qualified medical practitioners, undergoing treatment for any physiological, psychological and psychosomatic diseases/conditions.',

              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Exam Details',
          text: [],
        }
      },
      {
        type: 'u-list',
        content: {
          title:
            ' ',
          points: [
            {
              listItem: 'Students will be eligible to appear for the Final certification exams only if they have completed all the required course submissions, class work and the required attendance',
              subItems: [],
            },
            {
              listItem:
                'Students will have to appear for the final exams within 60 days from the course end date.',
              subItems: [],
            },
            {
              listItem: 'Students will be permitted a maximum of 3 attempts, to clear the final exams, failing which they will have to repeat the course.',
              subItems: ['In the 2nd attempt, they will be exempted from either theory or practical’s, if they have cleared it in their 1st attempt.',
                'They will have to appear for both in case of a 3rd attempt.'
              ],
            },
            {
              listItem: 'Students can register for either online or on-campus exams.',
              subItems: ['Once finalized, students will not be permitted to switch from online to on-campus and vice versa.'],
            },
            {
              listItem: 'Examiners will evaluate students only as per the final approved list.',
              subItems: [],
            },
          ],

        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Exam Schedule ',
          points: [
            {
              listItem: 'On-campus Final exams (both theory and practical’s) will be conducted on the last working day of each month, or as announced (in case of exigencies), between 10 am (IST) and 2 pm IST.',
              subItems: [

              ],
            },
            {
              listItem: 'Online Final exams will be conducted on the 1st Sat and the 3rd Sat of each month or as announced (in case of exigencies), subject to a minimum quorum of 8 students.',
              subItems: [
                'Theory exams will be conducted online at 5pm (IST)',
                'Practical exams will be conducted online at 3.30 pm (IST) / 6.30 pm (IST), subject to quorum.'
              ],
            },
            {
              listItem: 'Students, after submission, can log out /leave only with the explicit permission of the examiner.',
              subItems: [
                'For practical’s - students will have to wait till the exams of all students are completed.',
                'The examiner may call out students at random and not necessarily in alphabetic order'
              ],
            },
            {
              listItem: 'A Mock test will be held 7 -14 days prior to the course end date, to familiarise students with the exam process.',
              subItems: [],
            },
          ],
        }
      }
    ],

    offerings: [
      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem:
                'You will have an opportunity to directly interact with our Guru Dr. Hansaji Yogendra. She is always available to attend to your queries and give counsel.',
              subItems: [],
            },
            {
              listItem:
                'Our expert trainers, over 40 in number, have mastered the yogic way of life and are proficient is passing on their learnings in a simple and practical way. Armed with decades of experience, they offer tremendous value and insight to new learners.',
              subItems: [],
            },
            {
              listItem:
                'Apart from the main yoga curriculum, you will learn immensely from the class experience itself. You will meet people with diverse backgrounds and experiences that you can gain insights from. There will be sadhakas of all age groups – right from teenagers to senior citizens. Every person has had a unique experience in life and it will be enriching to know all these stories.',
              subItems: [],
            },
            {
              listItem:
                'This one-of-a-kind experience will groom you to calmly and mindfully handle people and situations',
              subItems: [],
            },
          ],
        },
      },

      {
        type: 'quote',
        content: {
          title: '',

          text: [
            'This is an exclusive experience, the memories of which will stay with you all your life!',
          ],
        },
      },

      {
        type: 'u-list',

        content: {
          title: 'Our Amenities / Our Facilities',

          points: [
            {
              listItem:
                'Yoga Library: Explore one of largest collections of spiritual and philosophical books to expand your knowledge',
              subItems: [],
            },
            {
              listItem:
                'Internet Room: Access the internet in a comfortable setting when you need',
              subItems: [],
            },
            {
              listItem:
                'Meditation Pods: Meditate on the five fundamental natural elements, the Panchamahabhutas,',
              subItems: [],
            },
            {
              listItem:
                'Acupressure Path: Give your feet a natural massage and activate your vital energies',
              subItems: [],
            },
            {
              listItem:
                'Gazebo: Enjoy the soothing sounds of flowing water and take in the peace and at this relaxation area',
              subItems: [],
            },
            {
              listItem:
                'Dining Area: Enjoy wholesome Sattvik meals, specially prepared for your nourishment',
              subItems: [],
            },
            {
              listItem:
                'Yoga Book Store: Shop in a wide selection of TYI publications. Find yourself the best yogic attire and mats.',
              subItems: [],
            },
          ],
        },
      },
    ],
  },
  5: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    key: 'seven-month-ttc',
    // templateId: 'COURSE900_7M_TTC',
    templateId: {
      templateOnline: 'COURSE900_7M_ON_TTC',
      templateOffline: {
        templateResidential: 'COURSE900_7M_RES_TTC',
        templateNonResidential: 'COURSE900_7M_NONRES_TTC',
      },
    },
    batch: 1,

    metaDescription:
      'You will learn the tools to master your physical, mental, and spiritual well-being. You will see a marked improvement in your life, relationships, and work.',
    title: '900 Hours Yoga TTC - 7 Months Yoga Teacher Training Course Online & On Campus - English',

    image: `${baseDomain}${courseAssets.courseAsset40}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset103}`,

    mostPopular: true,
    rating: 5,
    colorCode: '#C9705F',
    timing: 'Monday to Saturday: 5:00 p.m. - 8:00 p.m. (IST)',
    fees: {
      onlineFee: 60000,
      offlineFee: { residentialFee: 60000, nonResidentialFee: 60000 },
    },
    category: 'ttc',
    dates: [
      // '1st Jan 2024',
      // '1st Feb 2024',
      // '1st Mar 2024',
      // '1st Apr 2024',
      // '1st May 2024',
      // '1st Jun 2024',
      // '1st Jul 2024',
      // '1st Aug 2024',
      // '1st Sep 2024',
      // '1st Oct 2024',
      // '1st Nov 2024',
      '2nd Dec 2024',
      '1st Jan 2025',
      '1st Feb 2025',
      '1st Mar 2025',
      '1st Arp 2025',
      '1st May 2025',
      '2nd Jun 2025',
      '1st Jul 2025',
      '1st Aug 2025',
      '1st Sep 2025',
      '1st Oct 2025',
      '1st Nov 2025',
      '1st Dec 2025'

    ],
    online: true,
    onCampus: true,
    nonResidential: true,
    residential: true,
    certficate: false,

    tenure: '7 month',
    courseType: 'Advance TTC (900 Hour Courses)',
    language: 'English',
    preRequisite: '7 Days Health Camp, 21 Days Better Living Course, 200 Hours Teacher Training Course',
    courseCategory: 'Educational',
    courseSubType: '900 Hour Yoga Teacher Training',
    newName: '900 Hours - Advanced Yoga Teacher Training Course (YTTC)',
    days: [
      'Monday to Saturday'
    ],
    time: [
      '5:00 p.m. - 8:00 p.m. (IST)'
    ],
    enrollInfo: {
      duration: "7 months",
      timings: [
        {
          day: "Monday to Saturday",
          time: "5:00 p.m. - 8:00 p.m. (IST)"
        }
      ]
    },

    courseCertificate: 'TTC/Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            'With over 1,00,000 successfully certified students since 1918, the 7 Month Yoga Teacher Training Course at The Yoga Institute is an in-depth study of classical Ashtanga yoga (Eightfold Path Yoga). It teaches core yoga philosophy with a comprehensive approach to the science and technology of yoga. Strategically designed by the founder Shri Yogendraji, Smt. Sita Devi Yogendra, Dr. Jayadeva Yogendra, and Dr. Hansaji J Yogendra, The Yoga Institute course serves as a valuable ‘life school’ to those seeking balance and fulfillment in life.'
          ],
        },
      },
      {
        type: 'u-list',

        content: {
          title: 'Benefits of 7 Months Yoga Teacher Training Course',
          points: [
            {
              listItem:
                'You will learn the tools to master your physical, mental and spiritual well-being. You will see a marked improvement in your work, your relationships and life.',
              subItems: [],
            },
            {
              listItem:
                'A new career avenue opens up for you where you can pass on the knowledge to others and facilitate their wellness journeys. The art of Yoga is something you can teach anywhere, no matter where you are.',
              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: 'Course:',

          text: ['900 hrs TTC 7 Months YTTC Online & On Campus - English'],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: 'Duration',

          text: ['7 months | Course commences on the 1st of each month'],
        },
      },
      {
        type: 'paragraph',

        content: {
          title: 'Timings',

          text: ['Monday to Saturday: 5:00 p.m. - 8:00 p.m. (IST)'],
        },
      },
      {
        type: 'paragraph',

        content: {
          title: 'Fees:',

          text: [
            ' 2024 : INR. 60,000/- (Inclusive of course fee and study material) ',
          ],
        },
      },
      {
        type: 'paragraph',

        content: {
          title: 'Prerequisites:',

          text: [
            // '200 HRS TTC / 7 Day Health Camp / 21 Days Better Living Course from the Yoga Institute',
            'To enroll in the 7-month Teacher Training Course, completion of the 7-days Health Camp, 21-days Better Living Course, or 200-hour TTC is required.',
            'You can apply for the 7-month TTC within three months of finishing any of these prerequisite courses.'
          ],
        },
      },
      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            // 'Students have a choice to register for an online or offline (on-campus) course. Admissions are open for both options.',
          ],
        },
      },
      {
        type: 'table',

        content: {
          title: '',

          tableHeading: ['Mode of Learning', 'Online', 'On Campus'],

          tableData: [
            [
              'Details',

              'All yoga classes will be online except the 15 days which are to be done mandatorily on campus towards the end of the course.',

              'All classes to be attended on campus',
            ],
          ],
        },
      },
      // {
      //   type: 'paragraph',

      //   content: {
      //     title: '',

      //     text: [
      //       'Note: The course is currently being conducted online ONLY. Once the pandemic restrictions ease, we will open the course for on-campus attendance as well. Students can then choose to either continue their course online OR switch to on-campus mode.',
      //     ],
      //   },
      // },
    ],

    curriculum: [
      {
        type: 'table',

        content: {
          title: 'Seven Month Yoga Teacher Training Course Curriculum at a Glance',

          tableHeading: [],

          tableData: [
            [
              'In-depth practice of asana, pranayama and kriya',

              'Mastery over asana, pranayama and kriyas transforms and resets your body to its highest efficiency, making it strong and flexible. Your blocked energies will begin to flow again, giving you vitality. Kriyas help release that which harms our prana.',
            ],
            [
              'Anatomy and Physiology',

              'Gaining in-depth knowledge of your body systems – muscular, endocrine, respiratory etc. will empower you to manage modern-day diseases with yoga.Ullamco aute reprehenderit laboris dolore labore.',
            ],
            [
              'Samkhya Philosophy',

              'Learn about the tattvas that make you and the cosmos. Understand the elements and mechanisms that constitute your chitta, the mind.',
            ],
            [
              'Stress Management',

              'Learn the techniques to channel your mind and emotions so you are always determining your own destiny. This module will help you to manage into your relationship stresses and work pressures.            ',
            ],
            [
              'Patanjali’s Yoga Sutras',

              'Insight into the all-encompassing yoga sutra written by Patanjali 2500 years ago will give you the key to life itself. It is a text that will give you insight into your own subconscious and help you deal with your samskaras and vaasanas.            ',
            ],
            [
              'Quintessence of Bhagvada Gita',

              'Access the knowledge of karma yoga, gyaan yoga, bhakti yoga and utilize it to traverse through your present-day challenges situations.',
            ],
            [
              'Public speaking / Practice Teaching',

              'Learn how to transfer your knowledge and expertise to your students. Become a master of addressing a crowd with finesse and ease.',
            ],
            [
              'Experiential Learning',

              'Learn hands-on skills at the health camps and special activities.',
            ],
            [
              'Attitude Training',

              'Develop your personality through the right yogic attitude.            ',
            ],
            [
              'Yogic Diet',

              'Learn all about the nuances of yogi ahar, and understand the food categories – Sattvik, Rajasik, and Tamasik. With this module, you will learn to manage your diet habits for your overall well-being and weight management.             ',
            ],
            [
              'Meditation',

              'Learn to center yourself through sessions that will take you deep within yourself. Master the techniques to quite your mind and balance your energies. This acquired calmness and peace will stay with you throughout the day.            ',
            ],
          ],
        },
      },

      {
        type: 'url',

        content: {
          text: 'Access the advanced certified yoga instructor course detailed syllabus here:',

          url: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/courses_pdf/900hr.pdf',
        },
      },
    ],

    teaching: [
      {
        type: 'u-list',

        content: {
          title: 'Course Attendance Criteria',

          points: [
            {
              listItem: 'Attending a minimum 210 days of classes',
              subItems: [],
            },

            {
              listItem:
                'Volunteering at Camps, Workshops, Satsangs, Reach-Out Initiatives',

              subItems: [],
            },

            {
              listItem:
                'Completing assignments as given (submissions & presentations)',

              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [['Breakdown of hours:']],
        },
      },

      {
        type: 'table',

        content: {
          title: '',

          tableHeading: [],

          tableData: [
            [
              'Instructional program, including orientation for various camps',

              '550 hours',
            ],

            [
              'health & wellness camps, 7 days camp & other initiatives',

              '135 hours',
            ],

            [
              'Self-study, assignments, submissions and examinations.',

              '225 hours',
            ],

            ['TOTAL', '910 hours'],
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            'After qualifying the attendance criteria, students can appear for the exam (100 marks). Details below:',
          ],
        },
      },

      {
        type: 'table',

        content: {
          title: '',

          tableHeading: ['Details', 'Marks', 'Minimum Score Req.', ''],

          tableData: [
            [
              'Theory Component',

              '60',

              'Objective section-40 Marks,Subjective section-20 Marks',

              '50%',
            ],

            [
              'Practical Component',

              '40',

              'Technique Demonstration-10 Marks,Teaching Yogic Practice(viva included)-30 Marks',

              '50%',
            ],
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: ['On successfully passing the exam, students will be:'],
        },
      },

      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem:
                'Awarded a certificate – Advanced Yoga Teacher Training Certificate (900 hours) by The Yoga Institute',
              subItems: [],
            },
            {
              listItem: 'Qualified by Yoga Certification Board (AYUSH Level-3)',
              subItems: [],
            },
            {
              listItem: 'Eligible to apply with Yoga Alliance USA (RYT 500)',
              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: [
            'Post qualification, a number of opportunities are open to you, like:',
          ],
        },
      },
      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem: 'Opening your own yoga training center ',
              subItems: [],
            },
            {
              listItem: 'Working with corporates for their wellness programs',
              subItems: [],
            },
            {
              listItem: 'Working with schools and teaching children',
              subItems: [],
            },
            {
              listItem: 'Personal yoga coaching',
              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',

        content: {
          title: '',

          text: ['This Advanced Yoga Teacher Training course makes you eligible to teach:'],
        },
      },

      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem: 'Normal healthy individuals and groups,',

              subItems: [],
            },

            {
              listItem: 'For people with minor ailments or disorders and',

              subItems: [],
            },

            {
              listItem:
                'For people, under the guidance of qualified medical practitioners, undergoing treatment for any physiological, psychological and psychosomatic diseases/conditions.',

              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Exam Details',
          text: [],
        }
      },
      {
        type: 'u-list',
        content: {
          title:
            ' ',
          points: [
            {
              listItem: 'Students will be eligible to appear for the Final certification exams only if they have completed all the required course submissions, class work and the required attendance',
              subItems: [],
            },
            {
              listItem:
                'Students will have to appear for the final exams within 60 days from the course end date.',
              subItems: [],
            },
            {
              listItem: 'Students will be permitted a maximum of 3 attempts, to clear the final exams, failing which they will have to repeat the course.',
              subItems: ['In the 2nd attempt, they will be exempted from either theory or practical’s, if they have cleared it in their 1st attempt.',
                'They will have to appear for both in case of a 3rd attempt.'
              ],
            },
            {
              listItem: 'Students can register for either online or on-campus exams.',
              subItems: ['Once finalized, students will not be permitted to switch from online to on-campus and vice versa.'],
            },
            {
              listItem: 'Examiners will evaluate students only as per the final approved list.',
              subItems: [],
            },


          ],

        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Exam Schedule ',
          points: [
            {
              listItem: 'On-campus Final exams (both theory and practical’s) will be conducted on the last working day of each month, or as announced (in case of exigencies), between 10 am (IST) and 2 pm IST.',
              subItems: [

              ],
            },
            {
              listItem: 'Online Final exams will be conducted on the 1st Sat and the 3rd Sat of each month or as announced (in case of exigencies), subject to a minimum quorum of 8 students.',
              subItems: [
                'Theory exams will be conducted online at 5pm (IST)',
                'Practical exams will be conducted online at 3.30 pm (IST) / 6.30 pm (IST), subject to quorum.'
              ],
            },
            {
              listItem: 'Students, after submission, can log out /leave only with the explicit permission of the examiner.',
              subItems: [
                'For practical’s - students will have to wait till the exams of all students are completed.',
                'The examiner may call out students at random and not necessarily in alphabetic order'
              ],
            },
            {
              listItem: 'A Mock test will be held 7 -14 days prior to the course end date, to familiarise students with the exam process.',
              subItems: [],
            },
          ],
        }
      }
    ],

    offerings: [
      {
        type: 'u-list',

        content: {
          title: '',

          points: [
            {
              listItem:
                'You will have an opportunity to directly interact with our Guru Dr. Hansaji Yogendra. She is always available to attend to your queries and give counsel.',

              subItems: [],
            },

            {
              listItem:
                'Our expert trainers, over 40 in number, have mastered the yogic way of life and are proficient is passing on their learnings in a simple and practical way. Armed with decades of experience, they offer tremendous value and insight to new learners.',

              subItems: [],
            },

            {
              listItem:
                'Apart from the main yoga curriculum, you will learn immensely from the class experience itself. You will meet people with diverse backgrounds and experiences that you can gain insights from. There will be sadhakas of all age groups – right from teenagers to senior citizens. Every person has had a unique experience in life and it will be enriching to know all these stories.',

              subItems: [],
            },

            {
              listItem:
                'This one-of-a-kind experience will groom you to calmly and mindfully handle people and situations',

              subItems: [],
            },
          ],
        },
      },

      {
        type: 'quote',

        content: {
          title: '',

          text: [
            'This is an exclusive experience, the memories of which will stay with you all your life!',
          ],
        },
      },

      {
        type: 'u-list',

        content: {
          title: 'Our Amenities / Our Facilities',

          points: [
            {
              listItem:
                'Yoga Library: Explore one of largest collections of spiritual and philosophical books to expand your knowledge',

              subItems: [],
            },

            {
              listItem:
                'Internet Room: Access the internet in a comfortable setting when you need',

              subItems: [],
            },

            {
              listItem:
                'Meditation Pods: Meditate on the five fundamental natural elements, the Panchamahabhutas,',

              subItems: [],
            },

            {
              listItem:
                'Acupressure Path: Give your feet a natural massage and activate your vital energies',

              subItems: [],
            },

            {
              listItem:
                'Gazebo: Enjoy the soothing sounds of flowing water and take in the peace and at this relaxation area',

              subItems: [],
            },

            {
              listItem:
                'Dining Area: Enjoy wholesome Sattvik meals, specially prepared for your nourishment',

              subItems: [],
            },

            {
              listItem:
                'Yoga Book Store: Shop in a wide selection of TYI publications. Find yourself the best yogic attire and mats.',

              subItems: [],
            },
          ],
        },
      },
    ],
  },
}

const camps = {
  // 1: {
  //      id: '',
  // textdescription6: '',
  // textdescription7: '',
  // textdescription8: '',
  // textdescription9: '',
  // textdescription10: '',
  // textdescription1:'',
  // textdescription2:'',
  // textdescription3:'',
  // textdescription4:'',
  // textdescription5:'',
  //   title: 'Bhava Workshop',
  //   key: 'bhava-workshop',
  //   batch: 1,
  //   mostPopular: false,
  //   rating: 5,
  //   // templateId: 'GENERAL_AUTO_EMAIL',
  //   templateId: {
  //     templateOnline: 'GENERAL_AUTO_EMAIL',
  //     templateOffline: {
  //       templateResidential: '',
  //       templateNonResidential: ''
  //     }
  //   },
  //   colorCode: '#EDBE66',
  //   timing: '9.30 AM to 5.30 PM',
  //   fees: {
  //     onlineFee: 500,
  //     offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
  //   },
  //   category: 'camps',
  //   metaDescription:
  //     'Specific pranayama, asana and relaxation techniques to enhance your state of mind',
  //   image: `${baseDomain}${courseAssets.courseAsset8}`,
  //   cardImage: `${baseDomain}${courseAssets.courseAsset112}`,
  //   dates: ['27th Aug 2022', '22nd Oct 2022', '26th Dec 2022'],
  //   online: true,
  //   onCampus: true,
  //   nonResidential: false,
  //   residential: false,
  //   certficate: false,
  //   details: [
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Who is this workshop for?',
  //         text: [
  //           'All of life comes down to your attitude. Your attitude determines each and every event of your life. So if you can master the right attitude, there is nothing that you will not be able to do. In Yoga, the concept of bhavas has a lot of significance in life. It means an attitude. This workshop by The Yoga Institute is uniquely designed to systematically present to you the fundamental bhavas or attitudes that one should possess to live a life full of clarity, purpose, insight and understanding. This will enhance your relationships, your work, your health, your overall well-being.                   ',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'What can you expect?',
  //         text: [
  //           'The right bhavas augment the subtle and gross energies and help us deal with daily stresses and make the spiritual experience more profound. -Dr. Hansaji Yogendra ',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Program Offerings',
  //         points: [
  //           {
  //             listItem:
  //               'Effective tools and techniques to help you manage your life',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Specific pranayama, asana and relaxation techniques to enhance your state of mind',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Diet guidelines to manage your health',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Tips to maintain health by our spiritual guru Institute Director, Dr. Hansaji who has decades of experience in the field',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Fitness tips',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: 'Offered with the program',
  //         points: [
  //           {
  //             listItem: 'Booklet with guidelines  ',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Wholesome Sattvik lunch',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Healthy snacks  ',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Time',
  //         text: ['9.30 AM to 5.30 PM'],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Fees',
  //         text: ['INR 500'],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Guidelines',
  //         points: [
  //           {
  //             listItem:
  //               'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute dress code.',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Campers are requested to have their breakfast and medication (as prescribed by their doctor). ',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // },

  2: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Cardiac & Hypertension Yoga Workshop - On Campus',
    weekDays: true,
    key: 'cardiac-hypertension-workshop',
    tenure: '1 day',
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    mostPopular: false,
    rating: 5,
    colorCode: '#DDAB58',
    timing: '9:30 am – 5:30 pm (IST)',
    fees: {
      onlineFee: 1000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 1000 },
    },
    category: 'camps',
    metaDescription:
      'Asanas, pranayamas, and relaxation techniques for treating heart conditions',
    image: `${baseDomain}${courseAssets.courseAsset111}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset6}`,
    dates: ['5th Jan 2025', '2nd Mar 2025', '4th May 2025', '6th Jul 2025', '7th Sep 2025', '2nd Nov 2025'],
    online: false,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: false,

    courseType: 'Camps & Workshop',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Camps & Workshops',
    newName: 'Cardiac & Hypertension Yoga Workshop',
    days: [''],
    time: [
      '9:30 am – 5:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "1 Day",
      timings: [
        {
          day: "Time",
          time: "9:30 am – 5:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this yoga workshop for?',
          text: [
            'The heart, which has always been a symbol of light and love, of joy and happiness, has recently been heaped with relentless abuse. This yoga for Hypertension & Cardiac workshop / camp has been thoughtfully designed by Dr. Hansaji Yogendra for individuals struggling with hypertension and heart ailments. India has one of the highest burdens of cardiovascular disease worldwide. It is thought to be incurable, but we at The Yoga Institute have formulated a holistic and yogic pathway with ancient knowledge, where the mind, body and soul are aligned to overcome this.',
            'In collaboration with medical specialists, we have conducted various researches on the beneficial effects of our techniques on individuals with coronary artery blockages, ischemic chest pains, HBP, Myocardial Infarction, and have found promising results among patients.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            'High blood pressure is a lifestyle disorder. It can be corrected, it can be improved and an individual can come out of it. All you have to do is put in the right effort. - Dr. Hansaji Yogendra',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Program Offerings',
          points: [
            {
              listItem:
                'Powerful and effective asana, pranayama, and relaxation techniques to alleviate heart ailments',
              subItems: [],
            },
            {
              listItem: 'Diet guidelines for a healthy and happy heart',
              subItems: [],
            },
            {
              listItem: 'Insightful yoga session with heart specialist',
              subItems: [],
            },
            {
              listItem:
                'Lifestyle tips by spiritual guru Dr. Hansaji Yogendra who has decades of experience in the field',
              subItems: [],
            },
            {
              listItem: 'Stress Management',
              subItems: [],
            },
          ],
        },
      },
      // {
      //   type: 'u-list',
      //   content: {
      //     title: 'Offered with the program',
      //     points: [
      //       {
      //         listItem: 'Booklet with guidelines  ',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Wholesome Sattvik lunch',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Healthy snacks  ',
      //         subItems: [],
      //       },
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: ['9:30 am – 5:30 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['INR 1000 (Inclusive Lunch and Snacks)'],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Guidelines',
          points: [
            {
              listItem:
                'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeve-less T-shirts are not permitted as per the Institute dress code.',
              subItems: [],
            },
          ],
        },
      },
    ],
  },

  3: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Diabetes Yoga Camp - On Campus',
    key: 'diabetes-camp',
    weekDays: true,
    // batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    dates: ['2nd Feb 2025', '6th Apr 2025', '1st Jun 2025', '3rd Aug 2025', '5th Oct 2025', '7th Dec 2025'],
    mostPopular: false,
    rating: 5,
    colorCode: '#CE9B51',
    timing: '9:30 am – 5:30 pm (IST)',
    fees: {
      onlineFee: 1000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 1000 },
    },
    category: 'camps',
    metaDescription:
      'Holistic approach with wholesome tools and technique for managing diabetes',
    image: `${baseDomain}${courseAssets.courseAsset7}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset105}`,
    online: false,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '',
    courseType: 'Camps & Workshop',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Camps & Workshops',
    newName: 'Diabetes Yoga Camp',
    days: [''],
    time: [
      '9:30 am – 5:30 pm (IST)'
    ],

    enrollInfo: {
      duration: "1 Day",
      timings: [
        {
          day: "Time",
          time: "9:30 am – 5:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this Yoga Camp for?',
          text: [
            'Diabetes is an insidious disease that can remain symptomless and undetected for years. The modern management of diabetes works more at the physical level; it is only Yoga that has the capability to influence the psychopathogenesis as well. Carefully curated by our Director Dr. Hansaji Yogendra, the aim of this yoga health camp is to train you in yogic dinacharya and ritucharya to help you take charge of your diabetes through proper self-management. Whether you have been recently diagnosed or are working with diabetes for a while, this yoga for diabetes camp will give you valuable tools to enhance your life. The Yoga Institute’s work in the area of diabetes since the last 60 years has received immense recognition in the community.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            'Diabetes is best controlled not by fear and dread but by knowledge, self-assurance, and a positive attitude.  - Dr. Hansaji Yogendra',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Program Offerings',
          points: [
            {
              listItem:
                'Wholesome tools and techniques to help you manage your condition completely',
              subItems: [],
            },
            {
              listItem:
                'Specific yoga asanas for diabetes, pranayama, and relaxation techniques to enhance your health',
              subItems: [],
            },
            {
              listItem: 'Diet guidelines to manage your health',
              subItems: [],
            },
            {
              listItem: 'Insightful yoga session with diabetes specialist',
              subItems: [],
            },
            {
              listItem:
                'Lifestyle tips by our spiritual guru Institute Director, Dr. Hansaji who has decades of experience in the field',
              subItems: [],
            },
            {
              listItem: 'Fitness tips',
              subItems: [],
            },
          ],
        },
      },
      // {
      //   type: 'u-list',
      //   content: {
      //     title: 'Offered with the program',
      //     points: [
      //       {
      //         listItem: 'Booklet with guidelines  ',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Wholesome Sattvik lunch',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Healthy snacks  ',
      //         subItems: [],
      //       },
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: ['9:30 am – 5:30 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['INR 1000 (Inclusive Lunch and Snacks)'],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Guidelines',
          points: [
            {
              listItem:
                'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeve-less T-shirts are not permitted as per the Institute dress code',
              subItems: [],
            },
            // {
            //   listItem:
            //     'Campers are requested to have their breakfast and medication (as prescribed by their doctor). ',
            //   subItems: [],
            // },
          ],
        },
      },
    ],
  },

  // 4: {
  //      id: '',
  // textdescription6: '',
  // textdescription7: '',
  // textdescription8: '',
  // textdescription9: '',
  // textdescription10: '',
  // textdescription1:'',
  // textdescription2:'',
  // textdescription3:'',
  // textdescription4:'',
  // textdescription5:'',
  //   title: 'Diet Workshop',
  //   key: 'diet-workshop',
  //   batch: 1,
  //   // templateId: 'GENERAL_AUTO_EMAIL',
  //   templateId: {
  //     templateOnline: 'GENERAL_AUTO_EMAIL',
  //     templateOffline: {
  //       templateResidential: '',
  //       templateNonResidential: ''
  //     }
  //   },
  //   dates: [],
  //   mostPopular: false,
  //   rating: 5,
  //   colorCode: '#EDBE66',
  //   timing: '9:30 am – 5:30 pm (IST)',
  //   fees: {
  //     onlineFee: 500,
  //     offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
  //   },
  //   category: 'camps',
  //   metaDescription:
  //     'Wholesome tools and techniques to help you manage your condition completely',
  //   image: `${baseDomain}${courseAssets.courseAsset85}`,
  //   cardImage: `${baseDomain}${courseAssets.courseAsset85}`,
  //   details: [
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Who is this workshop for?',
  //         text: [
  //           'Do you find yourself juggling multiple responsibilities every day? Do you feel you are not able to focus consciously on your meals? You are not alone! A lot of us, with our erratic lifestyles, eat whatever is quick to manage and thus end up really creating imbalances in our bodily energies. At the Yoga Institute’s Diet Workshop, we will give you the wisdom of ancient Sattvik ahar, which is really easy to prepare and which will bring your physical, spiritual and mental energies in balance.                   ',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'What can you expect?',
  //         text: [
  //           'Sattvic means purity, health, harmony and well-being. Sattvik food has the power to safeguard us against many modern-day ailments. - Dr. Hansaji Yogendra ',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Program Offerings',
  //         points: [
  //           {
  //             listItem:
  //               'Effective tools and techniques to help you manage your life',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Specific pranayama, asana and relaxation techniques to enhance your state of mind',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Easy high-fibre Sattvik recipes',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Diet guidelines to manage your health',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Tips to maintain health by our spiritual guru Institute Director, Dr. Hansaji who has decades of experience in the field',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Fitness tips',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //     // {
  //     //   type: 'u-list',
  //     //   content: {
  //     //     title: 'Offered with the program',
  //     //     points: [
  //     //       {
  //     //         listItem: 'Booklet with guidelines  ',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem: 'Wholesome Sattvik lunch',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem: 'Healthy snacks  ',
  //     //         subItems: [],
  //     //       },
  //     //     ],
  //     //   },
  //     // },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Time',
  //         text: [' 5:00 pm – 8:00 pm (IST)'],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Fees',
  //         text: ['INR 500'],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Guidelines',
  //         points: [
  //           {
  //             listItem:
  //               'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute dress code.',
  //             subItems: [],
  //           },
  //           // {
  //           //   listItem:
  //           //     'Campers are requested to have their breakfast and medication (as prescribed by their doctor). ',
  //           //   subItems: [],
  //           // },
  //         ],
  //       },
  //     },
  //   ],
  // },

  // 5: {
  //      id: '',
  // textdescription6: '',
  // textdescription7: '',
  // textdescription8: '',
  // textdescription9: '',
  // textdescription10: '',
  // textdescription1:'',
  // textdescription2:'',
  // textdescription3:'',
  // textdescription4:'',
  // textdescription5:'',
  //   title: 'Digestion Camp',
  //   key: 'digestion-camp',
  //   batch: 1,
  //   // templateId: 'GENERAL_AUTO_EMAIL',
  //   templateId: {
  //     templateOnline: 'GENERAL_AUTO_EMAIL',
  //     templateOffline: {
  //       templateResidential: '',
  //       templateNonResidential: '',
  //     },
  //   },
  //   mostPopular: false,
  //   rating: 5,
  //   colorCode: '#DDAB58',
  //   timing: '9.30 AM to 5.30 PM',
  //   fees: {
  //     onlineFee: 500,
  //     offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
  //   },
  //   category: 'camps',
  //   metaDescription:
  //     'Specific pranayama, and relaxation techniques to enhance your wellbeing',
  //   image: `${baseDomain}${courseAssets.courseAsset13}`,
  //   cardImage: `${baseDomain}${courseAssets.courseAsset106}`,
  //   dates: [],
  //   online: true,
  //   onCampus: true,
  //   nonResidential: false,
  //   residential: false,
  //   certficate: false,
  //   details: [
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Who is this workshop for?',
  //         text: [
  //           'Given our fast-paced and unhealthy lifestyles, it is no wonder that most of us struggle with our digestive systems – be it diarrhea, IBS, constipation, ulcers, acidity, and celiac disease. Fortunately, yoga shows us a holistic path out of this towards well-being and total health. At The Yoga Institute, we have designed a powerful health camp to help you tackle all your troubles with digestion. With the aid of Sattvik ahar, yogic guidelines, effective asanas, in no time your digestion will be your friend and not your enemy.                   ',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'What can you expect?',
  //         text: [
  //           'If your digestion is good, all the cells in your body will be happy and free of disease. -Dr. Hansaji Yogendra ',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Program Offerings',
  //         points: [
  //           {
  //             listItem:
  //               'Yoga Sadhana: Specific pranayama, and relaxation techniques to enhance your wellbeing',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Diet guidelines',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Health counseling',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Lifestyle tips by spiritual guru Dr. Hansaji who has decades of experience in the field',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Fitness tips',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //     // {
  //     //   type: 'u-list',
  //     //   content: {
  //     //     title: 'Offered with the program',
  //     //     points: [
  //     //       {
  //     //         listItem: 'Booklet with guidelines  ',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem: 'Wholesome Sattvik lunch',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem: 'Healthy snacks  ',
  //     //         subItems: [],
  //     //       },
  //     //     ],
  //     //   },
  //     // },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Time',
  //         text: ['9.30 AM to 5.30 PM'],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Fees',
  //         text: ['INR 500'],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Guidelines',
  //         points: [
  //           {
  //             listItem:
  //               'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute dress code.',
  //             subItems: [],
  //           },
  //           // {
  //           //   listItem:
  //           //     'Campers are requested to have their breakfast and medication (as prescribed by their doctor). ',
  //           //   subItems: [],
  //           // },
  //         ],
  //       },
  //     },
  //   ],
  // },

  // 6: {
  //      id: '',
  // textdescription6: '',
  // textdescription7: '',
  // textdescription8: '',
  // textdescription9: '',
  // textdescription10: '',
  // textdescription1:'',
  // textdescription2:'',
  // textdescription3:'',
  // textdescription4:'',
  // textdescription5:'',
  //   title: 'Karma Yoga',
  //   key: 'karma-yoga-course',
  //   batch: 1,
  //   // templateId: 'GENERAL_AUTO_EMAIL',
  //   templateId: {
  //     templateOnline: 'GENERAL_AUTO_EMAIL',
  //     templateOffline: {
  //       templateResidential: '',
  //       templateNonResidential: '',
  //     },
  //   },
  //   mostPopular: false,
  //   rating: 5,
  //   colorCode: '#CE9B51',
  //   timing: '9.30 AM to 5.30 PM',
  //   fees: {
  //     onlineFee: 500,
  //     offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
  //   },
  //   category: 'camps',
  //   metaDescription: 'Diet guidelines to manage your health',
  //   image: `${baseDomain}${courseAssets.courseAsset10}`,
  //   cardImage: `${baseDomain}${courseAssets.courseAsset10}`,
  //   dates: [],
  //   online: true,
  //   onCampus: true,
  //   nonResidential: false,
  //   residential: false,
  //   certficate: false,
  //   details: [
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Who is this workshop for?',
  //         text: [
  //           'This beautifully technique of Karma Yoga presented in Bhagvada Gita is relevant even today. It is a system that can help us be focused and efficient throughout the day in a time when are attentions ae divided among a multitude of tasks and responsibilities. This camp will give you a formula to manage your state of mind in your work and will help u attain success and efficiency in all activity. We hope to see you soon!                   ',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'What can you expect?',
  //         text: [
  //           'Mere doing is not action. The intent behind every action is important. ',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Program Offerings',
  //         points: [
  //           {
  //             listItem:
  //               'Effective tools and techniques to help you manage your life',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Specific pranayama, asana and relaxation techniques to enhance your state of mind',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Easy high-fibre Sattvik recipes',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Diet guidelines to manage your health',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Tips to maintain health by our spiritual guru Institute Director, Dr. Hansaji who has decades of experience in the field',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Fitness tips',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //     // {
  //     //   type: 'u-list',
  //     //   content: {
  //     //     title: 'Offered with the program',
  //     //     points: [
  //     //       {
  //     //         listItem: 'Booklet with guidelines  ',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem: 'Wholesome Sattvik lunch',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem: 'Healthy snacks  ',
  //     //         subItems: [],
  //     //       },
  //     //     ],
  //     //   },
  //     // },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Time',
  //         text: ['9.30 AM to 5.30 PM'],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Fees',
  //         text: ['INR 500'],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Guidelines',
  //         points: [
  //           {
  //             listItem:
  //               'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute dress code.',
  //             subItems: [],
  //           },
  //           // {
  //           //   listItem:
  //           //     'Campers are requested to have their breakfast and medication (as prescribed by their doctor). ',
  //           //   subItems: [],
  //           // },
  //         ],
  //       },
  //     },
  //   ],
  // },

  // 7: {
  //   id: '',
  //   textdescription6: '',
  //   textdescription7: '',
  //   textdescription8: '',
  //   textdescription9: '',
  //   textdescription10: '',
  //   textdescription1: '',
  //   textdescription2: '',
  //   textdescription3: '',
  //   textdescription4: '',
  //   textdescription5: '',
  //   title: 'Life Management Yoga Camp - On Campus',
  //   weekDays: true,
  //   key: 'life-management-camp',
  //   batch: 1,
  //   // templateId: 'GENERAL_AUTO_EMAIL',
  //   templateId: {
  //     templateOnline: 'GENERAL_AUTO_EMAIL',
  //     templateOffline: {
  //       templateResidential: '',
  //       templateNonResidential: '',
  //     },
  //   },
  //   mostPopular: false,
  //   rating: 5,
  //   colorCode: '#EDBE66',
  //   timing: '9:30 am – 5:30 pm (IST)',
  //   fees: {
  //     onlineFee: 1000,
  //     offlineFee: { residentialFee: 0, nonResidentialFee: 1000 },
  //   },
  //   category: 'camps',
  //   metaDescription:
  //     'Enhance state of mind with pranayama, yoga asana and relaxation techniques',
  //   image: `${baseDomain}${courseAssets.courseAsset8}`,
  //   cardImage: `${baseDomain}${courseAssets.courseAsset112}`,
  //   dates: [],
  //   online: false,
  //   onCampus: true,
  //   nonResidential: false,
  //   residential: false,
  //   certficate: false,

  //   tenure: '',
  //   courseType: 'Camps & Workshop',
  //   language: 'English',
  //   preRequisite: 'None',
  //   courseCategory: 'Self Help',
  //   courseSubType: 'Camps & Workshops',
  //   newName: 'Life Management Yoga Camp',
  //   days: [''],
  //   time: [
  //     '9:30 am – 5:30 pm (IST)'
  //   ],



  //   enrollInfo: {
  //     duration: "1 Day",
  //     timings: [
  //       {
  //         day: "Time",
  //         time: "9:30 am – 5:30 pm (IST)"
  //       }
  //     ]
  //   }
  //   ,

  //   courseCertificate: 'NO',
  //   onlineInfo: {
  //     courseMode: false,
  //   },
  //   residentialInfo: {
  //     courseMode: false,
  //     residentialMode: false
  //   },
  //   nonResidentialInfo: {
  //     courseMode: true,
  //     nonResidentialMode: true
  //   },
  //   details: [
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Who is this yoga camp for?',
  //         text: [
  //           'If you think about it, most of the time we are living our life without consciously thinking about what we are doing and why we are doing it. It is only when we face a problem of some kind, that we are forced to stop and take stock of things. This kind of approach does us no good. If you feel that you are stuck in life, or don’t know what to do next, or feel like your life is going nowhere, this is the camp for you! Here is a Life management yoga camp by The Yoga Institute to give you direction and discipline to go through your life happily, joyfully and with balance. Designed with the wisdom of ancient Indian teachings, there’s a whole treasure chest of tools waiting for you at this camp. Hope to see you soon!'
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'What can you expect?',
  //         text: [
  //           'Life is a choice between B and D. Birth and Death. We are in control of C. That is the Choices we make, so make the right choices and live a happy life. -Dr. Hansaji Yogendra',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Program Offerings',
  //         points: [
  //           {
  //             listItem:
  //               'Effective tools and techniques to help you manage your life',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Specific pranayama, yoga asana and relaxation techniques to enhance your state of mind',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Yogic Diet guidelines to manage your health',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Tips to maintain health by our spiritual guru Institute Director, Dr. Hansaji who has decades of experience in the field',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Fitness tips',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: 'Offered with the program',
  //         points: [
  //           {
  //             listItem: 'Booklet with guidelines',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Wholesome Sattvik lunch',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Healthy snacks',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Time',
  //         text: ['9:30 am – 5:30 pm (IST)'],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Fees',
  //         text: ['INR 1000 (Inclusive Lunch and Snacks)'],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Guidelines',
  //         points: [
  //           {
  //             listItem:
  //               'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute dress code.',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Campers are requested to have their breakfast and medication (as prescribed by their doctor).',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // },

  // 8: {
  //      id: '',
  // textdescription6: '',
  // textdescription7: '',
  // textdescription8: '',
  // textdescription9: '',
  // textdescription10: '',
  // textdescription1:'',
  // textdescription2:'',
  // textdescription3:'',
  // textdescription4:'',
  // textdescription5:'',
  //   title: 'Meditation Camp',
  //   key: 'meditation-camp',
  //   batch: 1,
  //   // templateId: 'GENERAL_AUTO_EMAIL',
  //   templateId: {
  //     templateOnline: 'GENERAL_AUTO_EMAIL',
  //     templateOffline: {
  //       templateResidential: '',
  //       templateNonResidential: '',
  //     },
  //   },
  //   mostPopular: false,
  //   rating: 5,
  //   colorCode: '#DDAB58',
  //   timing: '9.30 AM to 5.30 PM',
  //   fees: {
  //     onlineFee: 500,
  //     offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
  //   },
  //   category: 'camps',
  //   metaDescription:
  //     'Specific pranayama, asana and relaxation techniques to enhance your state of mind',
  //   image: `${baseDomain}${courseAssets.courseAsset9}`,
  //   cardImage: `${baseDomain}${courseAssets.courseAsset9}`,
  //   dates: [],
  //   online: true,
  //   onCampus: true,
  //   nonResidential: false,
  //   residential: false,
  //   certficate: false,
  //   details: [
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Who is this workshop for?',
  //         text: [
  //           'The Meditation Camp at The Yoga Institute will take you into a deep ocean of silence, focus and peace. Here, we will give you the tools to handle your daily stresses and challenges. With the unique techniques developed at The Yoga Institute, you will learn to be less reactive and more responsive to all of life’s situations and events. You will experience the power of tranquility in every aspect of your life. You will become a new person who is balanced no matter the situation they are in.                ',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'What can you expect?',
  //         text: [
  //           'A meditative state can bring a change in your approach toward life and efficiency in work. -Dr. Hansaji Yogendra ',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Program Offerings',
  //         points: [
  //           {
  //             listItem:
  //               'Effective tools and techniques to help you manage your life',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Specific pranayama, asana and relaxation techniques to enhance your state of mind',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Diet guidelines to manage your health',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Tips to maintain health by our spiritual guru Institute Director, Dr. Hansaji who has decades of experience in the field',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Fitness tips',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //     // {
  //     //   type: 'u-list',
  //     //   content: {
  //     //     title: 'Offered with the program',
  //     //     points: [
  //     //       {
  //     //         listItem: 'Booklet with guidelines  ',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem: 'Wholesome Sattvik lunch',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem: 'Healthy snacks  ',
  //     //         subItems: [],
  //     //       },
  //     //     ],
  //     //   },
  //     // },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Time',
  //         text: ['9.30 AM to 5.30 PM'],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Fees',
  //         text: ['INR 500'],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Guidelines',
  //         points: [
  //           {
  //             listItem:
  //               'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute dress code.',
  //             subItems: [],
  //           },
  //           // {
  //           //   listItem:
  //           //     'Campers are requested to have their breakfast and medication (as prescribed by their doctor). ',
  //           //   subItems: [],
  //           // },
  //         ],
  //       },
  //     },
  //   ],
  // },

  9: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Pranayama Workshop - On Campus & Online',
    key: 'pranayama-workshop',
    tenure: '1 day',
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    dates: ['12th Jan 2025', '9th Mar 2025', '11th May 2025', '13th Jul 2025', '14th Sep 2025', '9th Nov 2025'],
    mostPopular: false,
    rating: 5,
    colorCode: '#CE9B51',
    timing: '8:00 am – 11:00 am (IST)',
    fees: {
      onlineFee: 500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 500 },
    },
    category: 'camps',
    metaDescription:
      'Specific pranayama, yoga asana and relaxation techniques to enhance your state of mind',
    image: `${baseDomain}${courseAssets.courseAsset37}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset37}`,
    online: true,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,

    courseType: 'Camps & Workshop',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Camps & Workshops',
    newName: 'Prayanama Workshop',
    days: [''],
    time: [
      '8:00 am – 11:00 am (IST)'
    ],


    enrollInfo: {
      duration: "Half day (4 hours)",
      timings: [
        {
          day: "Time",
          time: "8:00 am – 11:00 am (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this yoga workshop for?',
          text: [
            'Prana, the essence of life, regulates your biological system. Bio-energy is the key to your physical, mental, and spiritual well-being. Pranayama, the yogic system of regulating your life force, will help you regulate your being, keeping you meditative and balanced. At The Yoga Institute, we have pioneered specific yoga breathing techniques and pranayama techniques to help you enhance the quality of your breath and life. Come, experience the power of pranayama with us!',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            '“Master the control over your prana to experience quietude and inner harmony” - Dr. Hansaji Yogendra',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Program Offerings',
          points: [
            {
              listItem:
                'Effective tools and techniques to help you manage your life',
              subItems: [],
            },
            {
              listItem:
                'Specific pranayama techniques, yoga asana and relaxation practices to enhance your state of mind',
              subItems: [],
            },
            {
              listItem: 'Yogic Diet guidelines to manage your health',
              subItems: [],
            },
            {
              listItem:
                'Tips to maintain health by our spiritual guru and Institute’s Director, Dr. Hansaji who has decades of experience in the field',
              subItems: [],
            },
            {
              listItem: 'Fitness tips',
              subItems: [],
            },
          ],
        },
      },
      // {
      //   type: 'u-list',
      //   content: {
      //     title: 'Offered with the program',
      //     points: [
      //       {
      //         listItem: 'Booklet with guidelines  ',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Wholesome Sattvik lunch',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Healthy snacks  ',
      //         subItems: [],
      //       },
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: ['8:00 am – 11:00 am (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['INR 500'],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Guidelines',
          points: [
            {
              listItem:
                'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute’s dress code.',
              subItems: [],
            },
            // {
            //   listItem:
            //     'Campers are requested to have their breakfast and medication (as prescribed by their doctor). ',
            //   subItems: [],
            // },
          ],
        },
      },
    ],
  },

  10: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Pregnancy Yoga Camp For Ante & Post Natal - On Campus',
    key: 'pregnancy-camp-for-ante-post-natal',
    tenure: '2 days',
    batch: 1,
    join: true,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    dates: [
      '14th Dec & 15th Dec 2024',
      '20th Jan & 21st Jan 2025',
      '15th Feb & 16th Feb 2025',
      '17th Mar & 18th Mar 2025',
      '19th Apr & 20th Apr 2025',
      '19th May & 20th May 2025',
      '21st Jun & 22nd Jun 2025',
      '21st Jul & 22nd Jul 2025',
      '16th Aug & 17th Aug 2025',
      '15th Sep & 16th Sep 2025',
      '18th Oct & 19th Oct 2025',
      '17th Nov & 18th Nov 2025',
      '20th Dec & 21st Dec 2025',
    ],
    mostPopular: false,
    rating: 5,
    colorCode: '#EDBE66',
    timing: '9:30 am – 5:30 pm (IST)',
    fees: {
      onlineFee: 2000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 2000 },
    },
    category: 'camps',
    metaDescription: 'Insightful session with a medical specialists.',
    image: `${baseDomain}${courseAssets.courseAsset11}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset11}`,
    online: false,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: false,

    courseType: 'Camps & Workshop',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Camps & Workshops',
    newName: 'Pregnancy Yoga Camp For Ante & Post Natal',
    days: [''],
    time: [
      '9:30 am – 5:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "2 days",
      timings: [
        {
          day: "Time",
          time: "9:30 am – 5:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this yoga camp for?',
          text: [
            'Parenthood is a career that never ends and the ancient path of yoga has great lessons to offer on this path. At The Yoga Institute, we have pioneered yoga techniques that are simple, scientific and useful for expecting mothers. You will be able to experience a happy and healthy pregnancy and easy delivery by following simple yoga techniques and practical guidance at our Pregnancy Yoga Camp. Come – join us, learn and practice simple yoga techniques for a happier pregnancy, delivery and baby!'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            'The birth of a baby is an affirmation of life. A new baby is perhaps the greatest of teachers and an opportunity to see the continuity that life is -Dr. Hansaji Yogendra',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Program Offerings',
          points: [
            {
              listItem:
                'Specific pranayama, asana and relaxation techniques to enhance your state of mind',
              subItems: [],
            },
            {
              listItem: 'Yogic Diet guidelines to manage your health',
              subItems: [],
            },
            {
              listItem: 'Insightful yoga session with a medical specialists.',
              subItems: [],
            },
            {
              listItem:
                'Lifestyle tips by spiritual guru Dr. Hansaji who has decades of experience in the field',
              subItems: [],
            },
            {
              listItem: 'Fitness tips',
              subItems: [],
            },
          ],
        },
      },
      // {
      //   type: 'u-list',
      //   content: {
      //     title: 'Offered with the program',
      //     points: [
      //       {
      //         listItem: 'Booklet with guidelines  ',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Wholesome Sattvik lunch',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Healthy snacks  ',
      //         subItems: [],
      //       },
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: ['9:30 am – 5:30 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['INR 2,000 (Inclusive Lunch and Snacks)'],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Guidelines',
          points: [
            {
              listItem:
                'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute dress code.',
              subItems: [],
            },
            // {
            //   listItem:
            //     'Campers are requested to have their breakfast and medication (as prescribed by their doctor). ',
            //   subItems: [],
            // },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Note',
          text: ['*Please note, the pregnancy yoga camp is open only for women after 3 months of pregnancy till the 9 months of pregnancy.'],
        },
      },
    ],
  },

  // 11: {
  //   id: '',
  //   textdescription6: '',
  //   textdescription7: '',
  //   textdescription8: '',
  //   textdescription9: '',
  //   textdescription10: '',
  //   textdescription1: '',
  //   textdescription2: '',
  //   textdescription3: '',
  //   textdescription4: '',
  //   textdescription5: '',
  //   title: 'Respiratory Yoga Workshop - On Campus',
  //   key: 'respiratory-workshop',
  //   batch: 1,
  //   // templateId: 'GENERAL_AUTO_EMAIL',
  //   templateId: {
  //     templateOnline: 'GENERAL_AUTO_EMAIL',
  //     templateOffline: {
  //       templateResidential: '',
  //       templateNonResidential: '',
  //     },
  //   }, text: ['INR 2,000'],
  //   dates: ['14th Dec 2024'],
  //   mostPopular: false,
  //   rating: 5,
  //   colorCode: '#DDAB58',
  //   timing: '9:30 am – 5:30 pm (IST)',
  //   fees: {
  //     onlineFee: 1000,
  //     offlineFee: { residentialFee: 0, nonResidentialFee: 1000 },
  //   },
  //   category: 'camps',
  //   metaDescription: 'Insightful yoga session with a specialist',
  //   image: `${baseDomain}${courseAssets.courseAsset83}`,
  //   cardImage: `${baseDomain}${courseAssets.courseAsset83}`,
  //   online: false,
  //   onCampus: true,
  //   nonResidential: false,
  //   residential: false,
  //   certficate: false,

  //   tenure: '',
  //   courseType: 'Camps & Workshop',
  //   language: 'English',
  //   preRequisite: 'None',
  //   courseCategory: 'Self Help',
  //   courseSubType: 'Camps & Workshops',
  //   newName: 'Respiratory Yoga Workshop',
  //   days: [''],
  //   time: [
  //     '9:30 am – 5:30 pm (IST)'
  //   ],
  //   enrollInfo: {
  //     duration: "1 Day",
  //     timings: [
  //       {
  //         day: "Time",
  //         time: "9:30 am – 5:30 pm (IST)"
  //       }
  //     ]
  //   }
  //   ,

  //   courseCertificate: 'NO',
  //   onlineInfo: {
  //     courseMode: false,
  //   },
  //   residentialInfo: {
  //     courseMode: false,
  //     residentialMode: false
  //   },
  //   nonResidentialInfo: {
  //     courseMode: true,
  //     nonResidentialMode: true
  //   },

  //   details: [
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Who is this yoga workshop for?',
  //         text: [
  //           'Prana is the vital energy or life force, which exists in all of us. The fight against respiratory problems begins with a strong will to overcome one’s disability and has to be backed by an implicit faith in oneself. With a strong emphasis on ancient yogic knowledge and techniques, The Yoga Institute has been conducting powerful Respiratory Camps since 1987. In these camps, participants are taught yoga breathing techniques and pranayama techniques for improving lung capacity and function, mind-body balance, increased calmness and healing. Respiratory health has been well-documented in traditional systems of well-being over several millennia. The Yogic life strengthens your will, augments your faith in yourself and opens air passages. How soon you can breathe with ease and on your own, depends on yourself. What are you waiting for? Begin now!'
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'What can you expect?',
  //         text: [
  //           '“If you learn to breathe the right way, your life will be longer” - Dr. Hansaji Yogendra',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Program Offerings',
  //         points: [
  //           {
  //             listItem:
  //               'Specific pranayama techniques, and relaxation practices to enhance your health',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Dietary guidelines for overall health',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Insightful yoga session with a specialist',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Tips to maintain health by our spiritual guru and Institute’s Director, Dr. Hansaji who has decades of experience in the field',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Fitness tips',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: 'Offered with the program',
  //         points: [
  //           {
  //             listItem: 'Booklet with guidelines',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Wholesome Sattvik lunch',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Healthy snacks',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Time',
  //         text: ['9:30 am – 5:30 pm (IST)'],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Fees',
  //         text: ['INR 1000 (Inclusive Lunch and Snacks)'],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Guidelines',
  //         points: [
  //           {
  //             listItem:
  //               'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute’s dress code.',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Campers are requested to have their breakfast and medication (as prescribed by their doctor).',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // },

  // 12: {
  //   id: '',
  //   textdescription6: '',
  //   textdescription7: '',
  //   textdescription8: '',
  //   textdescription9: '',
  //   textdescription10: '',
  //   textdescription1: '',
  //   textdescription2: '',
  //   textdescription3: '',
  //   textdescription4: '',
  //   textdescription5: '',
  //   title: 'Senior Citizen Yoga Workshop - On Campus',
  //   key: 'senior-citizen-workshop',
  //   batch: 1,
  //   // templateId: 'GENERAL_AUTO_EMAIL',
  //   templateId: {
  //     templateOnline: 'GENERAL_AUTO_EMAIL',
  //     templateOffline: {
  //       templateResidential: '',
  //       templateNonResidential: '',
  //     },
  //   },
  //   dates: [],
  //   mostPopular: false,
  //   rating: 5,
  //   colorCode: '#CE9B51',
  //   timing: '10.00 am to 1:00 pm (IST)',
  //   fees: {
  //     onlineFee: 0,
  //     offlineFee: { residentialFee: 0, nonResidentialFee: 1000 },
  //   },
  //   category: 'camps',
  //   metaDescription:
  //     'Specific pranayama, yoga asana and relaxation techniques to enhance your state of mind',
  //   image: `${baseDomain}${courseAssets.courseAsset12}`,
  //   cardImage: `${baseDomain}${courseAssets.courseAsset12}`,
  //   online: false,
  //   onCampus: true,
  //   nonResidential: false,
  //   residential: false,
  //   certficate: false,

  //   tenure: '',
  //   courseType: 'Camps & Workshop',
  //   language: 'English',
  //   preRequisite: 'None',
  //   courseCategory: 'Self Help',
  //   courseSubType: 'Camps & Workshops',
  //   newName: 'Senior Citizen Yoga Workshop',
  //   days: [''],
  //   time: [
  //     '10.00 am to 1:00 pm (IST)'
  //   ],


  //   enrollInfo: {
  //     duration: "Half Day",
  //     timings: [
  //       {
  //         day: "Time",
  //         time: "10.00 am to 1:00 pm (IST)"
  //       }
  //     ]
  //   }
  //   ,

  //   courseCertificate: 'NO',
  //   onlineInfo: {
  //     courseMode: false,
  //   },
  //   residentialInfo: {
  //     courseMode: false,
  //     residentialMode: false
  //   },
  //   nonResidentialInfo: {
  //     courseMode: true,
  //     nonResidentialMode: true
  //   },


  //   details: [
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Who is this yoga workshop for?',
  //         text: [
  //           'Aging is a natural and unavoidable part of life but how you handle yourself at every age can determine the quality of your life. If you are looking to improve your quality of life at a mature age, this yoga for senior citizens camp will have immense value for you. At The Yoga Institute, we teach specially simplified asana that you can do with ease and enhance your wellbeing. These yoga techniques will help you keep your body system strong and help you live a fuller life'
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'What can you expect?',
  //         text: [
  //           'Age is just a number! To live a long and healthy life, take care of your mental and physical health. -Dr. Hansaji Yogendra',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Program Offerings',
  //         points: [
  //           {
  //             listItem:
  //               'Effective tools and techniques to help you manage your life',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Specific pranayama, yoga asana and relaxation techniques to enhance your state of mind',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Yogic Diet guidelines to manage your health',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Tips to maintain health by our spiritual guru Institute Director, Dr. Hansaji who has decades of experience in the field',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Fitness tips',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //     // {
  //     //   type: 'u-list',
  //     //   content: {
  //     //     title: 'Offered with the program',
  //     //     points: [
  //     //       {
  //     //         listItem: 'Booklet with guidelines  ',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem: 'Wholesome Sattvik lunch',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem: 'Healthy snacks  ',
  //     //         subItems: [],
  //     //       },
  //     //     ],
  //     //   },
  //     // },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Time',
  //         text: ['10.00 am to 1:00 pm (IST)'],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Fees',
  //         text: ['INR 500 (Inclusive Lunch and Snacks)'],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Guidelines',
  //         points: [
  //           {
  //             listItem:
  //               'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute dress code.',
  //             subItems: [],
  //           },
  //           // {
  //           //   listItem:
  //           //     'Campers are requested to have their breakfast and medication (as prescribed by their doctor). ',
  //           //   subItems: [],
  //           // },
  //         ],
  //       },
  //     },
  //   ],
  // },

  13: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Stress Management Yoga Camp - On Campus',
    key: 'stress-management-camp',
    tenure: '1 day',
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    dates: ['8th Dec 2024', '12th Jan 2025', '9th Feb 2025', '9th Mar 2025', '13th Apr 2025', '11th May 2025', '8th Jun 2025', '13th Jul 2025', '10th Aug 2025', '14th Sep 2025', '12th Oct 2025', '9th Nov 2025', '14t Dec 2025'],
    mostPopular: false,
    rating: 5,
    colorCode: '#EDBE66',
    timing: '9:30 am – 5:30 pm (IST)',
    fees: {
      onlineFee: 1000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 1000 },
    },
    category: 'camps',
    metaDescription:
      'Specific pranayama, yoga asana and relaxation techniques to enhance your state of mind',
    image: `${baseDomain}${courseAssets.courseAsset13}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset13}`,
    online: false,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: false,

    courseType: 'Camps & Workshop',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Camps & Workshops',
    newName: 'Stress Management Yoga Camp',
    days: [''],
    time: [
      '9:30 am – 5:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "1 day",
      timings: [
        {
          day: "Time",
          time: "9:30 am – 5:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this yoga workshop for?',
          text: [
            'Stress is the “wear and tear” our bodies experience as we adjust to our continually changing environment. It has physical and emotional effects on us and can create positive or negative feelings. Stress loses its sting when the balanced mind perceives a stressful situation from a different scale of observation. Our goal here at the Yoga Institute is not to eliminate stress but to learn how to manage it and how to use it to help us. Learn to lead a healthy life under stressful situations at our 1-day Yoga for Stress Management camp.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            'Positivity and certainty are the cornerstones of a normal healthy mind. -Dr. Hansaji Yogendra',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Program Offerings',
          points: [
            {
              listItem:
                'Yoga Sadhana: Specific pranayama, and relaxation techniques to enhance your wellbeing',
              subItems: [],
            },
            {
              listItem: 'Yogic Diet guidelines',
              subItems: [],
            },
            {
              listItem: 'Health counselling',
              subItems: [],
            },
            {
              listItem:
                'Tips to maintain health by our spiritual guru Institute Director, Dr. Hansaji who has decades of experience in the field',
              subItems: [],
            },
            {
              listItem: 'Fitness tips',
              subItems: [],
            },
          ],
        },
      },
      // {
      //   type: 'u-list',
      //   content: {
      //     title: 'Offered with the program',
      //     points: [
      //       {
      //         listItem: 'Booklet with guidelines  ',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Wholesome Sattvik lunch',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Healthy snacks  ',
      //         subItems: [],
      //       },
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: ['9:30 am – 5:30 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['INR 1000 (Inclusive Lunch and Snacks)'],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Guidelines',
          points: [
            {
              listItem:
                'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute dress code.',
              subItems: [],
            },
            // {
            //   listItem:
            //     'Campers are requested to have their breakfast and medication (as prescribed by their doctor). ',
            //   subItems: [],
            // },
          ],
        },
      },
    ],
  },

  14: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Weight Management Yoga Workshop',
    key: 'weight-management-workshop',
    tenure: '1 day',
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    dates: ['7th Dec 2024', '4th Jan 2025', '1st Feb 2025', '1st Mar 2025', '5th Apr 2025', '3rd May 2025', '7th Jun 2025', '5th Jul 2025', '2nd Aug 2025', '6th Sep 2025', '4th Oct 2025', '1st Nov 2025', '6th Dec 2025'],
    mostPopular: false,
    rating: 5,
    colorCode: '#DDAB58',
    timing: '9:30 am – 5:30 pm (IST)',
    fees: {
      onlineFee: 1000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 1000 },
    },
    category: 'camps',
    metaDescription: 'Low-calorie diet recipe',
    image: `${baseDomain}${courseAssets.courseAsset15}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset15}`,
    online: false,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: false,

    courseType: 'Camps & Workshop',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Camps & Workshops',
    newName: 'Weight Management Yoga Workshop',
    days: [''],
    time: [
      '9:30 am – 5:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "1 day",
      timings: [
        {
          day: "Time",
          time: "9:30 am – 5:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this yoga workshop for?',
          text: [
            'Obesity is increasing at an alarming rate all over the world and particularly in India. There are several contributing factors towards this problem. Despite following different types of diet and weight loss programs, the results are often not satisfactory. The basic reason for this ‘yo-yo effect’ is that obesity is not the cause of the problem. It’s only the effect of some deep-rooted problem within one’s total personality complex. Yoga deals with obesity at all levels - physical, mental and spiritual and with a focus on diet. Keeping these factors in mind, The Yoga Institute has designed a special 1-day yoga camp on Weight Management. This camp will provide you with a holistic approach on wellness and train you in effective Yoga poses for weight loss. ',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            '“First, learn to accept yourself as you are” - Dr. Hansaji Yogendra',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Program Offerings',
          points: [
            {
              listItem:
                'Yoga for weight loss',
              subItems: [],
            },
            {
              listItem:
                'Specific pranayama, and relaxation techniques to enhance your health',
              subItems: [],
            },
            {
              listItem: 'Low-calorie diet recipe',
              subItems: [],
            },
            {
              listItem: 'Health counseling',
              subItems: [],
            },
            {
              listItem:
                'Tips to maintain health by our spiritual guru and Institute’s Director, Dr. Hansaji who has decades of experience in the field',
              subItems: [],
            },
            {
              listItem: 'Fitness tips',
              subItems: [],
            },
          ],
        },
      },
      // {
      //   type: 'u-list',
      //   content: {
      //     title: 'Offered with the program',
      //     points: [
      //       {
      //         listItem: 'Booklet with guidelines  ',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Wholesome Sattvik lunch',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Healthy snacks  ',
      //         subItems: [],
      //       },
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: ['9:30 am – 5:30 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['INR 1000 (Inclusive Lunch and Snacks)'],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Guidelines',
          points: [
            {
              listItem:
                'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute’s dress code.',
              subItems: [],
            },
            // {
            //   listItem:
            //     'Campers are requested to have their breakfast and medication (as prescribed by their doctor). ',
            //   subItems: [],
            // },
          ],
        },
      },
    ],
  },

  15: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Women’s Yoga Camp',
    key: 'womens-camp',
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    dates: [],
    mostPopular: false,
    rating: 5,
    colorCode: '#CE9B51',
    timing: '9:30 am – 5:30 pm (IST)',
    fees: {
      onlineFee: 1000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 1000 },
    },
    category: 'camps',
    metaDescription:
      'Specific yoga asana, pranayama, and relaxation techniques to enhance your health',
    image: `${baseDomain}${courseAssets.courseAsset84}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset84}`,
    online: false,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '',
    courseType: 'Camps & Workshop',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Camps & Workshops',
    newName: 'Womens Yoga Camp',
    days: [''],
    time: [
      '9:30 am – 5:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "1 Day",
      timings: [
        {
          day: "Time",
          time: "9:30 am – 5:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this yoga workshop for?',
          text: [
            'As a woman, to say that you face a multitude of challenges would be an understatement. So, you need the power of yoga in your life to help you cope and thrive with these challenges. Additionally, there a lot of biological changes that come at different stages of life – menopause, pregnancy, and menstruation can cause a variety of symptoms like premenstrual syndrome, menstrual cramps, headaches, and a lot of other things. We, at The Yoga Institute, have devised this women’s yoga camp to help you be strong, focused, energetic, and balanced, so that you can take on everything that comes your way and continue being the superwoman that you are! With a strong emphasis on powerful yogic routines like ritucharya and dinacharya, the yoga for women’s health camp will give you the toolbox to help you create your desired life.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            'Women tend to take their health for granted because they are so focused on their responsibilities. What is needed is active effort to make well-being a priority. -Dr. Hansaji Yogendra',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Program Offerings',
          points: [
            {
              listItem:
                'Wholesome tools and yoga techniques to help you manage your health',
              subItems: [],
            },
            {
              listItem:
                'Specific asana, pranayama, and relaxation techniques to enhance your health',
              subItems: [],
            },
            {
              listItem: 'Yogic Diet guidelines to manage your health',
              subItems: [],
            },
            {
              listItem: 'Insightful yoga session with medical specialist',
              subItems: [],
            },
            {
              listItem:
                'Tips to maintain health by our spiritual guru Institute Director, Dr. Hansaji who has decades of experience in the field',
              subItems: [],
            },
            {
              listItem: 'Fitness tips',
              subItems: [],
            },
          ],
        },
      },
      // {
      //   type: 'u-list',
      //   content: {
      //     title: 'Offered with the program',
      //     points: [
      //       {
      //         listItem: 'Booklet with guidelines  ',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Wholesome Sattvik lunch',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Healthy snacks  ',
      //         subItems: [],
      //       },
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: ['9:30 am – 5:30 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['INR 1000 (Inclusive Lunch and Snacks)'],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Guidelines',
          points: [
            {
              listItem:
                'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute dress code.',
              subItems: [],
            },
            // {
            //   listItem:
            //     'Campers are requested to have their breakfast and medication (as prescribed by their doctor). ',
            //   subItems: [],
            // },
          ],
        },
      },
    ],
  },

  16: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Back & Joint Disorder Yoga Workshop',
    key: 'back-joint-disorder-workshop',
    tenure: '1 day',
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    dates: ['22nd Dec 2024', '26th Jan 2025', '23rd Feb 2025', '23rd Mar 2025', '27th Apr 2025', '25th May 2025',
      '22nd Jun 2025', '27th Jul 2025', '24th Aug 2025', '28th Sep 2025', '26th Oct 2025', '23rd Nov 2025', '28th Dec 2025'
    ],
    mostPopular: false,
    rating: 5,
    colorCode: '#EDBE66',
    timing: '9:30 am – 5:30 pm (IST)',
    fees: {
      onlineFee: 1000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 1000 },
    },
    category: 'camps',
    metaDescription: 'Insightful session with an ortho-specialist',
    image: `${baseDomain}${courseAssets.courseAsset10}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset10}`,
    online: false,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: false,

    courseType: 'Camps & Workshop',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Camps & Workshops',
    newName: 'Back & Joint Disorder Yoga Workshop',
    days: [''],
    time: [
      '9:30 am – 5:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "1 Day",
      timings: [
        {
          day: "Time",
          time: "9:30 am – 5:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this yoga workshop for?',
          text: [
            'Chances are that you have accepted your back and joint pain as part of your life. But it need not be so. There is potential for healing to take place within us if we cooperate with our innate healing powers. At The Yoga Institute, we have been conducting specialized orthopedic camps for over 60 years and more so, our research shows the effectiveness of our methods. We give you potent yogic methods that will have a life-changing effect. Come attend our 1-day yoga camp for back and joint disorders and experience the potential for well-being in your life.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            'You don’t need a teacher, mentor, or guide to heal yourself if you have an attitude to learn from your mistakes and your pain.. -Dr. Hansaji Yogendra',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Program Offerings',
          points: [
            {
              listItem: 'Yogic Diet guidelines to manage your condition and health',
              subItems: [],
            },
            {
              listItem: 'Insightful yoga session with an ortho-specialist',
              subItems: [],
            },
            {
              listItem:
                'Specific pranayama, and relaxation techniques to enhance your health',
              subItems: [],
            },
            {
              listItem:
                'Lifestyle tips by spiritual guru Dr. Hansaji who has decades of experience in the field',
              subItems: [],
            },
            {
              listItem: 'Fitness tips',
              subItems: [],
            },
          ],
        },
      },
      // {
      //   type: 'u-list',
      //   content: {
      //     title: 'Offered with the program',
      //     points: [
      //       {
      //         listItem: 'Booklet with guidelines  ',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Wholesome Sattvik lunch',
      //         subItems: [],
      //       },
      //       {
      //         listItem: 'Healthy snacks  ',
      //         subItems: [],
      //       },
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: ['9:30 am – 5:30 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['INR 1000 (Inclusive Lunch and Snacks)'],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Guidelines',
          points: [
            {
              listItem:
                'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute dress code.',
              subItems: [],
            },
            // {
            //   listItem:
            //     'Campers are requested to have their breakfast and medication (as prescribed by their doctor). ',
            //   subItems: [],
            // },
          ],
        },
      },
    ],
  },
  17: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: '7 Days Health Camp - On Campus - Yoga Camp - Hindi',
    key: '7-days-camp',
    weekDays: true,
    join: true,
    batch: 1,
    // templateId: 'CAMP_FOR_7_DAYS_HEALTH',
    templateId: {
      templateOnline: 'CAMP_FOR_7_DAYS_HEALTH',
      templateOffline: {
        templateResidential: 'CAMP_FOR_7_DAYS_HEALTH',
        templateNonResidential: 'CAMP_FOR_7_DAYS_HEALTH',
      },
    },
    dates: [
      // '13rd Jul to 19th Jul 2024', 
      // '14th Sep to 20th Sep 2024',
      // '9th Nov to 15th Nov 2024',
      '11th Jan to 17th Jan 2025',
      '8th Mar to 14th Mar 2025',
      '10th May to 16th May 2025',
      '12th Jul to 18th Jul 2025',
      '13th Sep to 19th Sep 2025',
      '8th Nov to 14th Nov 2025',

    ],
    online: false,
    onCampus: true,
    nonResidential: true,
    residential: true,
    certficate: false,

    tenure: '7 days',
    courseType: '7 DAYS HEALTH CAMP',
    language: 'Hindi',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: '7 Days Health Camp',
    newName: '7 Days Health Camp',
    days: [
      'Saturday to Friday'
    ],
    time: [
      '7:00 am - 7:00 pm (IST)'
    ],
    enrollInfo: {
      duration: "7 days",
      timings: [
        {
          day: "Saturday to Friday",
          time: "7:00 am - 7:00 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: true,
      residentialMode: true
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    mostPopular: true,
    rating: 5,
    colorCode: '#EDBE66',
    timing: 'Saturday to Friday : 7:00 am - 7:00 pm (IST)',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 16500, nonResidentialFee: 10000 },
    },
    category: 'camps',
    metaDescription:
      'Are you feeling burned out and looking for ways to develop mental strength, improve focus & emotional quotient and reduce stress? Join our 7-Days Yoga Camp reshape your life and health.',
    image: `${baseDomain}${courseAssets.courseAsset35}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset35}`,
    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Overview',
          text: [
            'Are you feeling burned out and looking for ways to develop mental strength, improve focus & emotional quotient and reduce stress? Join our 7-Days Health Camp reshape your life and health.',
            'The 7 Days Health Camp is an experiential, introductory course to Yoga theory and practice where one can get a glimpse of a yogic lifestyle within the premises of The Yoga Institute. In this yoga program you will learn how to achieve a balance between your mind, body, and soul. Through its powerful processes, this 7 days yoga program presents to you the possibility to access and re-configure your life and health in a way that improves productivity and imparts better control on your emotions. In this fast-paced and unwavering world, this 7 Days’ Health Camp offers you the much-needed respite to connect with yourself and experience the abundant joy and beauty in life.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' The programme covers',
          points: [
            {
              listItem: 'Yoga Asanas',
              subItems: [],
            },
            {
              listItem: 'Pranayama',
              subItems: [],
            },
            {
              listItem: 'Kriyas',
              subItems: [],
            },
            {
              listItem: 'Yogic concepts',
              subItems: [],
            },
            {
              listItem: 'Diet',
              subItems: [],
            },
            {
              listItem: 'Games and recreation,',
              subItems: [],
            },
            {
              listItem: 'Attitude training',
              subItems: [],
            },
            {
              listItem: 'Relaxation and meditation.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'It is ideal for anyone who wants to live a yoga way of life and experience peace within. It helps you to incorporate Yogic ideals in every field of your daily life.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Benefits of this yoga camp',
          text: [],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Mind & Soul',
          points: [
            {
              listItem:
                'Helps build upon mental strength to improve focus, clarity, and emotional quotient',
              subItems: [],
            },
            {
              listItem: 'Helps build better mind-body connection',
              subItems: [],
            },
            {
              listItem: 'Helps you reduce stress and anxiety',
              subItems: [],
            },
            {
              listItem: 'Helps you get more in control of your feelings',
              subItems: [],
            },
            {
              listItem:
                'Helps you in developing and maintaining the right routine for your overall health',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Physical',
          points: [
            {
              listItem:
                'Helps you in detoxifying your body of the accumulated toxins',
              subItems: [],
            },
            {
              listItem:
                'Helps you in strengthening immunity and digestive system',
              subItems: [],
            },
            {
              listItem: 'Helps in overall development of your personality',
              subItems: [],
            },
            {
              listItem:
                'Helps you become self-reliant in maintaining to your physical health',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Trained teachers at The Yoga Institute will be conducting this 7 days course.Come and experience, amidst nature and beauty, our rejuvenating 7 Days Health Camp, which has transformed millions of people.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'PROGRAM DETAILS',
          text: [],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'UPCOMING DATES FOR 2022',
      //     text: ['13th Aug 2022to 19th Aug 2022'],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Timings',
          text: [
            'Saturday to Friday : 7:00 am - 7:00 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'REGISTRATION',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'For registration in the 7-Days Yoga Camp, please click on the link below and fill in all the details. Within 24 hours after registering, you will receive an email containing the link to the payment portal. You can use the link to make the payment.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'FEES',
          text: [

            '2024: Non-Residential: 10,000/- (inclusive of course fees and 4 Meals)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Residential',
          text: [

            '2024:',
            'Rs. 16,500/- Triple Sharing per person (Inclusive of course fee and 4 meals a day)',
            // 'Rs. 23,000/- Dual Sharing per person (Inclusive of course fee and 4 meals a day)',
            // 'Rs. 38,000/- Single Occupancy (Inclusive of course fee and 4 meals a day)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            '*Please note fees once paid will be non-refundable. We support all credit cards. For making payments using MasterCard. Alternatively, you can also make payments using net banking or opt for wire transfer by writing to us.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'FAQs  ',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title:
            '1. What is the time to reach the Institute during the course duration?',
          text: [
            'For residential campers, students should arrive at The Yoga Institute between 7.00 a.m. to 7.00 p.m. IST (Indian Standard Time). Please read on Hostel Facility for more details.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title:
            '2. Can a person who has problem with sitting on the floor for a long time enroll for the course?',
          text: [
            'Backrest and chairs are available on request in special and exceptional cases. – Rest and recreation are also a part of the course.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '3. How many seats are available in one course?',
          text: [
            'Only 35 seats are available. Registrations on a first come, first-serve basis only.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'UNIQUE OFFERINGS ',
          text: [''],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Our Amenities / Our Facilities',
          points: [
            {
              listItem:
                'Yoga Library: Explore one of largest collections of spiritual and philosophical books to expand your knowledge',
              subItems: [],
            },
            {
              listItem:
                'Internet Room: Access the internet in a comfortable setting when you need Meditation Pods: Meditate on the five fundamental natural elements, the Panchamahabhutas,',
              subItems: [],
            },
            {
              listItem:
                'Acupressure Path: Give your feet a natural massage and activate your vital energies',
              subItems: [],
            },
            {
              listItem:
                'Yogic Gazebo: Enjoy the soothing sounds of flowing water and take in the peace and at this relaxation area',
              subItems: [],
            },
            {
              listItem:
                'Dining Area: Enjoy wholesome Sattvik meals, specially prepared for your nourishment',
              subItems: [],
            },
            {
              listItem:
                'Yoga Book Store: Shop in a wide selection of TYI publications. Find yourself the best yogic attire and mats.',
              subItems: [],
            },
          ],
        },
      },
    ],
  },
  18: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: '7 Days Health Camp - On Campus - Yoga Camp - English',
    key: '7-days-camp-english',
    join: true,
    batch: 1,
    // templateId: 'CAMP_FOR_7_DAYS_HEALTH',
    templateId: {
      templateOnline: 'CAMP_FOR_7_DAYS_HEALTH',
      templateOffline: {
        templateResidential: 'CAMP_FOR_7_DAYS_HEALTH',
        templateNonResidential: 'CAMP_FOR_7_DAYS_HEALTH',
      },
    },
    dates: [

      // '23rd Mar to 29th Mar 2024',
      // '27th Apr to 3rd May 2024',
      // '25th May to 31st May 2024',
      // '22nd Jun to 28th Jun 2024',
      // '27th Jul to 2nd Aug 2024',
      // '24th Aug to 30th Aug 2024',
      // '28th Sep to 4th Oct 2024',
      // '26th Oct to 1st Nov 2024',
      // '23rd Nov to 29th Nov 2024',
      '28th Dec to 3rd Jan 2025',
      '25th Jan to 31st Jan 2025',
      '22nd Feb to 28th Feb 2025',
      '22nd Mar to 28th Mar 2025',
      '26th Apr to 2nd May 2025',
      '24th May to 30th May 2025',
      '28th Jun to 4th Jul 2025',
      '26th Jul to 1st Aug 2025',
      '23rd Aug to 29th Aug 2025',
      '27th Sep to 3rd Oct 2025',
      '25th Oct to 31st Oct 2025',
      '22nd Nov to 28th Nov 2025',
      '27th Dec to 2nd Jan 2026'
    ],
    online: false,
    onCampus: true,
    nonResidential: true,
    residential: true,
    certficate: false,

    tenure: '7 days',
    courseType: '7 DAYS HEALTH CAMP',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: '7 Days Health Camp',
    newName: '7 Days Health Camp',
    days: [
      'Saturday to Friday'
    ],
    time: [
      '7:00 am - 7:00 pm (IST)'
    ],
    enrollInfo: {
      duration: "7 days",
      timings: [
        {
          day: "Saturday to Friday",
          time: "7:00 am - 7:00 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: true,
      residentialMode: true
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },


    mostPopular: true,
    rating: 5,
    colorCode: '#EDBE66',
    timing: 'Saturday to Friday : 7:00 am - 7:00 pm (IST)',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 16500, nonResidentialFee: 10000 },
    },
    category: 'camps',
    metaDescription:
      'Are you feeling burned out and looking for ways to develop mental strength, improve focus & emotional quotient and reduce stress? Join our 7-Days Yoga Camp reshape your life and health.',
    image: `${baseDomain}${courseAssets.courseAsset34}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset34}`,
    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Overview',
          text: [
            'Are you feeling burned out and looking for ways to develop mental strength, improve focus emotional quotient and reduce stress? Join our 7-Days Health Camp reshape your life and health.',
            'The 7 Days Health Camp is an experiential, introductory course to Yoga theory and practice where one can get a glimpse of a yogic lifestyle within the premises of The Yoga Institute. In this yoga health-camp you will learn how to achieve a balance between your mind, body, and soul. Through its powerful processes, this yoga program presents to you the possibility to access and re-configure your life and health in a way that improves productivity and imparts better control on your emotions. In this fast-paced and unwavering world, this 7 Days’ Yoga Health Camp offers you the much-needed respite to connect with yourself and experience the abundant joy and beauty in life.'
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' The programme covers',
          points: [
            {
              listItem: 'Yoga Asanas',
              subItems: [],
            },
            {
              listItem: 'Pranayama',
              subItems: [],
            },
            {
              listItem: 'Kriya Yoga',
              subItems: [],
            },
            {
              listItem: 'Yogic concepts',
              subItems: [],
            },
            {
              listItem: 'Diet',
              subItems: [],
            },
            {
              listItem: 'Games and recreation',
              subItems: [],
            },
            {
              listItem: 'Attitude training',
              subItems: [],
            },
            {
              listItem: 'Relaxation and meditation.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'It is ideal for anyone who wants to live a yoga way of life and experience peace within. It helps you to incorporate Yogic ideals in every field of your daily life.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Benefits of this yoga camp',
          text: [],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Mind & Soul',
          points: [
            {
              listItem:
                'Helps build upon mental strength to improve focus, clarity, and emotional quotient',
              subItems: [],
            },
            {
              listItem: 'Helps build better mind-body connection',
              subItems: [],
            },
            {
              listItem: 'Helps you reduce stress and anxiety',
              subItems: [],
            },
            {
              listItem: 'Helps you get more in control of your feelings',
              subItems: [],
            },
            {
              listItem:
                'Helps you in developing and maintaining the right routine for your overall health',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Physical',
          points: [
            {
              listItem:
                'Helps you in detoxifying your body of the accumulated toxins',
              subItems: [],
            },
            {
              listItem:
                'Helps you in strengthening immunity and digestive system',
              subItems: [],
            },
            {
              listItem: 'Helps in overall development of your personality',
              subItems: [],
            },
            {
              listItem:
                'Helps you become self-reliant in maintaining to your physical health',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Trained yoga teachers at The Yoga Institute will be conducting this yoga course.Come and experience, amidst nature and beauty, our rejuvenating 7 Days Health Camp, which has transformed millions of people.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'PROGRAM DETAILS',
          text: [],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'UPCOMING DATES FOR 2022',
      //     text: ['13th Aug 2022to 19th Aug 2022'],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Timings',
          text: [
            'Saturday to Friday : 7:00 am - 7:00 pm (IST)'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'REGISTRATION',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'For registration in the 7-Days Yoga Camp, Within 24 hours after registering, you will receive an email containing the link to the payment portal. You can use the link to make the payment.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'FEES',
          text: [
            '2024: Non-Residential: 10,000/- (inclusive of course fees and 4 Meals)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Residential',
          text: [

            '2024:',
            'Rs. 16,500/- Triple Sharing per person (Inclusive of course fee and 4 meals a day)',
            // 'Rs. 23,000/- Dual Sharing per person (Inclusive of course fee and 4 meals a day)',
            // 'Rs. 38,000/- Single Occupancy (Inclusive of course fee and 4 meals a day)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            '*Please note fees once paid will be non-refundable. We support all credit cards. For making payments using MasterCard. Alternatively, you can also make payments using net banking or opt for wire transfer by writing to us.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'FAQs  ',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title:
            '1. What is the time to reach the Institute during the course duration?',
          text: [
            'For residential campers, students should arrive at The Yoga Institute between 7.00 a.m. to 7.00 p.m. IST (Indian Standard Time). Please read on Hostel Facility for more details.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title:
            '2. Can a person who has problem with sitting on the floor for a long time enroll for the course?',
          text: [
            'Backrest and chairs are available on request in special and exceptional cases. – Rest and recreation are also a part of the course.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '3. How many seats are available in one course?',
          text: [
            'Only 35 seats are available. Registrations on a first come, first-serve basis only.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'UNIQUE OFFERINGS ',
          text: [''],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Our Amenities / Our Facilities',
          points: [
            {
              listItem:
                'Yoga Library: Explore one of largest collections of spiritual and philosophical books to expand your knowledge',
              subItems: [],
            },
            {
              listItem:
                'Internet Room: Access the internet in a comfortable setting when you need Meditation Pods: Meditate on the five fundamental natural elements, the Panchamahabhutas,',
              subItems: [],
            },
            {
              listItem:
                'Acupressure Path: Give your feet a natural massage and activate your vital energies',
              subItems: [],
            },
            {
              listItem:
                'Yogic Gazebo: Enjoy the soothing sounds of flowing water and take in the peace and at this relaxation area',
              subItems: [],
            },
            {
              listItem:
                'Dining Area: Enjoy wholesome Sattvik meals, specially prepared for your nourishment',
              subItems: [],
            },
            {
              listItem:
                'Yoga Book Store: Shop in a wide selection of TYI publications. Find yourself the best yogic attire and mats.',
              subItems: [],
            },
          ],
        },
      },
    ],
  },
  19: {
    id: '',
    textdescription6: 'Please Note: Both morning and evening batches are required to participate in Satsang, followed by their classes, on all Sundays during their 21-day course duration. Class timings are from 8:30 am to 9:30 am, followed by Satsang from 9:30 am to 10:30 am.',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    textdescription10: '',
    weekDays: true,
    title: '21-Days Better Living Yoga Course - Morning Online and On Campus – English - Batch 1',
    key: '21-days-better-living-course',
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: 'GENERAL_AUTO_EMAIL',
      },
    },
    batch: 1,
    dates: [

      // '3rd Mar to 23rd Mar 2024',
      // '7th Apr to 27th Apr 2024',
      // '5th May to 25th May 2024',
      // '2nd Jun to 22nd Jun 2024',
      // '7th Jul to 27th Jul 2024',
      // '4th Aug to 24th Aug 2024',
      // '1st Sep to 21st Sep 2024',
      // '6th Oct to 26th Oct 2024',
      // '3rd Nov to 23rd Nov 2024',
      '1st Dec to 21st Dec 2024',
      '5th Jan to 25th Jan 2025',
      '2nd Feb to 22nd Feb 2025',
      '2nd Mar to 22nd Mar 2025',
      '6th Apr to 26th Apr 2025',
      '4th May to 24th May 2025',
      '1st Jun to 21st Jun 2025',
      '6th Jul to 26th Jul 2025',
      '3rd Aug to 23rd Aug 2025',
      '7th Sep to 27th Sep 2025',
      '5th Oct to 25th Oct 2025',
      '2nd Nov to 22nd Nov 2025',
      '7th Dec to 27th Dec 2025'
    ],
    online: true,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,

    tenure: '21 days',
    courseType: '21-Days Better living Course',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: '21 Days Better Living',
    newName: '21 Days Better Living Course Batch 1',
    days: [''],
    time: [
      'Morning: 7:00 am – 8:30 am (IST)'
    ],
    enrollInfo: {
      duration: "21 days",
      timings: [
        {
          day: "",
          time: "Morning: 7:00 am – 8:30 am (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'certificate Of Participation',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },


    // tenure: '',
    // courseType: '21-Days Better living Course',
    // language: 'English',
    // preRequisite: 'None',
    // courseCategory: 'Self Help', 
    // courseSubType: 'Yoga Course',

    // courseCertificate: 'certificate Of Participation',
    // onlineInfo: {
    //   courseMode: false,
    // },
    // residentialInfo: {
    //   courseMode: false,
    //   residentialMode: false
    // },
    // nonResidentialInfo: {
    //   courseMode: true,
    //   nonResidentialMode: true
    // },

    mostPopular: true,
    rating: 5,
    colorCode: '#EDBE66',
    timing: 'Morning: 7:00 am – 8:30 am (IST)',
    fees: {
      onlineFee: 2500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 2500 },
    },
    category: 'camps',
    metaDescription: 'Become successful, healthy, stress-free, and more mindful in your relationships by learning the yogic way of living. Join The Yoga Institute’s 21-Days Better Living Course. ',
    image: `${baseDomain}${courseAssets.courseAsset34}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset34}`,
    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Overview',
          text: [
            'Become successful, healthy, stress-free, and more mindful in your relationships by learning the yogic way of living. Join The Yoga Institute’s 21-Days Better Living Yoga Course.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'It takes 21 days to build a habit into your daily routine. The Yoga Institute’s 21 Days Better Living Course is a course specifically designed to introduce you to the yogic way of living. begin your journey into the discovery of Yoga. Working professionals and house makers can take just one hour from their day to practice and learn yoga and begin their transformational journey.',
            'This is a yoga certificate course and has been successfully conducted for 50 years by the Institute. The program trains you holistically and enables you to achieve equilibrium between your mind and body and thus achieve a state of peace and happiness in life. The course is conducted by trained teachers at the Institute.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' The programme covers an introduction to: ',
          points: [
            {
              listItem: 'Astanga Yoga',
              subItems: [],
            },
            {
              listItem: 'Yoga Asanas',
              subItems: [],
            },
            {
              listItem: 'Pranayama',
              subItems: [],
            },
            {
              listItem: 'Kriyas',
              subItems: [],
            },
            {
              listItem: 'Attitude training  ',
              subItems: [],
            },
            {
              listItem: 'Parisamvad (sessions with Dr. Hansaji)',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'It is ideal for anyone who wants to live a yoga way of life and experience peace within. It helps you to incorporate Yogic ideals in every field of your daily life.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Benefits of this camp: ',
          points: [
            {
              listItem:
                'Helps you in developing and maintaining the right routine for your overall health',
              subItems: [],
            },

            {
              listItem: 'Helps you reduce stress and anxiety',
              subItems: [],
            },
            {
              listItem: 'Helps you feel energetic throughout the day',
              subItems: [],
            },
            {
              listItem: 'Helps you become mindful in your relationships',
              subItems: [],
            },
            {
              listItem: 'Helps you get more in control of your feelings',
              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Come and experience, amidst nature and beauty, our enriching 21 Days Better Living yoga Course.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'PROGRAM DETAILS',
          text: [
            'The yoga certificate course is held once a month, starting on the first Sunday (7:00 a.m.) of every month. The classes are of 1 hr. 30 mins each day for 21 consecutive days. Participants can opt for the morning or evening batches.',
          ],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'UPCOMING DATES FOR 2022',
      //     text: [],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Timings',
          text: ['Morning 7:00 am – 8:30 am (IST)', 'Please Note: Both morning and evening batches are required to participate in Satsang, followed by their classes, on all Sundays during their 21-day course duration. Class timings are from 8:30 am to 9:30 am, followed by Satsang from 9:30 am to 10:30 am.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'REGISTRATION',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'For registration in the 21 Days Better Living Yoga Course, please click on the link below and fill in all the details. Within 24 hours after registering, you will receive an email containing the link to the payment portal. You can use the link to make the payment.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'FEES',
          text: [

            '2024: Rs. 2500/-'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          // title: '',
          text: [
            '*Please note fees once paid will be non-refundable. Also, after making the payment, please provide us with a screenshot of the transaction.',
            'We support all credit cards. For making payments using MasterCard. Alternatively, you can also make payments using net banking or opt for wire transfer by writing to us.',
          ],
        },
      },

      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'PRE-REQUISITES',
      //     text: [],
      //   },
      // },
      // {
      //   type: 'u-list',
      //   content: {
      //     title: ' A MANDATORY PREREQUISITE FOR THIS COURSE IS:',
      //     points: [
      //       {
      //         listItem:
      //           'Covid test RT-PCR results dated 20th Jan 2022to 28th Jan 2022and later for entry into the premises post prior registration. ',
      //         subItems: [],
      //       },
      //       {
      //         listItem:
      //           'Students must use personal vehicles for commuting, throughout the course duration. We would discourage the use of public transport. (For non-residential students)',
      //         subItems: [],
      //       },
      //       {
      //         listItem:
      //           'This course is for healthy individuals. Kindly note if you suffer from any health issues like diabetes, hypertension, or orthopaedic problems, kindly take prior approval.',
      //         subItems: [],
      //       },
      //       {
      //         listItem:
      //           'For international Students, it is mandatory to do 14 Day Quarantine as per the laws of Government of India along with RT-PCR test reports. ',
      //         subItems: [],
      //       },
      //       {
      //         listItem:
      //           'Last but not the least, do not forget your masks and Yoga Mats',
      //         subItems: [],
      //       },
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'COURSE CERTIFICATION',
          text: [
            'To be eligible for a certificate, a minimum of 18 days attendance is compulsory.  If, for any reason, you were unable to attend 2 or 3 sessions and would like to make up for these in the upcoming batch, you will be required to re-enroll for the course and complete the payment process again.',
            'Online students must activate their video cameras for attendance to be recorded.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'FAQs  ',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title:
            '1. What is the time to reach the Institute during the course duration?',
          text: [
            'For residential campers, students should arrive at The Yoga Institute between 7.00 a.m. to 7.00 p.m. IST (Indian Standard Time). Please read on Hostel Facility for more details.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title:
            '2. Can a person who has problem with sitting on the floor for a long time enroll for the yoga course?',
          text: [
            'Backrest and chairs are available on request in special and exceptional cases. – Rest and recreation are also a part of the course.',
          ],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: '3. How many seats are available in one course?',
      //     text: [
      //       'Only 35 seats are available. Registrations on a first come, first-serve basis only',
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Please Note',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'The payment validity and session attendance for 21-Days Better Living Course is only applicable for one month.',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'UNIQUE OFFERINGS ',
          text: [''],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Our Amenities / Our Facilities',
          points: [
            {
              listItem:
                'Yoga Library: Explore one of largest collections of spiritual and philosophical books to expand your knowledge',
              subItems: [],
            },
            {
              listItem:
                'Internet Room: Access the internet in a comfortable setting when you need Meditation Pods: Meditate on the five fundamental natural elements, the Panchamahabhutas,',
              subItems: [],
            },
            {
              listItem:
                'Acupressure Path: Give your feet a natural massage and activate your vital energies',
              subItems: [],
            },
            {
              listItem:
                'Yogic Gazebo: Enjoy the soothing sounds of flowing water and take in the peace and at this relaxation area',
              subItems: [],
            },
            {
              listItem:
                'Dining Area: Enjoy wholesome Sattvik meals, specially prepared for your nourishment',
              subItems: [],
            },
            {
              listItem:
                'Yoga Book Store: Shop in a wide selection of TYI publications. Find yourself the best yogic attire and mats.',
              subItems: [],
            },
          ],
        },
      },
    ],
  },
  20: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    weekDays: true,
    title: '21-Days Better Living Yoga Course - Evening - Online & On Campus – English - Batch 2',
    key: '21-days-better-living-course-batch-2',
    batch: 2,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: 'GENERAL_AUTO_EMAIL',
      },
    },
    dates: [
      // '3rd Mar to 23rd Mar 2024',
      // '7th Apr to 27th Apr 2024',
      // '5th May to 25th May 2024',
      // '2nd Jun to 22nd Jun 2024',
      // '7th Jul to 27th Jul 2024',
      // '4th Aug to 24th Aug 2024',
      // '1st Sep to 21st Sep 2024',
      // '6th Oct to 26th Oct 2024',
      // '3rd Nov to 23rd Nov 2024',
      '1st Dec to 21st Dec 2024',
      '5th Jan to 25th Jan 2025',
      '2nd Feb to 22nd Feb 2025',
      '2nd Mar to 22nd Mar 2025',
      '6th Apr to 26th Apr 2025',
      '4th May to 24th May 2025',
      '1st Jun to 21st Jun 2025',
      '6th Jul to 26th Jul 2025',
      '3rd Aug to 23rd Aug 2025',
      '7th Sep to 27th Sep 2025',
      '5th Oct to 25th Oct 2025',
      '2nd Nov to 22nd Nov 2025',
      '7th Dec to 27th Dec 2025'
    ],
    online: true,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,

    tenure: '21 Days',
    courseType: '21-Days Better living Course',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: '21 Days Better Living',
    newName: '21 Days Better Living Course Batch 2',
    days: [''],
    time: ['Evening 6:30 pm – 8:00 pm (IST)'],
    enrollInfo: {
      duration: "21 Days",
      timings: [
        {
          day: "",
          time: "Evening 6:30 pm – 8:00 pm (IST)"
        }
      ]
    },

    courseCertificate: 'certificate Of Participation',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    mostPopular: true,

    rating: 5,
    colorCode: '#EDBE66',
    timing: 'Evening 6:30 pm – 8:00 pm (IST)',
    fees: {
      onlineFee: 2500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 2500 },
    },
    category: 'camps',
    metaDescription:
      `Become successful, healthy, stress-free, and more mindful in your relationships by learning the yogic way of living. Join The Yoga Institute’s 21-Days Better Living Yoga Course.¿
      Please Note: Both morning and evening batches are required to participate in Satsang, followed by their classes, on all Sundays during their 21-day course duration. Class timings are from 8:30 am to 9:30 am, followed by Satsang from 9:30 am to 10:30 am.`,
    image: `${baseDomain}${courseAssets.courseAsset35}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset35}`,
    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Overview',
          text: [
            'Become successful, healthy, stress-free, and more mindful in your relationships by learning the yogic way of living. Join The Yoga Institute’s 21-Days Better Living Yoga Course.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'It takes 21 days to build a habit into your daily routine. The Yoga Institute’s 21 Days Better Living Course is a course specifically designed to introduce you to the yogic way of living. begin your journey into the discovery of Yoga. Working professionals and house makers can take just one hour from their day to practice and learn yoga and begin their transformational journey.',
            'This is a yoga certificate course and has been successfully conducted for 50 years by the Institute. The program trains you holistically and enables you to achieve equilibrium between your mind and body and thus achieve a state of peace and happiness in life. The course is conducted by trained teachers at the Institute.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'The programme covers an introduction to:',
          points: [
            {
              listItem: 'Astanga Yoga',
              subItems: [],
            },
            {
              listItem: 'Yoga Asanas',
              subItems: [],
            },
            {
              listItem: 'Pranayama',
              subItems: [],
            },
            {
              listItem: 'Kriyas',
              subItems: [],
            },
            {
              listItem: 'Attitude training  ',
              subItems: [],
            },
            {
              listItem: 'Parisamvad (sessions with Dr. Hansaji)',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'It is ideal for anyone who wants to live a yoga way of life and experience peace within. It helps you to incorporate Yogic ideals in every field of your daily life.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Benefits of this camp: ',
          points: [
            {
              listItem:
                'Helps you in developing and maintaining the right routine for your overall health',
              subItems: [],
            },

            {
              listItem: 'Helps you reduce stress and anxiety',
              subItems: [],
            },
            {
              listItem: 'Helps you feel energetic throughout the day',
              subItems: [],
            },
            {
              listItem: 'Helps you become mindful in your relationships',
              subItems: [],
            },
            {
              listItem: 'Helps you get more in control of your feelings',
              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Come and experience, amidst nature and beauty, our enriching 21 Days Better Living yoga Course.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'PROGRAM DETAILS',
          text: [
            'The yoga certificate course is held once a month, starting on the first Sunday (7:00 a.m.) of every month. The classes are of 1 hr. 30 mins each day for 21 consecutive days. Participants can opt for the morning or evening batches.',
          ],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'UPCOMING DATES FOR 2022',
      //     text: ['7th Aug 2022to 27th Aug 2022'],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Timings',
          text: ['Evening 6:30 pm – 8:00 pm (IST)', 'Please Note: Both morning and evening batches are required to participate in Satsang, followed by their classes, on all Sundays during their 21-day course duration. Class timings are from 8:30 am to 9:30 am, followed by Satsang from 9:30 am to 10:30 am.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'REGISTRATION',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'For registration in the 21 Days Better Living Yoga Course, please click on the link below and fill in all the details. Within 24 hours after registering, you will receive an email containing the link to the payment portal. You can use the link to make the payment.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'FEES',
          text: [

            '2024: Rs. 2500/-'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          // title: 'Rs. 2500/-',
          text: [
            '*Please note fees once paid will be non-refundable. Also, after making the payment, please provide us with a screenshot of the transaction.',
            'We support all credit cards. For making payments using MasterCard. Alternatively, you can also make payments using net banking or opt for wire transfer by writing to us.',
          ],
        },
      },

      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'PRE-REQUISITES',
      //     text: [],
      //   },
      // },
      // {
      //   type: 'u-list',
      //   content: {
      //     title: ' A MANDATORY PREREQUISITE FOR THIS COURSE IS:',
      //     points: [
      //       {
      //         listItem:
      //           'Covid test RT-PCR results dated 20th Jan 2022to 28th Jan 2022and later for entry into the premises post prior registration. ',
      //         subItems: [],
      //       },
      //       {
      //         listItem:
      //           'Students must use personal vehicles for commuting, throughout the course duration. We would discourage the use of public transport. (For non-residential students)',
      //         subItems: [],
      //       },
      //       {
      //         listItem:
      //           'This course is for healthy individuals. Kindly note if you suffer from any health issues like diabetes, hypertension, or orthopaedic problems, kindly take prior approval.',
      //         subItems: [],
      //       },
      //       {
      //         listItem:
      //           'For international Students, it is mandatory to do 14 Day Quarantine as per the laws of Government of India along with RT-PCR test reports. ',
      //         subItems: [],
      //       },
      //       {
      //         listItem:
      //           'Last but not the least, do not forget your masks and Yoga Mats',
      //         subItems: [],
      //       },
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'COURSE CERTIFICATION',
          text: [
            'To be eligible for a certificate, a minimum of 18 days attendance is compulsory.  If, for any reason, you were unable to attend 2 or 3 sessions and would like to make up for these in the upcoming batch, you will be required to re-enroll for the course and complete the payment process again.',
            'Online students must activate their video cameras for attendance to be recorded.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'FAQs  ',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title:
            '1. What is the time to reach the Institute during the course duration?',
          text: [
            'For residential campers, students should arrive at The Yoga Institute between 7.00 a.m. to 7.00 p.m. IST (Indian Standard Time). Please read on Hostel Facility for more details.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title:
            '2. Can a person who has problem with sitting on the floor for a long time enroll for the course?',
          text: [
            'Backrest and chairs are available on request in special and exceptional cases. – Rest and recreation are also a part of the course.',
          ],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: '3. How many seats are available in one course?',
      //     text: [
      //       'Only 35 seats are available. Registrations on a first come, first-serve basis only.',
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Please Note',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'The payment validity and session attendance for 21-Days Better Living Course is only applicable for one month. However, in emergency circumstances, the participant can attend the 21-Days BLC sessions for the next month but only on approval of the management. Without authorisation, the participant will not be allowed to attend the sessions post the actual sessions of the course.',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'UNIQUE OFFERINGS ',
          text: [''],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Our Amenities / Our Facilities',
          points: [
            {
              listItem:
                'Yoga Library: Explore one of largest collections of spiritual and philosophical books to expand your knowledge',
              subItems: [],
            },
            {
              listItem:
                'Internet Room: Access the internet in a comfortable setting when you need Meditation Pods: Meditate on the five fundamental natural elements, the Panchamahabhutas,',
              subItems: [],
            },
            {
              listItem:
                'Acupressure Path: Give your feet a natural massage and activate your vital energies',
              subItems: [],
            },
            {
              listItem:
                'Yogic Gazebo: Enjoy the soothing sounds of flowing water and take in the peace and at this relaxation area',
              subItems: [],
            },
            {
              listItem:
                'Dining Area: Enjoy wholesome Sattvik meals, specially prepared for your nourishment',
              subItems: [],
            },
            {
              listItem:
                'Yoga Book Store: Shop in a wide selection of TYI publications. Find yourself the best yogic attire and mats.',
              subItems: [],
            },
          ],
        },
      },
    ],
  },
  21: {
    id: '',
    textdescription6: 'Please Note: Both morning and evening batches are required to participate in Satsang, followed by their classes, on all Sundays during their 21-day course duration. Class timings are from 8:30 am to 9:30 am, followed by Satsang from 9:30 am to 10:30 am.',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: '21-Days Better Living Yoga Course - Evening - Online & On Campus – Hindi - Batch 3',
    key: '21-days-better-living-course-batch-3',
    batch: 3,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: 'GENERAL_AUTO_EMAIL',
      },
    },
    dates: [

      // '10th Mar to 30th Mar 2024',
      // '14th Jul to 3rd Aug 2024',
      // '11th Aug to 31st Aug 2024',
      // '8th Sep to 28th Sep 2024',
      // '13th Oct to 2nd Nov 2024',
      // '10th Nov to 30th Nov 2024',
      '8th Dec to 28th Dec 2024',
      '9th Feb to 1st Mar 2025',
      '13th Apr to 3rd May 2025',
      '8th Jun to 28th Jun 2025',
      '10th Aug to 30th Aug 2025',
      '12th Oct to 1st Nov 2025',
      '14th Dec to 3rd Jan 2026'

    ],
    online: true,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,

    tenure: '21 days',
    courseType: '21-Days Better living Course',
    language: 'Hindi',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: '21 Days Better Living',
    newName: '21 Days Better Living Course Batch 3',
    days: [''],
    time: [
      'Evening 6:30 pm – 8:00 pm (IST)'
    ],
    enrollInfo: {
      duration: "21 days",
      timings: [
        {
          day: "",
          time: "Evening 6:30 pm – 8:00 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'certificate Of Participation',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    mostPopular: true,
    rating: 5,
    colorCode: '#EDBE66',
    timing: 'Evening 6:30 pm – 8:00 pm (IST)',
    fees: {
      onlineFee: 2500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 2500 },
    },
    category: 'camps',
    metaDescription: 'Become successful, healthy, stress-free, and more mindful in your relationships by learning the yogic way of living. Join The Yoga Institute"s 21-Days Better Living Course. ',
    image: `${baseDomain}${courseAssets.courseAsset34}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset34}`,
    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Overview',
          text: [
            'Become successful, healthy, stress-free, and more mindful in your relationships by learning the yogic way of living. Join The Yoga Institute’s 21-Days Better Living Yoga Course.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'It takes 21 days to build a habit into your daily routine. The Yoga Institute’s 21 Days Better Living Course is a course specifically designed to introduce you to the yogic way of living. begin your journey into the discovery of Yoga. Working professionals and house makers can take just one hour from their day to practice and learn yoga and begin their transformational journey.',
            'This is a yoga certificate course and has been successfully conducted for 50 years by the Institute. The program trains you holistically and enables you to achieve equilibrium between your mind and body and thus achieve a state of peace and happiness in life. The course is conducted by trained teachers at the Institute.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' The programme covers an introduction to: ',
          points: [
            {
              listItem: 'Astanga Yoga',
              subItems: [],
            },
            {
              listItem: 'Yoga Asanas',
              subItems: [],
            },
            {
              listItem: 'Pranayama',
              subItems: [],
            },
            {
              listItem: 'Kriyas',
              subItems: [],
            },
            {
              listItem: 'Attitude training  ',
              subItems: [],
            },
            {
              listItem: 'Parisamvad (sessions with Dr. Hansaji)',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'It is ideal for anyone who wants to live a yoga way of life and experience peace within. It helps you to incorporate Yogic ideals in every field of your daily life.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Benefits of this camp: ',
          points: [
            {
              listItem:
                'Helps you in developing and maintaining the right routine for your overall health',
              subItems: [],
            },

            {
              listItem: 'Helps you reduce stress and anxiety',
              subItems: [],
            },
            {
              listItem: 'Helps you feel energetic throughout the day',
              subItems: [],
            },
            {
              listItem: 'Helps you become mindful in your relationships',
              subItems: [],
            },
            {
              listItem: 'Helps you get more in control of your feelings',
              subItems: [],
            },
          ],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Come and experience, amidst nature and beauty, our enriching 21 Days Better Living yoga Course.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'PROGRAM DETAILS',
          text: [
            'The yoga course is held once a month, starting on the first Sunday (7:00 a.m.) of every month. The classes are of 1 hr. 30 mins each day for 21 consecutive days. Participants can opt for the morning or evening batches.',
          ],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'UPCOMING DATES FOR 2022',
      //     text: [],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Timings',
          text: ['Evening 6:30 pm – 8:00 pm (IST) ( Yoga in Hindi)', 'Please Note: Both morning and evening batches are required to participate in Satsang, followed by their classes, on all Sundays during their 21-day course duration. Class timings are from 8:30 am to 9:30 am, followed by Satsang from 9:30 am to 10:30 am.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'REGISTRATION',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'For registration in the 21 Days Better Living Course, please click on the link below and fill in all the details. Within 24 hours after registering, you will receive an email containing the link to the payment portal. You can use the link to make the payment.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'FEES',
          text: [

            '2024: Rs. 2500/-'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          // title: 'Rs. 2500/-',
          text: [
            '*Please note fees once paid will be non-refundable. Also, after making the payment, please provide us with a screenshot of the transaction.',
            'We support all credit cards. For making payments using MasterCard. Alternatively, you can also make payments using net banking or opt for wire transfer by writing to us.',
          ],
        },
      },

      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'PRE-REQUISITES',
      //     text: [],
      //   },
      // },
      // {
      //   type: 'u-list',
      //   content: {
      //     title: ' A MANDATORY PREREQUISITE FOR THIS COURSE IS:',
      //     points: [
      //       {
      //         listItem:
      //           'Covid test RT-PCR results dated 20th Jan 2022to 28th Jan 2022and later for entry into the premises post prior registration. ',
      //         subItems: [],
      //       },
      //       {
      //         listItem:
      //           'Students must use personal vehicles for commuting, throughout the course duration. We would discourage the use of public transport. (For non-residential students)',
      //         subItems: [],
      //       },
      //       {
      //         listItem:
      //           'This course is for healthy individuals. Kindly note if you suffer from any health issues like diabetes, hypertension, or orthopaedic problems, kindly take prior approval.',
      //         subItems: [],
      //       },
      //       {
      //         listItem:
      //           'For international Students, it is mandatory to do 14 Day Quarantine as per the laws of Government of India along with RT-PCR test reports. ',
      //         subItems: [],
      //       },
      //       {
      //         listItem:
      //           'Last but not the least, do not forget your masks and Yoga Mats',
      //         subItems: [],
      //       },
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'COURSE CERTIFICATION',
          text: [
            'To be eligible for a certificate, a minimum of 18 days attendance is compulsory.  If, for any reason, you were unable to attend 2 or 3 sessions and would like to make up for these in the upcoming batch, you will be required to re-enroll for the course and complete the payment process again.',
            'Online students must activate their video cameras for attendance to be recorded.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'FAQs  ',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title:
            '1. What is the time to reach the Institute during the course duration?',
          text: [
            'For residential campers, students should arrive at The Yoga Institute between 7.00 a.m. to 7.00 p.m. IST (Indian Standard Time). Please read on Hostel Facility for more details.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title:
            '2. Can a person who has problem with sitting on the floor for a long time enroll for the course?',
          text: [
            'Backrest and chairs are available on request in special and exceptional cases. – Rest and recreation are also a part of the course.',
          ],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: '3. How many seats are available in one course?',
      //     text: [
      //       'Only 35 seats are available. Registrations on a first come, first-serve basis only.',
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Please Note',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'The payment validity and session attendance for 21-Days Better Living Course is only applicable for one month. However, in emergency circumstances, the participant can attend the 21-Days BLC sessions for the next month but only on approval of the management. Without authorisation, the participant will not be allowed to attend the sessions post the actual sessions of the course.',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'UNIQUE OFFERINGS ',
          text: [''],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Our Amenities / Our Facilities',
          points: [
            {
              listItem:
                'Yoga Library: Explore one of largest collections of spiritual and philosophical books to expand your knowledge',
              subItems: [],
            },
            {
              listItem:
                'Internet Room: Access the internet in a comfortable setting when you need Meditation Pods: Meditate on the five fundamental natural elements, the Panchamahabhutas,',
              subItems: [],
            },
            {
              listItem:
                'Acupressure Path: Give your feet a natural massage and activate your vital energies',
              subItems: [],
            },
            {
              listItem:
                'Yogic Gazebo: Enjoy the soothing sounds of flowing water and take in the peace and at this relaxation area',
              subItems: [],
            },
            {
              listItem:
                'Dining Area: Enjoy wholesome Sattvik meals, specially prepared for your nourishment',
              subItems: [],
            },
            {
              listItem:
                'Yoga Book Store: Shop in a wide selection of TYI publications. Find yourself the best yogic attire and mats.',
              subItems: [],
            },
          ],
        },
      },
    ],
  },

  // new courses 
  22: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Deep Sleep Workshop',
    key: 'deep-sleep-workshop',
    batch: 3,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: 'GENERAL_AUTO_EMAIL',
      },
    },
    dates: [
      '11th Jan 2025', '8th Mar 2025', '10th May 2025', '12th Jul 2025', '13th Sep 2025',
      '8th Nov 2025'

    ],
    online: false,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,

    tenure: '1 day',
    courseType: 'Deep Sleep Workshop',
    language: 'Hindi',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Deep Sleep Workshop',
    newName: 'Deep Sleep Workshop',
    days: [''],
    time: [
      '9:30 am – 1:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "1 day",
      timings: [
        {
          day: "",
          time: "9:30 am – 1:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'certificate Of Participation',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    mostPopular: false,
    rating: 5,
    colorCode: '#EDBE66',
    timing: '9:30 am – 1:30 pm (IST)',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 0, nonResidentialFee: 500 },
    },
    category: 'camps',
    metaDescription: 'Discover the art of deep relaxation and rejuvenation. In this workshop, learn proven techniques to unwind, de-stress, and achieve restorative sleep for a healthier, more peaceful life. Transform your nights and energize your days!',
    image: `${baseDomain}${courseAssets.courseAsset34}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset34}`,
    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this Yoga Workshop For?',
          text: [
            'This workshop is ideal for anyone struggling with restless nights, stress, or fatigue. Whether you’re a busy professional, a parent, or simply looking to enhance your sleep quality, this session offers practical yoga-based techniques to help you relax, rejuvenate, and enjoy truly restorative sleep.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            'Experience deep relaxation as you learn simple yet effective techniques to enhance your sleep quality. This workshop will equip you with tools to unwind, reduce stress, and establish a restful sleep routine, leaving you feeling rejuvenated and energized',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Program Offerings',
          points: [
            {
              listItem: 'Gentle yoga stretches to relax the body and calm the mind',
              subItems: [],
            },
            {
              listItem: 'Breathing techniques to reduce stress and promote relaxation',
              subItems: [],
            },
            {
              listItem: 'Guided meditations for deep mental and emotional rejuvenation',
              subItems: [],
            },
            {
              listItem: 'Tips and practices for improving sleep quality naturally',
              subItems: [],
            },
            {
              listItem: 'Expert guidance on creating a bedtime routine for restorative sleep',
              subItems: [],
            }
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: [
            '9:30 am – 1:30 pm (IST)',
          ],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [
            'INR 500 (Inclusive Lunch)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Guidelines',
          text: [
            'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute’s dress code.',
          ],
        },
      }
    ],
  },
  23: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Emotional Wellness Workshop ',
    key: 'emotional-wellness-workshop',
    batch: 3,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: 'GENERAL_AUTO_EMAIL',
      },
    },
    dates: [
      '16th Feb 2025', '29th Jun 2025', '19th Oct 2025'],
    online: false,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,

    tenure: '1 day',
    courseType: 'Emotional Wellness Workshop',
    language: 'Hindi',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Emotional Wellness Workshop',
    newName: 'Emotional Wellness Workshop',
    days: [''],
    time: [
      '9:30 am – 1:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "1 day",
      timings: [
        {
          day: "",
          time: "9:30 am – 1:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'certificate Of Participation',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    mostPopular: false,
    rating: 5,
    colorCode: '#EDBE66',
    timing: '9:30 am – 1:30 pm (IST)',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 0, nonResidentialFee: 500 },
    },
    category: 'camps',
    metaDescription: "Unlock the power of emotional well-being with our Emotional Wellness Workshop. This session is designed to help you manage stress, enhance emotional resilience, and cultivate a positive mindset. Through mindful practices, yoga, and self-reflection, you’ll learn techniques to better navigate life's challenges and foster a sense of inner peace and emotional balance. ",
    image: `${baseDomain}${courseAssets.courseAsset34}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset34}`,
    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this Workshop For?',
          text: [
            "This workshop is for anyone looking to improve their emotional health and well-being. Whether you're experiencing stress, anxiety, or simply want to build emotional resilience, this session is perfect for individuals seeking practical tools to enhance their mental and emotional state.",
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            'In this workshop, you will explore a variety of mindfulness and emotional regulation techniques, including yoga, breathing exercises, and guided meditations. Expect to leave feeling more grounded, with a toolkit of practices to help you manage emotions, reduce stress, and create a deeper connection to yourself.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Program Offerings',
          points: [
            {
              listItem: 'Yoga sequences designed to release emotional tension and promote relaxation  ',
              subItems: [],
            },
            {
              listItem: 'Breathing techniques to calm the nervous system and enhance emotional balance',
              subItems: [],
            },
            {
              listItem: 'Mindfulness practices to increase self-awareness and emotional clarity  ',
              subItems: [],
            },
            {
              listItem: 'Guided meditations to foster inner peace and reduce anxiety  ',
              subItems: [],
            },
            {
              listItem: 'Tools for building emotional resilience and coping with life’s challenges  ',
              subItems: [],
            }
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: [
            '9:30 am – 1:30 pm (IST)',
          ],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [
            'INR 500 (Inclusive Lunch)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Guidelines',
          text: [
            'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute’s dress code.',
          ],
        },
      }
    ],
  },
  24: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Gut Health Workshop',
    key: 'gut-health-workshop',
    batch: 3,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: 'GENERAL_AUTO_EMAIL',
      },
    },
    dates: [
      '22nd Feb 2025', '26th Apr 2025', '15th Jun 2025'
      , '17th Aug 2025', '19th Oct 2025', '21st Dec 2025'],
    online: false,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,

    tenure: '1 day',
    courseType: 'Gut Health Workshop',
    language: 'Hindi',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Gut Health Workshop',
    newName: 'Gut Health Workshop',
    days: [''],
    time: [
      '9:30 am – 1:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "1 day",
      timings: [
        {
          day: "",
          time: "9:30 am – 1:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'certificate Of Participation',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    mostPopular: false,
    rating: 5,
    colorCode: '#EDBE66',
    timing: '9:30 am – 1:30 pm (IST)',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 0, nonResidentialFee: 500 },
    },
    category: 'camps',
    metaDescription: "Transform your digestive health with our Gut Health Workshop. This session focuses on the vital connection between your gut and overall well-being, offering practical tips and holistic practices to support a balanced digestive system. Learn about gut-friendly nutrition, the importance of gut flora, and effective techniques to enhance digestion and boost immunity.",
    image: `${baseDomain}${courseAssets.courseAsset34}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset34}`,
    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this Yoga Workshop For?',
          text: [
            "This workshop is for anyone experiencing digestive discomfort, bloating, or seeking to improve their gut health. Whether you're dealing with chronic gut issues or simply looking to maintain a healthy digestive system, this session provides the tools you need for optimal gut health.",
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            'You will gain insight into the importance of gut health, learn techniques to improve digestion, and explore the impact of diet and lifestyle on your gut. Expect to leave with practical tips, easy-to-follow nutrition advice, and a renewed understanding of how to nurture your digestive system for better health.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Program Offerings',
          points: [
            {
              listItem: 'Nutrition tips for a gut-friendly, anti-inflammatory diet',
              subItems: [],
            },
            {
              listItem: 'Breathing exercises to aid digestion and reduce stress',
              subItems: [],
            },
            {
              listItem: 'Yoga sequences to improve gut function and alleviate discomfort',
              subItems: [],
            },
            {
              listItem: 'Insights into the role of probiotics and gut microbiota in overall health',
              subItems: [],
            },
            {
              listItem: 'Mindfulness practices to enhance digestion and support emotional well-being',
              subItems: [],
            },
            {
              listItem: 'Expert guidance on creating a personalized self-care routine for long-term health',
              subItems: [],
            }
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: [
            '9:30 am – 1:30 pm (IST)',
          ],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [
            'INR 500 (Inclusive Lunch)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Guidelines',
          text: [
            'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute’s dress code.',
          ],
        },
      }
    ],
  },
  25: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Healthy & Sattvik Cooking Workshop',
    key: 'healthy-sattvik-cooking-workshop',
    batch: 3,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: 'GENERAL_AUTO_EMAIL',
      },
    },
    dates: [
      '25th Jan 2025', '22nd Mar 2025', '24th May 2025'
      , '26th Jul 2025', '27th Sep 2025', '22nd Nov 2025'],
    online: false,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,

    tenure: '1 day',
    courseType: 'Healthy & Sattvik Cooking Workshop',
    language: 'Hindi',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Healthy & Sattvik Cooking Workshop',
    newName: 'Healthy & Sattvik Cooking Workshop',
    days: [''],
    time: [
      '9:30 am – 1:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "1 day",
      timings: [
        {
          day: "",
          time: "9:30 am – 1:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'certificate Of Participation',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    mostPopular: false,
    rating: 5,
    colorCode: '#EDBE66',
    timing: '9:30 am – 1:30 pm (IST)',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 0, nonResidentialFee: 500 },
    },
    category: 'camps',
    metaDescription: "Nourish your body with wholesome, Sattvik-inspired meals that promote vitality and well-being. In this workshop, you’ll learn how to prepare simple, nutritious, and balanced dishes using fresh, plant-based ingredients. Embrace the principles of Sattvik cooking, which focus on purity, mindfulness, and healing, to support a healthier lifestyle from the inside out.",
    image: `${baseDomain}${courseAssets.courseAsset34}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset34}`,
    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this Yoga Workshop For?',
          text: [
            "This workshop is for anyone looking to improve their eating habits, increase energy levels, and adopt a more mindful approach to cooking and eating. Whether you’re new to sattvik cooking or seeking to deepen your understanding, this workshop will provide valuable tools for a healthier, more balanced lifestyle.",
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            'You can expect to learn practical cooking techniques for preparing delicious, easy-to-make sattvik meals that support overall health. Gain insight into the importance of food choices, ingredients, and mindful eating practices. Leave with a collection of recipes to incorporate into your daily routine, fostering nourishment and wellness from within.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Program Offerings',
          points: [
            {
              listItem: 'Introduction to sattvik cooking principles and their benefits',
              subItems: [],
            },
            {
              listItem: 'Simple, plant-based recipes for every meal',
              subItems: [],
            },
            {
              listItem: 'Techniques for cooking with fresh, wholesome ingredients',
              subItems: [],
            },
            {
              listItem: 'Mindful eating practices to enhance digestion and well-being',
              subItems: [],
            },
            {
              listItem: 'Tips for creating balanced meals that nourish the body and mind',
              subItems: [],
            },
            {
              listItem: 'Expert guidance on creating a personalized self-care routine for long-term health',
              subItems: [],
            }
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: [
            '9:30 am – 1:30 pm (IST)',
          ],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [
            'INR 500 (Inclusive Lunch)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Guidelines',
          text: [
            'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute’s dress code.',
          ],
        },
      }
    ],
  },
  26: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Menopause Wellness Workshop',
    key: 'menopause-wellness-workshop',
    batch: 3,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: 'GENERAL_AUTO_EMAIL',
      },
    },
    dates: [
      '15th Feb 2025', '31st May 2025', '31st Aug 2025'
      , '30th Nov 2025'],
    online: false,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,

    tenure: '1 day',
    courseType: 'Menopause Wellness Workshop',
    language: 'Hindi',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Menopause Wellness Workshop',
    newName: 'Menopause Wellness Workshop',
    days: [''],
    time: [
      '9:30 am – 1:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "1 day",
      timings: [
        {
          day: "",
          time: "9:30 am – 1:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'certificate Of Participation',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    mostPopular: false,
    rating: 5,
    colorCode: '#EDBE66',
    timing: '9:30 am – 1:30 pm (IST)',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 0, nonResidentialFee: 500 },
    },
    category: 'camps',
    metaDescription: "Navigate the changes of menopause with confidence and ease in our Menopause Wellness Workshop. This session offers holistic practices and expert guidance to support you through this transformative phase. Learn how to manage symptoms, balance hormones, and improve overall health through yoga, mindful nutrition, and stress-reducing techniques, empowering you to embrace this new chapter of life with vitality.",
    image: `${baseDomain}${courseAssets.courseAsset34}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset34}`,
    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this Yoga Workshop For?',
          text: [
            "This workshop is designed for women who are experiencing peri-menopause, menopause, or post-menopause. Whether you’re seeking to manage symptoms like hot flashes, mood swings, or sleep disturbances, or simply want to feel more balanced and energized, this workshop offers valuable tools for enhancing wellness during this life transition.",
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            'You will explore yoga sequences, breathing techniques, and lifestyle tips tailored to ease menopause symptoms and improve overall well-being. Expect to gain a deeper understanding of how to support your body during menopause, with practical strategies for physical, emotional, and mental balance.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Program Offerings',
          points: [
            {
              listItem: 'Yoga practices designed to ease symptoms like hot flashes and fatigue',
              subItems: [],
            },
            {
              listItem: 'Breathing exercises to reduce stress and improve sleep quality',
              subItems: [],
            },
            {
              listItem: 'Nutrition advice for managing hormone levels and boosting energy',
              subItems: [],
            },
            {
              listItem: 'Mindfulness practices for emotional balance and mental clarity',
              subItems: [],
            },
            {
              listItem: 'Tools for enhancing bone health, circulation, and overall vitality during menopause',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: [
            '9:30 am – 1:30 pm (IST)',
          ],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [
            'INR 500 (Inclusive Lunch)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Guidelines',
          text: [
            'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute’s dress code.',
          ],
        },
      }
    ],
  },
  27: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'PCOD & PCOS Wellness Workshop',
    key: 'pcod-pcos-wellness-workshop',
    batch: 3,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: 'GENERAL_AUTO_EMAIL',
      },
    },
    dates: [
      '19th Jan 2025', '16th Mar 2025', '18th May 2025'
      , '20th Jul 2025', '21st Sep 2025', '16th Nov 2025'],
    online: false,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,

    tenure: '1 day',
    courseType: 'PCOD & PCOS Wellness Workshop',
    language: 'Hindi',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'PCOD & PCOS Wellness Workshop',
    newName: 'PCOD & PCOS Wellness Workshop',
    days: [''],
    time: [
      '9:30 am – 1:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "1 day",
      timings: [
        {
          day: "",
          time: "9:30 am – 1:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'certificate Of Participation',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    mostPopular: false,
    rating: 5,
    colorCode: '#EDBE66',
    timing: '9:30 am – 1:30 pm (IST)',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 0, nonResidentialFee: 500 },
    },
    category: 'camps',
    metaDescription: "Take charge of your health with our specialized PCOD & PCOS Wellness Workshop. This session offers natural, holistic practices to help manage symptoms, balance hormones, and improve overall well-being. Learn effective yoga, nutrition tips, and stress-reduction techniques to support your journey toward better health and a more balanced life.",
    image: `${baseDomain}${courseAssets.courseAsset34}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset34}`,
    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this Yoga Workshop For?',
          text: [
            "This workshop is ideal for individuals dealing with PCOD and PCOS or those looking to prevent or manage related symptoms. Whether you’re new to yoga or have been practicing for years, this session is designed for anyone seeking holistic support for hormonal balance, stress management, and overall wellness",
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            'In this workshop, you will experience a combination of yoga poses, breathing exercises, and mindfulness practices specifically tailored to address the needs of those with PCOD and PCOS. Expect to leave feeling more relaxed, empowered, and equipped with tools to support your hormonal health and overall vitality.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Program Offerings',
          points: [
            {
              listItem: 'Yoga sequences to support hormonal balance and menstrual health',
              subItems: [],
            },
            {
              listItem: 'Breathing techniques to reduce stress and improve circulation',
              subItems: [],
            },
            {
              listItem: 'Targeted exercises for improving ovarian health and reducing inflammation',
              subItems: [],
            },
            {
              listItem: 'Nutrition tips to manage weight and enhance overall wellness',
              subItems: [],
            },
            {
              listItem: 'Mindfulness practices for emotional balance and mental clarity',
              subItems: [],
            },
            {
              listItem: 'Expert guidance on creating a personalized self-care routine for long-term health',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: [
            '9:30 am – 1:30 pm (IST)',
          ],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [
            'INR 500 (Inclusive Lunch)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Guidelines',
          text: [
            'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute’s dress code.',
          ],
        },
      }
    ],
  },
  28: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Total Body Detox Workshop',
    key: 'total-body-detox-workshop',
    batch: 3,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: 'GENERAL_AUTO_EMAIL',
      },
    },
    dates: [
      '8th Feb 2025', '12th Apr 2025', '14th Jun 2025'
      , '10th Aug 2025', '11th Oct 2025', '13th Dec 2025'],
    online: false,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,

    tenure: '1 day',
    courseType: 'Total Body Detox Workshop',
    language: 'Hindi',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Total Body Detox Workshop',
    newName: 'Total Body Detox Workshop',
    days: [''],
    time: [
      '9:30 am – 1:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "1 day",
      timings: [
        {
          day: "",
          time: "9:30 am – 1:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'certificate Of Participation',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    mostPopular: false,
    rating: 5,
    colorCode: '#EDBE66',
    timing: '9:30 am – 1:30 pm (IST)',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 0, nonResidentialFee: 500 },
    },
    category: 'camps',
    metaDescription: "Refresh, energize, and revitalize your body and mind! This workshop offers powerful techniques, mindful practices, and holistic approaches to cleanse toxins, improve digestion, boost energy levels, and promote overall well-being. Achieve balance and rejuvenation from within for a healthier, more vibrant you. ",
    image: `${baseDomain}${courseAssets.courseAsset34}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset34}`,
    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Who is this Yoga Workshop For?',
          text: [
            "This workshop is designed for anyone looking to rejuvenate their body and mind through natural, holistic practices. Whether you’re feeling sluggish, dealing with stress, or simply want to reset your system, this session is perfect for individuals seeking to improve their health, vitality, and overall well-being.",
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'What can you expect?',
          text: [
            'In this workshop, you will learn a variety of cleansing techniques, yoga practices, and mindfulness exercises to detoxify your body and energize your mind. Expect to feel lighter, more focused, and recharged as you explore ways to restore balance and harmony from within.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Program Offerings',
          points: [
            {
              listItem: 'Detoxifying yoga sequences to cleanse and revitalize the body',
              subItems: [],
            },
            {
              listItem: 'Breathing exercises to boost energy and enhance mental clarity',
              subItems: [],
            },
            {
              listItem: 'Mindful practices for stress relief and emotional balance',
              subItems: [],
            },
            {
              listItem: 'Holistic tips for improving digestion and boosting metabolism',
              subItems: [],
            },
            {
              listItem: 'Guided meditations for mental rejuvenation and focus',
              subItems: [],
            },
            {
              listItem: 'Personalized guidance to help you integrate detox practices into daily life',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: [
            '9:30 am – 1:30 pm (IST)',
          ],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [
            'INR 500 (Inclusive Lunch)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Guidelines',
          text: [
            'Campers are requested to come attired to comfortable loose fitting clothes. Shorts, capris, tank tops, low cut shirts, short and sleeveless T-shirts are not permitted as per the Institute’s dress code.',
          ],
        },
      }
    ],
  },
  // 22: {
  //      id: '',
  // textdescription6: '',
  // textdescription7: '',
  // textdescription8: '',
  // textdescription9: '',
  // textdescription10: '',
  // textdescription1:'',
  // textdescription2:'',
  // textdescription3:'',
  // textdescription4:'',
  // textdescription5:'',
  //   title: '21 Days BLC - Morning - Online – English - Batch 4',
  //   key: '21-days-better-living-course-batch-4',
  //   batch: 4,
  //   // templateId: 'GENERAL_AUTO_EMAIL',
  //   templateId: {
  //     templateOnline: 'GENERAL_AUTO_EMAIL',
  //     templateOffline: {
  //       templateResidential: '',
  //       templateNonResidential: '',
  //     },
  //   },
  //   dates: [
  //     '8th Jan to 28th ',
  //   ],
  //   online: true,
  //   onCampus: false,
  //   nonResidential: false,
  //   residential: false,
  //   certficate: false,
  //   mostPopular: true,
  //   rating: 5,
  //   colorCode: '#EDBE66',
  //   timing: 'Morning 7:00 am – 8:30 am (IST)',
  //   fees: {
  //     onlineFee: 2100,
  //     offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
  //   },
  //   category: 'camps',
  //   metaDescription:
  //     'Become successful, healthy, stress-free, and more mindful in your relationships by learning the yogic way of living. Join The Yoga Institute’s 21-Days Better Living Course. ',
  //   image: `${baseDomain}${courseAssets.courseAsset34}`,
  //   cardImage: `${baseDomain}${courseAssets.courseAsset34}`,
  //   details: [
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Overview',
  //         text: [
  //           'Become successful, healthy, stress-free, and more mindful in your relationships by learning the yogic way of living. Join The Yoga Institute’s 21-Days Better Living Course. ',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: '',
  //         text: [
  //           'It takes 21 days to build a habit into your daily routine. The Yoga Institute’s 21 Days Better Living Course is a course specifically designed to introduce you to the yogic way of living. begin your journey into the discovery of Yoga. Working professionals and house makers can take just one hour from their day to practice and learn yoga and begin their transformational journey. ',
  //           'This is a certificate course and has been successfully conducted for 50 years by the Institute. The program trains you holistically and enables you to achieve equilibrium between your mind and body and thus achieve a state of peace and happiness in life. The course is conducted by trained teachers at the Institute.',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' The programme covers an introduction to: ',
  //         points: [
  //           {
  //             listItem: 'Astanga Yoga',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Asanas',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Pranayama',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Kriyas',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Attitude training  ',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Parisamvad (sessions with Dr. Hansaji)',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: '',
  //         text: [
  //           'It is ideal for anyone who wants to live a yoga way of life and experience peace within. It helps you to incorporate Yogic ideals in every field of your daily life. ',
  //         ],
  //       },
  //     },

  //     {
  //       type: 'u-list',
  //       content: {
  //         title: ' Benefits of this camp: ',
  //         points: [
  //           {
  //             listItem:
  //               'Helps you in developing and maintaining the right routine for your overall health ',
  //             subItems: [],
  //           },

  //           {
  //             listItem: 'Helps you reduce stress and anxiety',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Helps you feel energetic throughout the day',
  //             subItems: [],
  //           },
  //           {
  //             listItem: 'Helps you become mindful in your relationships',
  //             subItems: [],
  //           },
  //           {
  //             listItem: ' Helps you get more in control of your feelings',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },

  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: '',
  //         text: [
  //           'Come and experience, amidst nature and beauty, our enriching 21 Days Better Living Course.',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'PROGRAM DETAILS',
  //         text: [
  //           'The course is held once a month, starting on the first Sunday (7:00 a.m.) of every month. The classes are of 1 hr. 30 mins each day for 21 consecutive days. Participants can opt for the morning or evening batches.',
  //         ],
  //       },
  //     },
  //     // {
  //     //   type: 'paragraph',
  //     //   content: {
  //     //     title: 'UPCOMING DATES FOR 2022',
  //     //     text: [],
  //     //   },
  //     // },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Timings',
  //         text: ['Morning 7:00 am – 8:30 am (IST)'],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'REGISTRATION',
  //         text: [],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: '',
  //         text: [
  //           'For registration in the 21 Days Better Living Course, please click on the link below and fill in all the details. Within 24 hours after registering, you will receive an email containing the link to the payment portal. You can use the link to make the payment.',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'FEES',
  //         text: [],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Rs. 2100/-',
  //         text: [
  //           '*Please note fees once paid will be non-refundable.',
  //           ' We support all credit cards. For making payments using MasterCard. Alternatively, you can also make payments using net banking or opt for wire transfer by writing to us.',
  //         ],
  //       },
  //     },

  //     // {
  //     //   type: 'paragraph',
  //     //   content: {
  //     //     title: 'PRE-REQUISITES',
  //     //     text: [],
  //     //   },
  //     // },
  //     // {
  //     //   type: 'u-list',
  //     //   content: {
  //     //     title: ' A MANDATORY PREREQUISITE FOR THIS COURSE IS:',
  //     //     points: [
  //     //       {
  //     //         listItem:
  //     //           'Covid test RT-PCR results dated 20th Jan 2022to 28th Jan 2022and later for entry into the premises post prior registration. ',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem:
  //     //           'Students must use personal vehicles for commuting, throughout the course duration. We would discourage the use of public transport. (For non-residential students)',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem:
  //     //           'This course is for healthy individuals. Kindly note if you suffer from any health issues like diabetes, hypertension, or orthopaedic problems, kindly take prior approval.',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem:
  //     //           'For international Students, it is mandatory to do 14 Day Quarantine as per the laws of Government of India along with RT-PCR test reports. ',
  //     //         subItems: [],
  //     //       },
  //     //       {
  //     //         listItem:
  //     //           'Last but not the least, do not forget your masks and Yoga Mats',
  //     //         subItems: [],
  //     //       },
  //     //     ],
  //     //   },
  //     // },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'FAQs  ',
  //         text: [],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title:
  //           '  1. What is the time to reach the Institute during the course duration?  ',
  //         text: [
  //           'For residential campers, students should arrive at The Yoga Institute between 7.00 a.m. to 7.00 p.m. IST (Indian Standard Time). Please read on Hostel Facility for more details. ',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title:
  //           ' 2. Can a person who has problem with sitting on the floor for a long time enroll for the course?',
  //         text: [
  //           'Backrest and chairs are available on request in special and exceptional cases. – Rest and recreation are also a part of the course.',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: '3. How many seats are available in one course?',
  //         text: [
  //           'Only 35 seats are available. Registrations on a first come, first-serve basis only.',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Please Note',
  //         text: [],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'The payment validity and session attendance for 21-Days Better Living Course is only applicable for one month. However, in emergency circumstances, the participant can attend the 21-Days BLC sessions for the next month but only on approval of the management. Without authorisation, the participant will not be allowed to attend the sessions post the actual sessions of the course.',
  //         text: [],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'UNIQUE OFFERINGS ',
  //         text: [''],
  //       },
  //     },
  //     {
  //       type: 'u-list',
  //       content: {
  //         title: 'Our Amenities / Our Facilities',
  //         points: [
  //           {
  //             listItem:
  //               'Library: Explore one of largest collections of spiritual and philosophical books to expand your knowledge ',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Internet Room: Access the internet in a comfortable setting when you need Meditation Pods: Meditate on the five fundamental natural elements, the Panchamahabhutas,',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Acupressure Path: Give your feet a natural massage and activate your vital energies',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Yogic Gazebo: Enjoy the soothing sounds of flowing water and take in the peace and at this relaxation area',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Dining Area: Enjoy wholesome Sattvik meals, specially prepared for your nourishment',
  //             subItems: [],
  //           },
  //           {
  //             listItem:
  //               'Book Store: Shop in a wide selection of TYI publications. Find yourself the best yogic attire and mats.',
  //             subItems: [],
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // },

}

const classes = {
  1: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    weekDays: true,
    title: 'Advanced Yoga Asana Regular Class - Online (Only for TYI Teachers)',
    isRegular: true,
    key: 'advanced-regular-yoga-classes',
    batch: 1,
    newName: 'Advanced Asana Regular Class (Only for TYI Teachers)',
    days: [
      'Monday, Wednesday, Friday',
      ' Tuesday, Thursday & Saturday'
    ],
    time: [
      'Morning: 6:30 am - 8:00 am (IST)',
      '5:30 pm - 7:00 pm (IST)'

    ],
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    mostPopular: false,
    rating: 5,
    colorCode: '#D3A29D',
    join: true,
    timing:
      'Monday, Wednesday, Friday: Morning: 6:30 am - 8:00 am (IST) Tuesday, Thursday & Saturday:Evening: 5:30 pm - 7:00 pm (IST) *',
    fees: {
      onlineFee: 1500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    metaDescription: 'Stay connected with your practices of the advanced Yoga asanas at TYI',
    image: `${baseDomain}${courseAssets.courseAsset67}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset108}`,
    dates: ['6:30 a.m. to 8:00 a.m', '5:30 p.m. to 7:00 p.m'],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: true,

    tenure: '',
    courseType: '',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Regular Asana Classes',
    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Monday, Wednesday, Friday",
          time: "Morning: 6:30 am - 8:00 am (IST)"
        },
        {
          day: "Tuesday, Thursday & Saturday",
          time: "Evening: 5:30 pm - 7:00 pm (IST) *"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },
    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'So, you have finished with your basic or advanced yoga teacher training course! Good job! Now you wish to continue your association with The Yoga Institute and we have just the right offering for you. Here is an opportunity to stay updated and connected with the gurus, expert teachers, and advanced yoga asana practices of The Yoga Institute.',
            'This course shall help you to maintain continuity with our Yoga TTC practices and techniques. Join us to stay with our Gurus and expert teachers forever.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Course content',
          text: [
            'Each yoga session will be 1.5 hrs.',
            'First 1 hour 15 minutes will include the following practice',
            'Advanced Yoga asana and stretches',
            'Pranayam and kriyas',
            'Meditation and relaxation',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Course Timings and fees:',
          text: [
            'Monday, Wednesday & Friday: Morning: 6:30 am - 8:00 am (IST)',
            'Fees: Rs 1500/month',
            'Tuesday, Thursday & Saturday: Evening: 5:30 pm - 7:00 pm (IST) (Join on any date)',
            'Fees: Rs 1500/ (Per month)',
          ],
        },
      },
    ],
  },
  2: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    weekDays: true,
    title: 'Healing Yoga Movement & Rhythm - Online',
    key: 'healing-movement-and-rhythm',
    isRegular: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset68}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset109}`,
    mostPopular: false,
    rating: 5,
    colorCode: '#C48E8C',
    metaDescription:
      'A movement-based therapy programme for stress management, emotional well-being',
    join: true,
    timing:
      'Tuesday & Thursday - 8pm - 9pm (IST) (Online)',
    fees: {
      onlineFee: 1500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: ['8pm - 9pm'],
    online: true,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '',
    courseType: "HEALING YOGA MOVEMENT",
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Regular Asana Classes',
    newName: 'Healing Movement and Rhythm Classes',
    days: [
      'Tuesday',
      'Thursday'
    ],
    time: [
      '8pm - 9pm (IST)'
    ],
    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Tuesday & Thursday",
          time: "8pm - 9pm (IST) (Online)"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },


    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Harness the power of movement to unleash the power of positivity and joy in your life. Healing Yoga Movement & Rhythm is The Yoga Institute’s movement based therapy programme for stress management, emotional well-being and trauma healing.',
            'Join now if you’re looking for an immunity and vitality boost, emotional release, anxiety and anger management, glowing skin, inner radiance and enhanced creativity.',
            '#hackyourhappyhormones',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Duration',
          text: ['60 Minutes'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['1500'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Classes',
          text: [
            'Tuesday & Thursday - 8 pm - 9 pm (IST) (Online)',
            '(Join on any date)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Mode',
          text: ['Online'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'To Register write to us at info@theyogainstitute.org or call us at +91-22-26110506 or at +91-22-26203568',
          ],
        },
      },
    ],
  },
  3: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: ' Monday ,  Tuesday , Thursday and Friday',
    textdescription2: 'Morning Batch (IST): 6:30 am to 7:30 am, 7:30 am to 8:30 am, 8:30 am to 9:30 am',
    textdescription3: 'Evening Batch (IST): 4pm to 5pm, 5pm to 6pm, 6pm to 7pm, 7pm to 8pm',
    textdescription4: 'We also have Weekend batches and the details are:',
    textdescription5: 'Weekend Schedule(Sat-Sun): 7:30 am to 9 am',
    weekDays: true,
    title: 'Yoga Asana Regular Classes - (Men & Women) - Online Yoga Classes',
    key: 'asana-regular-classes-online',
    isRegular: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'YOGA_ASANA_REGULAR_CLASSES_M_AND_F_ONLINE',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset81}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset81}`,
    mostPopular: true,
    metaDescription:
      'Explore the world of Yoga asanas, practices from the comfort of your own home',
    rating: 5,
    colorCode: '#B77E7E',
    join: true,
    timing:
      'Regular Asana Classes is conducted 4 days in a week. ',
    fees: {
      onlineFee: 1100,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: [
      '6:30 a.m. to 7:30 a.m.',
      '7:30 a.m. to 8:30 a.m.',
      '8:30 a.m. to 9:30 a.m.',
      '4:00 p.m. to 5:00 p.m.',
      '5:00 p.m. to 6:00 p.m.',
      '6:00 p.m. to 7:00 p.m.',
      '7:00 p.m. to 8:00 p.m.',
      'Sat/Sun:7:30-9:00 a.m'],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '',
    courseType: 'Regular Asana Classes 1/ Online',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Regular Asana Classes',
    newName: 'Asana Regular Classes (Men & Women)',
    days: [
      'Monday',
      'Tuesday',
      'Thursday',
      'Friday',
      'Weekend batches'
    ],
    time: [
      'Morning Batch (IST): 6:30 am to 7:30 am, 7:30 am to 8:30 am, 8:30 am to 9:30 am',
      'Evening Batch (IST): 4pm to 5pm, 5pm to 6pm, 6pm to 7pm, 7pm to 8pm',
      'Weekend Schedule(Sat-Sun): 7:30 am to 9 am'
    ],
    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Monday , Tuesday , Thursday and Friday",
          time: "Morning Batch (IST): 6:30 am to 7:30 am, 7:30 am to 8:30 am, 8:30 am to 9:30 am"
        },
        {
          day: "Monday , Tuesday , Thursday and Friday",
          time: "Evening Batch (IST): 4pm to 5pm, 5pm to 6pm, 6pm to 7pm, 7pm to 8pm"
        },
        {
          day: "Weekend batches",
          time: "(Sat-Sun): 7:30 am to 9 am"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Explore the world of Yoga asanas from the comfort of right where you are with our online asana classes. Whether you want to perfect your headstand or work on your splits or if you are looking for a place to begin, we have you covered. If you are goal-oriented and are looking to lose the few extra kilos or get your emotions under control or become more flexible; we can get you there as well. Join now for an exciting journey in yoga asanas, pranayamas, yogic-lifestyle techniques and mindful living.'
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: '',
          points: [
            {
              listItem: 'Join on any date.',
              subItems: [],
            },
            {
              listItem:
                'Payments are valid for a month from the date of payment.',
              subItems: [],
            },
            {
              listItem:
                'Yoga',
              subItems: [],
            },
            {
              listItem: 'Online yoga classes are conducted via live Zoom sessions.',
              subItems: [],
            },
            {
              listItem: 'Class links will be sent via email and WhatsApp',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Rules and Regulations',
          points: [
            {
              listItem:
                'Missed online yoga classes cannot be compensated in other classes.',
              subItems: [],
            },
            {
              listItem: 'Batch changes are not permitted.',
              subItems: [],
            },
            {
              listItem:
                'Any form of recording of these yoga sessions is prohibited.',
              subItems: [],
            },
            {
              listItem: 'Fees once paid will be non-refundable.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Weekday Schedule for Regular Asana Class (Four Days in a week)',
          text: ['Mon-Tues-Thur-Fri'],
        },
      },
      {
        type: 'u-list',
        content: {
          title: ' Morning Batch',
          points: [
            {
              listItem: '6:30 am to 7:30 am ',
              subItems: [],
            },
            {
              listItem: '7:30 am to 8:30 am ',
              subItems: [],
            },
            {
              listItem: '8:30 am to 9:30 am ',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Evening Batch',
          points: [
            {
              listItem: '4pm to 5pm',
              subItems: [],
            },
            {
              listItem: '5pm to 6pm',
              subItems: [],
            },
            {
              listItem: '6pm to 7pm ',
              subItems: [],
            },
            {
              listItem: '7pm to 8pm',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Weekend Schedule for Regular Asana Class (Weekend batch)',
          text: ['Sat & Sun', '7:30 am to 9 am'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Monthly Fees',
          text: ['Rs. 1100/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Six Months Fees',
          text: ['Rs. 6600/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Annual Fees',
          text: ['Rs. 11000/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Note:-',
          text: ['We kindly urge you to regularly check your email inboxes, including your spam/junk folders, for important updates from the (email id - asanaclasses@theyogainstitute.in) from the institute regarding regular yoga asanas. Additionally, kindly note that new members will be added to the WhatsApp group on Wednesdays and Saturdays only.'],
        },
      },

      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Monthly',
      //     text: ['Rs.1000 '],
      //   },
      // },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Half yearly',
      //     text: ['Rs. 6000'],
      //   },
      // },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Annual ',
      //     text: ['Rs. 10,000'],
      //   },
      // },
    ],
  },
  4: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Yoga Classes for Men (Regular Asana) - On Campus',
    key: 'asana-regular-classes-on-campus',
    isRegular: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset70}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset70}`,
    mostPopular: true,
    metaDescription:
      'This is a special men-only regular asana class conducted by The Yoga Institute',
    rating: 5,
    colorCode: '#D3A29D',
    join: true,
    timing:
      'Monday,Tuesday,Thursday,Friday * :6:30 am to 7:30 am, 7:30 am to 8:30 am, 1:00 pm to 2:00 pm, 6:00 pm to 7:00 pm, 7:00 pm to 8:00 pm',
    fees: {
      onlineFee: 1100,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: [
      '6:30 a.m to 7:30 p.m.',
      '7:30 a.m. to 8:30 a.m.',
      '1:00 p.m to 2:00 p.m.',
      '6:00 p.m. to 7:00 p.m',
      '7:00 p.m. to 8:00 p.m.',
    ],
    online: false,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '',
    courseType: 'Regular Asana Classes 1/ on campus',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Regular Asana Classes',
    newName: 'Asana Regular Classes for Men',
    days: [
      'Monday',
      'Tuesday',
      'Thursday',
      'Friday'
    ],
    time: [
      '6:30 am to 7:30 am',
      '7:30 am to 8:30 am',
      '1:00 pm to 2:00 pm',
      '6:00 pm to 7:00 pm',
      '7:00 pm to 8:00 pm'
    ],

    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Monday,Tuesday,Thursday,Friday*",
          time: "6:30 am to 7:30 am, 7:30 am to 8:30 am, 1:00 pm to 2:00 pm, 6:00 pm to 7:00 pm, 7:00 pm to 8:00 pm"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'This is a special men-only regular yoga asana class conducted by The Yoga Institute. The yoga classes for men cover Asanas, Pranayamas, Kriyas, diet and Yogic lifestyle recommendations, personal attention on health, and counseling on other aspects of life.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Duration',
          text: [
            'Yoga Classes for Men are of 1-hour duration held every Monday, Tuesday, Thursday, Friday',
            'A minimum attendance of 3 months is recommended, to experience the difference in your life. Can join on any day of the month.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Timing options ',
          text: [
            '6:30 am to 7:30 am',
            '7:30 am to 8:30 am',
            '1:00 pm to 2:00 pm',
            '6:00 pm to 7:00 pm',
            '7:00 pm to 8:00 pm',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: ['Fees'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Monthly Fees',
          text: ['Rs. 1100/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Six Months Fees',
          text: ['Rs. 6600/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Annual Fees',
          text: ['Rs. 11000/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Note',
          text: ['Kindly note the fees once paid will not be refundable.'],
        },
      },
    ],
  },
  5: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Yoga Classes for Women (Regular Asana) - On Campus',
    key: 'asana-regular-classes-on-campus-women',
    isRegular: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset71}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset71}`,
    mostPopular: true,
    metaDescription:
      'This is a special women-only regular asana class conducted by The Yoga Institute',
    rating: 5,
    colorCode: '#C48E8C',
    join: true,
    timing:
      'Monday,Tuesday,Thursday,Friday * :6:30 am to 7:30 am, 7:30 am to 8:30 am,8:30 am to 9:30 am, 9:30 am to 10:30 am, 10:30 am to 11:30 am, 11:30 am to 12:30 pm, 1:00 pm to 2:00 pm, 2:00 pm to 3:00 pm, 3:00 pm to 4:00 pm, 4:00 pm to 5:00 pm, 5:00 pm to 6:00 pm, 6:00 pm to 7:00 pm, 7:00 pm to 8:00 pm',
    fees: {
      onlineFee: 1100,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: [
      '6:30 a.m to 7:30 a.m.',
      '7:30 a.m. to 8:30 a.m.',
      '8:30 a.m to 9:30 a.m',
      '9:30 a.m. to 10:30 a.m.',
      '10:30 a.m. to 11:30 a.m',
      '11:30 a.m. to 12:30 p.m',
      '1:00 p.m. to 2:00 p.m.',
      '2:00 p.m to 3:00 p.m',
      '3:00 p.m. to 4:00 p.m.',
      '4:00 p.m to 5:00 p.m.',
      '5:00 p.m. to 6:00 p.m',
      '6:00 p.m. to 7:00 p.m.',
      '7:00 p.m to 8:00 p.m',
    ],
    online: false,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '',
    courseType: 'Regular Asana Classes 1/ on campus',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Regular Asana Classes',
    newName: 'Asana Regular Classes for Women',
    timings: [
      {
        day: 'Monday, Tuesday, Thursday, Friday',
      }
    ],
    days: [
      'Monday',
      'Tuesday',
      'Thursday',
      'Friday'
    ],
    time: [
      '6:30 am to 7:30 am',
      '7:30 am to 8:30 am',
      '8:30 am to 9:30 am',
      '9:30 am to 10:30 am',
      '10:30 am to 11:30 am',
      '11:30 am to 12:30 pm',
      '1:00 pm to 2:00 pm',
      '2:00 pm to 3:00 pm',
      '3:00 pm to 4:00 pm',
      '4:00 pm to 5:00 pm',
      '5:00 pm to 6:00 pm',
      '6:00 pm to 7:00 pm',
      '7:00 pm to 8:00 pm'
    ],
    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Monday,Tuesday,Thursday,Friday *",
          time: "6:30 am to 7:30 am, 7:30 am to 8:30 am,8:30 am to 9:30 am, 9:30 am to 10:30 am, 10:30 am to 11:30 am, 11:30 am to 12:30 pm, 1:00 pm to 2:00 pm, 2:00 pm to 3:00 pm, 3:00 pm to 4:00 pm, 4:00 pm to 5:00 pm, 5:00 pm to 6:00 pm, 6:00 pm to 7:00 pm, 7:00 pm to 8:00 pm"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },
    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'This is a special women-only regular yoga asana class conducted by The Yoga Institute. The yoga classes for women cover Yoga Asanas, Pranayamas, Kriyas, diet and Yogic lifestyle recommendations, personal attention on health and counselling on other aspects of life.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Duration',
          text: [
            'Yoga Classes for Women are of 1-hour duration held every Monday, Tuesday, Thursday, Friday.',
            'A minimum attendance of 3 months is recommended, to experience the difference in your life. Can join on any day of the month.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Timing options ',
          text: [
            'Monday, Tuesday, Thursday & Friday',
            '6:30 am to 7:30 am',
            '7:30 am to 8:30 am',
            '8:30 am to 9:30 am',
            '9:30 am to 10:30 am',
            '10:30 am to 11:30 am',
            '11:30 am to 12:30 pm',
            '1:00 pm to 2:00 pm',
            '2:00 pm to 3:00 pm',
            ' 3:00 pm to 4:00 pm',
            '4:00 pm to 5:00 pm',
            '5:00 pm to 6:00 pm',
            '6:00 pm to 7:00 pm',
            '7:00 pm to 8:00 pm',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: ['Fees'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Monthly Fees',
          text: ['Rs. 1100/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Six Months Fees',
          text: ['Rs. 6600/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Annual Fees',
          text: ['Rs. 11000/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Note',
          text: ['Kindly note the fees once paid will not be refundable.'],
        },
      },
    ],
  },
  11: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Children\'s Yoga Classes (Regular) - On Campus',
    key: 'childrens-regular-classes',
    isRegular: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset76}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset76}`,
    mostPopular: false,
    metaDescription:
      'Use the power of Yoga to improve your child’s focus, memory, discipline, determination, and value system.',
    rating: 5,
    colorCode: '#C48E8C',
    timing: '4:00 pm -5:00 pm (Mon, Tues, Thurs, Fri)',
    fees: {
      onlineFee: 1100,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: ['4:00 p.m to 5:00 p.m.'],
    online: false,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: false,
    newName: 'Childrens Regular Classes',
    days: [
      'Mon',
      'Tues',
      'Thurs',
      'Fri'
    ],
    time: [
      '4:00 pm -5:00 pm'
    ],
    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Mon, Tues, Thurs, Fri",
          time: "4:00 pm -5:00 pm"
        }
      ]
    }
    ,

    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Introduce your child to mindfulness with our special program for children. Use the power of Yoga to improve your child’s focus, memory, discipline, determination and value system. The children`s yoga classes cover simple yoga asanas and a simple introduction to yogic philosophy through games, arts and other child-friendly activities. Ideal for children between the ages of 5-13 years and will not place any extra academic burden on the child. Children`s yoga classes are conducted in Hindi and English. Parents are encouraged to be actively involved in this yoga for children classes.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Evening Batch Time',
          text: ['4:00 pm -5:00 pm (Mon, Tues, Thurs, Fri) '],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [' Rs 1100 per month'],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: 'Rules',
          text: ['Fees once paid is non-refundable.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Please take a screenshot of your order receipt and share it with us at info@theyogainstitute.org',
          ],
        },
      },
    ],
  },
  12: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    weekends: true,
    title: 'Children\'s Weekend Yoga Class - On Campus',
    key: 'childrens-weekend-classes-on-campus',
    isRegular: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    newName: 'Childrens Weekend Classes',
    days: [
      'Saturday & Sunday'
    ],
    time: [
      '8:00 am - 9:30 am (IST)'
    ],
    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Saturday & Sunday",
          time: "8:00 am - 9:30 am (IST) *"
        }
      ]
    }
    ,
    image: `${baseDomain}${courseAssets.courseAsset76}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset76}`,
    mostPopular: false,
    metaDescription:
      'The course covers simple asanas, games, arts and other child-friendly activities that introduce your child to profound Yogic ideas and a yogic lifestyle',
    rating: 5,
    colorCode: '#B77E7E',
    join: true,
    timing: 'Saturday & Sunday: 8:00 am - 9:30 am (IST) *',
    fees: {
      onlineFee: 1100,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: ['8:00 a.m. to 9:30 a.m.'],
    online: false,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: false,
    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Don’t let your child waste the weekend. Make the end of the week super-fun and super-special with our Weekend Yoga Class for children. Let your child explore the world of Yoga to become more grounded, friendly, less bored and more happy. The Weekend kids yoga classes cover simple asanas, games, arts and other child-friendly activities that introduce your child to profound Yogic ideas and a yogic lifestyle. Ideal for children between the ages of 5-13 years.',
            'This kids yoga classes are conducted in Hindi and English.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: ' Batch Time',
          text: [
            'Saturday & Sunday: 8:00 am - 9:30 am (IST) (Join on any date)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [' Rs 1100 per month'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Rules',
          text: ['  Fees once paid is non-refundable'],
        },
      },
    ],
  },
  13: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Online Meditation Course  (Foundation Course)',
    key: 'meditation-foundation-course-online',
    isRegular: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset28}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset28}`,
    mostPopular: true,
    metaDescription: 'The science behind meditation',
    rating: 5,
    colorCode: '#D3A29D',
    timing: 'Monday, Wednesday & Friday: 7:00 am - 8:00 am (IST)',
    fees: {
      onlineFee: 1100,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: [
      // '4th Mar to 29th Mar 2024',
      // '8th Apr to 3rd May 2024',
      // '6th May to 31st May 2024',
      // '3rd Jun to 28th Jun 2024',
      // '1st Jul to 26th Jul 2024',
      // '5th Aug to 30th Aug 2024',
      // '2nd Sep to 27th Sep 2024',
      // '7th Oct to 1st Nov 2024',
      // '4th Nov to 29th Nov 2024',
      '2nd Dec to 27th Dec 2024',
      '6th Jan to 31st Jan 2025',
      '3rd Feb to 28th Feb 2025',
      '3rd Mar to 28th Mar 2025',
      '7th Apr to 2nd May 2025',
      '5th May to 30th May 2025',
      '2nd Jun to 12th Jun 2025',
      '7th Jul to 1st Aug 2025',
      '4th Aug to 29th Aug 2025',
      '1st Sep to 26th Sep 2025',
      '6th Oct to 31st Oct 2025',
      '3rd Nov to 28th Nov 2025',
      '1st Dec to 26th Dec 2025'
    ],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '',
    courseType: 'Regular Meditation Foundation Course',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Regular Asana Classes',
    newName: 'Online Meditation Course (Foundation)',
    days: [
      'Monday',
      'Wednesday',
      'Friday'
    ],
    time: ['7:00 am - 8:00 am (IST)'],
    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Monday, Wednesday & Friday",
          time: "7:00 am - 8:00 am (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Simple recommendations are provided in these meditation workshops to demonstrate how meditation can lead to a meditative state throughout the day, assisting in job and life efficiency. Recent research has demonstrated that frequent meditation improves brain waves.',
            'This is a 12-session online meditation course starting on the 1st of every month',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Course content',
          points: [
            {
              listItem: 'The science behind meditation',
              subItems: [],
            },
            {
              listItem: 'Intense guided meditation practices',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: ['Course Timings and fees'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Monday, Wednesday & Friday',
          text: [' 7:00 am - 8:00 am  (IST) '],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [

            '2024: Rs. 1100/-'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Kindly make the payments at least one day prior to the camp.',
          ],
        },
      },
    ],
  },
  14: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Regular Online Meditation Classes',
    key: 'regular-meditation-classes-online',
    isRegular: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset28}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset28}`,
    mostPopular: true,
    metaDescription:
      'Click now if you want to make bliss and calm a part of your life daily.',
    rating: 5,
    colorCode: '#C48E8C',
    join: true,
    timing:
      'Tuesday, Thursday & Saturday: 7:00 am - 7:45 am (IST) *',
    fees: {
      onlineFee: 1100,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: ['7:00 a.m. to 7:45 a.m.'],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '',
    courseType: 'Regular Class - 2 Regular Meditation Class',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Regular Asana Classes',
    newName: 'Regular Online Meditation Classes',
    days: [
      'Tuesday',
      'Thursday',
      'Saturday'
    ],
    time: [
      '7:00 am - 7:45 am (IST)'
    ],
    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Tuesday, Thursday & Saturday",
          time: "7:00 am - 7:45 am (IST) *"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Developing your meditation practice is easier than ever before with our regular online meditation classes. Whether you are new to meditation or an experienced meditator, our program is designed to guide you through every step. Connect with our renowned community of meditators and gurus to explore your personal meditation journey. These online meditation courses help you develop a daily habit of mindfulness, ensuring that you never miss your meditation goals again. You can conveniently join any day of the week, allowing you to learn meditation online with flexibility.',

            "Join our online yoga and meditation classes to start your journey towards inner peace and balance. If you're looking for structured classes for meditation, this program offers the perfect opportunity to integrate meditation into your routine.",
            // 'Click now to meditate daily!',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Click now to meditate daily!',
          text: [
            // 'Join on any date. Payments stay valid for a month from the date of joining.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Course Begins',
          text: [
            'Join on any date. Payments stay valid for a month from the date of joining.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Sessions conducted',
          text: [' Tuesday, Thursday and Saturday'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: ['7:00 am - 7:45 am (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [' Rs 1100/- '],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Please make your payments one day before the date you wish to start the class.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Please share a screenshot of your order receipt with us at info@theyogainstitute.org',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Rules and Regulations',
          points: [
            {
              listItem:
                'Missed classes cannot be covered in other sessions.',
              subItems: [],
            },
            {
              listItem:
                'Any form of recording of classes is strictly prohibited.',
              subItems: [],
            },
            {
              listItem: 'Change of batches is not permitted.',
              subItems: [],
            },
            {
              listItem: 'Fees once paid are non-refundable.',
              subItems: [],
            },
          ],
        },
      },
    ],
  },
  15: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: ' Satsang ',
    key: 'satsang',
    isRegular: true,
    tenure: '1 day',
    weekends: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'SATSANG_FORM_CONFIRMATION_MAIL',
      templateOffline: {
        templateResidential: 'SATSANG_FORM_CONFIRMATION_MAIL',
        templateNonResidential: 'SATSANG_FORM_CONFIRMATION_MAIL',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset80}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset80}`,
    mostPopular: false,
    metaDescription:
      'Towards the end, there is an enlightening question and answer session. ',
    rating: 5,
    colorCode: '#DDAB58',
    timing: 'Every Sunday between 9.30 am and 10.30am',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: ['1st Dec 2024', '8th Dec 2024', '15th Dec 2024', '22nd Dec 2024', '29th Dec 2024'
      , '5th Jan 2025', '12th Jan 2025', '19th Jan 2025', '26th Jan 2025'
      , '2nd Feb 2025', '9th Feb 2025', '16th Feb 2025', '23rd Feb 2025'
    ],
    online: true,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,
    newName: '',
    enrollInfo: {
      duration: "Every Sunday",
      timings: [
        {
          day: "Every Sunday",
          time: "9.30 am and 10.30am"
        }
      ]
    },
    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'The Yoga Institute’s Founder, Shri Yogendraji introduced Satsang with an objective for people to come together and find solutions for their worldly problems. It takes place on Sundays and is open to all so that everyone can benefit from the 1-hour session.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'The highlighted aspect of Satsang is a discourse by the Spiritual Guru and Director of TYI, Dr. Hansaji whoshares her enlightening wisdom on various topics including health &amp; wellness, relationships, career, food,parenting, family and so on. Through her compassionate and personalized approach, Dr. Hansaji has helped countless individuals overcome their challenges and find greater health and well-being.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'You can get your queries resolved by Dr. Hansaji as she is dedicated to helping you find the support and guidance you need to thrive. She helps people solve their problems by focusing on the root cause of their issues, rather than simply addressing symptoms. She understands that many physical and emotional problems are interconnected and that by addressing the underlying causes, individuals can experience long-term relief.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Yoga is a powerful tool for managing physical, mental and emotional challenges and helping individuals find greater peace and balance in their lives. Satsang helps bring positivity and awareness into our lives. Dr. Hansaji shares profound wisdom of the ancient scriptures and a Yogic way of living which enhances the lives of all the satsangis and is relevant in the present time and age.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Towards the end, there is an enlightening question and answer session.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'About the programme',
          text: [' Every Sunday between 9.30 am and 10.30am'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Prerequisites',
          text: ['The programme is open to all. No age bar.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Registration',
          text: [' free entry - on the spot registration.'],
        },
      },
    ],
  },
  16: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    weekends: true,
    title: 'Weekend Yoga Asana Classes - (Men & Women) - On Campus',
    key: 'weekend-classes',
    isRegular: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    newName: 'Weekend Asana Classes (Men & Women)',
    days: [
      'Saturday & Sunday'
    ],
    time: [
      'Saturday: 8:00 am - 9:30 am (IST)',
      'Sunday: 8:00 am - 9:30 am (IST) or 10:30 am - 12:00 pm (IST)'
    ],
    image: `${baseDomain}${courseAssets.courseAsset77}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset110}`,
    mostPopular: true,
    metaDescription:
      'Towards the end, there is an enlightening question and answer session.',
    rating: 5,
    colorCode: '#D3A29D',
    timing:
      'Saturday & Sunday: Saturday: 8:00 am - 9:30 am (IST) and Sunday: 8:00 am - 9:30 am (IST) or 10:30 am - 12:00 pm (IST)',
    fees: {
      onlineFee: 1100,
      // onlineFee: 0,
      offlineFee: { residentialFee: 0, nonResidentialFee: 1000 },
    },
    category: 'classes',
    dates: ['Saturday', 'Sunday'],
    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Saturday & Sunday",
          time: "Saturday: 8:00 am - 9:30 am (IST)"
        },
        {
          day: "Saturday & Sunday",
          time: "Sunday: 8:00 am - 9:30 am (IST) or 10:30 am - 12:00 pm (IST)"
        },
      ]
    }
    ,
    online: false,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: false,
    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Struggling to meet your yoga goals during the week? No worries, we got you! You have the option to register for regular weekend yoga class with The Yoga Institute. These are regular weekend yoga classes for those who are unable to attend on weekdays owing to other obligations. Weekend Yoga Asana Regular Class is an excellent course for daily yoga practise. Yoga Asanas, Pranayamas, Kriyas, and food and Yogic lifestyle suggestions are all covered in these weekend yoga classes. Participants are divided into groups based on their health status, and each group receives distinct training. In addition, as needed, all students receive individual attention.'
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Duration',
          points: [
            {
              listItem:
                'Weekend Yoga Classes are of 1 and half hour duration held every Saturday and Sunday',
              subItems: [],
            },
            {
              listItem:
                'A minimum attendance of 3 months is recommended, to experience the difference in your life. Can join on any day of the month.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Timing ',
          text: [''],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Saturday & Sunday',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Saturday',
          text: ['8:00 am - 9:30 am - 5:00 pm to 6:30 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Sunday',
          text: ['8:00 am - 9:30 am (IST) or 10:30 am - 12:00 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees ',
          text: [''],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Monthly Fees',
          text: ['Rs. 1100/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Six Months Fees',
          text: ['Rs. 6600/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Annual Fees',
          text: ['Rs. 11000/-'],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: 'Note',
          text: [' Kindly note the fees once paid will not be refundable. '],
        },
      },
    ],
  },
  17: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Yog Prayas - Online',
    key: 'yog-prayas-online',
    isRegular: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    newName: 'Yog Prayas',
    days: ['Monday', 'Wednesday', 'Friday'],
    time: [
      'Morning: 5:30 am - 6:30 am (IST) ',
      'Evening: 6:00 pm - 7:00 pm (IST)'
    ],
    image: `${baseDomain}${courseAssets.courseAsset82}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset82}`,
    mostPopular: false,
    metaDescription:
      'This is for people who are looking forward to yogic progress after being familiar with basic Yogasanas.',
    rating: 5,
    colorCode: '#C48E8C',
    join: true,
    timing:
      'Monday, Wednesday & Friday : Morning: 5:30 am - 6:30 am (IST) or Evening: 6:00 pm - 7:00 pm (IST) *',
    fees: {
      onlineFee: 1500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: ['5:30 am to 6:30 am ', '6:00 pm to 7:00 pm '],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,
    tenure: '',
    courseType: 'Regular Class - 2',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Regular Asana Classes',
    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Monday, Wednesday & Friday",
          time: "Morning: 5:30 am - 6:30 am (IST)"
        },
        {
          day: "Monday, Wednesday & Friday",
          time: "Evening: 6:00 pm - 7:00 pm (IST) *"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },
    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'This is for people who are looking forward to yogic progress after being familiar with basic Yogasanas. The focused Sadhaka’s, who just doesn’t want to become Yoga Professional, but aims for an even bigger goal, SELF DEVELOPMENT.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title:
            'Few of the basic qualities we look forward to in a Sadhaka for this yoga program are',
          text: [
            'Focus for yoga practice',
            'Curiosity for yogic knowledge',
            'Application of Yogic Principals in Practical life',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'PROGRAMME DETAILS',
          points: [
            {
              listItem:
                'The yoga program offers the best time for YOGASANA PRACTICE (early morning) in relation to Sattvik energy of the rising sun.',
              subItems: [],
            },
            {
              listItem:
                'Every day consultation time for 15 mins prior and 15 mins after the 1-hours YOG ABHYAS (Yogasana, Pranayama and basic Meditation)',
              subItems: [],
            },
            {
              listItem:
                'Personal attention- as seats are limited – 30 seats only each batch',
              subItems: [],
            },
            {
              listItem:
                'A balance of Yoga Asanas, Pranayamas, Relaxation techniques and much more.',
              subItems: [],
            },
            {
              listItem:
                'The yoga practice class shall be a live session via the Zoom App.',
              subItems: [],
            },
            {
              listItem:
                'Once registered and payment is made, we shall be sending you links on your email id or WhatsApp.',
              subItems: [],
            },
            {
              listItem:
                'This online yoga program is open to both males and females.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: ['Rs. 1500/-'],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'RULES AND REGULATIONS',
          points: [
            {
              listItem:
                'Carry forwarding of missed sessions, recording of the session, and shifting of the batch is not permitted.',
              subItems: [],
            },
            {
              listItem:
                'Please note fees once paid will be non-refundable.',
              subItems: [],
            },
            {
              listItem:
                'Join on any date. Your payment is valid for one month from the date of joining.',
              subItems: [],
            },
            {
              listItem:
                '3 Days a week – Morning Batch – Monday, Wednesday, Friday. – 1 Hour sessions will continue as it is.',
              subItems: [],
            },
            {
              listItem:
                '3 Days a week – Evening Batch – Monday, Wednesday, Friday. – 1 Hour sessions',
              subItems: [],
            },
            {
              listItem:
                'Rest is the same as the morning batch, a mix of philosophy and regular online yogaasanas session.',
              subItems: [],

            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'WEEKDAYS SCHEDULE',
          text: [
            'Monday, Wednesday & Friday : Morning: 5:30 am - 6:30 am (IST) or Evening: 6:00 pm - 7:00 pm (IST) (Join on any date)',
          ],
        },
      },
    ],
  },
  18: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Samattvam (Health Checkup) - On Campus',
    key: 'samattvam',
    isRegular: true,
    tenure: '1 day',
    weekends: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    // templateId: {
    //   templateOnline: 'SAMATTVAM_FORM_CONFIRMATION_MAIL',
    //   templateOffline: {
    //     templateResidential: 'SAMATTVAM_FORM_CONFIRMATION_MAIL',
    //     templateNonResidential: 'SAMATTVAM_FORM_CONFIRMATION_MAIL',
    //   },
    // },
    templateId: {
      templateOnline: 'SAMATTVAM_CLASSES',
      templateOffline: {
        templateResidential: 'SAMATTVAM_CLASSES',
        templateNonResidential: 'SAMATTVAM_CLASSES',
      },
    },
    image: `${baseDomain}${homeAssets.homeAsset16}`,
    cardImage: `${baseDomain}${homeAssets.homeAsset16}`,
    mostPopular: false,
    metaDescription:
      'Samatvam is one-of-a-kind personalised wellbeing program launched by The Yoga Institute under the guidance of Dr. Hansaji Yogendra. The program focuses on providing individual guidance for life management.',
    rating: 5,
    colorCode: '#DDAB58',
    timing: 'Every Saturday: 2.00 p.m. - 5.00 p.m. (IST)',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    dates: ['7th Dec 2024', '14th Dec 2024', '21st Dec 2024', '28th Dec 2024'],
    category: 'classes',

    online: false,
    onCampus: false,//true
    nonResidential: false,//true
    residential: false,
    certficate: false,

    courseType: "samatwam health checkup",
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Camps & Workshops',
    newName: '',
    enrollInfo: {
      duration: "Only on Saturday’s",
      timings: [
        {
          day: "Every Saturday",
          time: "2.00 p.m. - 5.00 p.m. (IST)"
        }
      ]
    },

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: false,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Are you looking for the right advice to deal with your health problems? Visit The Yoga Institute every Saturday between 2 pm to 5:00 pm and get advised from leading health experts and yoga practitioners.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Samatvam is one-of-a-kind personalised wellbeing program launched by The Yoga Institute under the guidance of Dr. Hansaji Yogendra. The health program focuses on providing individual guidance for life management.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'The health program consists of a session conducted by Dr. Hansaji on the importance of implementing yoga in daily life. Individuals can also interact with medical experts from various fields and yoga counsellors to get the right guidance for their requirements. Every case is examined on its merit and recommendations are given accordingly. People who could benefit just by following simple yogic techniques are suggested to join specific yoga classes or yoga camps. Others who require medical intervention are instructed accordingly.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Samatvam is an essential pre-requisite for special yoga classes and regular weekend yoga classes conducted at the Institute.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Duration',
          text: ['Every Saturday: 2.00 p.m. - 5.00 p.m. (IST)', '1.30 p.m. - 2.00 p.m. : Registration (Outside C1 Hall)', '2.00 p.m. - 2.30 p.m. : Physical Parameter Assessments', '2.30 p.m. - 3.00 p.m. : Institute Overview & Asana Introduction', '3.00 p.m. - 3.30 p.m. : Lifestyle Management Video by  Dr. Hansaji Yogendra', '3.30 p.m. - 3.45 p.m. : Sattvik Snack', '3.45 p.m. - 4.10 p.m. :  Special Talk by Doctor/Counsellor', '4:10 p.m. : Personalized one-on-one consultations with:-', 'Doctor', 'Yoga Counsellor', 'Asana Teacher (if required) '],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Prerequisites',
          text: [
            'Please bring along all your health reports (medical papers), a pen and pad.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Registration',
          text: ['On Saturday only between 1.30 p.m. and 2.00 p.m.'],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Fees',
      //     text: ['Rs. 200/- per person (inclusive of snacks).'],
      //   },
      // },
    ],
  },
  19: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Couples’ Yoga Classes  - Online',
    key: 'couples-classes',
    isRegular: true,
    tenure: '1 day',
    weekends: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset77}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset116}`,
    mostPopular: false,
    metaDescription:
      'The Yoga Institute’s Couple Class is an absolute must for anyone who is in a relationship.',
    rating: 5,
    colorCode: '#C48E8C',
    timing: 'Third Sunday of every month from 5.00 p.m. to 7.00 p.m.',
    fees: {
      onlineFee: 3500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: [
      // '21st Jan 2024',
      '19th Jan 2025',

    ],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,

    courseType: "Couple's Classes Online",
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Regular Asana Classes',
    newName: 'Couples Classes',
    days: [
      'Third Sunday of every month'
    ],
    time: [
      '5.00 p.m. to 7.00 p.m.'
    ],
    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Third Sunday of every month",
          time: "5.00 p.m. to 7.00 p.m."
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: 'About Couples Yoga Class',
          text: [
            'Since 1973, The Yoga Institute has been conducting Couples Yoga Class to strengthen the bond, nurture the relationship, and build intimacy and trust among couples. Relationships need constant efforts from both partners and an immense amount of understanding and compatibility.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Why Choose Couples Class?',
          text: [
            'Yoga when performed with your partner brings multiple benefits as it creates more awareness of the self and others, enhances communication and fortifies the connection. Through regular yoga practice, couples will learn to share, grow, build, trust, bond and have fun with each other.',
            'Most importantly, couples learn to give each other space when required and resolve conflicts constructively and respectfully. The Yoga Institute’s Couples Class will train you to work as one unit, work towards making your relationship better and stronger and lead a healthy and stress-free life.',
            'The Yoga Institute’s Couples Class will cover the following topics that will help couples to make their relationship healthy and successful '
          ],
        },
      },
      {
        type: 'o-list',
        content: {
          title: '',
          points: [
            {
              listItem: 'Understanding the Importance of Marriage',
              subItems: [],
            },
            {
              listItem: 'Role of Honesty and Faithfulness',
              subItems: [],
            },
            {
              listItem: 'Being a Supportive Partner',
              subItems: [],
            },
            {
              listItem: 'Enhancing your Communication Skills',
              subItems: [],
            },
            {
              listItem: 'Coping with Difficulties',
              subItems: [],
            },
            {
              listItem: 'Building Spiritual Connection',
              subItems: [],
            },
            {
              listItem: 'Performing Marital Duties Selflessly',
              subItems: [],
            },
            {
              listItem: 'Acceptance, Patience & Understanding',
              subItems: [],
            },
            {
              listItem: 'Open to Adjustment',
              subItems: [],
            },
            {
              listItem: 'Financial Transparency & Management',
              subItems: [],
            },
            {
              listItem: 'Balancing Interpersonal relationships',
              subItems: [],
            },
            {
              listItem: 'Positive Role Models to Children',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'USP of Couples Class',
          text: [
            'Smt. Hansaji addresses the Couples Yoga Classes once a month where various topics are discussed to help the householders in their daily commitments. Lectures by eminent personalities work as guidance to the couples. Further, group discussions and personal sharing on specific topics are regular features, which have been well-appreciated by members.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Duration',
          text: [
            'Enrollment is for a calendar year. Meetings are held on the third Sunday of every month from 5.00 p.m. to 7.00 p.m.in the Institute. Additionally, co-ordinated couples meet once during the same month in their respective groups.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Prerequisites',
          text: [
            'Couples should be connected with some activity with The Yoga Institute.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Registration',
          text: ['Starts 21st Jan 2024'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [
            'Annual fees - Rs. 3,500/- (per year for a couple)',
            '*Please note: Fees once paid will be non-refundable.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Note:',
          text: [
            'Please take screenshot of your order receipt immediately after the payment is made to info@theyogainstitute.org',
          ],
        },
      },
    ],
  },
  20: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    weekends: true,
    title: 'Weekend Yoga Asana Classes - (Men & Women) - Online',
    key: 'weekend-classes-online',
    isRegular: true,
    batch: 1,
    newName: 'Weekend Asana Classes (Men & Women)',
    days: [
      'Saturday & Sunday'
    ],
    time: [
      '7:30 am - 9:00 am (IST)'
    ],
    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Saturday & Sunday",
          time: "7:30 am - 9:00 am (IST)"
        }
      ]
    }
    ,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${homeAssets.homeAsset13}`,
    cardImage: `${baseDomain}${homeAssets.homeAsset13}`,
    mostPopular: true,
    metaDescription:
      'Weekend Yoga Regular Class is an excellent course for daily yoga practice.',
    rating: 5,
    colorCode: '#C48E8C',
    timing: 'Saturday & Sunday: 7:30 am - 9:00 am  (IST)',
    fees: {
      onlineFee: 1100,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: ['7:30 am to 9:00 am', ''],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,
    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Struggling to meet your yoga goals during the week? No worries, we got you! You have the option to register for regular weekend yoga asana class with The Yoga Institute. These are regular classes for those who are unable to attend on weekdays owing to other obligations. Weekend Yoga Asana Regular Class is an excellent course for daily yoga practice. Yoga Asanas, Pranayamas, Kriyas, and food and Yogic lifestyle suggestions are all covered in these weekend yoga classes. Participants are divided into groups based on their health status, and each group receives distinct training. In addition, as needed, all students receive individual attention.'
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Duration',
          points: [
            {
              listItem:
                'Weekend Yoga Classes are of 1 and half hour duration held every Saturday and sunday',
              subItems: [],
            },
            {
              listItem:
                'A minimum attendance of 3 months is recommended, to experience the difference in your life. Can join on any day of the month.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Timing',
          text: ['Saturday & Sunday: 7:30 am - 9:00 am  (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Monthly Fees',
          text: ['Rs. 1100/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Six Months Fees',
          text: ['Rs. 6600/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Annual Fees',
          text: ['Rs. 11000/-'],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: '',
          text: ['Please note : Yoga Classes will be conducted on Zoom'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            '*Note: Kindly note the fees once paid will not be refundable',
          ],
        },
      },
    ],
  },
  21: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Regular Pregnancy Yoga Classes - Online & On Campus',
    key: 'regular-pregnacy-classes',
    isRegular: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAssets115}`,
    cardImage: `${baseDomain}${courseAssets.courseAssets115}`,
    mostPopular: false,
    metaDescription:
      'Begin your pregnancy journey with The Yoga Institute. We will get you ready to welcome your little bundle of joy in a healthy and happy way!',
    rating: 5,
    colorCode: '#C48E8C',
    timing: '8:30 am to 9:30 am (Monday, Wednesday, Friday)',
    fees: {
      onlineFee: 1500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 1500 },
    },
    category: 'classes',
    dates: ['8:30 am to 9:30 am'],
    online: true,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,

    tenure: '',
    courseType: 'Regular Meditation Foundation Course',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Self Help',
    courseSubType: 'Regular Asana Classes',
    newName: 'Regular Pregnancy Yoga Class',
    days: [
      'Monday',
      'Wednesday',
      'Friday'
    ],
    time: [
      '8:30 am to 9:30 am'
    ],
    enrollInfo: {
      duration: "Upto 12 months",
      timings: [
        {
          day: "Monday, Wednesday, Friday",
          time: "8:30 am to 9:30 am"
        }
      ]
    }
    ,

    courseCertificate: 'NO',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: true,
      nonResidentialMode: true
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: 'About The Class:',
          text: [
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Pregnancy and parenthood are the two most beautiful and crucial phases of a woman’s life.',
            'During pregnancy, a woman’s body goes through multiple changes. The techniques offered at The Yoga Institute are tailored to assist the would-be-mother to adapt to these changes, cope with her emotions, be mindful, build strength, enjoy the process, connect with the baby and be prepared to welcome the child both on mental and physical level.',
            'So, come and join the Pregnancy Yoga classes for a healthy and happy pregnancy and easy delivery.'
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Class Offerings:',
          points: [
            {
              listItem: 'Yoga Asana',
              subItems: [],
            },
            {
              listItem: 'Guided Meditation',
              subItems: [],
            },
            {
              listItem: 'Breathing Technique',
              subItems: [],
            },
            {
              listItem: 'Yoga for Easy Delivery',
              subItems: [],
            },
            {
              listItem: 'Garbhsanskar',
              subItems: [],
            },
            {
              listItem: 'Prayer & Mantra Chanting',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Days:',
          text: ['Monday, Wednesday, Friday'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Time:',
          text: ['8:30 am to 9:30 am'],
        },
      },

      {
        type: 'paragraph',
        content: {
          title: 'Fees:',
          text: ['Rs. 1,500/- per month'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '*Please Note:',
          text: [
            'Women can join only after completing the first trimester of their pregnancy',
          ],
        },
      },
    ],
  },
  22: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'MA Yoga Shastra',
    key: 'ma-yoga-shastra',
    isRegular: true,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'MA_YOGA_SHASTRA',
      templateOffline: {
        templateResidential: 'MA_YOGA_SHASTRA',
        templateNonResidential: 'MA_YOGA_SHASTRA',
      },
    },
    // image: `${baseDomain}${courseAssets.courseAssets115}`,
    // cardImage: `${baseDomain}${courseAssets.courseAssets115}`,
    image: `${extraImage.mayogaImg}`,
    cardImage: `${extraImage.mayogaImg}`,
    mostPopular: false,
    metaDescription:
      'The Master of Arts (MA) in Yoga Shastra at The Yoga Institute, Santacruz East, is a comprehensive two-year program designed to deepen your understanding and practice of yoga.',
    rating: 5,
    colorCode: '#C48E8C',
    timing: '4:30 pm to 8:30 pm (Monday, Wednesday, Friday) 9:00 am to 3:00 pm (Saturday)',
    fees: {
      // onlineFee: 1500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 40000 },
      internationalFee: { residentialFee: 0, nonResidentialFee: 329837 },
    },
    category: 'classes',
    dates: [],
    online: false,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,
    details: [
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'About The Class:',
      //     text: [
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Overview',
          text: [
            'The Master of Arts (MA) in Yoga Shastra at The Yoga Institute, Santacruz East, is a comprehensive two-year program designed to deepen your understanding and practice of yoga. Established in 1918 by Shri Yogendraji, The Yoga Institute is the worlds oldest organized yoga centre. Our institute has a rich legacy of over 100 years, promoting the ideals, wisdom, and practice of yoga and the yogic way of life. This course integrates ancient yogic wisdom with modern scientific knowledge, preparing students for advanced studies and professional practice in yoga.'
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'The MA Yoga Shastra program includes a blend of theoretical and practical components, covering a wide range of topics such as:',
          points: [
            {
              listItem: 'Semester - I',
              subItems: ['Fundamentals of Indian Philosophy', 'Human Anatomy and Physiology', 'Yoga Tradition and Classical Texts', 'Practical and Yoga Teaching'],
            },
            {
              listItem: 'Semester - II',
              subItems: ['Principles of Indian Philosophy', 'Patanjali Yoga Sutras', 'Yoga Upanishad and modern philosophers', 'Applied Yoga', 'Practical and Yoga Teaching'],
            },
            {
              listItem: 'Semester - III',
              subItems: ['Traditional Yoga Texts – I', 'Yoga and allied sciences', 'Research Methodology', 'Yoga applications', 'Practical and Yoga Teaching'],
            },
            {
              listItem: 'Semester - IV',
              subItems: ['Traditional Yoga Texts – II', 'Western Psychology', 'Yoga Upanishad', 'Nature cure therapy', 'Practical and Yoga Teaching'],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Eligibility',
          text: ['Educational Qualification: Any Bachelors degree from a recognized university.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Admission Process',
          text: ['Application Deadline: 10th August', 'Course Commencement Date: 16th August 2024', 'Selection Criteria: Based on merit and timely registration.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Pricing',
          text: ['For National Students : ₹40,000 per year', 'For International Students : $3950 per year'],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Career Opportunities',
          points: [
            {
              listItem: 'Graduates of the MA Yoga Shastra program can pursue careers as:',
              subItems: ['Yoga Instructors', 'Yoga Advisors', 'Academic Professors in Yoga Studies', 'Yoga Researchers',],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'How to Apply',
          text: [
            'Interested candidates can apply through our website or contact our admissions office at Shri Yogendra Marg, Prabhat Colony, Santacruz East, Mumbai - 400055 India',
            'For more information, please visit our website or reach out to us at info@theyogainstitute.org or +91-22-26110506, +91-22-26103568. '
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Why Study MA Yoga Shastra at The Yoga Institute?',
          text: [
            'Rich Legacy: The Yoga Institute, established in 1918, is the world`s oldest organized yoga center.',
            'Experienced Faculty: Learn from highly qualified and experienced yoga practitioners and scholars.',
            'Holistic Approach: Our program emphasizes a holistic approach to yoga, integrating physical, mental, emotional, and spiritual aspects.',
            'Personalized Attention: We maintain a low student-to-teacher ratio, ensuring personalized attention and guidance',
            'State-of-the-Art Facilities: Enjoy access to advanced yoga studios, libraries, and research centers.',
            'Innovative Teaching Methods: Our faculty uses innovative teaching methods that blend traditional knowledge with modern pedagogical techniques.',
            'Research Opportunities: Engage in research in yoga and its applications, contributing to the growing body of scientific literature.',
            'Global Recognition: Our graduates are recognized globally and are in demand for their expertise and knowledge.',
            'Lifelong Learning: We offer lifelong learning opportunities through workshops, seminars, and advanced courses.',
            'Join us to embark on a transformative journey in the world of Yoga Shastra!'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Contact Us',
          text: [
            'The Yoga Institute',
            'Santacruz East',
            'Mumbai, Maharashtra 400055',
            'Email id - info@theyogainstitute.org',
            'Phone -  +91-22-26110506, +91-22-26103568.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Apply Now',
          text: [
            ' ',
            '',
            '* Affiliation: This course is affiliated with Kavikulaguru Kalidas Sanskrit University (KKSU) Ramtek, Nagpur, awarded with "A+" grade by the National Assessment and Accreditation Council (NAAC).'
          ],
        },
      },
    ],
  },
}

const extra = {
  1: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    weekDays: true,
    key: 'nutri-diet',
    title: 'Nutri Diet Clinic',
    cardImage: `${baseDomain}${nutriDiet.nutriDietCard}`,
    mostPopular: true,
    colorCode: '#B77E7E',
    dates: [],
    metaDescription: 'Get customized diet plans and holistic approach to health and wellness'
  }
}

const certificate = {
  1: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: 'Wednesday, Friday & Saturday: 6:00 pm - 7:00 pm (IST)',
    textdescription2: 'Mondays, Tuesdays & Thursdays: 11:30 am - 12:30 pm (IST) ',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Certificate Course on Advanced Pranayama Techniques ( Pranayama Online Course)',
    key: 'certificate-course-on-advanced-pranayama-techniques',
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset72}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset72}`,
    metaDescription: 'Knowledge of nadi system and energy channels',
    mostPopular: true,
    rating: 5,
    colorCode: '#B77E7E',
    timing: ' ',
    fees: {
      onlineFee: 24000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'certificate-course',
    dates: [
      // '5th Aug to 26th Sep 2024· Morning', 
      // '7th Aug to 28th Sep 2024· Evening',
      // '6th November 2024 to 28th December 2024· Evening',
      // '11th Nov 2024 to 2nd Jan 2025· Morning',
      '3rd February to 27th March 2025· Morning',
      '5th May to 26th June 2025· Morning',
      '7th May to 28th June 2025· Evening',
      '4th August to 25th September 2025· Morning',
      '3rd November to 25th December 2025· Morning',
    ],

    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '',
    courseType: 'Certificate Yoga Therapy Course - Online',
    language: 'English',
    preRequisite: 'None',
    courseCategory: 'Educational',
    courseSubType: 'Special Course - Pranayama',
    newName: 'Certificate Course on Advanced Pranayama Techniques',
    days: [
      'Wednesday',
      'Friday',
      'Saturday',
      'Mondays',
      'Tuesdays',
      'Thursdays'
    ],

    time: [
      '6:00 pm - 7:00 pm (IST)',
      '11:30 am - 12:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "24 sessions",
      timings: [
        {
          day: "Wednesday, Friday & Saturday",
          time: "6:00 pm - 7:00 pm (IST)"
        },
        {
          day: "Mondays, Tuesdays & Thursdays",
          time: "11:30 am - 12:30 pm (IST)"
        }
      ]
    }
    ,

    courseCertificate: 'Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Now that you have mastered the basics of pranayama practices, it is time to go one step ahead! The certificate course on advanced pranayama techniques by The Yoga Institute will help you get the hang of various advanced pranayama techniques along with the right teaching methodology to teach these breathing techniques efficiently.',
            'Pranayamas are powerful yogic breathing techniques that boost your body’s bioenergy as well as strengthen both body and mind. Maharishi Patanjali mentions in his text “Yoga Darshanam” which was written around 2500 years ago that pranayamas are powerful techniques to achieve the highest degree of breathe awareness and profound meditative state. It prepares you for deep meditation.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Advanced Pranayama Online Course content',
          points: [
            {
              listItem: 'Knowledge of nadi system and energy channels',
              subItems: [],
            },
            {
              listItem: 'Bandha yoga, yoga mudras and mantras in pranayama practice',
              subItems: [],
            },
            {
              listItem: 'Teaching techniques to become a capable teacher',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'You will receive a printed manual with a detailed list of subjects.',
            'access the detailed syllabus here',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: ['Course Timings and fees:'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Online Yoga Sessions',
          text: [
            'Morning batch : Mondays, Tuesdays & Thursdays: 11:30 am - 12:30 pm (IST)',

            'Evening batch: Wednesday, Friday & Saturday: 6:00 pm - 7:00 pm (IST)',

            '24 sessions of one hour each',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [

            '2024 : Rs. 24,000/-'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'NOTE',
          text: [
            'THE PRINTED MANUAL IS COMPULSORY FOR ALL ASPIRING YOGA STUDENTS; HOWEVER, THE KIT IS MANDATORY BUT NOT NECESSARY TO BE BOUGHT FROM THE INSTITUTE. (THE COURIER CHARGES SHALL BE INCURRED BY THE STUDENTS.)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'NOTE',
          text: [
            'All study material available for the yoga students for any course in physical (hard copy) only. The same can be collected by the student directly from the Institute or can be delivered to them by courier, charges of which will have to be borne by the student.',
          ],
        },
      },
    ],
  },
  // 2: {
  //      id: '',
  // textdescription6: '',
  // textdescription7: '',
  // textdescription8: '',
  // textdescription9: '',
  // textdescription10: '',
  textdescription1: '',
  textdescription2: '',
  textdescription3: '',
  textdescription4: '',
  textdescription5: '',
  //   title: 'Certificate Course on Yoga Sutra - Online',
  //   key: 'certification-course-on-yoga-sutra-online',
  //   batch: 1,
  //   // templateId: 'GENERAL_AUTO_EMAIL',
  //   templateId: {
  //     templateOnline: 'GENERAL_AUTO_EMAIL',
  //     templateOffline: {
  //       templateResidential: '',
  //       templateNonResidential: '',
  //     },
  //   },
  //   image: `${baseDomain}${courseAssets.courseAsset73}`,
  //   cardImage: `${baseDomain}${courseAssets.courseAsset73}`,
  //   metaDescription:
  //     'The Yoga Sutras help you unravel the mystical, the seemingly miraculous possibilities and powers that lie dormant within you',
  //   mostPopular: false,
  //   rating: 5,
  //   colorCode: '#D3A29D',
  //   timing: '1st Sunday of every month: 10:00 am - 12:30 pm (IST)',
  //   fees: {
  //     onlineFee: 15000,
  //     offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
  //   },
  //   category: 'certificate-course',
  //   dates: ['10:00 am - 12:30 pm'],
  //   online: true,
  //   onCampus: false,
  //   nonResidential: false,
  //   residential: false,
  //   certficate: false,
  //   details: [
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'What is the course about?',
  //         text: [
  //           'Patanjali’s Yoga Sutras say that by learning to develop a certain introspective awareness, with a commitment to making objective observations of oneself, we can begin a journey away from the pressures and pains of daily life. We become able to see things as they are, with a greater sense of perspective. It is not escapism, but instead a kind of ‘waking up’.',
  //           'Think of everything that you have always wanted to do. And think of what comes in the way of that. Even if it is hard to admit, the answer is – yourself. With this dynamic and powerful course on the Yoga Sutra of Patanjali, you will unlock the key to all that you want from yourself, and your life.',
  //           'The Yoga Sutras help you unravel the mystical, the seemingly miraculous possibilities and powers that lie dormant within you. Come, experience the power that is waiting within you!',
  //           'access the detailed syllabus here',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Eligibility',
  //         text: ['Open to all.  Prior knowledge of Yoga or the earlier chapters though not a prerequisite, would be helpful.'],
  //       },
  //     },
  //     // {
  //     //   type: 'paragraph',
  //     //   content: {
  //     //     title: 'Details (in tabular form)',
  //     //     text: [''],
  //     //   },
  //     // },
  //     // {
  //     //   type: 'paragraph',
  //     //   content: {
  //     //     title: 'Schedule',
  //     //     text: [''],
  //     //   },
  //     // },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Prerequisites',
  //         text: [
  //           'Open to all. Prior knowledge of yoga not required, though helpful.',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Duration',
  //         text: ['1 year – 1st & 3rd 2022, Sunday of every month'],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Fees',
  //         text: [
  //           'Rs. 15000/-',
  //           'Please note fees once paid will be non-refundable.',
  //         ],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Timings',
  //         text: ['1st Sunday of every month: 10:00 am - 12:30 pm (IST)'],
  //       },
  //     },

  //     // {
  //     //   type: 'paragraph',
  //     //   content: {
  //     //     title: 'Guidelines',
  //     //     text: [''],
  //     //   },
  //     // },
  //     // {
  //     //   type: 'paragraph',
  //     //   content: {
  //     //     title: 'Contact Details',
  //     //     text: [''],
  //     //   },
  //     // },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Registration',
  //         text: ['REGISTRATIONS ARE CLOSED..STAY TUNED FOR OUR NEXT BATCH.'],
  //       },
  //     },
  //     {
  //       type: 'paragraph',
  //       content: {
  //         title: 'Conducted By',
  //         text: ['Shri Kartik Vyas'],
  //       },
  //     },
  //   ],
  // },
  3: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Certificate Program on Yoga for Cancer - Online & On Campus',
    key: 'certificate-program-on-yoga-for-cancer',
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset74}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset74}`,
    metaDescription:
      'We offer a supportive and compassionate support group to understand and discuss Cancer confidently and intelligently.',
    mostPopular: true,
    rating: 5,
    colorCode: '#C48E8C',
    timing: 'Fridays & Saturdays: 3:00 pm - 5:00 pm (IST)',
    fees: {
      onlineFee: 24000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'certificate-course',
    dates: [
      // '2nd Aug to 7th Sep 2024',
      // '15th Nov - 21st Dec 2024',
      '7th Feb to 15th Mar 2025',
      '2nd May to 7th Jun 2025',
      '1st Aug to 6th Sep 2025',
      '7th Nov  to 13th Dec 2025'
    ],
    online: true,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: true,

    tenure: '',
    courseType: 'Certificate Yoga Therapy Course - Online',
    language: 'English',
    preRequisite: '200 Hour Teacher Training Course',
    courseCategory: 'Educational',
    courseSubType: 'Special Course - Cancer',
    newName: 'Certificate Program on Yoga for Cancer',
    days: [
      'Fridays',
      'Saturdays'
    ],
    time: [
      '3:00 pm - 5:00 pm (IST)'
    ],

    enrollInfo: {
      duration: "12 session",
      timings: [
        {
          day: "Fridays & Saturdays",
          time: "3:00 pm - 5:00 pm (IST)"
        }
      ]
    },

    courseCertificate: 'Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: 'Contact Details',
          text: [
            'Yoga offers a safe, effective and low cost modality that can add considerable value to any Cancer care continuum. This yoga for cancer patients program is designed to create a cadre of Yoga Professionals who are well-trained in Cancer care that is accessible and effective. The Yoga Certificate Program is a specialized Yoga module for the special needs of Cancer patients. This oncology based program trains Yoga teachers to safely and effectively work with Cancer survivors. Learn yoga practices and yoga techniques to address Cancer specific issues such as scar tissue, bone loss, lymphedema, weight gain, fatigue, sleeplessness and stress.',
            'We offer a supportive and compassionate support group to understand and discuss Cancer confidently and intelligently.',
          ],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'From',
      //     text: ['__________ to __________'],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Days',
          text: ['Fridays & Saturdays: 3:00 pm - 5:00 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: [
            '2024: Rs. 24,000/-'
          ],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Eligibility',
      //     text: [
      //       'Completion of 500 hrs or 900 hrs TTC programme is mandatory.',
      //     ],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Write to us at info@theyogainstitute.org or call us at +91-22-26122185 or +91-22-26110506',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Eligibility',
          text: ['Eligible for those who have completed minimum 200 hours TTC from any Institution.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Rules',
          text: ['Recordings are strictly prohibited.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Note',
          text: [
            'All study materials are available in physical form only.',
            'Physical copies maybe collected from the Institute.',
            'For delivery, the courier charges will have to be borne by the participant.',
          ],
        },
      },
    ],
  },
  4: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Certificate Program on Yoga for Lung Cancer - Online',
    key: 'certification-program-on-yoga-for-lung-cancer-online',
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset113}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset114}`,
    mostPopular: false,
    metaDescription:
      'The programme includes specific yoga asanas, pranayamas, mudras, meditation and dietary practices that will aid you in your cancer care and management',
    rating: 5,
    colorCode: '#B77E7E',
    timing: 'Tuesdays to Saturday: 5:00 p.m. - 7:00 p.m. (IST)',
    fees: {
      onlineFee: 10000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'certificate-course',
    dates: [
      // '2nd July to 6th July 2024',
      '7th Oct to 11th Oct 2025'
    ],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,

    tenure: '',
    courseType: 'Certificate Yoga Therapy Course - Online',
    language: 'English',
    preRequisite: '500 Hour Teacher Training Course, 900 Hour Teacher Training Course ',
    courseCategory: 'Educational',
    courseSubType: 'Special Course - Cancer',
    newName: 'Certificate Program on Yoga for Lung Cancer',
    days: [
      'Tuesdays',
      'Saturday'
    ],
    time: [
      '5:00 p.m. - 7:00 p.m. (IST)'
    ],
    enrollInfo: {
      duration: "5 days",
      timings: [
        {
          day: "Tuesdays to Saturday",
          time: "5:00 p.m. - 7:00 p.m. (IST)"
        }
      ]
    },

    courseCertificate: 'Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Help boost your body’s efforts in its fight against Lung Cancer with the Yoga for Lung Cancer Programme. The programme includes specific yoga asanas, pranayamas, mudras, meditation and dietary practices that will aid you in your cancer care and management. The theory part of the course will help you understand the anatomy and physiology of the yoga for lungs, causes of lung cancer, its symptoms and type, stages of lung cancer, treatments and side effects of treatments. The Yoga based practical part of the programme will be a holistic approach to Cancer management and care usingc yoga asanas, pranayamas and dietary modifications.'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Duration',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Time',
          text: ['Tuesdays to Saturday: 5:00 p.m. - 7:00 p.m. (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees',
          text: ['10,000/-'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Eligibility:',
          text: [
            'Completion of the Basic Programme on Yoga for Cancer Rehabilitation and Recovery.',
          ],
        },
      },
    ],
  },
  5: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Certificate Yoga Therapy Course - Online & On Campus',
    key: 'certificate-yoga-therapy-course-online',
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'GENERAL_AUTO_EMAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset75}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset75}`,
    mostPopular: true,
    metaDescription:
      'Become a certified Yoga therapist through The Yoga Institute.',
    rating: 5,
    colorCode: '#D3A29D',
    timing: 'Monday, Wednesday & Friday: 5:30 pm - 7:30 pm (IST)',
    fees: {
      onlineFee: 38500,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'certificate-course',
    dates: [
      // '28th Aug  to 20th Dec 2024'
      // '3rd Jan to 28th Apr 2025',
      // '14th May to 3rd Sep 2025',
      // '5th Sep to 29th Dec 2025',
      '3rd Jan to 28th Apr 2025',
      '14th May to 3rd Sep 2025',
      '5th Sep to 29th Dec 2025'
    ],
    online: true,
    onCampus: true,
    nonResidential: false,
    residential: false,
    certficate: true,

    tenure: '',
    courseType: 'Certificate Yoga Therapy Course - Online & On Campus',
    language: 'English',
    preRequisite: '500 Hour Teacher Training Course, 900 Hour Teacher Training Course ',
    courseCategory: 'Educational',
    courseSubType: 'Special Course - Therapy',
    newName: 'Certificate Yoga Therapy',
    days: [
      'Monday',
      'Wednesday',
      'Friday'
    ],
    time: [
      '5:30 pm - 7:30 pm (IST)'
    ],
    enrollInfo: {
      duration: "4 months",
      timings: [
        {
          day: "Monday, Wednesday & Friday",
          time: "5:30 pm - 7:30 pm (IST)"
        }
      ]
    },

    courseCertificate: 'Certificate',
    onlineInfo: {
      courseMode: true,
    },
    residentialInfo: {
      courseMode: false,
      residentialMode: false
    },
    nonResidentialInfo: {
      courseMode: false,
      nonResidentialMode: false
    },

    details: [
      {
        type: 'paragraph',
        content: {
          title: 'YOGA THERAPY COURSE',
          text: [
            'Become a certified Yoga therapist through The Yoga Institute',
            'Complete a one-stop destination to enhance your skills in Yogic Management of diseases through our YOGA THERAPY COURSE.',
            'This is a detailed and comprehensive 100 hours Yoga Therapy course wherein the yogic lifestyle and yogic management of diseases are taught in a practical and simple manner for easy understanding and teaching. This yoga therapy certification course will qualify you to become an extremely capable and confident Yoga Therapist to handle any case.'
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'What the course details?',
          points: [
            {
              listItem: 'Understanding of 95+ diseases:',
              subItems: [],
            },
            {
              listItem:
                'Practical application through Yogic management of each disease on the basis of',
              subItems: [
                'Yogic Diet,',
                'Specific day to day care in that condition,',
                'Relevant Yoga Asanas, Pranayamas and Kriyas',
                'Modification and demonstration of Yoga Therapy Techniques as per the Age and Body limitations in regards specific disease.',
                'Developing proper mental attitude to tackle the health conditions',
                'Lectures by Expert Guest Speakers from the field of Ayurveda and Specialist Doctors from various fields- for better understanding of the disease to help you design a Yoga Program for your clients.',
              ],
            },
            {
              listItem:
                'Lectures on Anatomy/ Physiology and Pathology of each health condition along with causes and symptoms will be given by Dr. Taruna Kardak and other visiting Medical Practitioners specialising in their particular fields.',
              subItems: [],
            },
            {
              listItem:
                'Lecture on basic Ayurveda to understand the body and the role of doshas in specific health conditions.',
              subItems: [],
            },
            {
              listItem:
                'Students will be encouraged to apply the yoga therapy learnt on their family and friends and prepare Case Studies of Combination Diseases to learn the practical application of the same while going through the course.',
              subItems: [],
            },
            {
              listItem: 'General & specialized topics covered in detail',
              subItems: [],
            },
            {
              listItem: 'Course material will be provided',
              subItems: [],
            },
            {
              listItem:
                'Special topics covered: Male/Female infertility, Couples counselling, Cancer, Substance abuse',
              subItems: [],
            },
            {
              listItem:
                'An exam will be conducted at the conclusion of the Yoga Therapy Course',
              subItems: [],
            },
            {
              listItem:
                'An opportunity to intern at the signature program conducted by The Yoga Institute called “Samatvam” where patients walk in to get guidance on resolving some of their chronic issues using the holistic approach taken by yoga. This is a 12 week (1 hour per week) as certified internship possible to be taken up only by Yoga Therapy pass outs.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Recording Policy of TYI',
          text: [
            'The Yoga Institute retains all rights to record the live interactive yoga sessions for their internal usage & quality purposes. These recordings are strictly for the institute and the data is stored securely in a manner that reasonably protects it from misuse and loss and from unauthorized access, modification or disclosure. Yoga Students are prohibited from recording these live sessions. We will not share any recordings with the students.'
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'SYSTEMS COVERED:',
          points: [
            {
              listItem: ' General disorders',
              subItems: ['Anaemia', ' Insomnia', ' Vertigo'],
            },
            {
              listItem: 'Gastro intestinal (GI) tract problems',
              subItems: [
                'Constipation',
                'Irritable bowel syndrome (IBS)',
                'Flatulence and bloating',
                'Haemorrhoids (piles) and anal fissures',
                'Perianal abscess and Anal Fistula',
                'Pilonidal Sinus',
                'Acidity and GERD',
                'Migraine',
                'Fatty Liver',
                'Inflammatory Bowel Disease (IBD)',
                'Ulcerative Colitis',
                'Crohn’s Disease',
                'Diverticulitis'
              ],
            },
            // {
            //   listItem: 'Inflammatory Bowel Disease (IBD)',
            //   subItems: [
            //     'Ulcerative Colitis',
            //     'Crohn’s Disease',
            //   ],
            // },
            {
              listItem: 'Respiratory problems',
              subItems: [
                'Common cold',
                'Respiratory allergies',
                'Pharyngitis',
                'Tonsillitis',
                'Laryngitis',
                'Sinustis',
                'Sleep apnoea',
                'Bronchitis',
                'Emphysema',
                'Chronic obstructive pulmonary disease (COPD)',
                'Asthma',
                'Tuberculosis (TB)',
              ],
            },
            {
              listItem: 'Circulatory system disorders',
              subItems: [
                'Low blood pressure',
                'High blood pressure',
                'Ventricular Septal Defect (VSD)',
                'Valvular Defects',
                'Pacemaker',
                'Atherosclerosis',
                'Angina',
                'Ischemic heart disease',
                'Varicose veins',
                'Deep Vein Thrombosis',
                'Stroke',
                'Facial paralysis',
              ],
            },
            {
              listItem: 'Diabetes',
              subItems: [],
            },
            {
              listItem: 'Thyroid',
              subItems: [
                'Hypothyroidism',
                'Hyperthyroidism',
                'Parathyroid Gland',
                'Hyperparathyroidism',
                'Hypoparathyroidism'
              ],
            },
            // {
            //   listItem: 'Parathyroid Gland',
            //   subItems: [
            //     'Hyperparathyroidism',
            //     'Hypoparathyroidism',
            //   ],
            // },
            {
              listItem: 'Weight Management',
              subItems: [
                'Obesity',
              ],
            },
            {
              listItem: 'Eating Disorders',
              subItems: [
                'Anorexia Nervosa',
                'Binge Eating Disorder',
                'Bulimia Nervosa',
                'Orthorexia Nervosa'
              ],
            },
            {
              listItem: 'Urinary Tract Infection',
              subItems: [],
            },
            {
              listItem: 'Female Reproductive System',
              subItems: [
                'Dysmenorrhea',
                'PMT (Premenstrual Tension) Or PMS (Premenstrual Syndrome)',
                'PCOS',
                'Fibroids',
                'Menopause',
                'Uterine Prolapse'
              ],
            },
            {
              listItem: 'Pregnancy',
              subItems: [],
            },
            {
              listItem: 'Infertility',
              subItems: [
                'Male Infertility',
                'Female Infertility',
                'Counselling For Couples',
              ],
            },
            {
              listItem: 'Orthopaedic problems',
              subItems: [
                'General management',
                'Sprain/Strain',
                'Fascia and Myofacial Pain',
                'Fibromyalgia',
                'Hernia',
                'Cervical Spondylitis',
                'Low Back Pain',
                'Lumbar Disc Prolapse (slipped Disc)',
                'Lumbar Canal Stenosis',
                'Lumbar Spondylosis (Osteoarthritis of Spine)',
                'Sciatica',
                'Piriformis Syndrome',
                'Coccydenia',
                'Osteoporosis',
                'Osteoarthritis',
                'Gout',
                'Tennis Elbow/Lateral Epicondylitis',
                'Frozen Shoulder',
                'Kyphosis',
              ],
            },
            {
              listItem: 'Autoimmune disorder affecting the joints:',
              subItems: ['Rheumatoid arthritis', 'Ankylosing spondylitis', 'Lupus'],
            },
            {
              listItem: 'Skin disorders',
              subItems: [
                'Acne',
                'Lichen planus',
                'Eczema',
                'Psoriasis',
                'Peeling skin syndrome',
                'Vitiligo',
              ],
            },
            {
              listItem: 'Psychological and psychiatric disorders',
              subItems: [
                'Hormones/Chemicals Behind Our Moods',
                'Happy Hormones',
                'Stress Hormones',
                'Stress Management',
                'Depression',
                'Anxiety Disorders',
                'Phobias and Panic Disorders',
                'Post-Traumatic Stress Disorder',
                'Prolonged Grief Disorder',
                'Substance Abuse/Addiction',
              ],
            },
            {
              listItem: 'Nervous system disorders',
              subItems: [
                'Obsessive Compulsive Disorder',
                'Bipolar disorder',
                'Schizophrenia',
                'Dementia',
                'Alzheimer’s disease',
                'Epilepsy',
                'Parkinson’s disease',
              ],
            },
            {
              listItem: 'Geriatric Care',
              subItems: [],
            },
            {
              listItem: 'Cancer',
              subItems: ['Oral cancer', 'Cervical cancer', 'Ovarian Cancer', 'Breast cancer'],
            },
            {
              listItem: 'Prostate enlargement',
              subItems: [],
            },
          ],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: '',
      //     text: [' access the detailed syllabus here:'],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Conducted by:',
          text: ['Dr. Taruna Kardak, Homoeopath and Senior Yoga Teacher'],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Eligibility:',
          points: [
            {
              listItem: 'Students who have completed 500/900 hrs TTC from The Yoga Institute',
              subItems: [],
            },
            {
              listItem: 'Medical Doctors who have completed any TTC (200/500/900 hrs) from The Yoga Institute.',
              subItems: [],
            },
            {
              listItem: 'Students who have completed YCB Level 2 or 3.',
              subItems: [],
            },
            {
              listItem: 'Students who have completed minimum 500 hrs TTC from any institution, can apply to info@theyogainstitute.org with their certificates & course curriculum for review.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Duration:',
          text: [' 4 months '],
        },
      },
      // {
      //   type: 'paragraph',
      //   content: {
      //     title: 'Date:',
      //     text: [' 24th Aug - 16th Dec 2022'],
      //   },
      // },
      {
        type: 'paragraph',
        content: {
          title: 'Timings:',
          text: ['Monday, Wednesday, Friday – 5:30 pm to 7:30 pm (IST)'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Fees:',
          text: [' Rs. 38,500/-'],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Note:',
          points: [
            {
              listItem: 'Please note only 30 percent of the sessions will be on campus( for those opting for on campus class)',
              subItems: [],
            },
            {
              listItem: 'The fees once paid will not be refundable.',
              subItems: [],
            },
            {
              listItem: 'All study material available for the students for any course in physical (hard copy) only. The same can be collected by the student directly from the Institute or can be delivered to them by courier, charges of which will have to be borne by the student.',
              subItems: [],
            },
          ],
        },
      },
    ],
  },
  6: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Restorative Yoga for Cancer Survivors - Online Program',
    key: 'restorative-yoga-for-cancer-survivors',
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: 'RESTORATIVE_YOGA_FOR_CANCER_PROGRAM_COFIRMATION_MAIL',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${courseAssets.courseAsset117}`,
    cardImage: `${baseDomain}${courseAssets.courseAsset117}`,
    metaDescription:
      'Surviving to Thriving: Embracing Life After Cancer',
    mostPopular: false,
    rating: 5,
    colorCode: '#C48E8C',
    timing: 'Wed, Fri, Sat 11.30 a.m. to 12.30 p.m.',
    fees: {
      onlineFee: 27000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'certificate-course',
    dates: [],
    online: true,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: true,
    details: [
      {
        type: 'paragraph',
        content: {
          title: 'About the Program',
          text: [
            'Restorative Yoga for Cancer is a specialized healing program that helps facilitate cancer recovery, support breast, ovaries and prostate health, reduce cancer related fatigue and manage lymphedema. This program takes into consideration the psychological effects as well as all the emotional upheavals associated with cancer.',
            'This is a new system of supported, gentle “flowing” Yoga that can be easily practiced at home. Sequencing the breath and Yoga pose is vital for overall physical and emotional recovery. These specialized Yoga poses are meant to strengthen and open every part of the body. Not only will these pose stretch, lengthen muscles and breast tissue - they will also improve the circulation of lymph fluid and blood flow. The use of Yoga props is recommended and encouraged with each pose in order to receive maximum healing benefits. These poses tone the internal organs, glands and nerves. The results are measured in increased vitality, energy and hope, sense of balance and mental peace.',
          ],
        },
      },
      {
        type: 'u-list',
        content: {
          title: 'Program Highlights',
          points: [
            {
              listItem: 'Specific Yoga asanas addressing side effects of cancer treatments.',
              subItems: [],
            },
            {
              listItem: 'Pranayama practices to improve breathing as well as bringing mental calm and poise.',
              subItems: [],
            },
            {
              listItem: 'Meditative practices and Kriyas for cleansing.',
              subItems: [],
            },
            {
              listItem: 'You will receive specialized training from highly experienced and certified yoga teachers who possess deep knowledge and empathy towards the physical, psychological, and emotional struggles faced by cancer survivors.',
              subItems: [],
            },
          ],
        },
      }, {
        type: 'u-list',
        content: {
          title: 'Program Benefits',
          points: [
            {
              listItem: 'The gentle and therapeutic movements will aid in restoring your body’s vitality and flexibility.',
              subItems: [],
            },
            {
              listItem: 'Improvements in your physical well-being and increased energy levels.',
              subItems: [],
            },
            {
              listItem: 'Pranayama practices can enhance your respiratory function, reduce anxiety, and bring a sense of calm and serenity to your mind.',
              subItems: [],
            },
            {
              listItem: 'Finding inner peace, releasing stress, and cultivating a positive mindset, empowering you to face the future with renewed optimism and resilience.',
              subItems: [],
            },
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Program Duration',
          text: ['3-Months Online Program (Total of 36 sessions)',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Timings',
          text: [],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Program Fees',
          text: ['1st 12 sessions: ₹12,000 (Learning major techniques)',
            'Next 12 sessions: ₹6,000 (Practicing)',
            'Next 12 sessions: ₹6,000 (Practicing)',
            'Cancer Kit: ₹3,000',
            'Total: ₹27,000'
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Admission Criteria',
          text: ['Open to breast, ovarian, prostate, and uterine cancer survivors who have completed surgery, chemotherapy, radiation therapy and other treatments.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Get in Touch',
          text: ['To learn more about our Program or to enroll, please contact on WhatsApp: Dharmesh Sukhadia – 9892550595, Puja Heliwal – 9911543557 or email us at info@theyogainstitute.org.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Medical Disclaimer',
          text: ['The programme will only aid in providing the best Yoga support to cancer survivors. It should not be a substitute to professional medical advice, diagnosis or treatment. A qualified healthcare professional should be consulted for medical advice and answers to personal health questions. This program is not an attempt to practice medicine. It is assumed that the participant has sought permission from their respective consulting oncologist for the practice of Yoga.'],
        },
      },
    ],
  },
}
const alibaug = {
  1: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Detox and Cleanse Yoga Retreat',
    key: 'detox-cleanse-yoga-retreat',
    isRegular: true,
    tenure: '2 day',
    weekends: false,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: '',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `/images/DetoxCleanse.webp`,
    cardImage: `/images/DetoxCleanse.webp`,
    mostPopular: false,
    metaDescription:
      'Towards the end, there is an enlightening question and answer session. ',
    rating: 5,
    colorCode: '#B77E7E',
    timing: '1 Night, 2 Days',
    fees: {
      onlineFee: 10000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: ['14th Dec 2024 to 15th Dec 2024 '
    ],
    online: false,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,
    newName: '',
    enrollInfo: {
      duration: "1 Night, 2 Days",
      timings: [
        {
          day: "",
          time: "8:00 AM (14th) to 7:00 PM (15th)"
        }
      ]
    },
    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'The Yoga Institute’s Founder, Shri Yogendraji introduced Satsang with an objective for people to come together and find solutions for their worldly problems. It takes place on Sundays and is open to all so that everyone can benefit from the 1-hour session.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'The highlighted aspect of Satsang is a discourse by the Spiritual Guru and Director of TYI, Dr. Hansaji whoshares her enlightening wisdom on various topics including health &amp; wellness, relationships, career, food,parenting, family and so on. Through her compassionate and personalized approach, Dr. Hansaji has helped countless individuals overcome their challenges and find greater health and well-being.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'You can get your queries resolved by Dr. Hansaji as she is dedicated to helping you find the support and guidance you need to thrive. She helps people solve their problems by focusing on the root cause of their issues, rather than simply addressing symptoms. She understands that many physical and emotional problems are interconnected and that by addressing the underlying causes, individuals can experience long-term relief.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Yoga is a powerful tool for managing physical, mental and emotional challenges and helping individuals find greater peace and balance in their lives. Satsang helps bring positivity and awareness into our lives. Dr. Hansaji shares profound wisdom of the ancient scriptures and a Yogic way of living which enhances the lives of all the satsangis and is relevant in the present time and age.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Towards the end, there is an enlightening question and answer session.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'About the programme',
          text: [' Every Sunday between 9.30 am and 10.30am'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Prerequisites',
          text: ['The programme is open to all. No age bar.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Registration',
          text: [' free entry - on the spot registration.'],
        },
      },
    ],
  },
  2: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Deep Dive Yoga and Meditation Retreat',
    key: 'deep-dive-yoga-meditation-retreat',
    isRegular: true,
    tenure: '2 day',
    weekends: false,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: '',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `/images/DeepOneYoga.webp`,
    cardImage: `/images/DeepOneYoga.webp`,
    mostPopular: false,
    metaDescription:
      'Towards the end, there is an enlightening question and answer session. ',
    rating: 5,
    colorCode: '#B77E7E',
    timing: '1 Night, 2 Days',
    fees: {
      onlineFee: 10000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: ['21st Dec 2024 to 22nd Dec 2024'
    ],
    online: false,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,
    newName: '',
    enrollInfo: {
      duration: "1 Night, 2 Days",
      timings: [
        {
          day: "",
          time: "8:00 AM (21st) to 7:00 PM (22nd)"
        }
      ]
    },
    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'The Yoga Institute’s Founder, Shri Yogendraji introduced Satsang with an objective for people to come together and find solutions for their worldly problems. It takes place on Sundays and is open to all so that everyone can benefit from the 1-hour session.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'The highlighted aspect of Satsang is a discourse by the Spiritual Guru and Director of TYI, Dr. Hansaji whoshares her enlightening wisdom on various topics including health &amp; wellness, relationships, career, food,parenting, family and so on. Through her compassionate and personalized approach, Dr. Hansaji has helped countless individuals overcome their challenges and find greater health and well-being.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'You can get your queries resolved by Dr. Hansaji as she is dedicated to helping you find the support and guidance you need to thrive. She helps people solve their problems by focusing on the root cause of their issues, rather than simply addressing symptoms. She understands that many physical and emotional problems are interconnected and that by addressing the underlying causes, individuals can experience long-term relief.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Yoga is a powerful tool for managing physical, mental and emotional challenges and helping individuals find greater peace and balance in their lives. Satsang helps bring positivity and awareness into our lives. Dr. Hansaji shares profound wisdom of the ancient scriptures and a Yogic way of living which enhances the lives of all the satsangis and is relevant in the present time and age.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Towards the end, there is an enlightening question and answer session.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'About the programme',
          text: [' Every Sunday between 9.30 am and 10.30am'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Prerequisites',
          text: ['The programme is open to all. No age bar.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Registration',
          text: [' free entry - on the spot registration.'],
        },
      },
    ],
  },
  3: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Forest Yoga Retreat',
    key: 'forest-yoga-retreat',
    isRegular: true,
    tenure: '2 day',
    weekends: false,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: '',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: "/images/ForestYoga.webp",
    cardImage: "/images/ForestYoga.webp",
    mostPopular: false,
    metaDescription:
      'Towards the end, there is an enlightening question and answer session. ',
    rating: 5,
    colorCode: '#B77E7E',
    timing: '1 Night, 2 Days',
    fees: {
      onlineFee: 10000,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: [' 28th Dec 2024 to 29th Dec 2024'
    ],
    online: false,
    onCampus: false,
    nonResidential: false,
    residential: false,
    certficate: false,
    newName: '',
    enrollInfo: {
      duration: "1 Night, 2 Days",
      timings: [
        {
          day: "",
          time: "8:00 AM (28th) to 7:00 PM (29th)"
        }
      ]
    },
    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'The Yoga Institute’s Founder, Shri Yogendraji introduced Satsang with an objective for people to come together and find solutions for their worldly problems. It takes place on Sundays and is open to all so that everyone can benefit from the 1-hour session.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'The highlighted aspect of Satsang is a discourse by the Spiritual Guru and Director of TYI, Dr. Hansaji whoshares her enlightening wisdom on various topics including health &amp; wellness, relationships, career, food,parenting, family and so on. Through her compassionate and personalized approach, Dr. Hansaji has helped countless individuals overcome their challenges and find greater health and well-being.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'You can get your queries resolved by Dr. Hansaji as she is dedicated to helping you find the support and guidance you need to thrive. She helps people solve their problems by focusing on the root cause of their issues, rather than simply addressing symptoms. She understands that many physical and emotional problems are interconnected and that by addressing the underlying causes, individuals can experience long-term relief.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Yoga is a powerful tool for managing physical, mental and emotional challenges and helping individuals find greater peace and balance in their lives. Satsang helps bring positivity and awareness into our lives. Dr. Hansaji shares profound wisdom of the ancient scriptures and a Yogic way of living which enhances the lives of all the satsangis and is relevant in the present time and age.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Towards the end, there is an enlightening question and answer session.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'About the programme',
          text: [' Every Sunday between 9.30 am and 10.30am'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Prerequisites',
          text: ['The programme is open to all. No age bar.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: 'Registration',
          text: [' free entry - on the spot registration.'],
        },
      },
    ],
  }
}

const specialEvents = {
  1: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Full moon meditation',
    key: 'fullmoon-meditation',
    isRegular: false,
    tenure: '1 day',
    weekends: false,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: '',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${homeAssets.homeAsset14}`,
    cardImage: `${homeAssets.fullmoonCard}`,//${baseDomain}
    mostPopular: false,
    metaDescription:
      '',
    rating: 5,
    colorCode: '#DDAB58',
    timing: '',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: ['15th Dec 2024', '14th Jan 2025', '12th Feb 2025', '14th Mar 2025', '13th Apr 2025'
      , '11th Jun 2025'
    ],
    online: true,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,
    newName: '',
    enrollInfo: {
      duration: "1 Day",
      timings: [
      ]
    },
    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Looking to reign in your rampant thoughts and mood swings and train your mental faculties to be more efficient and peaceful? Then, join us every full moon day to sit under the celestial glow and do meditation.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Since the beginning of civilization humans have been fascinated with our nearest celestial neighbour – Moon. It has found its place in children’s stories, lovers’ stories, mystical arts, and much more. And it is not without reason. Moon has the power to influence our thoughts and our rhythms. Under its soothing and calming light ancient gurus sat down to meditate and mull over the intricacies of life and gave us philosophies that we follow even to this day.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'However, in the hustle-bustle of today’s life and because of rising city skylines, we seldom get exposed to this enriching source of energy. On every full moon day, we at the Yoga Institute sit under this divine light to meditate and find balance and alignment under the heaven’s celestial glow! Unburden yourself by joining us for practicing meditation on full moon nights and feel your stress and worries melting away under the soft cool night breeze. Let the moon help you relax and rejuvenate your mind and body.',
          ],
        },
      },

    ],
  },
  2: {
    id: '',
    textdescription6: '',
    textdescription7: '',
    textdescription8: '',
    textdescription9: '',
    textdescription10: '',
    textdescription1: '',
    textdescription2: '',
    textdescription3: '',
    textdescription4: '',
    textdescription5: '',
    title: 'Yoga By The Bay',
    key: 'yoga-by-the-bay',
    isRegular: false,
    tenure: '1 day',
    weekends: false,
    batch: 1,
    // templateId: 'GENERAL_AUTO_EMAIL',
    templateId: {
      templateOnline: '',
      templateOffline: {
        templateResidential: '',
        templateNonResidential: '',
      },
    },
    image: `${baseDomain}${homeAssets.homeAsset12}`,
    cardImage: `${baseDomain}${homeAssets.homeAsset12}`,
    mostPopular: false,
    metaDescription:
      '',
    rating: 5,
    colorCode: '#DDAB58',
    timing: '',
    fees: {
      onlineFee: 0,
      offlineFee: { residentialFee: 0, nonResidentialFee: 0 },
    },
    category: 'classes',
    dates: ['29th Dec 2024', '26th Jan 2025', '23rd Feb 2025', '30th Mar 2025', '27th Apr 2025'
      , '25th May 2025', '29th Jun 2025', '27th Jul 2025', '31st Aug 2025', '28th Sep 2025', '26th Oct 2025'
      , '30th Nov 2025', '28th Dec 2025'
    ],
    online: true,
    onCampus: true,
    nonResidential: true,
    residential: false,
    certficate: false,
    newName: '',
    enrollInfo: {
      duration: "1 Day",
      timings: [
      ]
    },
    details: [
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Meditating alone is beneficial but meditating and practicing yoga as part of a group can impress upon the psyche in ways unimaginable. Join us to be part of this profound experience called Yoga by the Bay.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Yoga and the sea are two things that bring immense joy, calm, and relaxation to the human body. Imagine the effect and the impact two of them will have when they come together. Yoga by the Bay is one such initiative where we practice the yogic way of life immersed in the mesmerising symphony of the crashing sea waves, chirping birds, and whistling winds.'],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Come out with us and give your practice a new dimension. Let your being become one with the vastness of the divine. Experience true peace in the throbbing heart of Mumbai along with hundreds of seekers.',
          ],
        },
      },
      {
        type: 'paragraph',
        content: {
          title: '',
          text: [
            'Meet us opposite the ‘Pizza by the Bay – Marine Lines’ restaurant and be part of this profound experience.',
          ],
        },
      },

    ],
  }
}

export const c200hr = Object.values(ttc200hr)
export const c500hr = Object.values(ttc500hr)
export const c900hr = Object.values(ttc900hr)
export const campsArr = Object.values(camps)
export const classesArr = Object.values(classes)
export const extraArr = Object.values(extra)
export const certificateArr = Object.values(certificate)
export const alibaugArr = Object.values(alibaug)
export const sepecialEventArr = Object.values(specialEvents)
export const AllCourses = [
  ...certificateArr,
  ...extraArr,
  ...c200hr,
  ...c500hr,
  ...c900hr,
  ...campsArr,
  ...classesArr,
  ...sepecialEventArr
]
export const mostPopular = [
  extraArr[0], c200hr[4], c500hr[0],
  ...certificateArr,
  extraArr.shift(),
  ...c200hr,
  c200hr.slice(0, 4),  // Get elements before index 4
  c200hr.slice(5),
  ...c900hr,
  ...campsArr,
  ...classesArr,
]